export const NEW_QUESTION_REQUEST = 'NEW_QUESTION_REQUEST';
export const NEW_QUESTION_SUCCESS = 'NEW_QUESTION_SUCCESS';
export const QUESTION_UPLOAD_PROGRESS = 'QUESTION_UPLOAD_PROGRESS';
export const NEW_QUESTION_RESET = 'NEW_QUESTION_RESET';
export const NEW_QUESTION_FAIL = 'NEW_QUESTION_FAIL';

export const ALL_QUESTIONS_REQUEST = 'ALL_QUESTIONS_REQUEST';
export const ALL_QUESTIONS_SUCCESS = 'ALL_QUESTIONS_SUCCESS';
export const ALL_QUESTIONS_RESET = 'ALL_QUESTIONS_RESET';
export const ALL_QUESTIONS_FAIL = 'ALL_QUESTIONS_FAIL';

export const QUESTION_DETAILS_REQUEST = 'QUESTION_DETAILS_REQUEST';
export const QUESTION_DETAILS_SUCCESS = 'QUESTION_DETAILS_SUCCESS';
export const QUESTION_DETAILS_RESET = 'QUESTION_DETAILS_RESET';
export const QUESTION_DETAILS_FAIL = 'QUESTION_DETAILS_FAIL';

export const MY_QUESTIONS_REQUEST = 'MY_QUESTIONS_REQUEST';
export const MY_QUESTIONS_SUCCESS = 'MY_QUESTIONS_SUCCESS';
export const MY_QUESTIONS_FAIL = 'MY_QUESTIONS_FAIL';

export const RATE_QUESTION_REQUEST = 'RATE_QUESTION_REQUEST';
export const RATE_QUESTION_SUCCESS = 'RATE_QUESTION_SUCCESS';
export const RATE_QUESTION_RESET = 'RATE_QUESTION_RESET';
export const RATE_QUESTION_FAIL = 'RATE_QUESTION_FAIL';

export const EDIT_QUESTION_REQUEST = 'EDIT_QUESTION_REQUEST';
export const EDIT_QUESTION_SUCCESS = 'EDIT_QUESTION_SUCCESS';
export const EDIT_QUESTION_RESET = 'EDIT_QUESTION_RESET';
export const EDIT_QUESTION_FAIL = 'EDIT_QUESTION_FAIL';

export const DELETE_QUESTION_REQUEST = 'DELETE_QUESTION_REQUEST';
export const DELETE_QUESTION_SUCCESS = 'DELETE_QUESTION_SUCCESS';
export const DELETE_QUESTION_RESET = 'DELETE_QUESTION_RESET';
export const DELETE_QUESTION_FAIL = 'DELETE_QUESTION_FAIL';

export const CLEAR_ERRORS = 'CLEAR_ERRORS'