import React, { useEffect, useRef, useState } from 'react';
import { BiMinus, BiPlus } from 'react-icons/bi';
import { MdArrowBackIos, MdOutlineAddCircleOutline } from 'react-icons/md';
import { RiDeleteBin6Line } from 'react-icons/ri';
import { TbPencil } from 'react-icons/tb';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams, useLocation, Link } from 'react-router-dom';
import { ADD_PROMPTER_RESET } from '../../constants/activityConstants';
import { addActivityKeywords, getActivityKeywords } from '../../actions/activityActions';
import ReactGA from 'react-ga4';
import { Toaster, toast } from 'react-hot-toast';
import CustomToast from '../popovers/CustomToast';

const Prompter = () => {
    const [inputFields, setInputFields] = useState([{ keyword: '' }]);
    const [wordsNumber, setWordsNumber] = useState(1);
    const [newWord, setNewWord] = useState('');
    const [editKeyword, setEditKeyword] = useState(0);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const inputRef = useRef(null);
    const { id, activityId } = useParams();

    const { addedPrompter, keywords, activityWordsNumber } = useSelector(state => state.activity);

    useEffect(() => {
        function handleResize() {
            if (window.innerWidth >= 768) { 
                navigate(`/group/${id}`);
            }
        }
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        if (editKeyword !== null) {
            inputRef?.current?.focus();
        }
    }, [editKeyword]);

    useEffect(() => {
        dispatch(getActivityKeywords(activityId));
        if (addedPrompter) {
            dispatch({ type: ADD_PROMPTER_RESET });
            navigate(`/${id}/${activityId}/update`);
        }
    }, [addedPrompter]);

    useEffect(() => {
        if (keywords && keywords?.length) {
            setInputFields(keywords);
        } else {
            setInputFields([{ keyword: '' }]);
        }
        if (Number(activityWordsNumber?.wordsNumber) > 0) {
            setWordsNumber(activityWordsNumber?.wordsNumber);
        } else {
            setWordsNumber(1);
        }
    }, [keywords, activityWordsNumber]);

    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: (location.pathname + location.search), title: "Prompter" });
    }, [location]);

    const showToast = (info) => {
        toast.custom(<CustomToast info={info} />, {
            duration: 3000,
        });
    };

    const addFields = (e) => {
        e.preventDefault();
        if (!newWord) {
            return;
        }
        let newfield = { keyword: newWord };
        setInputFields([...inputFields, newfield]);
        setNewWord('');
    };

    const removeFields = (index) => {
        let data = [...inputFields];
        data.splice(index, 1);
        setInputFields(data);
    };

    const handleFormChange = (index, event) => {
        let data = [...inputFields];
        data[index][event.target.name] = event.target.value;
        setInputFields(data);
    };

    const handleSubmit = () => {
        if (!(inputFields.every(item => item.keyword))) {
            showToast("Please fill in all the blanks!");
            return;
        }
        if (wordsNumber > inputFields?.length) {
            showToast("The number entered here must be less than or equal to the total number of words.");
            return
        }
        const uniqueValues = new Set(inputFields.map(v => v.keyword));
        if (uniqueValues.size < inputFields.length) {
            // Duplicates found
            showToast("Duplicate keywords exist!");
            return;
        }
        const keywords = inputFields;
        dispatch(addActivityKeywords(activityId, wordsNumber, keywords));
    }
    
  return (
    <div>
        <div className='h-[58px] flex justify-between items-center p-3 border-b border-gray-300'>
            <MdArrowBackIos onClick={() => navigate(`/${id}/${activityId}/update`)} />
            <p className='createGroupLabel'>Prompter Setting</p>
            <p></p>
        </div>
        <Toaster position="bottom-center" />
        <div className='w-full	py-4'>
            <p className='activityTypeCinfo px-4 pb-4'>
                Please add all keywords to be used as a prompter.
            </p>
            <div className='pb-4 border-t border-[#CBD2E080]'>
                {inputFields.map((input, index) =>
                    <div key={index} className='w-full flex flex-row py-3 border-b border-[#CBD2E080] px-4'>
                        {editKeyword !== index ? 
                        <p className='w-3/4 prompterInputLabels' translate="no">{input.keyword}</p>:
                        <input translate="no" className='w-3/4 border-b border-[#2d3648b3] outline-none prompterInputLabels' name='keyword' 
                            value={input.keyword} 
                            onChange={(event) => handleFormChange(index, event)}
                            ref={inputRef} />}
                        <div className='w-1/4 flex flex-row'>
                            <TbPencil className='w-1/2' onClick={() => setEditKeyword(index)} />
                            <RiDeleteBin6Line className='w-1/2' onClick={() => {removeFields(index); showToast('You have deleted a keyword!');}} />
                        </div>
                    </div>
                )}
            </div>
            <div className="flex w-full py-4 bg-[#CBD2E0] mb-4 cursor-pointer">
                <form onSubmit={addFields} className='px-4 w-full flex justify-between gap-2'>
                    <button><MdOutlineAddCircleOutline type='submit' /></button>
                    <input className='w-full createFormLabels outline-none bg-[#CBD2E0]' value={newWord} onChange={(e) => setNewWord(e.target.value)} placeholder='Add Prompter Keyword' />
                </form>
            </div>

            <div className='flex flex-row gap-4 justify-center mb-4'>
                <div className='bg-[#E1E5ED] rounded-xl p-2'>
                    <p className='pb-3 prompterCardInfo'>You've Made</p>
                    <div className='flex flex-row'>
                        <p className='w-1/2'>{inputFields.length}</p>
                        <p className='w-1/2 prompterCardInfo'>words</p>
                    </div>
                </div>

                <div className='bg-[#E1E5ED] rounded-xl p-2 w-[198px] flex items-center flex-col'>
                    <p className='prompterCardInfo'>Number of words at a time</p>
                    <div className='w-[130px] flex flex-row justify-between rounded-[41px] bg-white items-center px-2 py-1'>
                        <div onClick={() => (wordsNumber > 0 && setWordsNumber(wordsNumber - 1))} className={`w-[24px] h-[24px] rounded-full ${wordsNumber === 0 ? "bg-[#E0E5F1]" : "bg-[#7C8494]"} flex justify-center items-center`}>
                            <BiMinus className='text-white' />
                        </div>
                        <p>{wordsNumber}</p>
                        <div onClick={() => {if (wordsNumber === inputFields?.length) {
                            showToast("The number entered here must be less than or equal to the total number of words.");
                            return
                        } setWordsNumber(wordsNumber + 1)}} 
                        className={`w-[24px] h-[24px] rounded-full ${wordsNumber === inputFields?.length ? "bg-[#E0E5F1]" : "bg-[#7C8494]"} flex justify-center items-center`}>
                            <BiPlus className='text-white' />
                        </div>
                    </div>
                </div>
            </div>
            
            <div className="mb-4 px-4 flex flex-col w-full items-center">
                <button className='w-[238px] h-10 mb-3 bg-[#CBD2E0] rounded-[20px]' onClick={handleSubmit}>
                    Save
                </button>
                <Link to={`/${id}/${activityId}/update`} className='flex justify-center items-center w-[238px] h-10 border rounded-[20px]'>Cancel</Link>
            </div>
        </div>
    </div>
  )
}

export default Prompter