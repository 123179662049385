import React, { Fragment } from 'react'
import { Routes, Route, Navigate, Outlet, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'

const AdminRoute = ({ allowedRoles }) => {
  
    const location = useLocation();

    const { isAuthenticated, loading, user } = useSelector(state => state.auth);

    return (
        <Fragment>
            {allowedRoles.includes(user?.roleId) ? <Outlet /> : <Navigate to="/admin/login" state={{ from: location }} replace />}
            
        </Fragment>
    )

}

export default AdminRoute