import React, { useState, useEffect } from 'react';

export const LayoutContext = React.createContext();

function GroupLayout(WrappedComponent) {
    const EnhancedComponent = (props) => {
        const [isMobile, setIsMobile] = useState(false);

        useEffect(() => {
            const handleResize = () => {
                setIsMobile(window.innerWidth < 768);
            };

            handleResize();
            window.addEventListener('resize', handleResize);

            return () => {
                window.removeEventListener('resize', handleResize);
            };
        }, []);

        const toggleLayout = () => {
            setIsMobile((prevState) => !prevState);
        };

        return (
            <LayoutContext.Provider value={{ isMobile, toggleLayout }}>
                <WrappedComponent {...props} />
            </LayoutContext.Provider>
        );
    };

    return EnhancedComponent;
}

export default GroupLayout;