import { ALL_QUESTIONS_FAIL, ALL_QUESTIONS_REQUEST, ALL_QUESTIONS_RESET, ALL_QUESTIONS_SUCCESS, CLEAR_ERRORS, DELETE_QUESTION_FAIL, DELETE_QUESTION_REQUEST, DELETE_QUESTION_RESET, DELETE_QUESTION_SUCCESS, EDIT_QUESTION_FAIL, EDIT_QUESTION_REQUEST, EDIT_QUESTION_RESET, EDIT_QUESTION_SUCCESS, MY_QUESTIONS_FAIL, MY_QUESTIONS_REQUEST, MY_QUESTIONS_SUCCESS, NEW_QUESTION_FAIL, NEW_QUESTION_REQUEST, NEW_QUESTION_RESET, NEW_QUESTION_SUCCESS, QUESTION_DETAILS_FAIL, QUESTION_DETAILS_REQUEST, QUESTION_DETAILS_RESET, QUESTION_DETAILS_SUCCESS, QUESTION_UPLOAD_PROGRESS, RATE_QUESTION_FAIL, RATE_QUESTION_REQUEST, RATE_QUESTION_RESET, RATE_QUESTION_SUCCESS } from "../constants/questionConstants"

export const questionsReducer = (state = { questions: [], mobileQuestions: [], activities: [] }, action) => {
    switch (action.type) {
        case ALL_QUESTIONS_REQUEST:
            return {
                ...state,
                // questions: [],
                mobileQuestions: [],
                loading: true
            }

        case ALL_QUESTIONS_SUCCESS:
            return {
                ...state,
                loading: false,
                questions: action.payload.questions,
                mobileQuestions: action.payload.questions,
                responses: action.payload.responses
            }
        
        case ALL_QUESTIONS_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload
            }

        case ALL_QUESTIONS_RESET:
            return {
                ...state,
                loading: false,
                questions: [],
                mobileQuestions: [],
                responses: [],
            }

        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }
    
        default:
            return state;
    }
}

export const newQuestionReducer = (state = {}, action ) => {
    switch (action.type) {
        case NEW_QUESTION_REQUEST:
        case RATE_QUESTION_REQUEST:
        case EDIT_QUESTION_REQUEST:
        case DELETE_QUESTION_REQUEST:
            return {
                loading: true,
                error: null
            }

        case QUESTION_UPLOAD_PROGRESS:
            return {
                ...state,
                progress: action.payload,
            }

        case NEW_QUESTION_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                success: action.payload.success,
                question: action.payload.createQuestion
            }

        case EDIT_QUESTION_SUCCESS:
            return {
                ...state,
                loading: false,
                editedQuestion: action.payload.success,
            }

        case DELETE_QUESTION_SUCCESS:
            return {
                ...state,
                loading: false,
                deletedQuestion: action.payload.success,
            }

        case RATE_QUESTION_SUCCESS:
            return {
                loading: false,
                ratedQuiz: action.payload.ratedQuiz,
            }

        case NEW_QUESTION_FAIL:
        case RATE_QUESTION_FAIL:
        case EDIT_QUESTION_FAIL:
        case DELETE_QUESTION_FAIL:
            return {
                loading: false,
                error: action.payload
            }

        case NEW_QUESTION_RESET:
            return {
                loading: false,
                success: false,
                question: false,
                error: null
            }

        case RATE_QUESTION_RESET:
            return {
                loading: false,
                ratedQuiz: false,
                error: null
            }

        case EDIT_QUESTION_RESET:
            return {
                loading: false,
                editedQuestion: false,
            }

        case DELETE_QUESTION_RESET:
            return {
                loading: false,
                deletedQuestion: false,
            }

        case CLEAR_ERRORS:
            return {
                loading: false,
                error: null
            }
    
        default:
            return state
    }
}

export const questionDetailsReducer = (state = {question: {}, comments: [], responseChoices: [] }, action ) => {
    switch (action.type) {
        case QUESTION_DETAILS_REQUEST:
            return {
                ...state,
                loading: true,
                // question: {}, 
                // comments: []
            }

        case QUESTION_DETAILS_SUCCESS:
            return {
                loading: false,
                question: action.payload.question,
                comments: action.payload.comments,
                responseChoices: action.payload.responseChoices
            }

        case QUESTION_DETAILS_FAIL:
            return {
                ...state,
                error: action.payload
            }

        case QUESTION_DETAILS_RESET:
            return {
                loading: false,
                question: {},
                comments: [],
                responseChoices: []
            }

        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }
    
        default:
            return state
    }
}

export const myQuestionsReducer = (state = { questions: [] }, action) => {
    switch (action.type) {
        case MY_QUESTIONS_REQUEST:
            return {
                loading: true,
                questions: []
            }

        case MY_QUESTIONS_SUCCESS:
            return {
                loading: false,
                questions: action.payload
            }
        
        case MY_QUESTIONS_FAIL:
            return {
                loading: false,
                error: action.payload
            }

        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }
    
        default:
            return state;
    }
}