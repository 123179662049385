import React, { useEffect } from 'react';
import Sidebar from './Sidebar';
import Default from '../../../assets/smilelogo.png';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { AiFillEdit } from 'react-icons/ai';
import { FiEye } from 'react-icons/fi'
import { useDispatch, useSelector } from 'react-redux';
import { getAllRoles } from '../../../actions/adminActions';
import { GET_PERMISSIONS_RESET } from '../../../constants/adminConstants';
import ReactGA from 'react-ga4';

const Roles = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const { roles } = useSelector(state => state.countStats);

    useEffect(() => {
        dispatch(getAllRoles());
        dispatch({ type: GET_PERMISSIONS_RESET });
    }, [dispatch]);

    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: (location.pathname + location.search), title: "Roles" });
    }, [location]);

  return (
    <div className="m-0 font-sans text-base antialiased font-normal leading-default text-slate-500">

        <main className="relative h-full max-h-screen transition-all duration-200 ease-in-out xl:ml-68 rounded-xl">
            <nav className="fixed top-0 flex flex-wrap items-center px-0 py-2 mx-6 transition-all ease-in shadow-none duration-250 rounded-2xl lg:flex-nowrap" navbar-main navbar-scroll="false">
                <div className="flex items-center w-full px-4 py-1 mx-auto flex-wrap-inherit">
                <nav>
                <Link to="/admin/dashboard">
                    <img className='w-12 flex items-center' src={Default} alt="Smile Logo" />
                </Link>
                </nav>
                </div>
            </nav>
                <div className='mt-[80px] ml-14 mb-10 md:ml-64 px-6 py-6 mx-auto'>
                    <div className='flex justify-between mb-5'>
                        <h4 className="mb-4 text-lg font-semibold text-gray-600">Roles</h4>
                        <button className="signinbtn" onClick={() => navigate('/admin/roles/create')}>Create Role</button>
                    </div>

                    <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
                        <table className="w-full text-sm text-left text-gray-500">
                            {/* <caption className="p-5 text-lg font-semibold text-left text-gray-900 bg-white dark:text-white dark:bg-gray-800">
                                Roles
                                <p className="mt-1 text-sm font-normal text-gray-500">Create and assign new roles to users in the system.</p>
                            </caption> */}
                            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700">
                                <tr>
                                    <th scope="col" className="px-6 py-3">
                                        Role name
                                    </th>
                                    <th scope="col" className="px-6 py-3">
                                        Actions
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {roles && roles.map((role, index) => (
                                    <tr key={index} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                        <th scope="row" className="px-6 py-4 font-medium text-gray-900 ">
                                            {role.name}
                                        </th>
                                        <td className="px-6 py-4">
                                            <FiEye className='w-4 h-4 cursor-pointer' onClick={() => navigate(`/admin/roles/${role.name}`)} />
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            <Sidebar />
        </main>
    </div>
  )
}

export default Roles