import React from 'react'
import { AiOutlineInfoCircle } from 'react-icons/ai'

const CustomToast = ({ info }) => {
  return (
    <div className='flex min-w-[300px] bg-[#F8F9FA] rounded-[10px]'>
        <div className='h-full w-[10px] py-6 rounded-l-[10px] left-0 bg-[#2F86E7]'></div>
        <div className='flex justify-between items-center w-full customToastLabel px-3'>
            <div className='flex flex-row justify-between gap-1 items-center'>
                <AiOutlineInfoCircle className='min-w-[16px] min-h-[16px] text-[#2F86E7]' />
                <div className=''>{info}</div>
            </div>
            {/* <div className='text-[#2F86E7]'>Undo</div> */}
        </div>
    </div>
  )
}

export default CustomToast