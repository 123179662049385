import React from 'react';

const QuestionConfirm = ({ setQuestionConfirm, deleteQuestionHandler }) => {
  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
        <div className="relative max-w-[300px] bg-[#F1F1F1] rounded-lg mx-2">
            <p className='px-4 pt-4 pb-6 confirmHeader'>Question deletion will also delete all ratings and answers. Are you sure you want to remove this question?</p>
            <div className="grid grid-cols-2 border-t-2 w-full">
                <div className='col-span-1 text-center border-r-2' onClick={() => {setQuestionConfirm(false);}}>
                    <div className=" text-blue-600 inline-flex items-center px-5 py-2.5 text-center">
                        Cancel
                    </div>
                </div>
                <div className='col-span-1 text-center' onClick={() => deleteQuestionHandler()}>
                    <div className="text-red-600 inline-flex px-5 py-2.5">
                        Delete
                    </div>
                </div>
            </div> 
        </div>
    </div>
  )
}

export default QuestionConfirm