import React, { useEffect, useState } from 'react';
import { FiDownload } from 'react-icons/fi';
import Default from '../../../assets/groupdefault.jpg';
import { AiOutlineStar } from 'react-icons/ai';
import { useDispatch, useSelector } from 'react-redux';
import { getGroupQuestions } from '../../../actions/questionActions';
import { useParams } from 'react-router-dom';
import { BsChatDots } from 'react-icons/bs';
import { BiStopwatch } from 'react-icons/bi';
import * as XLSX from 'xlsx';
import moment from 'moment';

const GroupReport = () => {
    const dispatch = useDispatch();
    let { groupId } = useParams();
    const [name, setName] = useState('all');
    const [activityName, setActivityName] = useState('all');

    const { groupQuestions, activities, groupUsers } = useSelector(state => state.groupResources);

    useEffect(() => {
        dispatch(getGroupQuestions(groupId, activityName, name));
    }, [dispatch, groupId, activityName, name]);

    const numberOfQuestions = groupQuestions?.length

    const downloadDetails = () => {
        const worksheet = XLSX.utils.json_to_sheet([]);
      
        const header = [
          'Creator',
          'Activity',
          'Content',
          'Ratings',
          'Response Count',
          'Used Time',
        ];
        XLSX.utils.sheet_add_aoa(worksheet, [header], { origin: 'A1' });
      
        const data = groupQuestions?.map((question) => [
            question?.creator?.firstName,
            question?.activity?.name,
            question?.content,
            question?.ratings,
            question?.responseCount,
            question?.questiontimes?.usedTime,
          ]);
        XLSX.utils.sheet_add_aoa(worksheet, data, { origin: 'A2' });
      
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Group Report');
      
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
      
        const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      
        const url = URL.createObjectURL(blob);
      
        const link = document.createElement('a');
        link.href = url;
        link.download = 'group_report.xlsx';
      
        link.click();
      
        URL.revokeObjectURL(url);
      };

  return (
    <div>
        <div className='flex flex-row justify-between'>
            <div className='flex flex-col'>
                <p>Name</p>
                <select onClick={(e) => setName(e.target.value)} className="rounded-full border border-gray-300 bg-white text-gray-700 h-7 pl-5 pr-10 py-0.5 text-sm">
                    <option value="all">All</option>
                    {groupUsers?.map((author, index) =>
                    <option key={index} value={author?.email} className="icon-option" data-icon="icon-2" translate="no">{author?.firstName}</option>)}
                </select>
            </div>
            <div className='flex flex-col'>
                <p>Activity</p>
                <select onClick={(e) => setActivityName(e.target.value)} className="rounded-full border border-gray-300 bg-white text-gray-700 h-7 pl-5 pr-10 py-0.5 text-sm">
                    <option value="all">All</option>
                    {activities?.map((activity, index) =>
                    <option key={index} value={activity?._id} className="icon-option" data-icon="icon-2" translate="no">{activity?.name}</option>)}
                </select>
            </div>
            <div className='flex flex-col'>
                <div className='flex justify-center items-center rounded-full w-7 h-7'></div>
                <div className={`flex justify-center items-center rounded-full w-7 h-7 bg-[#EAEBED]`}>
                    <FiDownload onClick={downloadDetails} className='text-black cursor-pointer' />
                </div>
            </div>
        </div>

        <div className='mt-6 pb-6 border-b border-gray-300'>
            <div className='flex justify-between'>
                <p className='questionsMainLabel'>Total <span className='bold'>{numberOfQuestions}</span> Question{numberOfQuestions === 1 ? '' : 's'}</p>
                <p className='questionReportsInfoLabel'>*The Reports are displayed only on the PC version.</p>
            </div>
        </div>

        <div className='flex flex-col'>
            {groupQuestions?.map((question, index) => 
            <div key={index} className='flex flex-col gap-2 py-4 border-b px-3 border-gray-300'>
                <div className='flex justify-between'>
                    <div className='flex flex-row gap-1'>
                        <img className="w-[20px] h-[20px] rounded-full" src={Default} alt='' />
                        <p className='reportAuthorLabel' translate="no">{question?.creator?.username ? question?.creator?.username : question?.creator?.firstName}</p>
                        <p className='reportActivityLabel'>|</p>
                        <p className='reportActivityLabel' translate="no">{question?.activity?.name}</p>
                    </div>
                    <div className='reportActivityLabel'>{moment(question?.createdAt).utc().format('MM-DD-YYYY')}</div>
                </div>
                <div className='reportQuestionLabel'>
                    <p translate="no">{question?.content}</p>
                </div>
                <div className='flex flex-row justify-end gap-2 reportActionsLabel'>
                    <div className='flex flex-row gap-1'>
                        <AiOutlineStar />
                        <p>{question?.ratings}</p>
                    </div>
                    <div className='flex flex-row gap-1'>
                        <BsChatDots />
                        <p>{question?.responseCount}</p>
                    </div>
                    <div className='flex flex-row gap-1'>
                        <BiStopwatch />
                        <p>{question?.questiontimes?.usedTime}</p>
                    </div>
                </div>
            </div>)}
        </div>
    </div>
  )
}

export default GroupReport