import React, { useEffect } from 'react'
import { FiSettings } from 'react-icons/fi';
import { MdOutlineArrowForwardIos } from 'react-icons/md';
import { RxCross2 } from 'react-icons/rx';
import { Link, useNavigate, useParams, useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';
import { useSelector } from 'react-redux';

const GroupSettings = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { id } = useParams();

    const { group } = useSelector(state => state.groupDetails);

    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: (location.pathname + location.search), title: "Settings" });
    }, [location]);

    useEffect(() => {
        function handleResize() {
            if (window.innerWidth >= 768) { 
                navigate(`/group/${id}`);
            }
        }
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

  return (
    <div className='block'>
        <div className={'flex justify-between w-full items-center py-4 px-3 border-b border-gray-300'}>
            <RxCross2 onClick={() => navigate(`/group/${id}`)} /> 
            <p>Group Settings</p>
            <p></p>
        </div>
        <div className={'flex gsnav md:border-p border-gray-300'}>
            <div className='gsnavcontent w-full'>
                <div className='gsnavcontentleft w-full'>
                    <FiSettings className='hidden md:flex settingsIcon' />
                    <p className='hidden md:flex gsnavlabel'>Group Settings</p>
                    <div className='gsnavlist relative w-full flex flex-col md:flex-row flex-wrap items-center justify-between'>
                        {(group?.role === 2 || group?.role === 4) &&
                        <Link to={`/${id}/settings/general`} className={"gsnavlistitem py-4 px-4 flex items-center justify-between border-b border-gray-300 w-full"}>
                            General
                            <MdOutlineArrowForwardIos />
                        </Link>}
                        <Link to={`/${id}/settings/members`} className={"gsnavlistitem py-4 px-4 flex items-center justify-between border-b border-gray-300 w-full"}>
                            Members
                            <MdOutlineArrowForwardIos />
                        </Link>
                        <Link to={`/${id}/settings/resources`} className={"gsnavlistitem py-4 px-4 flex items-center justify-between border-b border-gray-300 w-full"}>
                            Resources
                            <MdOutlineArrowForwardIos />
                        </Link>
                        <Link to={`/group/${id}`} className={"gsnavlistitem md:hidden py-4 px-4 flex items-center justify-between border-b border-gray-300 w-full"}>
                            Activities
                            <MdOutlineArrowForwardIos />
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default GroupSettings