import React, { useEffect, useState } from 'react'
import { FaUserEdit } from 'react-icons/fa'
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { changeRole, getGroupMembers, removeUserGroup } from '../../../actions/groupActions';
import { useDispatch, useSelector } from 'react-redux';
import { CHANGE_ROLE_RESET, REMOVE_UGROUP_RESET } from '../../../constants/groupConstants';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { RoleMapper } from '../../helpers/Mappers';
import MembersPop from '../../popovers/MembersPop';
import { useNavigate } from 'react-router-dom';

const Members = ({ members, groupId }) => {

    const [showModal, setShowModal] = useState(false);
    const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { user } = useSelector(state => state.auth);
    const { updatedRole, removedUser, loading } = useSelector(state => state.group);
    const { group } = useSelector(state => state.groupDetails);

    const showToastMessage = (message) => {
        toast.success(message, {
            position: toast.POSITION.TOP_RIGHT
        });
    };

  useEffect(() => {

    if (updatedRole) {
        showToastMessage("Changed Role successfully!");
        setShowModal(false);
        dispatch({ type: CHANGE_ROLE_RESET });
        dispatch(getGroupMembers(groupId));
    }
    }, [updatedRole]);

    useEffect(() => {

        if (removedUser) {
            showToastMessage("Removed User successfully!");
            setShowModal(false);
            dispatch({ type: REMOVE_UGROUP_RESET });
            group?.role === 3 ? navigate("/groups") : dispatch(getGroupMembers(groupId));
        }
    }, [removedUser]);

    useEffect(() => {
        selectedCheckboxes?.length ? setShowModal(true) : setShowModal(false);
    }, [selectedCheckboxes]);

    const userRoleMapper = (idX) => {
        return RoleMapper.find(i => i?.key === (idX))?.value
    };

    const handleCheckboxChange = (event) => {
        const value = event.target.value;
        if (event.target.checked) {
            setSelectedCheckboxes([...selectedCheckboxes, value]);
        } else {
            setSelectedCheckboxes(selectedCheckboxes.filter((v) => v !== value));
        }
    };

    const handleSelectAll = (event) => {
        if (event.target.checked) {
            const allValues = members?.filter((checkbox) => checkbox.role !== 2)?.map((checkbox) => checkbox.user._id);
            setSelectedCheckboxes(allValues);
        } else {
            setSelectedCheckboxes([]);
        }
    };

    const handleRemoveFromGroup = () => {
        const group = groupId;
        const users = selectedCheckboxes;
        dispatch(removeUserGroup(users, group))
    };

    const changeRoleHandler = (role) => {
        const group = groupId;
        const users = selectedCheckboxes;
        dispatch(changeRole(users, group, role));
    };

  return (
    <div className='memberrow w-full'>
        <div className='memberrowcontent w-full py-3 px-4'>
            <div className='flex flex-row gap-2'>
                {(group?.role === 2 || group?.role === 4) ?
                <input id="checkbox-all-search" type="checkbox" onChange={handleSelectAll} className="w-4 h-4 cursor-pointer text-blue-600 bg-gray-100 border-gray-300 rounded" /> :
                <div className='w-4 h-4'></div>}
                <p className='memberTitle'>Name</p>
            </div>
            <p className='memberTitle'>Role</p>
            <p className='memberTitle'>Login Id</p>
        </div>
        {members && (
            members?.map((member, index) =>
            <div className='memberrowcontent w-full py-3 px-4' key={index}>
                <div className="group relative inline-block">
                    <div className='flex flex-row gap-2'>
                        {group?.role === 3 && (user?._id === member?.user?._id ?
                        <input id="checkbox-all-search" type="checkbox" value={member.user._id} checked={selectedCheckboxes.includes(member.user._id)} onChange={handleCheckboxChange} className="w-4 h-4 cursor-pointer text-blue-600 bg-gray-100 border-gray-300 rounded" /> :
                        <div className='w-4 h-4'></div>)}
                        {(group?.role === 2 || group?.role === 4) && 
                        (member?.role === 2 ? <div className='w-4 h-4'></div> :
                        <input id="checkbox-all-search" type="checkbox" value={member.user._id} checked={selectedCheckboxes.includes(member.user._id)} onChange={handleCheckboxChange} className="w-4 h-4 cursor-pointer text-blue-600 bg-gray-100 border-gray-300 rounded" />)}
                        <p className='memberDescription cursor-pointer' translate="no">{member?.user?.firstName + " " + member?.user?.lastName}</p>
                    </div>
                </div>
                
                <p className='memberDescription'>{userRoleMapper(member.role)}</p>
                <div className="group relative inline-block">
                    <div className='flex flex-row'>
                    <p className='memberDescription cursor-pointer' translate="no">{member?.user?.firstName + " " + member?.user?.lastName}</p>
                    </div>
                </div>
            </div>
            )
        )}

        <MembersPop showModal={showModal} setShowModal={setShowModal} selectedCheckboxes={selectedCheckboxes} members={members} handleRemoveFromGroup={handleRemoveFromGroup} loading ={loading} changeRoleHandler={changeRoleHandler} />
    </div>
  )
}

export default Members