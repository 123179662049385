import React, { useEffect } from 'react'
import Navbar from './Navbar'
import dalle2 from '../../assets/dalle-research.jpeg';
import smilepdf1 from '../../assets/research/2.SMILE Jumpstart.pdf';
import smilepdf2 from '../../assets/research/3.Integrating_Technology_and_Pedagogy_for.pdf';
import smilepdf3 from '../../assets/research/4._India study (1).pdf';
import smilepdf4 from '../../assets/research/5.IJIIE 3(2-3) Paper 2.pdf';
import smilepdf5 from '../../assets/research/6.Paul Kim study2_Paper.pdf';
import smilepdf6 from '../../assets/research/7.action research.pdf';
import smilepdf7 from '../../assets/research/8.SMILE_Donggil_Math.pdf';
import smilepdf8 from '../../assets/research/1.New_Evaluation_Vector.pdf';
import ReactGA from 'react-ga4';
import { Link, useLocation } from 'react-router-dom';

const ResourceItem = ({ title, authors, pdfUrl }) => {
  return (
    <div>
      <p className="mb-0 text-xl px-9 pt-5 dark:text-gray-400"><a href={pdfUrl} className="text-black hover:text-[#8C1515]"target="_blank" rel="noreferrer">{title}</a></p>

      <p className="mb-3 text-gray-400 text px-9 pt-0">{authors}</p>
    </div>
  );
};

const Resources = () => {
    const location = useLocation();

    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: (location.pathname + location.search), title: "Resources" });
    }, [location]);

  return (
    <div style={{fontFamily: 'IBM Plex Sans',}}>
        <Navbar />

        <div
          className="bg-no-repeat bg-cover bg-center p-0 h-96"
          style={{
            backgroundImage: `url("${dalle2}")`,
          }}
        >
          <div className="bg-black bg-opacity-50 w-full h-full flex items-end justify-center">
            <p className="text-white text-4xl font-light p-8">Research & Publications</p>
          </div>
        </div>

        <div className="container mx-auto grid grid-cols-1 gap-4 sm:grid-cols-2">
            <ResourceItem title="SMILE Jumpstart" authors="Seeds of Empowerment" pdfUrl={smilepdf1} />
            <ResourceItem title="Preservice teachers’ uses of SMILE to enact studentgenerated questioning practices" authors="Hsu, H. Y., & Kim, P. (2016)" pdfUrl={smilepdf4} />
            <ResourceItem title="A comparative analysis of a game-based mobile learning model in low-socioeconomic communities of India" authors="Kim, P., Buckner, E., Kim, H., Makany, T., Taleja, N., & Parikh, V. (2012)" pdfUrl={smilepdf3} />
            <ResourceItem title="Effects of group reflection variations in project-based learning integrated in a Web 2.0 learning space" authors="Kim, P., Hong, J. S., Bonk, C., & Lim, G. (2011)" pdfUrl={smilepdf5} />
        </div>

        <div className="container mx-auto">
          <Link to={"/contactus"}
              className="block w-full px-4 py-2 my-5 text-center rounded-md shadow text-white bg-[#8C1515] hover:bg-gray-800">
              Create an account to view more publications
          </Link>
        </div>

        <div className="container mx-auto grid grid-cols-1 gap-6 sm:grid-cols-2">
            <ResourceItem title="Integrating Technology and Pedagogy for Inquiry Based Learning: The Stanford Mobile Inquiry-based Learning Environment (SMILE)" authors="Buckner, E., Kim, P. (2014)" pdfUrl={smilepdf2} />
            <ResourceItem title="Action research approach on mobile learning design for the underserved" authors="Kim, P. H. (2009)" pdfUrl={smilepdf6} />
            <ResourceItem title="Inquiry-based Learning Environment
Using Mobile Devices in Math Classroom" authors="Song, D., Kim, P., & Karimi, A. (2012)" pdfUrl={smilepdf7} />
            <ResourceItem title="New Evaluation Vector through the Stanford Mobile Inquiry-Based Learning Environment (SMILE) for Participatory Action Research" authors="Kim, P., & An, J. Y. (2016)" pdfUrl={smilepdf8} />
        </div>
    </div>
  )
}

export default Resources