import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { AiOutlineClose } from 'react-icons/ai';
import { BiCloudUpload } from 'react-icons/bi';
import { MdOutlineArrowBackIos } from 'react-icons/md'
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams, useLocation } from 'react-router-dom';
import download from 'downloadjs';
import moment from 'moment';
import { BsFileEarmark } from 'react-icons/bs';
import { getGroupResources, uploadResource } from '../../actions/groupActions';
import { UPLOAD_RESOURCE_RESET } from '../../constants/groupConstants';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactGA from 'react-ga4';

const GroupResources = () => {
    const { id } = useParams();
    const owningGroup = id;
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const { resources } = useSelector(state => state.groupResources);
    const { group } = useSelector(state => state.groupDetails);
    const { user } = useSelector(state => state.auth);
    const { updatedSettings, loading: sloading, uploadedResource, error: groupUploadError } = useSelector(state => state.group);
    const creator = user?._id;

    const [showUpload, setShowUpload] = useState(false);
    const [groupUploadErrorMsg, setGroupUploadErrorMsg] = useState(false);
    const [searchResourceQuery, setSearchResourceQuery] = useState('');
    const [doc, setdoc] = useState('');

    const showToastMessage = (message) => {
        toast.success(message, {
            position: toast.POSITION.TOP_RIGHT
        });
    };

    const downloadFile = async (id, doc, filemimetype) => {
        try {
          const result = await axios.get(`/api/group/resource/download/${id}`, {
            responseType: 'blob'
          });
          const split = doc.split('/');
          const filename = split[split.length - 1];
          return download(result.data, filename, filemimetype);
        } catch (error) {
          console.log(error)
        }
    };

    const handleUploadedFile = (e) => {
        setdoc(e.target.files[0]);
    };

    const upload = (e) => {
        e.preventDefault();
        const MAX_FILE_SIZE = 1024 * 1024 * 10;
        if(doc.size > MAX_FILE_SIZE){
            setGroupUploadErrorMsg("File size is greater than maximum limit");
            return
        }
        const group = owningGroup;
        dispatch(uploadResource(creator, group, doc));
    };

    useEffect (() => {
        dispatch(getGroupResources(owningGroup));

        if (uploadedResource) {
            showToastMessage("Resource uploaded successfully!");
            dispatch({ type: UPLOAD_RESOURCE_RESET });
            dispatch(getGroupResources(owningGroup));
            setShowUpload(false);
        }
    }, [dispatch, owningGroup, uploadedResource]);

    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: (location.pathname + location.search), title: "Group Resources" });
    }, [location]);

  return (
    <div className='block'>
        <div className={'flex justify-between w-full items-center py-4 px-3 border-b border-gray-300'}>
            <MdOutlineArrowBackIos onClick={() => navigate(`/${id}/settings`)} /> 
            <p>Resources</p>
            <p></p>
        </div>
        <div className="w-full mt-3 px-3">
            <div className='research w-full'>
                <input type='text' onChange={(e) => setSearchResourceQuery(e.target.value)} className='researchinput' />
                <Link to='' onClick={() => {setShowUpload(true); setGroupUploadErrorMsg(false);}} className={group?.role === 2 || group?.role === 4 ? "researchright" : "hidden"}>
                    <BiCloudUpload className='fileicon' />
                    <p className=''>Upload file</p>
                </Link>
            </div>
            {resources ? (
            resources?.filter((resource) => resource?.originalname.toLowerCase().includes(searchResourceQuery))?.map((resource, idx) =>
                <div className='rediv w-full' key={idx}>
                    <div className='redivrow w-full'>
                    <div className='redivcontent w-full mt-7'>
                        <div className='redivleft'>
                            <Link to='' onClick={() => {downloadFile(resource?._id, resource?.doc, resource?.file_mimetype)}}><BsFileEarmark className='fileicon'  /> </Link>
                            <p className='refilename'>{resource?.originalname}</p>
                        </div>
                        <p className='redate'>{moment(resource?.createdAt).utc().format('DD/MM/YY')}</p>
                    </div>
                    </div>
                </div>
                    )
            ) : null}
            {showUpload ? (
                <>
                <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                    <div className='joinpmodal w-[300px] md:w-[430px]'>
                        <div className='joinpmodalcontent p-4 w-full'>
                            <div className='joinpmodalheader w-full'>
                                <p className='joinpmodalheaderight'>Upload Resource</p>
                                <AiOutlineClose className='joinpmodalheaderleft cursor-pointer' onClick={() => setShowUpload(false)} />
                            </div>
                            <form encType="multipart/form-data" className='joinpmodalbody w-full' onSubmit={upload}>
                                <div className='joinpmodalbodydiv w-full'>
                                    <input type="file" id="doc" name="doc" onChange={handleUploadedFile} className='joinpmodalbodyinput' required />
                                    <p className='text-sm text-red-600'>{groupUploadErrorMsg}</p>
                                </div>
                                <button className='joinpmodalsubmit' type='submit'>Upload</button>
                            </form>
                        </div>
                    </div>
                </div>
                <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                </>
            ) : null}
        </div>
    </div>
  )
}

export default GroupResources