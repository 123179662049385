import React, { useEffect } from 'react';
import Sidebar from './Sidebar';
import Default from '../../../assets/smilelogo.png';
import { Link, useParams, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getCurrentLevelData, getPermissions } from '../../../actions/adminActions';
import ReactGA from 'react-ga4';

const ViewRole = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const { levelData, permissions } = useSelector(state => state.roles);
    const { role } = useParams();

    useEffect(() => {
        dispatch(getCurrentLevelData("Admin"));
        dispatch(getPermissions(role));
    }, [dispatch, role]);

    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: (location.pathname + location.search), title: "Role" });
    }, [location]);

  return (
    <div className="m-0 font-sans text-base antialiased font-normal leading-default text-slate-500">

        <main className="relative h-full max-h-screen transition-all duration-200 ease-in-out xl:ml-68 rounded-xl">
            <nav className="fixed top-0 flex flex-wrap items-center px-0 py-2 mx-6 transition-all ease-in shadow-none duration-250 rounded-2xl lg:flex-nowrap" navbar-main navbar-scroll="false">
                <div className="flex items-center w-full px-4 py-1 mx-auto flex-wrap-inherit">
                    <nav>
                    <Link to="/admin/dashboard">
                        <img className='w-12 flex items-center' src={Default} alt="Smile Logo" />
                    </Link>
                    </nav>
                </div>
            </nav>
                <div className='mt-[80px] ml-14 mb-10 md:ml-64 px-6 py-6 mx-auto'>
                    <h4 className="mb-4 text-lg font-semibold text-gray-600">Roles & Priviledges</h4>

                    <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
                        <table className="w-full text-sm text-left text-gray-500">
                            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                                <tr>
                                    <th scope="col" className="px-6 py-3">
                                        Modules
                                    </th>
                                    <th scope="col" className="px-6 py-3">
                                        Read
                                    </th>
                                    <th scope="col" className="px-6 py-3">
                                        Write
                                    </th>
                                    <th scope="col" className="px-6 py-3">
                                        Delete
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {levelData && levelData?.modules?.map((role, index) => (
                                    <tr key={index} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                        <th scope="row" className="px-6 py-4 font-medium text-gray-900 ">
                                            {role?.moduleId?.name}
                                        </th>
                                        <td className="px-6 py-4">
                                            {levelData?.selectablePermissions.find(element => element.name === (role.name + ".Read")) ?
                                            <input type='checkbox' key={role.name + ".Read"} checked={permissions?.find(ele => ele.levelModulePermissionId.name === (role.name + ".Read"))} /> : "n/a"}
                                        </td>
                                        <td className="px-6 py-4">
                                            {levelData?.selectablePermissions.find(element => element.name === (role.name + ".Write")) ?
                                            <input type='checkbox' key={role.name + ".Read"} checked={permissions?.find(element => element?.levelModulePermissionId?.name === (role.name + ".Write"))} /> : "n/a"}
                                        </td>
                                        <td className="px-6 py-4">
                                            {levelData?.selectablePermissions.find(element => element.name === (role.name + ".Delete")) ?
                                            <input type='checkbox' key={role.name + ".Read"} checked={permissions?.find(element => element?.levelModulePermissionId?.name === (role.name + ".Delete"))} /> : "n/a"}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            <Sidebar />
        </main>
    </div>
  )
}

export default ViewRole