import React, { useEffect } from 'react'
import Navbar from './Navbar'
import smile6 from '../../assets/smile6.jpeg';
import smile7 from '../../assets/smile7.jpeg';
import { Link, useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';

const Home = () => {
    const location = useLocation();

    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: (location.pathname + location.search), title: "Home" });
    }, [location]);

  return (
    <div style={{fontFamily: 'IBM Plex Sans',}}>
        <Navbar />

        <div
          className="bg-no-repeat bg-cover bg-center p-0 h-96"
          style={{
            backgroundImage: `url("${smile6}")`,
          }}
        >
          <div className="bg-black bg-opacity-50 w-full h-full flex items-end justify-center">
            <p className=" text-white text-4xl font-light p-8">Can you create a good question?</p>
          </div>
        </div>

        <section className="py-12 bg-gray-200">
          <div className="container mx-auto px-4">
            <div className="flex flex-col md:flex-row items-center">
              <div className="md:w-1/2">
                <h2 className="text-3xl font-bold mb-4">Learning happening anywhere, anytime</h2>
                <p className="text-lg mb-6">
                  SMILE (Stanford Mobile Inquiry-based Learning Environment) is a mobile learning management software and pedagogical model that promotes active learning, student engagement, and higher-order thinking skills.
                </p>
              </div>
              <div className="md:w-1/2 mt-8 md:mt-0 md:ml-6">
                <p className="text-lg mb-6">
                  With SMILE, students become active participants in their learning journey, asking questions, sharing knowledge, and evaluating their own progress. Teachers act as facilitators, providing guidance and support throughout the process.
                </p>
                <Link to="/login" className="inline-flex mr-5 items-center px-4 py-2 my-5 text-center rounded-md shadow text-white bg-[#8C1515] hover:bg-gray-800 font-semibold">
                    Sign in
                </Link>
                <Link to="/register" className="inline-flex items-center px-4 py-2 my-5 text-center rounded-md shadow bg-white hover:bg-gray-100">
                    Sign up for new account
                </Link>
              </div>
            </div>
          </div>
        </section>

        <section id="signup" className="bg-no-repeat bg-cover bg-center h-72"
            style={{
                backgroundImage: `url("${smile7}")`,
            }}>
          <div className="bg-black bg-opacity-50 w-full h-full flex items-end justify-center">
            <div className="container mx-auto pb-10 text-center">
              <h2 className="text-3xl font-bold text-white mb-4  justify-center">Try SMILE Today</h2>
              <p className="text-lg text-white mb-6">Sign up now and experience a whole new way to SMILE!</p>
            </div>
          </div>
        </section>

        <footer className="text-white bg-gray-800 py-4">
          <div className="container mx-auto px-4 text-center">
            <p className="text-sm">&copy; 2023 Seeds of Empowerment. All rights reserved. 
              <Link className="pl-2 text-gray-400 hover:text-white" to={"/smile/privacy"}>Privacy Policy</Link>
              <Link className="pl-2 text-gray-400 hover:text-white"  to={"/smile/terms"}>Terms of Use</Link>
            </p>
          </div>
        </footer>
    </div>
  )
}

export default Home