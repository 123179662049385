import axios from "axios"
import { CLEAR_ERRORS, FORGOT_PASSWORD_FAIL, FORGOT_PASSWORD_REQUEST, FORGOT_PASSWORD_SUCCESS, FORGOT_USERNAME_FAIL, FORGOT_USERNAME_REQUEST, FORGOT_USERNAME_SUCCESS, GOOGLE_LOGIN_FAIL, GOOGLE_LOGIN_REQUEST, GOOGLE_LOGIN_SUCCESS, LOAD_USER_FAIL, LOAD_USER_REQUEST, LOAD_USER_SUCCESS, LOGIN_FAIL, LOGIN_REQUEST, LOGIN_SUCCESS, LOGOUT_FAIL, LOGOUT_SUCCESS, NEW_PASSWORD_FAIL, NEW_PASSWORD_REQUEST, NEW_PASSWORD_SUCCESS, REGISTER_USER_FAIL, REGISTER_USER_REQUEST, REGISTER_USER_SUCCESS, UPDATE_PROFILE_FAIL, UPDATE_PROFILE_REQUEST, UPDATE_PROFILE_SUCCESS } from "../constants/userConstants"
import Cookies from "js-cookie";

export const login = (emailusername, password) => async (dispatch) => {
    try {
        dispatch({ type: LOGIN_REQUEST })
        const config = {
            headers: {
                'Content-Type' : 'application/json'
            }
        }
        const roleId = 1;
        const { data } = await axios.post('/api/auth/login', { emailusername, password, roleId }, config);
        // Cookies.remove("session");
        // Cookies.set('session', JSON.stringify(data), { expires: 1/48 });
        // sessionStorage.setItem('userInfo', JSON.stringify(data.user));
        dispatch({
            type: LOGIN_SUCCESS,
            payload: data
        })
    } catch (error) {
        dispatch({
            type: LOGIN_FAIL,
            payload: error.response.data.error
        })
    }
}

export const adminLogin = (emailusername, password) => async (dispatch) => {
    try {
        dispatch({ type: LOGIN_REQUEST })
        const config = {
            headers: {
                'Content-Type' : 'application/json'
            }
        }
        const roleId = 2;
        const { data } = await axios.post('/api/auth/login', { emailusername, password, roleId }, config);
        // Cookies.remove("session");
        // Cookies.set('session', JSON.stringify(data), { expires: 1/48 });
        dispatch({
            type: LOGIN_SUCCESS,
            payload: data
        })
    } catch (error) {
        dispatch({
            type: LOGIN_FAIL,
            payload: error.response.data.error
        })
    }
}


export const register = (userData) => async (dispatch) => {
    try {
        dispatch({ type: REGISTER_USER_REQUEST })
        const config = {
            headers: {
                'Content-Type' : 'multipart/form-data'
            }
        }
        const { data } = await axios.post('/api/auth/register', userData, config);
        Cookies.remove("session");
        Cookies.set('session', JSON.stringify(data), { expires: 1/48 });

        dispatch({
            type: REGISTER_USER_SUCCESS,
            payload: data
        })
    } catch (error) {
        dispatch({
            type: REGISTER_USER_FAIL,
            payload: error.response.data.error
        })
    }
}

export const googleLogin = (googleAccessToken) => async (dispatch) => {
    try {
        dispatch({ type: GOOGLE_LOGIN_REQUEST })
        const config = {
            headers: {
                'Content-Type' : 'application/json'
            }
        }
        const { data } = await axios.post('/api/auth/google/login', { googleAccessToken }, config);
        Cookies.remove("session");
        Cookies.set('session', JSON.stringify(data.user), { expires: 1/48 });
        dispatch({
            type: GOOGLE_LOGIN_SUCCESS,
            payload: data
        })
    } catch (error) {
        dispatch({
            type: GOOGLE_LOGIN_FAIL,
            payload: error.response.data.error
        })
    }
}


export const loadUser = (id) => async (dispatch) => {
    try {
        dispatch({ type: LOAD_USER_REQUEST })

        const { data } = await axios.get(`/api/auth/me/${id}`)

        dispatch({
            type: LOAD_USER_SUCCESS,
            payload: data
        })
    } catch (error) {
        console.log(error);
        dispatch({
            type: LOAD_USER_FAIL,
            payload: error.response.data.message
        })
    }
}

export const logout = () => async (dispatch) => {
    try {
        await axios.get('/api/auth/logout');
        Cookies.remove("session");
        Cookies.remove("perm");

        dispatch({
            type: LOGOUT_SUCCESS,
        })
    } catch (error) {
        dispatch({
            type: LOGOUT_FAIL,
            payload: error.response.data.message
        })
    }
}

export const updateProfile = (id, firstName, lastName, username, email, avatar) => async (dispatch) => {
    try {
        dispatch({ type: UPDATE_PROFILE_REQUEST })
        const config = {
            headers: {
                'Content-Type' : 'multipart/form-data'
            }
        }
        const { data } = await axios.put(`/api/auth/profile/update/${id}`, {firstName, lastName, username, email, avatar}, config)

        dispatch({
            type: UPDATE_PROFILE_SUCCESS,
            payload: data.success
        })
    } catch (error) {
        dispatch({
            type: UPDATE_PROFILE_FAIL,
            payload: error.response.data.message
        })
    }
}

export const forgotPassword = (email) => async (dispatch) => {
    try {
        dispatch({ type: FORGOT_PASSWORD_REQUEST })
        const config = {
            headers: {
                'Content-Type' : 'application/json'
            }
        }
        const { data } = await axios.post('/api/auth/password/forgot', {email}, config)

        dispatch({
            type: FORGOT_PASSWORD_SUCCESS,
            payload: data.data
        })
    } catch (error) {
        dispatch({
            type: FORGOT_PASSWORD_FAIL,
            payload: error.response.data.error
        })
        console.log(error);
    }
}

export const forgotUsername = (email) => async (dispatch) => {
    try {
        dispatch({ type: FORGOT_USERNAME_REQUEST })
        const config = {
            headers: {
                'Content-Type' : 'application/json'
            }
        }
        const { data } = await axios.post('/api/auth/username/forgot', {email}, config)

        dispatch({
            type: FORGOT_USERNAME_SUCCESS,
            payload: data.data
        })
    } catch (error) {
        dispatch({
            type: FORGOT_USERNAME_FAIL,
            payload: error.response.data.error
        })
        console.log(error);
    }
}

export const resetPassword = (token, password) => async (dispatch) => {
    try {
        dispatch({ type: NEW_PASSWORD_REQUEST })
        const config = {
            headers: {
                'Content-Type' : 'application/json'
            }
        }
        const { data } = await axios.put(`/api/auth/password/reset/${token}`, {password}, config)

        dispatch({
            type: NEW_PASSWORD_SUCCESS,
            payload: data
        })
    } catch (error) {
        dispatch({
            type: NEW_PASSWORD_FAIL,
            payload: error.response.data.error
        })
    }
}

export const clearErrors = () => async (dispatch) => {
    dispatch({
        type: CLEAR_ERRORS
    })
}