import React, { useEffect, useMemo, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { Line, Bar, Pie } from "react-chartjs-2"
import { Chart as ChartJs, LineElement, BarElement, Title, Tooltip, Legend, CategoryScale, LinearScale, PointElement } from 'chart.js'
import { useDispatch, useSelector } from 'react-redux'
import { getActivitiesCount, getAllHistories, getAllMonthsCount, getAllUsers, getGroupsCount, getQuestionMonthsCount, getQuestionsCount, getUsersCount, month } from '../../../actions/adminActions'
import { FaUserAlt, FaLock } from 'react-icons/fa';
import { HiUserGroup } from 'react-icons/hi';
import { TbActivity } from 'react-icons/tb';
import { BsQuestionCircleFill } from 'react-icons/bs';
import Sidebar from './Sidebar';
import Pagination from './Pagination';
import Default from '../../../assets/smilelogo.png';
import ReactGA from 'react-ga4';

let PageSize = 20;

const Dashboard = () => {

  const dispatch = useDispatch();
  const location = useLocation();

  const { usersCount, groupsCount, activitiesCount, questionsCount, users, histories, months, questionMonths } = useSelector(state => state.countStats);
  const { permissions } = useSelector(state => state.auth);

  const [currentPage, setCurrentPage] = useState(1);
  const [currentUPage, setCurrentUPage] = useState(1);
  const isAllowed = permissions?.find((element) => element?.levelModulePermissionId?.name === 'Admin.Dashboard.Read');

  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return histories?.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, histories]);

  const currentUsersTableData = useMemo(() => {
    const firstPageIndex = (currentUPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return users?.slice(firstPageIndex, lastPageIndex);
  }, [currentUPage, users]);

  let montths = [];

  for (let i = 5; i >= 0; i--) {
    const date = new Date();
    date.setMonth(date.getMonth() - i);
    const month = date.getMonth() + 1;
    montths.push(month);
  }

  useEffect(() => {
    if (isAllowed) {
        dispatch(getUsersCount());
        dispatch(getGroupsCount());
        dispatch(getActivitiesCount());
        dispatch(getQuestionsCount());
        dispatch(getAllUsers());
        dispatch(getAllHistories());
        dispatch(getAllMonthsCount(montths));
        dispatch(getQuestionMonthsCount(montths));
    }
  }, [])

    ChartJs.register(
        LineElement,
        CategoryScale,
        LinearScale,
        BarElement,
        Title,
        Tooltip,
        Legend,
        PointElement
    )
  
    const monthNames = [
        'Jan', 'Feb', 'Mar', 'Apr',
        'May', 'Jun', 'Jul', 'Aug',
        'Sept', 'Oct', 'Nov', 'Dec'
      ];
      
    function getMonthName(monthNumber) {
        return monthNames[monthNumber - 1];
    }

    const data = {
        labels: months.map(item => {
            return getMonthName(item.month);
        }),
        datasets: [
          {
            label: "Signups",
            data: months.map(item => {
                return item.count;
            }),
            fill: true,
            backgroundColor: "rgba(140, 21, 21, 1)",
            borderColor: "rgba(140, 21, 21, 1)",
            pointBorderColor: "transparent",
            pointBorderWidth: 4,
            tension: 0.5
          }
        ]
    };

    const questionsData = {
        labels: questionMonths.map(item => {
            return getMonthName(item.month);
        }),
        datasets: [
            {
            label: "Questions",
            data: questionMonths.map(item => {
                return item.count;
            }),
            fill: true,
            backgroundColor: "rgba(140, 21, 21, 1)",
            borderColor: "rgba(140, 21, 21, 1)",
            pointBorderColor: "transparent",
            pointBorderWidth: 4,
            tension: 0.5
            }
        ]
    };

    const options = {
        plugins: {
            legend: false
        },
        scales: {
            x: {
                grid: {
                    display: false
                }
            },
            y: {
                min: 2,
                max: 10,
                ticks: {
                    stepSize: 2,
                    callback: (value) => value + "K"
                },
                grid: {
                    borderDash: [10]
                }
            }
        }
    };

    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: (location.pathname + location.search), title: "Admin Dashboard" });
    }, [location]);

  return (
    <div className="m-0 font-sans text-base antialiased font-normal dark:bg-slate-900 leading-default text-slate-500">
        <main className="relative h-full max-h-screen transition-all duration-200 ease-in-out xl:ml-68 rounded-xl">
            <nav className="fixed top-0 flex flex-wrap items-center px-0 py-2 mx-6 transition-all ease-in shadow-none duration-250 rounded-2xl lg:flex-nowrap" navbar-main navbar-scroll="false">
            <div className="flex items-center w-full px-4 py-1 mx-auto flex-wrap-inherit">
                <nav>
                <Link to="/admin/dashboard">
                    <img className='w-12 flex items-center' src={Default} />
                </Link>
                </nav>
            </div>
            </nav>

            <Sidebar />

            {isAllowed ?
            <div className="mt-[80px] ml-14 mb-10 md:ml-64 px-6 py-6 mx-auto">
            <div className="flex flex-wrap -mx-3">
                <div className="w-full max-w-full px-3 mb-6 sm:w-1/2 sm:flex-none xl:mb-0 xl:w-1/4">
                <div className="relative flex flex-col min-w-0 break-words bg-white shadow-xl dark:bg-slate-850 dark:shadow-dark-xl rounded-2xl bg-clip-border">
                    <div className="flex-auto p-4">
                    <div className="flex flex-row -mx-3">
                        <div className="flex-none w-2/3 max-w-full px-3">
                        <div>
                            <p className="mb-0 font-sans text-sm font-semibold leading-normal uppercase dark:text-white dark:opacity-60">Users</p>
                            <h5 className="mb-2 font-bold dark:text-white">{usersCount}</h5>
                        </div>
                        </div>
                        <div className="px-3 text-right basis-1/3">
                            <FaUserAlt className='w-12 h-12' />
                        </div>
                    </div>
                    </div>
                </div>
                </div>

                <div className="w-full max-w-full px-3 mb-6 sm:w-1/2 sm:flex-none xl:mb-0 xl:w-1/4">
                <div className="relative flex flex-col min-w-0 break-words bg-white shadow-xl dark:bg-slate-850 dark:shadow-dark-xl rounded-2xl bg-clip-border">
                    <div className="flex-auto p-4">
                    <div className="flex flex-row -mx-3">
                        <div className="flex-none w-2/3 max-w-full px-3">
                        <div>
                            <p className="mb-0 font-sans text-sm font-semibold leading-normal uppercase dark:text-white dark:opacity-60">Groups</p>
                            <h5 className="mb-2 font-bold dark:text-white">{groupsCount}</h5>
                        </div>
                        </div>
                        <div className="px-3 text-right basis-1/3">
                            <HiUserGroup className='w-12 h-12' />
                        </div>
                    </div>
                    </div>
                </div>
                </div>

                <div className="w-full max-w-full px-3 mb-6 sm:w-1/2 sm:flex-none xl:mb-0 xl:w-1/4">
                <div className="relative flex flex-col min-w-0 break-words bg-white shadow-xl dark:bg-slate-850 dark:shadow-dark-xl rounded-2xl bg-clip-border">
                    <div className="flex-auto p-4">
                    <div className="flex flex-row -mx-3">
                        <div className="flex-none w-2/3 max-w-full px-3">
                        <div>
                            <p className="mb-0 font-sans text-sm font-semibold leading-normal uppercase dark:text-white dark:opacity-60">Activities</p>
                            <h5 className="mb-2 font-bold dark:text-white">{activitiesCount}</h5>
                        </div>
                        </div>
                        <div className="px-3 text-right basis-1/3">
                            <TbActivity className='w-12 h-12' />
                        </div>
                    </div>
                    </div>
                </div>
                </div>

                <div className="w-full max-w-full px-3 sm:w-1/2 sm:flex-none xl:w-1/4">
                <div className="relative flex flex-col min-w-0 break-words bg-white shadow-xl dark:bg-slate-850 dark:shadow-dark-xl rounded-2xl bg-clip-border">
                    <div className="flex-auto p-4">
                    <div className="flex flex-row -mx-3">
                        <div className="flex-none w-2/3 max-w-full px-3">
                        <div>
                            <p className="mb-0 font-sans text-sm font-semibold leading-normal uppercase dark:text-white dark:opacity-60">Questions</p>
                            <h5 className="mb-2 font-bold dark:text-white">{questionsCount}</h5>
                        </div>
                        </div>
                        <div className="px-3 text-right basis-1/3">
                        <BsQuestionCircleFill className='w-12 h-12' />
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </div>

            <div className="flex flex-wrap mt-6 -mx-3">
                <div className="w-full max-w-full px-3 mt-0 lg:w-6/12 lg:flex-none">
                <div className="border-black/12.5 dark:bg-slate-850 dark:shadow-dark-xl shadow-xl relative z-20 flex min-w-0 flex-col break-words rounded-2xl border-0 border-solid bg-white bg-clip-border">
                    <div className="border-black/12.5 mb-0 rounded-t-2xl border-b-0 border-solid p-6 pt-4 pb-0">
                    <h6 className="capitalize dark:text-white">Questions overview</h6>
                    </div>
                    <div className="flex-auto p-4">
                    <div >
                        <Bar data={questionsData} style={{ height: '300px' }} ></Bar>
                    </div>
                    </div>
                </div>
                </div>

                <div className="w-full max-w-full px-3 mt-0 lg:w-6/12 lg:flex-none">
                <div className="border-black/12.5 dark:bg-slate-850 dark:shadow-dark-xl shadow-xl relative z-20 flex min-w-0 flex-col break-words rounded-2xl border-0 border-solid bg-white bg-clip-border">
                    <div className="border-black/12.5 mb-0 rounded-t-2xl border-b-0 border-solid p-6 pt-4 pb-0">
                    <h6 className="capitalize dark:text-white">Signups overview</h6>
                    </div>
                    <div className="flex-auto p-4">
                    <div >
                        <Bar data={data} style={{ height: '300px' }} ></Bar>
                    </div>
                    </div>
                </div>
                </div>
            </div>

            <div className="flex flex-wrap mt-6 -mx-3">
                <div className="w-full max-w-full px-3 mt-0 mb-6 lg:mb-0 lg:w-7/12 lg:flex-none">
                <div className="relative flex flex-col min-w-0 break-words bg-white border-0 border-solid shadow-xl dark:bg-slate-850 dark:shadow-dark-xl dark:bg-gray-950 border-black-125 rounded-2xl bg-clip-border">
                    <div className="p-4 pb-0 mb-0 rounded-t-4">
                    <div className="flex justify-between">
                        <h6 className="mb-2 dark:text-white">System users</h6>
                    </div>
                    </div>
                    <div className="overflow-x-auto">
                    {currentUsersTableData ? currentUsersTableData.map((user, index) => 
                    <table className="items-center w-full mb-4 align-top border-collapse border-gray-200 dark:border-white/40">
                        <tbody>
                        <tr>
                            <td className="p-2 align-middle bg-transparent border-b w-3/10 whitespace-nowrap dark:border-white/40">
                            <div className="flex items-center px-2 py-1">
                                <div className=''>
                                <img src={user?.avatar?.url} className="w-8" alt="Avatar" />
                                </div>
                            </div>
                            </td>
                            <td className="p-2 align-middle bg-transparent border-b whitespace-nowrap dark:border-white/40">
                            <div className="text-center">
                                <p className="mb-0 text-xs font-semibold leading-tight dark:text-white dark:opacity-60">Username:</p>
                                <h6 className="mb-0 text-sm leading-normal dark:text-white">{user?.username}</h6>
                            </div>
                            </td>
                            <td className="p-2 align-middle bg-transparent border-b whitespace-nowrap dark:border-white/40">
                            <div className="text-center">
                                <p className="mb-0 text-xs font-semibold leading-tight dark:text-white dark:opacity-60">Firstname:</p>
                                <h6 className="mb-0 text-sm leading-normal dark:text-white">{user?.firstName}</h6>
                            </div>
                            </td>
                            <td className="p-2 align-middle bg-transparent border-b whitespace-nowrap dark:border-white/40">
                            <div className="text-center">
                                <p className="mb-0 text-xs font-semibold leading-tight dark:text-white dark:opacity-60">Lastname:</p>
                                <h6 className="mb-0 text-sm leading-normal dark:text-white">{user?.lastName}</h6>
                            </div>
                            </td>
                            <td className="p-2 text-sm leading-normal align-middle bg-transparent border-b whitespace-nowrap dark:border-white/40">
                            <div className="flex-1 text-center">
                                <p className="mb-0 text-xs font-semibold leading-tight dark:text-white dark:opacity-60">Email:</p>
                                <h6 className="mb-0 text-sm leading-normal dark:text-white">{user?.email}</h6>
                            </div>
                            </td>
                        </tr>
                        </tbody>
                    </table>) : null}
                    <Pagination
                        className="pagination-bar"
                        currentPage={currentUPage}
                        totalCount={users?.length}
                        pageSize={PageSize}
                        onPageChange={page => setCurrentUPage(page)}
                        />
                    </div>
                </div>

                
                </div>

                <div className="w-full max-w-full px-3 mt-0 lg:w-5/12 lg:flex-none">
                <div className="border-black/12.5 shadow-xl dark:bg-slate-850 dark:shadow-dark-xl relative flex min-w-0 flex-col break-words rounded-2xl border-0 border-solid bg-white bg-clip-border">
                    <div className="p-4 pb-0 rounded-t-4">
                    <h6 className="mb-0 dark:text-white">Page visit history</h6>
                    </div>
                    <div className="flex-auto p-4">
                    <ul className="flex flex-col pl-0 mb-0 rounded-lg">
                        {currentTableData.map((history, index) => 
                        (
                        <li className="relative flex justify-between py-2 pr-4 mb-2 border-0 rounded-t-lg rounded-xl text-inherit">
                            <div className="flex items-center">
                            <div className="inline-block w-8 h-8 mr-4 text-center text-black bg-center shadow-sm fill-current stroke-none bg-gradient-to-tl from-zinc-800 to-zinc-700 dark:bg-gradient-to-tl dark:from-slate-750 dark:to-gray-850 rounded-xl">
                                <i className="text-white ni ni-mobile-button relative top-0.75 text-xxs"></i>
                            </div>
                            <div className="flex flex-col">
                                <h6 className="mb-1 text-sm leading-normal text-slate-700 dark:text-white">{history?.user?.firstName + " " + history?.user?.lastName}</h6>
                                <span className="text-xs leading-tight dark:text-white/80"><span className="font-semibold">{history?.page ? ("Page: " + history?.page) : null}</span></span>
                                <span className="text-xs leading-tight dark:text-white/80"><span className="font-semibold">{history?.group ? ("Page: " + history?.group?.name + " activity") : null}</span></span>
                            </div>
                            </div>
                            <div className="flex">
                            <button className="group ease-in leading-pro text-xs rounded-3.5xl p-1.2 h-6.5 w-6.5 mx-0 my-auto inline-block cursor-pointer border-0 bg-transparent text-center align-middle font-bold text-slate-700 shadow-none transition-all dark:text-white"><i class="ni ease-bounce text-2xs group-hover:translate-x-1.25 ni-bold-right transition-all duration-200" aria-hidden="true"></i></button>
                            </div>
                        </li>
                        )
                        )}
                        <Pagination
                        className="pagination-bar"
                        currentPage={currentPage}
                        totalCount={histories?.length}
                        pageSize={PageSize}
                        onPageChange={page => setCurrentPage(page)}
                        />
                    </ul>
                    </div>
                </div>
                </div>


            </div>

            <footer className="pt-4">
                <div className="w-full px-6 mx-auto">
                <div className="flex flex-wrap items-center -mx-3 lg:justify-between">
                    <div className="w-full max-w-full px-3 mt-0 mb-6 shrink-0 lg:mb-0 lg:w-1/2 lg:flex-none">
                    </div>
                    <div className="w-full max-w-full px-3 mt-0 shrink-0 lg:w-1/2 lg:flex-none">
                    <ul className="flex flex-wrap justify-center pl-0 mb-0 list-none lg:justify-end">
                        <li className="nav-item">
                        <Link to="/admin/dashboard" className="block px-4 pt-0 pb-1 pr-0 text-sm font-normal transition-colors ease-in-out text-slate-500">Smile</Link>
                        </li>
                    </ul>
                    </div>
                </div>
                </div>
            </footer>
            </div> : 
            <div className='mt-[80px] pt-[50px] ml-14 mb-10 md:ml-64 flex flex-col items-center h-full justify-center'>
                <h4 className="text-lg font-semibold mb-6 text-gray-600">Forbidden 403</h4>
                <FaLock className='w-[300px] h-[300px]' />
                <h4 className="text-lg font-semibold text-gray-600">Access denied!</h4>
                <p className=" text-gray-600 font-light">Please check with the site admin if you believe this is a mistake.</p>
            </div>}
        </main>
    </div>
  )
}

export default Dashboard