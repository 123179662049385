import { CLEAR_ERRORS, DELETE_COMMENT_FAIL, DELETE_COMMENT_REQUEST, DELETE_COMMENT_RESET, DELETE_COMMENT_SUCCESS, EDIT_RESPONSE_FAIL, EDIT_RESPONSE_REQUEST, EDIT_RESPONSE_RESET, EDIT_RESPONSE_SUCCESS, GET_RESPONSES_FAIL, GET_RESPONSES_REQUEST, GET_RESPONSES_SUCCESS, NEW_RCHOICE_FAIL, NEW_RCHOICE_REQUEST, NEW_RCHOICE_RESET, NEW_RCHOICE_SUCCESS, NEW_RESPONSE_FAIL, NEW_RESPONSE_REQUEST, NEW_RESPONSE_RESET, NEW_RESPONSE_SUCCESS } from "../constants/responseConstants"

export const responsesReducer = (state = { responses: [] }, action) => {
    switch (action.type) {
        case GET_RESPONSES_REQUEST:
            return {
                loading: true,
                responses: []
            }

        case GET_RESPONSES_SUCCESS:
            return {
                loading: false,
                responses: action.payload
            }
        
        case GET_RESPONSES_FAIL:
            return {
                loading: false,
                error: action.payload
            }

        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }
    
        default:
            return state;
    }
}

export const newResponseReducer = (state = {}, action ) => {
    switch (action.type) {
        case NEW_RESPONSE_REQUEST:
        case NEW_RCHOICE_REQUEST:
        case DELETE_COMMENT_REQUEST:
        case EDIT_RESPONSE_REQUEST:
            return {
                ...state,
                loading: true
            }

        case NEW_RESPONSE_SUCCESS:
            return {
                loading: false,
                success: action.payload.success,
                response: action.payload.response
            }

        case NEW_RCHOICE_SUCCESS:
            return {
                loading: false,
                responseChoice: action.payload.success,
            }

        case DELETE_COMMENT_SUCCESS:
            return {
                loading: false,
                deletedComment: action.payload.success
            }

        case EDIT_RESPONSE_SUCCESS:
            return {
                loading: false,
                editedComment: action.payload.success
            }

        case NEW_RESPONSE_FAIL:
        case DELETE_COMMENT_FAIL:
        case NEW_RCHOICE_FAIL:
        case EDIT_RESPONSE_FAIL:
            return {
                ...state,
                error: action.payload
            }

        case NEW_RESPONSE_RESET:
            return {
                ...state,
                success: false,
                response: false
            }

        case DELETE_COMMENT_RESET:
            return {
                ...state,
                loading: false,
                deletedComment: false
            }

        case NEW_RCHOICE_RESET:
            return {
                ...state,
                loading: false,
                responseChoice: false
            }

        case EDIT_RESPONSE_RESET:
            return {
                ...state,
                loading: false,
                editedComment: false
            }

        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }
    
        default:
            return state
    }
}