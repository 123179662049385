import React, { Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom'
import { logout } from '../../actions/userActions';
import { Menu as NavMenu, Transition } from '@headlessui/react';
import Default from '../../assets/smilelogo.png';
const baseUrl = process.env.REACT_APP_IMAGES_BASEURL;

const Navbar = () => {

    function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
    };

    const { user } = useSelector(state => state.auth);
    const dispatch = useDispatch();

    const logoutHandler = () => {
        dispatch(logout());
    };

  return (
    <div className='nav bg-gray-50'>
        <Link to="/groups" className='navbrand'>
            <img className="w-14" src={Default} alt="" />
        </Link>
        <NavMenu as="div" className="right-0 top-0 absolute">
            <div>
                <NavMenu.Button className="relative navuser">
                    <div className='content' translate="no">
                        <img src={user?.avatar?.url ? (user?.avatar?.public_id === null ? (baseUrl + user?.avatar?.url) : user?.avatar?.url) : Default} className='profilePic' alt='' />
                        {user && user?.firstName + " " + user?.lastName}
                    </div>
                </NavMenu.Button>
            </div>

            <Transition as={Fragment} enter="transition ease-out duration-100" enterFrom="transform opacity-0 scale-95" enterTo="transform opacity-100 scale-100" leave="transition ease-in duration-75" leaveFrom="transform opacity-100 scale-100" leaveTo="transform opacity-0 scale-95">
                <NavMenu.Items className="absolute right-3 z-10 mt-[60px] w-40 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                <div className="py-1">
                    <NavMenu.Item>
                        {({ active }) => (
                            <Link to="/" className={classNames(active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-1  text-sm')} >
                                About SMILE
                            </Link>
                        )}
                    </NavMenu.Item>
                </div>
                <div className="py-1">
                    <NavMenu.Item>
                        {({ active }) => (
                            <Link to="/profile" className={classNames(active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-1 text-sm')} >
                                Profile
                            </Link>
                        )}
                    </NavMenu.Item>
                </div>
                <div className="py-1">
                    <NavMenu.Item>
                        {({ active }) => (
                            <Link to="/my/questions" className={classNames(active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-1 text-sm')} >
                                My Questions
                            </Link>
                        )}
                    </NavMenu.Item>
                </div>
                <div className="py-1">
                    <NavMenu.Item>
                        {({ active }) => (
                            <a onClick={logoutHandler} className={classNames(active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block cursor-pointer px-4 py-1 text-sm')}>
                                Logout
                            </a>
                        )}
                    </NavMenu.Item>
                </div>
                </NavMenu.Items>
            </Transition>
        </NavMenu>
    </div>
  )
}

export default Navbar