import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams, useLocation } from 'react-router-dom';
import { getMemberShips } from '../../../actions/adminActions';
import Sidebar from './Sidebar'
import DefaultLogo from '../../../assets/smilelogo.png';
import { FaRegEdit } from 'react-icons/fa';
import DataTable from 'react-data-table-component';
import { RoleMapper } from '../../helpers/Mappers';
import moment from 'moment';
import ReactGA from 'react-ga4';

const GroupMemberships = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const { id } = useParams();

    const { memberships, error, loading } = useSelector(state => state.adminUsers);
    const userRoleMapper = (idX) => {
        return RoleMapper.find(i => i?.key === (idX))?.value
    };

    const columns = [
        {
            name: 'User',
            selector: row => (row?.user?.firstName + ' ' + row?.user?.lastName),
        },
        {
            name: 'Role',
            selector: row => userRoleMapper(row.role),
        },
        {
            name: 'Joined At',
            selector: row => moment(row?.createdAt).utc().format('DD/MM/YY'),
        },
        {
            name: 'Actions',
            selector: row => (<FaRegEdit className='cursor-pointer' />),
        },
    ];


    useEffect(() => {

        dispatch(getMemberShips(id))

    }, [dispatch]);

    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: (location.pathname + location.search), title: "Admin Dashboard Membership" });
    }, [location]);

  return (
    <div class="m-0 font-sans text-base antialiased font-normal dark:bg-slate-900 leading-default bg-gray-50 text-slate-500">

    <main class="relative h-full max-h-screen transition-all duration-200 ease-in-out xl:ml-68 rounded-xl">
      <nav class="fixed top-0 flex flex-wrap items-center justify-between px-0 py-2 mx-6 transition-all ease-in shadow-none duration-250 rounded-2xl lg:flex-nowrap lg:justify-start" navbar-main navbar-scroll="false">
        <div class="flex items-center justify-between w-full px-4 py-1 mx-auto flex-wrap-inherit">
          <nav>
          <Link to="/admin/dashboard">
              <img className='w-12' src={DefaultLogo} />
          </Link>
          </nav>
        </div>
      </nav>

      <Sidebar />
        <div className='mt-[80px] ml-14 mb-10 md:ml-64 px-6 py-6 mx-auto'>
            <h4 class="mb-4 text-lg font-semibold text-gray-600 dark:text-gray-300">Group Members for: {memberships && memberships[0]?.group?.name}</h4>
            <DataTable
                columns={columns}
                data={memberships}
                pagination
            />
        </div>

    </main>
    </div>
  )
}

export default GroupMemberships