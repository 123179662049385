import React from 'react'
import { BsFileEarmarkFill } from 'react-icons/bs'
import { BiCloudUpload } from 'react-icons/bi'
import { Link } from 'react-router-dom'
import cloudinary from 'cloudinary-core';
import axios from 'axios';
import download from 'downloadjs';
import moment from 'moment'

const Resources = ({resource}) => {

    const downloadFile = async (id, doc, filemimetype) => {
        try {
          const result = await axios.get(`/api/group/resource/download/${id}`, {
            responseType: 'blob'
          });
          const split = doc.split('/');
          const filename = split[split.length - 1];
          return download(result.data, filename, filemimetype);
        } catch (error) {
          console.log(error)
        }
      };      

  return (
    <>    
      <div className='redivcontent w-full mt-7'>
          <div className='redivleft'>
              <Link to='' onClick={() => {downloadFile(resource?._id, resource?.doc, resource?.file_mimetype)}}><BsFileEarmarkFill className='fileicon'  /> </Link>
              <p className='refilename'>{resource?.originalname}</p>
          </div>
          <p className='redate'>{moment(resource?.createdAt).utc().format('DD/MM/YY')}</p>
      </div>
    </>
  )
}

export default Resources