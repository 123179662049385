import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { clearErrors, loadUser, updateProfile } from '../actions/userActions';
import Navbar from '../components/layouts/Navbar'
import { UPDATE_PROFILE_RESET } from '../constants/userConstants';
import Default from '../assets/default.png';
import ReactGA from 'react-ga4';
import { useLocation } from 'react-router-dom';
const baseUrl = process.env.REACT_APP_IMAGES_BASEURL;

const UpdateProfile = () => {

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [avatar, setAvatar] = useState('');
    const [avatarPreview, setAvatarPreview] = useState(Default);
    const [avatarSizeError, setAvatarSizeError] = useState(false);

    let navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();

    const { user } = useSelector(state => state.auth);
    const { error, isUpdated, loading } = useSelector(state => state.user);

    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: (location.pathname + location.search), title: "Profile view" });
    }, [location]);

    useEffect(() => {

        if(user) {
            setFirstName(user?.firstName);
            setLastName(user?.lastName);
            setUsername(user?.username);
            setEmail(user?.email);
            setAvatarPreview(user?.avatar?.public_id === null ? (baseUrl + user?.avatar?.url) : user?.avatar?.url);
        }

        if(error) {
            dispatch(clearErrors());
        }

        if (isUpdated) {
            dispatch(loadUser(user._id));
            setAvatarSizeError(false);
            navigate("/profile", { replace: true });
            dispatch({
                type: UPDATE_PROFILE_RESET
            })
        }

    }, [dispatch, error, isUpdated])

    const submitHandler = (e) => {
        e.preventDefault();
        const MAX_FILE_SIZE = 1024 * 1024 * 10;

        if(avatar.size > MAX_FILE_SIZE){
            setAvatarSizeError("Image size is greater than 10 Mbs");
            return
        }
        dispatch(updateProfile(user._id, firstName, lastName, username, email, avatar));
    };

    const onChange = e => {
        const reader = new FileReader();
        reader.onload = () => {
            if (reader.readyState === 2) {
                setAvatarPreview(reader.result)
            }
        }
        reader.readAsDataURL(e.target.files[0])
        setAvatar(e.target.files[0])
    };

  return (
    <>
    <Navbar />

    <div className='min-h-full mt-[53px] flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8'>
        <div className='flex flex-col max-w-md'>
        <label className='pb-2'>Upload profile picture</label>

            <div className='flex flex-col items-center'>
                <div className='profileimg' style={{ 
                    backgroundImage: `url(${avatarPreview})`,
                    backgroundPosition: 'center',
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat'
                }}>
                </div>
                <input type="file" id="avatar" className='profiledivFile' name="avatar" onChange={onChange} accept='image/*' required />

                <div className='formelements'>
                <p className='text-sm text-red-600'>{avatarSizeError}</p>
                    <form onSubmit={submitHandler} className="mt-3" encType='multipart/form-data'>
                    <label for="firstname">First name:</label>
                    <input type="text" translate="no" name="firstName" value={firstName} onChange={(e) => setFirstName(e.target.value)} />
                    <label for="lastname">Last name:</label>
                    <input type="text" translate="no" className='required' id="lastName" name="lastName" value={lastName} onChange={(e) => setLastName(e.target.value)} />
                    <label for="username">Username:</label>
                    <input type="text" translate="no" className='required' id="username" name="username" value={username} onChange={(e) => setUsername(e.target.value)} />
                    <label for="email">Email:</label>
                    <input type="email" translate="no" className='required' id="email" name="email" value={email} onChange={(e) => setEmail(e.target.value)} />
                    <button type='submit' className='signinbtn'>Save</button>
                    </form>
            </div>

            </div>
        
            
        </div>
    </div>

    </>
  )
}

export default UpdateProfile