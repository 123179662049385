import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { getAdminAnswers, getAdminQuestions, getAllActivities, getAllUsers } from '../../../actions/adminActions';
import Sidebar from './Sidebar'
import DefaultLogo from '../../../assets/smilelogo.png';
import { FaLock, FaRegEdit } from 'react-icons/fa';
import { ImBlocked } from 'react-icons/im';
import DataTable from 'react-data-table-component';
import ReactGA from 'react-ga4';
import moment from 'moment';

const TimeAnswerList = () => {
    const dispatch = useDispatch();
    const location = useLocation();

    const { answers, error, loading } = useSelector(state => state.adminUsers);
    const { permissions } = useSelector(state => state.auth);

    const isAllowed = permissions?.find((element) => element?.levelModulePermissionId?.name === 'Admin.Questions.Read');
    const allowEdit = permissions?.find((element) => element?.levelModulePermissionId?.name === 'Admin.Questions.Write');

    function msToTime(duration) {
        var milliseconds = Math.floor((duration % 1000) / 100),
          seconds = Math.floor((duration / 1000) % 60),
          minutes = Math.floor((duration / (1000 * 60)) % 60),
          hours = Math.floor((duration / (1000 * 60 * 60)) % 24);
      
        hours = (hours < 10) ? "0" + hours : hours;
        minutes = (minutes < 10) ? "0" + minutes : minutes;
        seconds = (seconds < 10) ? "0" + seconds : seconds;
      
        return hours + ":" + minutes + ":" + seconds + "." + milliseconds;
    }

    const columns = [
        {
            name: 'Student',
            selector: row => (row?.student?.firstName + ' ' + row?.student?.lastName),
            wrap: true, 
            style: { whiteSpace: "normal" },
        },
        {
            name: 'Response',
            selector: row => row?.response?.content,
            wrap: true, // enable wrap
            style: { whiteSpace: "normal" },
        },
        {
            name: 'Created At',
            selector: row => (moment(row?.response?.createdAt).utc().format('MM/DD/YYYY')),
            wrap: true, // enable wrap
            style: { whiteSpace: "normal" },
        },
        {
            name: 'Time to Think',
            selector: row => msToTime(row?.timeThink),
            wrap: true, 
            style: { whiteSpace: "normal" },
        },
        {
            name: 'Time to Type',
            selector: row => msToTime(row?.timeType),
            wrap: true, 
            style: { whiteSpace: "normal" },
        },
        {
            name: 'Question',
            selector: row => row?.question?.content,
            wrap: true, 
            style: { whiteSpace: "normal" },
        },
    ];

    let navigate = useNavigate();

    useEffect(() => {

        dispatch(getAdminAnswers())

    }, [dispatch]);

    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: (location.pathname + location.search), title: "Admin Dashboard Answers List" });
    }, [location]);

    const downloadCSV = () => {
        const csvData = columns.map(column => column.selector);
        const csvColumns = columns.map(column => column.name);
        const csvRows = answers.map(row => csvData.map(fn => fn(row)));
    
        const csvArray = [csvColumns, ...csvRows];
    
        const csvContent = csvArray.map(row => row.join(',')).join('\n');
    
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
    
        link.setAttribute('href', url);
        link.setAttribute('download', 'responses.csv');
        link.style.visibility = 'hidden';
    
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

  return (
    <div class="m-0 font-sans text-base antialiased font-normal leading-default text-slate-500">
        <main class="relative h-full max-h-screen transition-all duration-200 ease-in-out xl:ml-68 rounded-xl">
        <nav class="fixed top-0 flex flex-wrap items-center justify-between px-0 py-2 mx-6 transition-all ease-in shadow-none duration-250 rounded-2xl lg:flex-nowrap lg:justify-start" navbar-main navbar-scroll="false">
            <div class="flex items-center justify-between w-full px-4 py-1 mx-auto flex-wrap-inherit">
                <nav>
                    <Link to="/admin/dashboard">
                        <img className='w-12' src={DefaultLogo} />
                    </Link>
                </nav>
            </div>
        </nav>
        <Sidebar />
            {isAllowed ? <div className='mt-[80px] ml-14 mb-10 md:ml-64 px-6 py-6 mx-auto'>
                <div className='flex items-center justify-between w-full px-4 py-1 mx-auto '>
                    <h4 class="mb-4 text-lg font-semibold text-gray-600 dark:text-gray-300">Table showing response reports</h4>
                    <div className="w-30 text-right">
                        <button onClick={() => downloadCSV()} className="inline-flex w-full justify-center rounded-md bg-black bg-opacity-20 px-4 py-2 text-sm font-medium text-white hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75">
                            Export
                        </button>
                    </div>
                </div>
                <DataTable
                    columns={columns}
                    data={answers}
                    pagination
                    responsive
                />
            </div> :
            <div className='mt-[80px] pt-[50px] ml-14 mb-10 md:ml-64 flex flex-col items-center h-full justify-center'>
                <h4 className="text-lg font-semibold mb-6 text-gray-600">Forbidden 403</h4>
                <FaLock className='w-[300px] h-[300px]' />
                <h4 className="text-lg font-semibold text-gray-600">Access denied!</h4>
                <p className=" text-gray-600 font-light">Please check with the site admin if you believe this is a mistake.</p>
            </div>}

        </main>
    </div>
  )
}

export default TimeAnswerList