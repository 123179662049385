import React from 'react';
import { MdOutlineClose } from 'react-icons/md';
import { AiOutlineArrowUp, AiOutlineArrowDown } from 'react-icons/ai';

const DiscoverFilter = ({ setSortParam2, sortParam2, showDiscoverFilter, setshowDiscoverFilter, sortCreatedAt, setSortCreatedAt, sortbyName, setSortbyName, sortUpdatedAt, setSortUpdatedAt }) => {
  return (
    <div className={`w-full h-[200px] z-90 bottom-0 right-0 left-0 bg-[#FAF9F8] transition-all duration-200` + (showDiscoverFilter ? ' fixed' : ' hidden')}>
        <div className='filterPopHeader h-[50px] border-b-2 border-[#00000033] px-4 flex flex-row justify-between items-center'>
            <p></p>
            <p>Filter and sort</p>
            <MdOutlineClose className='color-[#2D3648] w-6 h-6' onClick={() => setshowDiscoverFilter(false)} />
        </div>
        <div className="h-[150px] w-full flex flex-col px-6 justify-center">
            <div className="flex justify-between items-center mb-4" onClick={() => {setSortbyName(!sortbyName); setSortParam2('name');}}>
                <p className={`ml-2 text-sm font-medium text-gray-900 ${sortParam2 === 'name' ? 'activeFilterType' : 'inactiveFilterType'}`}>Name</p>
                {sortbyName ? <AiOutlineArrowUp /> : <AiOutlineArrowDown />}
            </div>
            <div className="flex justify-between items-center mb-4" onClick={() => {setSortUpdatedAt(!sortUpdatedAt); setSortParam2('updatedAt');}}>
                <p className={`ml-2 text-sm font-medium text-gray-900 ${sortParam2 === 'updatedAt' ? 'activeFilterType' : 'inactiveFilterType'}`}>Date Updated</p>
                {sortUpdatedAt ? <AiOutlineArrowUp /> : <AiOutlineArrowDown />}
            </div>
            <div className="flex justify-between items-center mb-4" onClick={() => {setSortCreatedAt(!sortCreatedAt); setSortParam2('createdAt');}}>
                <p className={`ml-2 text-sm font-medium text-gray-900 ${sortParam2 === 'createdAt' ? 'activeFilterType' : 'inactiveFilterType'}`}>Date Created</p>
                {sortCreatedAt ? <AiOutlineArrowUp /> : <AiOutlineArrowDown />}
            </div>
        </div>
    </div>
  )
}

export default DiscoverFilter