import React, { useEffect, useState } from 'react';
import "./Group.css";
import { BsPlusLg } from 'react-icons/bs';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { createSearchParams, Link, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { createGroup, discoverGroups, getGroupUsers, getMyGroups, joinGroup } from '../../actions/groupActions';
import { JOIN_GROUP_RESET, NEW_GROUP_RESET, SINGLE_GROUP_RESET } from '../../constants/groupConstants';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Navbar from '../layouts/Navbar';
import { ALL_QUESTIONS_RESET } from '../../constants/questionConstants';
import { ALL_ACTIVITIES_RESET } from '../../constants/activityConstants';
import { RiQuestionLine } from 'react-icons/ri';
import { CiBasketball } from 'react-icons/ci';
import { RxLightningBolt } from 'react-icons/rx';
// import { GoSettings } from 'react-icons/go';
import { MdOutlineLibraryAdd, MdLogout, MdOutlineSettingsInputComposite } from 'react-icons/md';
import { AiOutlineUser } from 'react-icons/ai';
import { RxCross2 } from 'react-icons/rx';
import Default from '../../assets/groupdefault.jpg';
import smileLogo from '../../assets/smilelogo.png';
import FilterComponent from '../popovers/FilterComponent';
import GroupCard from './GroupCard';
import DiscoverFilter from '../popovers/DiscoverFilter';
import { logout } from '../../actions/userActions';
import ReactGA from 'react-ga4';
import noresults from '../../assets/noresults.png';

const baseUrl = process.env.REACT_APP_IMAGES_BASEURL;

const Group = () => {

    const [showModal, setShowModal] = useState(false);
    const [showJoin, setShowJoin] = useState(false);
    const [showJoin2, setShowJoin2] = useState(false);
    const [openSection, setOpenSection] = useState(1);
    const [selectedGroupId, setSelectedGroupId] = useState('');
    const [selectedGroupName, setSelectedGroupName] = useState('');
    const [name, setName] = useState('');
    const [gpassword, setGpassword] = useState('');
    const [query, setQuery] = useState('');
    const [query2, setQuery2] = useState('');
    const [query3, setQuery3] = useState('');
    const [query4, setQuery4] = useState('');
    const [query5, setQuery5] = useState('');
    const [showFilter, setshowFilter] = useState(false);
    const [showDiscoverFilter, setshowDiscoverFilter] = useState(false);
    const [selectedType, setselectedType] = useState([]);
    const [sortCreatedAt, setSortCreatedAt] = useState(false);
    const [sortCreatedAt2, setSortCreatedAt2] = useState(false);
    const [sortUpdatedAt, setSortUpdatedAt] = useState(false);
    const [sortUpdatedAt2, setSortUpdatedAt2] = useState(false);
    const [sortbyName, setSortbyName] = useState(false);
    const [sortbyName2, setSortbyName2] = useState(false);
    const [sortParam, setSortParam] = useState("createdAt");
    const [sortParam2, setSortParam2] = useState("createdAt");
    const [openNav, setOpenNav] = useState(false);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { user } = useSelector(state => state.auth);
    const { groups, error, users } = useSelector(state => state.groups);
    const { hasJoined, error: joinError, loading } = useSelector(state => state.group);
    const { disGroups } = useSelector(state => state.discoverGroups);
    const { createdGroup } = useSelector(state => state.newGroup);
    const creator = user?._id;

    const showToastMessage = (message) => {
        toast.success(message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000
        });
    };

    const handleSubmit = (type) => {
        const search = [['groupName', type === 'groups' ? query4 : query5], ['type', type]]
        navigate({pathname: '/groups/search', search: `?${createSearchParams(search)}`,});
        setQuery4('');
        setQuery5('');
    };

    const location = useLocation();

    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: (location.pathname + location.search), title: "Groups" });
    }, [location]);

    useEffect(() => {
        dispatch({ type: ALL_QUESTIONS_RESET });
        dispatch({ type: SINGLE_GROUP_RESET });
        dispatch({ type: ALL_ACTIVITIES_RESET });
    }, []);

    useEffect (() => {
        dispatch(getGroupUsers());
        if (createdGroup) {
            showToastMessage("Group created successfully!");
            dispatch({ type: NEW_GROUP_RESET });
            navigate(`/group/${createdGroup._id}`);
        }

        if (hasJoined) {
            showToastMessage("Joined Group successfully!");
            setShowJoin(false);
            setShowJoin2(false);
            dispatch({ type: JOIN_GROUP_RESET });
            dispatch(getMyGroups(creator, sortParam, sortUpdatedAt2, sortCreatedAt2, sortbyName2));
            dispatch(discoverGroups(creator, sortParam2, sortCreatedAt, sortUpdatedAt, sortbyName));
            // navigate(`/group/${hasJoined?._id}`);
        }
    }, [error, createdGroup, hasJoined]);

    useEffect(() => {
        dispatch(getMyGroups(creator, sortParam, sortUpdatedAt2, sortCreatedAt2, sortbyName2));
    }, [creator, sortParam, sortUpdatedAt2, sortCreatedAt2, sortbyName2]);

    useEffect(() => {
        dispatch(discoverGroups(creator, sortParam2, sortCreatedAt, sortUpdatedAt, sortbyName));
    }, [creator, sortCreatedAt, sortUpdatedAt, sortParam2, sortbyName]);

    const createGroupHandler = () => {
        dispatch(createGroup(creator, name, gpassword));
        setName('');
        setShowModal(false);
    };

    const joinGroupHandler = (group, gpassword) => {
        const student = user?._id
        dispatch(joinGroup(student, group, gpassword));
    };

    const onCheckboxChange = (id) => {
        const currentStatus = [...selectedType];
        const findIdx = currentStatus.indexOf(id);
        
        if (findIdx > -1) {
            currentStatus.splice(findIdx, 1);
        } else {
            currentStatus.push(id);
        }
        setselectedType(currentStatus);
    };

    const logoutHandler = () => {
        dispatch(logout());
    };

  return (
    <div>
        <div className='hidden md:block'>
            <Navbar />
        </div>
        <>
        <div className={`block bottom-0 shadow-md right-0 top-0 md:hidden fixed transition-all duration-500 transform inset-y-0 z-50 outline-none focus:outline-none ${openNav ? 'translate-x-0 opacity-100' : 'translate-x-full opacity-0 invisible'} ease-in-out h-screen w-5/6 px-4 py-5 bg-[#FAF9F8]`}>
            <div className='flex justify-between mb-5'>
                <div></div>
                <RxCross2 className='w-15 h-15' onClick={() => setOpenNav(false)} />
            </div>
            <div className='flex flex-row gap-4 items-center mb-8'>
                <img src={user?.avatar?.url ? (user?.avatar?.public_id === null ? (baseUrl + user?.avatar?.url) : user?.avatar?.url) : Default} className="w-[36px] h-[36px] rounded-full" alt='user' />
                <p className='sidenavName capitalize' translate="no">{user?.firstName}</p>
            </div>
            <div>
                <div className='flex flex-row gap-6 items-center'>
                    <AiOutlineUser className='w-[18px] mb-3 h-[18px]' />
                    <p className='sideNavLabel' onClick={() => navigate("/profile")}>Account Setting</p>
                </div>
                <div className='flex flex-row gap-6 items-center'>
                    <RiQuestionLine className='w-[18px] h-[18px]' />
                    <p className='sideNavLabel' onClick={() => navigate("/my/questions")}>My Questions</p>
                </div>
                <div className='flex flex-row gap-6 items-center'>
                    <MdLogout className='w-[18px] h-[18px]' />
                    <a onClick={logoutHandler} className='sideNavLabel'>Logout</a>
                </div>
            </div>
        </div>
        {openNav && <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>}
        </>
        <div className='container px-5 py-3 mx-auto groupContainer md:mt-[80px]'>
            <div className='block md:hidden pb-3'>
                <div className='flex flex-column justify-center align-center py-4'>
                    <img className="w-14" src={smileLogo} alt="Smile Logo" />
                </div>
                <div className='flex justify-between align-center pb-4'>
                    <div className=''>
                        <p className='welcomeText'>Welcome,</p>
                        <p className='welcomeUser capitalize'>{user?.firstName}</p>
                    </div>
                    <img onClick={() => setOpenNav(true)} src={user?.avatar?.url ? (user?.avatar?.public_id === null ? (baseUrl + user?.avatar?.url) : user?.avatar?.url) : Default} className="w-[24px] h-[24px] rounded-full" alt='user' />
                </div>
                <div className="flex flex-row justify-center space-x-2 p-1">
                    <div onClick={() => {setshowDiscoverFilter(false); setOpenSection(1);}} className={'flex flex-row gap-3 justify-center align-center py-2.5 cursor-pointer ' + (openSection === 1 ? "activeTab border-b-2 border-black" : "inactiveTab")}>
                        <CiBasketball className='w-5 h-5' />
                        My Groups
                    </div>
                    <div onClick={() => {setshowFilter(false); setOpenSection(2)}} className={'flex flex-row gap-3 justify-center align-center py-2.5 cursor-pointer ' + (openSection === 2 ? "activeTab border-b-2 border-black" : "inactiveTab")}>
                        <RxLightningBolt className='w-5 h-5' />
                        Discover Groups
                    </div>
                </div>
                <div className="mt-2">
                        <div className={openSection === 1 ? "block" : "hidden"}>
                        <div className='flex justify-between gap-4'>
                            <div className="flex items-center">   
                                <form onSubmit={() => handleSubmit('groups')} encType='multipart/form-data'>
                                    <label htmlFor="simple-search" className="sr-only" type='submit'>Search</label>
                                    <div className="relative w-full">
                                        <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                                            <svg aria-hidden="true" className="w-5 h-5 text-gray-500 dark:text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clipRule="evenodd"></path></svg>
                                        </div>
                                        <input type="text" id="simple-search" value={query4} onChange={(e) => setQuery4(e.target.value)} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-3xl focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-1.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Search" />
                                    </div>
                                </form>
                            </div>
                            <div className='flex gap-1 items-center' onClick={() => setshowFilter(true)}>
                                <p className='filterText'>Filter and sort</p>
                                <div className="w-[24px] h-[24px] rounded-full bg-[#E8EAED] flex items-center justify-center">
                                    <MdOutlineSettingsInputComposite className='w-[12px] h-[12px]' />
                                </div>
                            </div>
                        </div>
                        <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6 pt-4 w-full'>
                            {groups?.filter((group) => group?.group?.name.toLowerCase().includes(query2))?.filter((item) => selectedType?.length === 0 || (selectedType.includes(String(item?.role))))?.map((group, index) =>
                                <GroupCard key={index} group={group} joinedGroup={true} gusers={users?.filter(dg =>  group?.group?._id === dg?.group?._id)} />
                            )}
                        </div>
                        </div>
                        <div className={openSection === 2 ? "block" : "hidden"}>
                            <div className='flex justify-between gap-4'>
                                <div className="flex items-center"> 
                                    <form onSubmit={() => handleSubmit('discover')} encType='multipart/form-data'>
                                        <label htmlFor="simple-search" className="sr-only">Search</label>
                                        <div className="relative w-full">
                                            <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                                                <svg aria-hidden="true" className="w-5 h-5 text-gray-500 dark:text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clipRule="evenodd"></path></svg>
                                            </div>
                                            <input type="text" id="simple-search" value={query5} onChange={(e) => setQuery5(e.target.value)} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-3xl focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-1.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Search" />
                                        </div>
                                    </form>  
                                </div>
                                <div className='flex gap-1 items-center' onClick={() => setshowDiscoverFilter(true)}>
                                    <p className='filterText'>Filter and sort</p>
                                    <div className="w-[24px] h-[24px] rounded-full bg-[#E8EAED] flex items-center justify-center">
                                        <MdOutlineSettingsInputComposite className='w-[12px] h-[12px]' />
                                    </div>
                                </div>
                            </div>
                            <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6 pt-4 w-full'>
                                {disGroups?.filter((discovergroup) => discovergroup?.name.toLowerCase().includes(query))?.map((discovergroup, index) => 
                                    <GroupCard key={index} group={discovergroup} joinedGroup={false} gusers={users?.filter(dg =>  discovergroup?._id === dg?.group?._id)} />
                                )} 
                            </div>
                        </div>
                    </div>
                <div onClick={() => navigate('/groups/create')} className='createGroupBtn flex md:hidden flex-row gap-3 bg-[#E8EAED] rounded-lg px-5 py-3 fixed z-90 bottom-10 right-8'>
                    <p>Create Group</p>
                    <MdOutlineLibraryAdd className='w-[12px] h-[12px]' />
                </div>
                <FilterComponent setSortParam={setSortParam} sortParam={sortParam} setSortbyName2={setSortbyName2} sortbyName2={sortbyName2} sortUpdatedAt2={sortUpdatedAt2} setSortUpdatedAt2={setSortUpdatedAt2} sortCreatedAt2={sortCreatedAt2} setSortCreatedAt2={setSortCreatedAt2} showFilter={showFilter} setshowFilter={setshowFilter} onCheckboxChange={onCheckboxChange} />
                <DiscoverFilter setSortParam2={setSortParam2} sortParam2={sortParam2} showDiscoverFilter={showDiscoverFilter} setshowDiscoverFilter={setshowDiscoverFilter} sortCreatedAt={sortCreatedAt} setSortCreatedAt={setSortCreatedAt} sortbyName={sortbyName} setSortbyName={setSortbyName} sortUpdatedAt={sortUpdatedAt} setSortUpdatedAt={setSortUpdatedAt} />
            </div>
            
            <div className='hidden md:block'>
                <div className=''>
                    <div className='flex flex-col lg:flex-row justify-between'>
                        <p className='groupLabel'>My Groups</p>
                        <div className='flex justfiy-between gap-2 flex-wrap'>
                            <form className="flex items-center" onSubmit={() => handleSubmit('groups')} encType='multipart/form-data'>   
                                <label htmlFor="simple-search" className="sr-only">Search</label>
                                <div className="relative w-full">
                                    <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                                        <svg aria-hidden="true" className="w-5 h-5 text-gray-500 dark:text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clipRule="evenodd"></path></svg>
                                    </div>
                                    <input type="text" id="simple-search" value={query3} onChange={(e) => setQuery3(e.target.value)} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-3xl focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-1.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Search" />
                                </div>
                            </form>
                            <Link to={""} onClick={() => setShowModal(true)} className='groupLabelLeft'>
                                <BsPlusLg className='plus' />
                                <p className='question'>Create a Group</p>
                                <ToastContainer  autoClose={1000} />
                            </Link>
                        </div>
                        {showModal ? (
                            <>
                            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                                <div className='modalbox w-[300px] md:w-[430px]'>
                                    <div className='modalboxcontent py-4 w-full'>
                                        <div className='modalboxheader px-4 pb-2 w-full'>
                                            <p className='modalboxheaderight'>Create Group</p>
                                            <AiOutlineCloseCircle className='modalboxheaderleft' onClick={() => setShowModal(false)} />
                                        </div>
                                        <div className='px-4 w-full'>
                                            <input type="text" value={name} onChange={(e) => setName(e.target.value)} name="groupname" id="groupname" className='modalboxbodyinput px-4 w-full' placeholder='Enter group name...' required />
                                        </div>
                                        <div className='modalboxbody px-4 w-full'>
                                            <button className='modalboxbodybutton' type='submit' onClick={createGroupHandler}>Create Group</button>
                                        </div>
                                    </div>
                                </div> 
                            </div>
                            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                            </>
                        ) : null}
                    </div>
                </div>
                <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6 pt-4 w-full'>
                    {groups?.filter((group) => group?.group?.name.toLowerCase().includes(query3))?.map((group, index) =>
                        <GroupCard key={index} group={group} joinedGroup={true} gusers={users?.filter(dg =>  group?.group?._id === dg?.group?._id)} />
                    )}
                    {!loading && query3 && groups?.filter((group) => group?.group?.name.toLowerCase().includes(query3))?.length === 0 &&
                    <div className='flex flex-col col-span-1 md:col-span-2 lg:col-span-3 xl:col-span-4 gap-4 pt-7 px-4 w-full items-center justify-center'>
                        <img class="w-24 h-24" src={noresults} alt="No results" />
                        <p className="boldHeader">Oops!! No Results Found</p>
                        <p className='inactiveHeader'>Please check spelling or try different key words</p>
                    </div>}
                </div>
                
            </div>

            <div className='hidden md:block container my-7'>
                <div className='groupRow2Header my-7'>
                    <div className='groupRow2HeaderContent'>
                        <p className='group2Label'>Discover Groups</p>
                        <div className="flex items-center">   
                            <label htmlFor="simple-search" className="sr-only">Search</label>
                            <div className="relative w-full">
                                <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                                    <svg aria-hidden="true" className="w-5 h-5 text-gray-500 dark:text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clipRule="evenodd"></path></svg>
                                </div>
                                <input type="text" id="simple-search" onChange={(e) => setQuery(e.target.value)} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-3xl focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-1.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Search" required />
                            </div>
                        </div>
                    </div>
                </div>
                <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6 pt-4 w-full'>
                    {disGroups?.filter((discovergroup) => discovergroup?.name.toLowerCase().includes(query))?.map((discovergroup, index) => 
                        <GroupCard key={index} group={discovergroup} joinedGroup={false}  gusers={users?.filter(dg =>  discovergroup?._id === dg?.group?._id)} />
                    )} 
                    {!loading && query && disGroups?.filter((discovergroup) => discovergroup?.name.toLowerCase().includes(query))?.length === 0 &&
                    <div className='flex flex-col col-span-1 md:col-span-2 lg:col-span-3 xl:col-span-4 gap-4 pt-7 px-4 w-full items-center justify-center'>
                        <img class="w-24 h-24" src={noresults} alt="No results" />
                        <p className="boldHeader">Oops!! No Results Found</p>
                        <p className='inactiveHeader'>Please check spelling or try different key words</p>
                    </div>}
                    {showJoin ? (
                        <>
                        <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                            <div className='joinpmodal w-[300px] md:w-[430px]'>
                                <div className='joinpmodalcontent py-4 w-full'>
                                    <div className='joinpmodalheader px-4 w-full'>
                                        <p className='joinpmodalheaderight'>Join Group</p>
                                        <AiOutlineCloseCircle className='joinpmodalheaderleft cursor-pointer' onClick={() => setShowJoin(false)} />
                                    </div>
                                    <div className='joinpmodalbodydiv px-4 mt-2 w-full'>
                                        <p className='joinpmodalbodylabel'>{selectedGroupName}</p>
                                        <input type='text' value={gpassword} onChange={(e) => setGpassword(e.target.value)} placeholder='Enter passsword...' className='px-4 joinpmodalbodyinput w-full' />
                                        {joinError?<p className='errormsg'>{joinError}</p>:null}
                                    </div>
                                    <div className='joinpmodalbody px-4 w-full'>
                                        <div className='joinpfooter'>
                                            <button className='joinpmodalsubmit' type='submit' onClick={() => joinGroupHandler(selectedGroupId)}>Join Group</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                        </>
                    ) : null}

                    {showJoin2 ? (
                        <>
                        <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                            <div className='modalbox w-[300px] md:w-[430px]'>
                                <div className='modalboxcontent py-4 w-full'>
                                    <div className='modalboxheader px-4 w-full'>
                                        <p className='modalboxheaderight'>Join Group</p>
                                        <AiOutlineCloseCircle className='modalboxheaderleft cursor-pointer' onClick={() => setShowJoin2(false)} />
                                    </div>
                                    <p className='modalname w-full px-4'>{selectedGroupName}</p>
                                    <div className='modalboxbody w-full px-4'>
                                        <button className='modalboxbodybutton' type='submit' onClick={() => joinGroupHandler(selectedGroupId)}>Join Group</button>
                                    </div>
                                </div>
                            </div> 
                        </div>
                        <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                        </>
                    ) : null}

                </div>
                
            </div>
        </div>
    </div>
  )
}

export default Group