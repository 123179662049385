import * as yup from "yup";

const passwordRules = /^(?=.*\d).{5,}$/;
// min 5 characters, 1 upper case letter, 1 lower case letter, 1 numeric digit.

export const basicSchema = yup.object().shape({
  email: yup.string().email("Please enter a valid email").required("Please provide an email"),
  password: yup
    .string()
    .min(8, 'Password is too short - should contain 8 or more characters.')
    .required("Please provide a password"),
  username: yup
    .string()
    .required("Please provide a username"),
  firstName: yup
    .string()
    .required("Please provide a name"),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password"), null], "Passwords must match")
    .required("Please provide a confirm password"),
});