export const NEW_ACTIVITY_REQUEST = 'NEW_ACTIVITY_REQUEST';
export const NEW_ACTIVITY_SUCCESS = 'NEW_ACTIVITY_SUCCESS';
export const NEW_ACTIVITY_RESET = 'NEW_ACTIVITY_RESET';
export const NEW_ACTIVITY_FAIL = 'NEW_ACTIVITY_FAIL';

export const GET_ACTIVITY_REQUEST = 'GET_ACTIVITY_REQUEST';
export const GET_ACTIVITY_SUCCESS = 'GET_ACTIVITY_SUCCESS';
export const GET_ACTIVITY_FAIL = 'GET_ACTIVITY_FAIL';

export const ALL_ACTIVITIES_REQUEST = 'ALL_ACTIVITIES_REQUEST';
export const ALL_ACTIVITIES_SUCCESS = 'ALL_ACTIVITIES_SUCCESS';
export const ALL_ACTIVITIES_RESET = 'ALL_ACTIVITIES_RESET';
export const ALL_ACTIVITIES_FAIL = 'ALL_ACTIVITIES_FAIL';

export const UPDATE_ACTIVITY_REQUEST = 'UPDATE_ACTIVITY_REQUEST';
export const UPDATE_ACTIVITY_SUCCESS = 'UPDATE_ACTIVITY_SUCCESS';
export const UPDATE_ACTIVITY_RESET = 'UPDATE_ACTIVITY_RESET';
export const UPDATE_ACTIVITY_FAIL = 'UPDATE_ACTIVITY_FAIL';

export const CHANGE_MODE_REQUEST = 'CHANGE_MODE_REQUEST';
export const CHANGE_MODE_SUCCESS = 'CHANGE_MODE_SUCCESS';
export const CHANGE_MODE_RESET = 'CHANGE_MODE_RESET';
export const CHANGE_MODE_FAIL = 'CHANGE_MODE_FAIL';

export const GET_RUBRIC_REQUEST = 'GET_RUBRIC_REQUEST';
export const GET_RUBRIC_SUCCESS = 'GET_RUBRIC_SUCCESS';
export const GET_RUBRIC_FAIL = 'GET_RUBRIC_FAIL';

export const UPDATE_RUBRIC_REQUEST = 'UPDATE_RUBRIC_REQUEST';
export const UPDATE_RUBRIC_SUCCESS = 'UPDATE_RUBRIC_SUCCESS';
export const UPDATE_RUBRIC_RESET = 'UPDATE_RUBRIC_RESET';
export const UPDATE_RUBRIC_FAIL = 'UPDATE_RUBRIC_FAIL';

export const UPLOAD_ARESOURCE_REQUEST = 'UPLOAD_ARESOURCE_REQUEST';
export const UPLOAD_ARESOURCE_SUCCESS = 'UPLOAD_ARESOURCE_SUCCESS';
export const UPLOAD_ARESOURCE_RESET = 'UPLOAD_ARESOURCE_RESET';
export const UPLOAD_ARESOURCE_FAIL = 'UPLOAD_ARESOURCE_FAIL';

export const ADD_PROMPTER_REQUEST = 'ADD_PROMPTER_REQUEST';
export const ADD_PROMPTER_SUCCESS = 'ADD_PROMPTER_SUCCESS';
export const ADD_PROMPTER_RESET = 'ADD_PROMPTER_RESET';
export const ADD_PROMPTER_FAIL = 'ADD_PROMPTER_FAIL';

export const GET_KEYWORDS_REQUEST = 'GET_KEYWORDS_REQUEST';
export const GET_KEYWORDS_SUCCESS = 'GET_KEYWORDS_SUCCESS';
export const GET_KEYWORDS_FAIL = 'GET_KEYWORDS_FAIL';

export const GET_ARESOURCES_REQUEST = 'GET_ARESOURCES_REQUEST';
export const GET_ARESOURCES_SUCCESS = 'GET_ARESOURCES_SUCCESS';
export const GET_ARESOURCES_FAIL = 'GET_ARESOURCES_FAIL';

export const CHANGE_LEVEL_REQUEST = 'CHANGE_LEVEL_REQUEST';
export const CHANGE_LEVEL_SUCCESS = 'CHANGE_LEVEL_SUCCESS';
export const CHANGE_LEVEL_RESET = 'CHANGE_LEVEL_RESET';
export const CHANGE_LEVEL_FAIL = 'CHANGE_LEVEL_FAIL';

export const ACTIVITY_REPORT_REQUEST = 'ACTIVITY_REPORT_REQUEST';
export const ACTIVITY_REPORT_SUCCESS = 'ACTIVITY_REPORT_SUCCESS';
export const ACTIVITY_REPORT_FAIL = 'ACTIVITY_REPORT_FAIL';

export const ACTIVITY_QUESTIONS_REQUEST = 'ACTIVITY_QUESTIONS_REQUEST';
export const ACTIVITY_QUESTIONS_SUCCESS = 'ACTIVITY_QUESTIONS_SUCCESS';
export const ACTIVITY_QUESTIONS_FAIL = 'ACTIVITY_QUESTIONS_FAIL';

export const DELETE_ACTIVITY_REQUEST = 'DELETE_ACTIVITY_REQUEST';
export const DELETE_ACTIVITY_SUCCESS = 'DELETE_ACTIVITY_SUCCESS';
export const DELETE_ACTIVITY_RESET = 'DELETE_ACTIVITY_RESET';
export const DELETE_ACTIVITY_FAIL = 'DELETE_ACTIVITY_FAIL';

export const CLEAR_ERRORS = 'CLEAR_ERRORS'