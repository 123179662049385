import React from 'react';
import { useSelector } from 'react-redux';

const CheckAnswer = ({ correct, answer, showCheck, setShowCheck }) => {
    
  return (
    <div className={`w-full pb-4 z-90 bottom-0 shadow-md right-0 left-0 ${correct === 1 ? 'bg-[#BEFEB8]' : correct === 2 ? 'bg-[#FEF1DE]' : correct === 3 ? 'bg-[#FEDEE2]' : ''} fixed transition-all duration-500 transform ${showCheck ? 'translate-y-0 opacity-100' : 'translate-y-full opacity-0 invisible'}`}>
        {correct === 1 && <p className='flex flex-row px-3 pt-5 gotItButton text-[#02CC3B]'>You answered correctly!</p>}
        {correct === 2 && <p className='flex flex-row px-3 pt-5 gotItButton text-[#F2B131]'>You answered incorrectly!</p>}
        {correct === 3 && <p className='flex flex-row px-3 pt-5 gotItButton text-[#FF4B61]'>Correct Answer</p>}
        {correct === 2 && <p className={`flex flex-row px-3 checkModalAnswer ${correct === 2 ? 'text-[#F2B131]' : correct === 3 ? 'text-[#FF4B61]' : ''}`}>Correct Answer: {Array.isArray(answer) ? answer?.map((obj) => obj.choice)?.join(', ') : answer?.choice}</p>}
        {correct === 3 && <p className={`flex flex-row px-3 checkModalAnswer ${correct === 2 ? 'text-[#F2B131]' : correct === 3 ? 'text-[#FF4B61]' : ''}`} translate="no">{Array.isArray(answer) ? answer?.map((obj) => obj.choice)?.join(', ') : answer?.choice}</p>}
        <div className='flex flex-col gap-3 p-3'>
            <div onClick={() => setShowCheck(!showCheck)} className={`flex justify-center rounded-[10px] checkModalButton ${correct === 1 ? 'bg-[#02CC3B]' : correct === 2 ? 'bg-[#F2B131]' : correct === 3 ? 'bg-[#FF4B61]' : ''} w-full py-2`}>
                Got It
            </div>
        </div>
    </div>
  )
}

export default CheckAnswer