import React, { useEffect } from 'react';
import MobileLayout from '../layouts/MobileLayout';
import ModeComponent from '../activity/ModeComponent';
import { MdKeyboardArrowLeft } from 'react-icons/md';
import Default from '../../assets/groupdefault.jpg';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { ChoiceMapper } from '../helpers/Mappers';

const VoterComponent = () => {

    const { id, activityId, questionId } = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const { activity } = useSelector(state => state.activity);

    useEffect(() => {
        function handleResize() {
            if (window.innerWidth >= 768) { 
                navigate(`/group/${id}`);
            }
        }
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const alphabetMapper = (idX) => {
        return ChoiceMapper.find(i => i?.key === (idX + 1))?.value
    };

  return (
    <MobileLayout>
        <div className='flex items-center border-b activityNames border-gray-200 p-3'>
            <MdKeyboardArrowLeft onClick={() => navigate(`/${id}/${activityId}/${questionId}/question`)} />
            <p className='flex-1 block overflow-y overflow-x-hidden break-words' translate="no">{activity?.name}</p>
        </div>
        <ModeComponent />
        <div className='flex flex-col py-4'>
            <p className='pb-2 px-3 questionContentq'>Vote Status</p>
            {location?.state?.choices?.map((choice, index) =>
            <div key={index} className='py-4 px-3 border-b border-gray-300'>
                <div className='choiceOptionLabel pb-4'><span translate="no">{choice?.choice}</span> [{location?.state?.responseChoices?.filter(res => res?.choices?.includes(alphabetMapper(index)))?.length}]</div>
                <div className='grid grid-cols-2 gap-4 items-center'>
                    {location?.state?.responseChoices?.filter(res => res?.choices?.includes(alphabetMapper(index))).map((user, index) =>
                        <div key={index} className='flex flex-row gap-4'>
                        <img className="imagequestion1 inline-flex" src={Default} alt='' />
                        <p className='' translate="no">{user?.creator?.firstName + " " + user?.creator?.lastName}</p>
                    </div>)}
                </div>
            </div>)}
            
        </div>
    </MobileLayout>
  )
}

export default VoterComponent