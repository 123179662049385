import { ALL_GROUPS_FAIL, ALL_GROUPS_REQUEST, ALL_GROUPS_SUCCESS, CHANGE_ROLE_FAIL, CHANGE_ROLE_REQUEST, CHANGE_ROLE_RESET, CHANGE_ROLE_SUCCESS, CLEAR_ERRORS, DELETE_GROUP_FAIL, DELETE_GROUP_REQUEST, DELETE_GROUP_RESET, DELETE_GROUP_SUCCESS, DISCOVER_GROUPS_FAIL, DISCOVER_GROUPS_REQUEST, DISCOVER_GROUPS_SUCCESS, GET_MEMBERS_FAIL, GET_MEMBERS_REQUEST, GET_MEMBERS_SUCCESS, GET_RESOURCES_FAIL, GET_RESOURCES_REQUEST, GET_RESOURCES_SUCCESS, GROUP_QUESTIONS_FAIL, GROUP_QUESTIONS_REQUEST, GROUP_QUESTIONS_SUCCESS, GROUP_USERS_FAIL, GROUP_USERS_REQUEST, GROUP_USERS_SUCCESS, JOIN_GROUP_FAIL, JOIN_GROUP_REQUEST, JOIN_GROUP_RESET, JOIN_GROUP_SUCCESS, MY_GROUPS_FAIL, MY_GROUPS_REQUEST, MY_GROUPS_SUCCESS, NEW_GROUP_FAIL, NEW_GROUP_REQUEST, NEW_GROUP_RESET, NEW_GROUP_SUCCESS, REMOVE_UGROUP_FAIL, REMOVE_UGROUP_REQUEST, REMOVE_UGROUP_RESET, REMOVE_UGROUP_SUCCESS, SEARCH_DISCOVER_FAIL, SEARCH_DISCOVER_REQUEST, SEARCH_DISCOVER_SUCCESS, SEARCH_GROUP_FAIL, SEARCH_GROUP_REQUEST, SEARCH_GROUP_RESET, SEARCH_GROUP_SUCCESS, SINGLE_GROUP_FAIL, SINGLE_GROUP_REQUEST, SINGLE_GROUP_RESET, SINGLE_GROUP_SUCCESS, UPDATE_SETTINGS_FAIL, UPDATE_SETTINGS_REQUEST, UPDATE_SETTINGS_RESET, UPDATE_SETTINGS_SUCCESS, UPLOAD_RESOURCE_FAIL, UPLOAD_RESOURCE_REQUEST, UPLOAD_RESOURCE_RESET, UPLOAD_RESOURCE_SUCCESS } from "../constants/groupConstants"

export const groupsReducer = (state = { groups: [], users: [] }, action) => {
    switch (action.type) {
        case ALL_GROUPS_REQUEST:
        case MY_GROUPS_REQUEST:
        case GROUP_USERS_REQUEST:
            return {
                loading: true,
                groups: [],
                users: [],
            }

        case ALL_GROUPS_SUCCESS:
        case MY_GROUPS_SUCCESS:
            return {
                ...state,
                loading: false,
                groups: action.payload
            }

        case GROUP_USERS_SUCCESS:
            return {
                ...state,
                loading: false,
                users: action.payload
            }
        
        case ALL_GROUPS_FAIL:
        case MY_GROUPS_FAIL:
        case GROUP_USERS_FAIL:
            return {
                loading: false,
                error: action.payload
            }

        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }
    
        default:
            return state;
    }
}

export const discoverGroupsReducer = (state = { disGroups: [] }, action) => {
    switch (action.type) {
        case DISCOVER_GROUPS_REQUEST:
            return {
                loading: true,
                disGroups: []
            }

        case DISCOVER_GROUPS_SUCCESS:
            return {
                loading: false,
                disGroups: action.payload
            }
        
        case DISCOVER_GROUPS_FAIL:
            return {
                loading: false,
                error: action.payload
            }

        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }
    
        default:
            return state;
    }
}

export const newGroupReducer = (state = { group : {} }, action ) => {
    switch (action.type) {
        case NEW_GROUP_REQUEST:
            return {
                ...state,
                loading: true
            }

        case NEW_GROUP_SUCCESS:
            return {
                loading: false,
                success: action.payload.success,
                createdGroup: action.payload.createdGroup
            }

        case NEW_GROUP_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload
            }

        case NEW_GROUP_RESET:
            return {
                ...state,
                loading: false,
                createdGroup: false
            }

        case CLEAR_ERRORS:
            return {
                ...state,
                loading: false,
                error: null
            }
    
        default:
            return state
    }
}

export const groupDetailsReducer = (state = {group: {} }, action ) => {
    switch (action.type) {
        case SINGLE_GROUP_REQUEST:
            return {
                ...state,
                loading: true
            }

        case SINGLE_GROUP_SUCCESS:
            return {
                loading: false,
                group: action.payload
            }

        case SINGLE_GROUP_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload
            }

        case SINGLE_GROUP_RESET:
            return {
                ...state,
                loading: false,
                group: {}
            }

        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }
    
        default:
            return state
    }
}

export const groupResourcesReducer = (state = { resources: [], groupQuestions: [], }, action ) => {
    switch (action.type) {
        case GET_RESOURCES_REQUEST:
        case GROUP_QUESTIONS_REQUEST:
            return {
                ...state,
                loading: true,
                groupQuestions: [],
            }

        case GET_RESOURCES_SUCCESS:
            return {
                ...state,
                loading: false,
                resources: action.payload
            }

        case GROUP_QUESTIONS_SUCCESS:
            return {
                ...state,
                loading: false,
                groupQuestions: action.payload.questions,
                activities: action.payload.activities,
                groupUsers: action.payload.groupUsers
            }

        case GET_RESOURCES_FAIL:
        case GROUP_QUESTIONS_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload
            }

        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }
    
        default:
            return state
    }
}

export const groupMembersReducer = (state = {members: [] }, action ) => {
    switch (action.type) {
        case GET_MEMBERS_REQUEST:
            return {
                ...state,
                loading: true
            }

        case GET_MEMBERS_SUCCESS:
            return {
                loading: false,
                members: action.payload
            }

        case GET_MEMBERS_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload
            }

        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }
    
        default:
            return state
    }
}

export const groupReducer = (state = {}, action ) => {
    switch (action.type) {
        case JOIN_GROUP_REQUEST:
        case UPDATE_SETTINGS_REQUEST:
        case UPLOAD_RESOURCE_REQUEST:
        case CHANGE_ROLE_REQUEST:
        case SEARCH_GROUP_REQUEST:
        case SEARCH_DISCOVER_REQUEST:
        case REMOVE_UGROUP_REQUEST:
        case DELETE_GROUP_REQUEST:
            return {
                discGroups: [],
                groups: [],
                loading: true
            }

        case JOIN_GROUP_SUCCESS:
            return {
                ...state,
                loading: false,
                hasJoined: action.payload
            }

        case REMOVE_UGROUP_SUCCESS:
            return {
                ...state,
                loading: false,
                removedUser: action.payload
            }

        case UPDATE_SETTINGS_SUCCESS:
            return {
                ...state,
                loading: false,
                updatedSettings: action.payload
            }

        case UPLOAD_RESOURCE_SUCCESS:
            return {
                ...state,
                loading: false,
                uploadedResource: action.payload
            }

        case CHANGE_ROLE_SUCCESS:
            return {
                ...state,
                loading: false,
                updatedRole: action.payload
            }

        case SEARCH_GROUP_SUCCESS:
            return {
                ...state,
                loading: false,
                groups: action.payload,
                discGroups: []
            }

        case SEARCH_DISCOVER_SUCCESS:
            return {
                ...state,
                loading: false,
                discGroups: action.payload,
                groups: []
            }

        case DELETE_GROUP_SUCCESS:
            return {
                ...state,
                loading: false,
                deletedGroup: action.payload.success,
            }

        case JOIN_GROUP_FAIL:
        case UPDATE_SETTINGS_FAIL:
        case UPLOAD_RESOURCE_FAIL:
        case CHANGE_ROLE_FAIL:
        case SEARCH_GROUP_FAIL:
        case SEARCH_DISCOVER_FAIL:
        case REMOVE_UGROUP_FAIL:
        case DELETE_GROUP_FAIL:
            return {
                // ...state,
                loading: false,
                error: action.payload
            }

        case JOIN_GROUP_RESET:
            return {
                ...state,
                hasJoined: false
            }

        case REMOVE_UGROUP_RESET:
            return {
                ...state,
                removedUser: false
            }

        case UPDATE_SETTINGS_RESET:
            return {
                ...state,
                updatedSettings: false
            }

        case UPLOAD_RESOURCE_RESET:
            return {
                ...state,
                uploadedResource: false
            }

        case CHANGE_ROLE_RESET:
            return {
                ...state,
                updatedRole: false
            }

        case SEARCH_GROUP_RESET:
            return {
                ...state,
                groups: []
            }

        case DELETE_GROUP_RESET:
            return {
                ...state,
                loading: false,
                deletedGroup: false
            }

        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }
    
        default:
            return state
    }
}