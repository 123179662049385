import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Sidebar from './Sidebar';
import DefaultLogo from '../../../assets/smilelogo.png';

const Report = () => {
    const navigate = useNavigate();

  return (
    <div class="m-0 font-sans text-base antialiased font-normal leading-default text-slate-500">

        <main class="relative h-full max-h-screen transition-all duration-200 ease-in-out xl:ml-68 rounded-xl">
            <nav class="fixed top-0 flex flex-wrap items-center justify-between px-0 py-2 mx-6 transition-all ease-in shadow-none duration-250 rounded-2xl lg:flex-nowrap lg:justify-start" navbar-main navbar-scroll="false">
                <div class="flex items-center justify-between w-full px-4 py-1 mx-auto flex-wrap-inherit">
                    <nav>
                        <Link to="/admin/dashboard">
                            <img className='w-12' src={DefaultLogo} />
                        </Link>
                    </nav>
                </div>
            </nav>

        <Sidebar />
        <div className='mt-[80px] ml-14 mb-10 md:ml-64 px-6 py-6 grid grid-cols-8 gap-6'>
            <button className="border-4 border-[#8C1515] h-[100px] col-span-2 rounded-lg hover:scale-105 duration-300" onClick={() => navigate('/admin/questions')}>Questions</button>
            <button className="border-4 border-[#8C1515] h-[100px] col-span-2 rounded-lg hover:scale-105 duration-300" onClick={() => navigate('/admin/answers')}>Responses</button>
        </div>
        
        </main>
    </div>
  )
}

export default Report