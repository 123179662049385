import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { getAllActivities } from '../../../actions/adminActions';
import Sidebar from './Sidebar'
import DefaultLogo from '../../../assets/smilelogo.png';
import { FaLock } from 'react-icons/fa';
import DataTable from 'react-data-table-component';
import moment from 'moment';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ReactGA from 'react-ga4';

const ListActivities = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const [searchName, setSearchName] = useState('');
    const [filteredData, setFilteredData] = useState([]);
    const [dateRange, setDateRange] = useState([null, null]);

    const { activities, questionsCount, latestQuestions, error, loading } = useSelector(state => state.adminUsers);
    const { permissions } = useSelector(state => state.auth);

    const isAllowed = permissions?.find((element) => element?.levelModulePermissionId?.name === 'Admin.Activities.Read');
    const allowEdit = permissions?.find((element) => element?.levelModulePermissionId?.name === 'Admin.Activities.Write');

    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: (location.pathname + location.search), title: "Admin Dashboard Activities List" });
    }, [location]);

    const handleDateChange = (dates) => {
        setDateRange([dates[0], dates[1]]);
    };

    const filteredActivities = activities?.filter((group) => {
        const createdAt = new Date(group.createdAt);
        return (
          (!dateRange[0] || createdAt >= dateRange[0]) &&
          (!dateRange[1] || createdAt <= dateRange[1])
        );
    });

    const columns = [
        {
            name: 'Name',
            selector: row => row.name,
            sortable: true,
            wrap: true, // enable wrap
            style: { whiteSpace: "normal" },
        },
        {
            name: 'Group',
            selector: row => row?.owningGroup?.name,
            sortable: true,
            wrap: true, // enable wrap
            style: { whiteSpace: "normal" },
        },
        {
            name: 'Description',
            selector: row => row.description,
            sortable: true,
            wrap: true, // enable wrap
            style: { whiteSpace: "normal" },
        },
        {
            name: 'Activity Type',
            selector: row => (row.activityType === "StudentPaced" ? "Student Paced" : "Organizer Paced"),
            sortable: true,
        },
        {
            name: 'Questions',
            selector: row => (questionsCount?.find(element => element._id === row._id)?.count || 0),
            sortable: true,
        },
        {
            name: 'Latest Question',
            selector: row => (latestQuestions?.find(element => element._id === row._id)?.latestQuestion ? moment(latestQuestions?.find(element => element._id === row._id)?.latestQuestion).utc().format('MM/DD/YYYY') : "n/a"),
            sortable: true,
        },
        {
            name: 'Actions',
            // selector: row => (allowEdit ? <FaRegEdit className='cursor-pointer' /> : <ImBlocked />),
        },
    ];

    useEffect(() => {

        dispatch(getAllActivities())

    }, [dispatch]);

    const handleFilter = e => {
        const value = e.target.value
        let updatedData = []
        setSearchName(value)

        if (value.length) {
            updatedData = activities.filter(item => {
                const startsWith =
                item.name.toLowerCase().startsWith(value.toLowerCase()) 

                const includes =
                item.name.toLowerCase().includes(value.toLowerCase())

                if (startsWith) {
                    return startsWith
                } else if (!startsWith && includes) {
                    return includes
                } else return null
            })
            setFilteredData(updatedData)
            setSearchName(value)
        }
    };

  return (
    <div className="m-0 font-sans text-base antialiased font-normal leading-default text-slate-500">

    <main className="relative h-full max-h-screen transition-all duration-200 ease-in-out xl:ml-68 rounded-xl">
      <nav className="fixed top-0 flex flex-wrap items-center justify-between px-0 py-2 mx-6 transition-all ease-in shadow-none duration-250 rounded-2xl lg:flex-nowrap lg:justify-start" navbar-main navbar-scroll="false">
        <div className="flex items-center justify-between w-full px-4 py-1 mx-auto flex-wrap-inherit">
          <nav>
          <Link to="/admin/dashboard">
              <img className='w-12' src={DefaultLogo} />
          </Link>
          </nav>
        </div>
      </nav>

      <Sidebar />
        {isAllowed ? <div className='mt-[80px] ml-14 mb-10 md:ml-64 px-6 py-6 mx-auto'>
            <div className='flex justify-between'>
                <h4 class="mb-4 text-lg font-semibold text-gray-600 dark:text-gray-300">All Activities</h4>
                <input value={searchName} onChange={handleFilter} placeholder="Search..." type="text" className="p-2 mb-4 border border-gray-600 w-[200px] rounded-lg" />
            </div>
            <div className='flex flex-row'>
                <p className='w-1/3'>Filter By Date</p>
                <DatePicker
                    selected={dateRange[0]}
                    onChange={(date) => handleDateChange([date, dateRange[1]])}
                    selectsStart
                    startDate={dateRange[0]}
                    endDate={dateRange[1]}
                    maxDate={new Date()}
                    placeholderText="Start Date"
                />

                <DatePicker
                    selected={dateRange[1]}
                    onChange={(date) => handleDateChange([dateRange[0], date])}
                    selectsEnd
                    startDate={dateRange[0]}
                    endDate={dateRange[1]}
                    minDate={dateRange[0]}
                    maxDate={new Date()}
                    placeholderText="End Date"
                />
            </div>
            <DataTable
                className='tableData'
                columns={columns}
                data={searchName.length ? filteredData : filteredActivities}
                progressPending={loading}
                pagination
            />
        </div> :
        <div className='mt-[80px] pt-[50px] ml-14 mb-10 md:ml-64 flex flex-col items-center h-full justify-center'>
            <h4 className="text-lg font-semibold mb-6 text-gray-600">Forbidden 403</h4>
            <FaLock className='w-[300px] h-[300px]' />
            <h4 className="text-lg font-semibold text-gray-600">Access denied!</h4>
            <p className=" text-gray-600 font-light">Please check with the site admin if you believe this is a mistake.</p>
        </div>}

    </main>
    </div>
  )
}

export default ListActivities