import axios from "axios"
import { ALL_GROUPS_FAIL, ALL_GROUPS_REQUEST, ALL_GROUPS_SUCCESS, CHANGE_ROLE_FAIL, CHANGE_ROLE_REQUEST, CHANGE_ROLE_SUCCESS, CLEAR_ERRORS, DELETE_GROUP_FAIL, DELETE_GROUP_REQUEST, DELETE_GROUP_SUCCESS, DISCOVER_GROUPS_FAIL, DISCOVER_GROUPS_REQUEST, DISCOVER_GROUPS_SUCCESS, GET_MEMBERS_FAIL, GET_MEMBERS_REQUEST, GET_MEMBERS_SUCCESS, GET_RESOURCES_FAIL, GET_RESOURCES_REQUEST, GET_RESOURCES_SUCCESS, GROUP_USERS_FAIL, GROUP_USERS_REQUEST, GROUP_USERS_SUCCESS, JOIN_GROUP_FAIL, JOIN_GROUP_REQUEST, JOIN_GROUP_SUCCESS, MY_GROUPS_FAIL, MY_GROUPS_REQUEST, MY_GROUPS_SUCCESS, NEW_GROUP_FAIL, NEW_GROUP_REQUEST, NEW_GROUP_SUCCESS, REMOVE_UGROUP_FAIL, REMOVE_UGROUP_REQUEST, REMOVE_UGROUP_SUCCESS, SEARCH_DISCOVER_FAIL, SEARCH_DISCOVER_REQUEST, SEARCH_DISCOVER_SUCCESS, SEARCH_GROUP_FAIL, SEARCH_GROUP_REQUEST, SEARCH_GROUP_SUCCESS, SINGLE_GROUP_FAIL, SINGLE_GROUP_REQUEST, SINGLE_GROUP_SUCCESS, UPDATE_SETTINGS_FAIL, UPDATE_SETTINGS_REQUEST, UPDATE_SETTINGS_SUCCESS, UPLOAD_RESOURCE_FAIL, UPLOAD_RESOURCE_REQUEST, UPLOAD_RESOURCE_SUCCESS } from "../constants/groupConstants"

export const getGroups = () => async (dispatch) => {
    try {
        dispatch({ type: ALL_GROUPS_REQUEST })
        
        const { data } = await axios.get(`/api/group/all`)
        dispatch({
            type: ALL_GROUPS_SUCCESS,
            payload: data.groups
        })
    } catch (error) {
        dispatch({
            type: ALL_GROUPS_FAIL,
            payload: error.response.data.message
        })
    }
}

export const getGroupUsers = () => async (dispatch) => {
    try {
        dispatch({ type: GROUP_USERS_REQUEST })
        
        const { data } = await axios.get(`/api/group/users`)
        dispatch({
            type: GROUP_USERS_SUCCESS,
            payload: data.users
        })
    } catch (error) {
        dispatch({
            type: GROUP_USERS_FAIL,
            payload: error.response.data.message
        })
    }
}

export const getMyGroups = (id, sortParam, sortUpdatedAt2, sortCreatedAt2, sortbyName2) => async (dispatch) => {
    try {
        dispatch({ type: MY_GROUPS_REQUEST })
        const { data } = await axios.get(`/api/group/mygroups/${id}?sortBy=${sortParam}&order=${(sortParam === 'updatedAt' ? sortUpdatedAt2 : sortParam === 'name' ? sortbyName2 : sortCreatedAt2) ? 'asc' : 'desc'}`)
        dispatch({
            type: MY_GROUPS_SUCCESS,
            payload: data.groups
        })
    } catch (error) {
        dispatch({
            type: MY_GROUPS_FAIL,
            payload: error.response.data.message
        })
    }
}

export const discoverGroups = (id, sortParam2, sortCreatedAt, sortUpdatedAt, sortbyName) => async (dispatch) => {
    try {
        dispatch({ type: DISCOVER_GROUPS_REQUEST })
        
        const { data } = await axios.get(`/api/group/discovergroups/${id}?sortBy=${sortParam2}&order=${(sortParam2 === 'updatedAt' ? sortUpdatedAt : sortParam2 === 'name' ? sortbyName : sortCreatedAt) ? 'asc' : 'desc'}`)
        dispatch({
            type: DISCOVER_GROUPS_SUCCESS,
            payload: data.groups
        })
    } catch (error) {
        dispatch({
            type: DISCOVER_GROUPS_FAIL,
            payload: error.response.data.message
        })
    }
}

export const createGroup = (creator, name, description, isPrivate, grpasscode, groupImage, requirePasscode) => async (dispatch) => {
    try {
        dispatch({ type: NEW_GROUP_REQUEST })

        const config = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }
        const { data } = await axios.post(`/api/group/create`, { creator, name, description, isPrivate, grpasscode, groupImage, requirePasscode }, config)
        dispatch({
            type: NEW_GROUP_SUCCESS,
            payload: data
        })
    } catch (error) {
        dispatch({
            type: NEW_GROUP_FAIL,
            payload: error.response.data.message
        })
    }
}

export const joinGroup = (student, group, gpassword) => async (dispatch) => {
    try {
        dispatch({ type: JOIN_GROUP_REQUEST })

        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }
        const { data } = await axios.post(`/api/group/join`, {student, group, gpassword}, config)
        dispatch({
            type: JOIN_GROUP_SUCCESS,
            payload: data.joinedGroup
        })
    } catch (error) {
        dispatch({
            type: JOIN_GROUP_FAIL,
            payload: error.response.data.error
        })
    }
}

export const removeUserGroup = (users, group) => async (dispatch) => {
    try {
        dispatch({ type: REMOVE_UGROUP_REQUEST })

        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }
        const { data } = await axios.post(`/api/group/removeusers`, { users, group }, config)
        dispatch({
            type: REMOVE_UGROUP_SUCCESS,
            payload: data.success
        })
    } catch (error) {
        dispatch({
            type: REMOVE_UGROUP_FAIL,
            payload: error.response.data.error
        })
    }
}

export const getSingleGroup = (id, user) => async (dispatch) => {
    try {
        dispatch({ type: SINGLE_GROUP_REQUEST })

        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }
        const { data } = await axios.post(`/api/group/${id}`, {id, user}, config)
        dispatch({
            type: SINGLE_GROUP_SUCCESS,
            payload: data.group
        })
    } catch (error) {
        dispatch({
            type: SINGLE_GROUP_FAIL,
            payload: error.response.data.message
        })
    }
}

export const updateGroupSettings = (id, grName, grDescription, isPrivate, grpasscode, requirepasscode, groupImage) => async (dispatch) => {
    try {
        dispatch({ type: UPDATE_SETTINGS_REQUEST })

        const config = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }
        const { data } = await axios.post(`/api/group/update/${id}`, {grName, grDescription, isPrivate, grpasscode, requirepasscode, groupImage}, config)
        dispatch({
            type: UPDATE_SETTINGS_SUCCESS,
            payload: data.success
        })
    } catch (error) {
        dispatch({
            type: UPDATE_SETTINGS_FAIL,
            payload: error.response.data.message
        })
    }
}

export const uploadResource = (creator, group, doc) => async (dispatch) => {
    try {
        dispatch({ type: UPLOAD_RESOURCE_REQUEST })

        const config = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }
        const { data } = await axios.post(`/api/group/upload/resource`, {creator, group, doc}, config)
        dispatch({
            type: UPLOAD_RESOURCE_SUCCESS,
            payload: data.resource
        })
    } catch (error) {
        dispatch({
            type: UPLOAD_RESOURCE_FAIL,
            payload: error.response.data.error
        })
    }
}

export const getGroupResources = (id) => async (dispatch) => {
    try {
        dispatch({ type: GET_RESOURCES_REQUEST })

        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }
        const { data } = await axios.get(`/api/group/resources/${id}`, config)
        dispatch({
            type: GET_RESOURCES_SUCCESS,
            payload: data.resources
        })
    } catch (error) {
        dispatch({
            type: GET_RESOURCES_FAIL,
            payload: error.response.data.message
        })
    }
}

export const getGroupMembers = (id) => async (dispatch) => {
    try {
        dispatch({ type: GET_MEMBERS_REQUEST })

        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }
        const { data } = await axios.get(`/api/group/members/${id}`, config)
        dispatch({
            type: GET_MEMBERS_SUCCESS,
            payload: data.members
        })
    } catch (error) {
        dispatch({
            type: GET_MEMBERS_FAIL,
            payload: error.response.data.message
        })
    }
}

export const changeRole = (users, group, role) => async (dispatch) => {
    try {
        dispatch({ type: CHANGE_ROLE_REQUEST })

        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }
        const { data } = await axios.put(`/api/group/role/update`, { users, group, role }, config)
        dispatch({
            type: CHANGE_ROLE_SUCCESS,
            payload: data.success
        })
    } catch (error) {
        dispatch({
            type: CHANGE_ROLE_FAIL,
            payload: error.response.data.message
        })
    }
}

export const searchGroup = (groupName, id) => async (dispatch) => {
    try {
        dispatch({ type: SEARCH_GROUP_REQUEST })

        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }
        const { data } = await axios.get(`/api/group/search/${groupName}/${id}`, config)
        dispatch({
            type: SEARCH_GROUP_SUCCESS,
            payload: data.groups
        })
    } catch (error) {
        dispatch({
            type: SEARCH_GROUP_FAIL,
            payload: error.response.data.message
        })
    }
}

export const searchDiscoverGroups = (groupName, id) => async (dispatch) => {
    try {
        dispatch({ type: SEARCH_DISCOVER_REQUEST })

        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }
        const { data } = await axios.get(`/api/group/discoversearch/${groupName}/${id}`, config)
        dispatch({
            type: SEARCH_DISCOVER_SUCCESS,
            payload: data.groups
        })
    } catch (error) {
        dispatch({
            type: SEARCH_DISCOVER_FAIL,
            payload: error.response.data.message
        })
    }
}

export const deleteGroup = (id) => async (dispatch) => {
    try {
        dispatch({ type: DELETE_GROUP_REQUEST })

        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }
        const { data } = await axios.delete(`/api/group/${id}`, config)
        dispatch({
            type: DELETE_GROUP_SUCCESS,
            payload: data
        })
    } catch (error) {
        dispatch({
            type: DELETE_GROUP_FAIL,
            payload: error.response.data.error
        })
    }
}

export const clearErrors = () => async (dispatch) => {
    dispatch({
        type: CLEAR_ERRORS
    })
}