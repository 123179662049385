import React, { useEffect, useState } from 'react';
import { MdArrowBackIos } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams, useLocation, Link } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { BiBaseball, BiHide, BiLockAlt, BiTime } from 'react-icons/bi';
import { createActivity } from '../../actions/activityActions';
import ReactGA from 'react-ga4';

const CreateActivity = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [activityType, setActivityType] = useState('StudentPaced');
    const [oneStar, setOneStar] = useState('');
    const [twoStar, setTwoStar] = useState('');
    const [threeStar, setThreeStar] = useState('');
    const [fourStar, setFourStar] = useState('');
    const [fiveStar, setFiveStar] = useState('');
    const [hideUsernames, setHideUsernames] = useState(false);
    const [hideActivities, setHideActivities] = useState(true);

    const { user } = useSelector(state => state.auth);
    const { createdActivity, loading } = useSelector(state => state?.newActivity);
    const creator = user?._id;
    const { id } = useParams();

    useEffect(() => {
        function handleResize() {
            if (window.innerWidth >= 768) { 
                navigate(`/group/${id}`);
            }
        }
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect (() => {
        if (createdActivity) {
            navigate(`/group/${id}`);
        }
    }, [createdActivity]);

    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: (location.pathname + location.search), title: "Create Activity" });
    }, [location]);

    const createActivityHandler = () => {
        const owningGroup = id;
        const gradeLevels = {oneStar, twoStar, threeStar, fourStar, fiveStar}
        dispatch(createActivity(creator, name, description, owningGroup, activityType, gradeLevels, hideUsernames, hideActivities));
    };

  return (
    <div>
        <ToastContainer autoClose={1000} />
        <div className='h-[58px] flex justify-between items-center p-3 border-b border-gray-300'>
            <MdArrowBackIos className='cursor-pointer' onClick={() => navigate(`/group/${id}`)} />
            <p className='createGroupLabel'>Create Activity</p>
            <p></p>
        </div>
        <div className=''>
            <div className="mb-4 pt-4 px-4">
                <label className=" createFormLabels block text-sm mb-2" htmlFor="group_name">
                    Activity Name
                </label>
                <input value={name} onChange={(e) => setName(e.target.value)} className="border rounded w-full py-2 px-3" id="group_name" type="text" />
            </div>
            <div className="mb-4 px-4">
                <label className=" createFormLabels block text-sm mb-2" htmlFor="group_description">
                    Activity Description
                </label>
                <textarea value={description} onChange={(e) => setDescription(e.target.value)} id="group_description" rows="3" className="mt-1 py-2 px-3 block w-full rounded border ">
                </textarea>
            </div>
            <div className="mb-4 pt-4">
                <label className="h-[45px] bg-[#CBD2E0] px-4 flex items-center createFormLabels text-sm mb-2" htmlFor="activityType">
                    Activity Type
                </label>
            </div>
            <div className="px-4" id='activityType'>
                <label className='gap-2 inline-flex items-center mb-2'>
                    <div className='flex flex-row gap-2'>
                        <div className='w-4'>
                            <BiBaseball className='w-4 h-4' />
                        </div>
                        <div className='flex flex-col'>
                            <p className='activityTypeCtitle'>Student Mode</p>
                            <p className='activityTypeCinfo'>Student can create, answer and review questions at their individual pace</p>
                        </div>
                    </div>
                    <input value="StudentPaced" checked={activityType === 'StudentPaced'} onChange={(e) => setActivityType(e.target.value)} name="activitytype" type="radio" className="form-radio" />
                </label>
                <label className='gap-2 inline-flex items-center'>
                    <div className='flex flex-row gap-2'>
                        <div className='w-4'>
                            <BiTime className='w-4 h-4' />
                        </div>
                        <div className='flex flex-col'>
                            <p className='activityTypeCtitle'>Exam Mode</p>
                            <p className='activityTypeCinfo'>The organizer controls the pace of the activity. All students are either in create-question mode, answer-question mode, or review mode.</p>
                        </div>
                    </div>
                    <input type="radio" value="OrganizerPaced" checked={activityType === 'OrganizerPaced'} onChange={(e) => setActivityType(e.target.value)} name="activitytype" className="form-radio" />
                </label>
            </div>

            <div className="mb-4 pt-4">
                <label className="h-[45px] bg-[#CBD2E0] px-4 flex items-center createFormLabels text-sm mb-2" htmlFor="activityType">
                    Setting
                </label>
            </div>
            <div className="px-4" id='activityType'>
                <label className='gap-2 inline-flex items-center mb-2'>
                    <div className='flex flex-row gap-2'>
                        <div className='w-4'>
                            <BiHide className='w-4 h-4' />
                        </div>
                        <div className='flex flex-col'>
                            <p className='activityTypeCtitle'>Hide Username</p>
                            <p className='activityTypeCinfo'>
                                Participants can see who created each question. To make questions anonymous, check this box.
                            </p>
                        </div>
                    </div>
                    <div className="flex">
                        <label className="inline-flex relative items-center cursor-pointer">
                            <input
                                type="checkbox"
                                className="sr-only peer"
                                checked={hideUsernames}
                                readOnly
                            />
                            <div onClick={() => { setHideUsernames(!hideUsernames); }}
                                className="w-[28px] h-[16px] bg-gray-200 rounded-full peer  peer-focus:black peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-[12px] after:w-[12px] after:transition-all peer-checked:bg-black"
                            ></div>
                        </label>
                    </div>
                    
                </label>
                <label className='gap-2 inline-flex items-center'>
                    <div className='flex flex-row gap-2'>
                        <div className='w-4'>
                            <BiLockAlt className='w-4 h-4' />
                        </div>
                        <div className='flex flex-col'>
                            <p className='activityTypeCtitle'>Visible</p>
                            <p className='activityTypeCinfo'>
                                Students can see all activities by default. To hide this activity from students, un-check this box.
                            </p>
                        </div>
                    </div>
                    <div className="flex">
                        <label className="inline-flex relative items-center cursor-pointer">
                            <input
                                type="checkbox"
                                className="sr-only peer"
                                checked={hideActivities}
                                readOnly
                            />
                            <div onClick={() => { setHideActivities(!hideActivities); }}
                                className="w-[28px] h-[16px] bg-gray-200 rounded-full peer  peer-focus:black peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-[12px] after:w-[12px] after:transition-all peer-checked:bg-black"
                            ></div>
                        </label>
                    </div>
                </label>
            </div>
            <div className="mb-4 pt-4">
                <label className="h-[45px] bg-[#CBD2E0] px-4 flex items-center createFormLabels text-sm mb-2" htmlFor="activityType">
                    Rubric
                </label>
            </div>
            <div className='px-8 pb-4'>
                <p className='activityTypeCinfo'>Please provide the description used on each star.</p>
                <div className='flex flex-col gap-3 py-2'>
                    <div className='flex flex-col w-full'>
                        <div className='flex flex-row justify-between gap-1 w-full'>
                            <svg aria-hidden="true" className="w-5 h-5 text-yellow-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><title>First star</title><path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path></svg>
                            <p className='rubricInfo'>1 Star</p>
                        </div>
                        <input type='text' value={oneStar} onChange={(e) => {setOneStar(e.target.value);}} className='inputBasic flex w-full]' placeholder='Needs severe improvement' />
                    </div>

                    <div className='flex flex-col w-full'>
                        <div className='flex flex-row justify-between gap-1 w-full'>
                            <div className="flex items-center">
                                <svg aria-hidden="true" className="w-5 h-5 text-yellow-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><title>First star</title><path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path></svg>
                                <svg aria-hidden="true" className="w-5 h-5 text-yellow-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><title>Second star</title><path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path></svg>
                            </div>
                            <p className='rubricInfo'>2 Stars</p>
                        </div>
                        <input type='text' value={twoStar} onChange={(e) => {setTwoStar(e.target.value);}} className='inputBasic flex w-full]' placeholder='Lacking in skill; Can improve in several places' />
                    </div>

                    <div className='flex flex-col w-full'>
                        <div className='flex flex-row justify-between gap-1 w-full'>
                            <div className="flex items-center">
                                <svg aria-hidden="true" className="w-5 h-5 text-yellow-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><title>First star</title><path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path></svg>
                                <svg aria-hidden="true" className="w-5 h-5 text-yellow-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><title>Second star</title><path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path></svg>
                                <svg aria-hidden="true" className="w-5 h-5 text-yellow-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><title>Third star</title><path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path></svg>
                            </div>
                            <p className='rubricInfo'>3 Stars</p>
                        </div>
                        <input type='text' value={threeStar} onChange={(e) => {setThreeStar(e.target.value);}} className='inputBasic flex w-full]' placeholder='Doing okay; Can do better but is working hard' />
                    </div>

                    <div className='flex flex-col w-full'>
                        <div className='flex flex-row justify-between gap-1 w-full'>
                            <div className="flex items-center">
                                <svg aria-hidden="true" className="w-5 h-5 text-yellow-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><title>First star</title><path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path></svg>
                                <svg aria-hidden="true" className="w-5 h-5 text-yellow-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><title>Second star</title><path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path></svg>
                                <svg aria-hidden="true" className="w-5 h-5 text-yellow-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><title>Third star</title><path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path></svg>
                                <svg aria-hidden="true" className="w-5 h-5 text-yellow-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><title>Fourth star</title><path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path></svg>
                            </div>
                            <p className='rubricInfo'>4 Stars</p>
                        </div>
                        <input type='text' value={fourStar} onChange={(e) => {setFourStar(e.target.value);}} className='inputBasic flex w-full]' placeholder='Great job; room for small improvements' />
                    </div>

                    <div className='flex flex-col w-full'>
                        <div className='flex flex-row justify-between gap-1 w-full'>
                            <div className="flex items-center">
                                <svg aria-hidden="true" className="w-5 h-5 text-yellow-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><title>First star</title><path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path></svg>
                                <svg aria-hidden="true" className="w-5 h-5 text-yellow-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><title>Second star</title><path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path></svg>
                                <svg aria-hidden="true" className="w-5 h-5 text-yellow-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><title>Third star</title><path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path></svg>
                                <svg aria-hidden="true" className="w-5 h-5 text-yellow-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><title>Fourth star</title><path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path></svg>
                                <svg aria-hidden="true" className="w-5 h-5 text-yellow-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><title>Fifth star</title><path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path></svg>
                            </div>
                            <p className='rubricInfo'>5 Stars</p>
                        </div>
                        <input type='text' value={fiveStar} onChange={(e) => {setFiveStar(e.target.value);}} className='inputBasic flex w-full]' placeholder='Amazing job; Keep up the great work!' />
                    </div>
                </div>

            </div>
            
            <div className="mb-4 px-4">
                <button className='w-full h-10 mb-3 bg-[#CBD2E0] rounded-[20px]' onClick={() => createActivityHandler()}>
                    {loading ?
                    <>
                    <svg role="status" class="inline mr-3 w-4 h-4 text-white animate-spin" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#E5E7EB"/>
                    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentColor"/>
                    </svg> Save </> : <>Save</>}
                </button>
                <Link to={`/group/${id}`} className='flex justify-center items-center w-full h-10 border rounded-[20px]'>Cancel</Link>
            </div>
        </div>
    </div>
  )
}

export default CreateActivity