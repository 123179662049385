import * as yup from "yup";

export const forgotSchema = yup.object().shape({
  password: yup
    .string()
    .min(6, 'Password is too short - should contain 6 or more characters.')
    .required("Please provide a password"),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password"), null], "Passwords do not match")
    .required("Please provide a confirm password"),
});