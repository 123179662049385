import React, { useEffect, useRef } from 'react';
import autosize from 'autosize';
import { useSelector } from 'react-redux';
import Default from '../../assets/groupdefault.jpg';
const baseUrl = process.env.REACT_APP_IMAGES_BASEURL;

const CommentQuestion = ({ answer, setAnswer, showCommentModal, handleComment, handleAnswerKeyDown, selectedComment, setSelectedComment }) => {
    const textareaRef = useRef(null);
    const { user } = useSelector(state => state.auth);
    useEffect(() => {
        autosize(textareaRef.current); 
    }, []);

    const userImage = user?.avatar?.url ? (user?.avatar?.public_id === null ? (baseUrl + user?.avatar?.url) : user?.avatar?.url) : Default;

  return (
    <div className={`w-full pb-6 z-90 bottom-0 shadow-md right-0 left-0 bg-[#F8F9FA] fixed transition-all duration-500 transform ${showCommentModal ? 'translate-y-0 opacity-100' : 'translate-y-full opacity-0 invisible'}`}>
        <div className='actionHeader h-[50px] border-b border-gray-200 px-4 flex justify-center items-center'>
        <p>Comment</p>
        </div>
        <div className='flex flex-col justify-center w-full gap-2'>
            <div className='flex w-full justify-center mt-6 gap-1'>
                <div className="flex flex-row gap-2 w-full px-4">
                    <img className="rounded-full w-[30px] h-[30px] inline-flex" src={userImage} alt='User profile' />
                    <form onSubmit={handleComment} className="flex resize-none h-auto flex-row w-full bg-[#cbd2e099] rounded-[47px] ">
                        <textarea
                            ref={textareaRef}
                            onKeyDown={handleAnswerKeyDown}
                            rows={1}
                            value={answer}
                            translate="no"
                            onChange={(e) => setAnswer(e.target.value)}
                            className="resize-none h-auto rounded-l-[47px] bg-[#cbd2e099] commentModalInput pr-2 py-2 w-full pl-4 outline-none"
                            type="text" placeholder="Add a comment..." />
                        <button type='submit'
                            className="flex items-center bg-[#cbd2e099] rounded-r-[47px] commentModalLabelInput font-bold pl-2 pr-4">
                            {selectedComment ? "save" : "post"}
                        </button>
                    </form>
                </div>
            </div>
        </div>
    </div>
  )
}

export default CommentQuestion