import axios from "axios"
import { ALL_QUESTIONS_FAIL, ALL_QUESTIONS_REQUEST, ALL_QUESTIONS_SUCCESS, CLEAR_ERRORS, DELETE_QUESTION_FAIL, DELETE_QUESTION_REQUEST, DELETE_QUESTION_SUCCESS, EDIT_QUESTION_FAIL, EDIT_QUESTION_REQUEST, EDIT_QUESTION_SUCCESS, MY_QUESTIONS_FAIL, MY_QUESTIONS_REQUEST, MY_QUESTIONS_SUCCESS, NEW_QUESTION_FAIL, NEW_QUESTION_REQUEST, NEW_QUESTION_SUCCESS, QUESTION_DETAILS_FAIL, QUESTION_DETAILS_REQUEST, QUESTION_DETAILS_SUCCESS, QUESTION_UPLOAD_PROGRESS, RATE_QUESTION_FAIL, RATE_QUESTION_REQUEST, RATE_QUESTION_SUCCESS } from "../constants/questionConstants"
import { GROUP_QUESTIONS_FAIL, GROUP_QUESTIONS_REQUEST, GROUP_QUESTIONS_SUCCESS } from "../constants/groupConstants"

export const getQuestions = (id) => async (dispatch) => {
    try {
        dispatch({ type: ALL_QUESTIONS_REQUEST })
        
        const { data } = await axios.get(`/api/question/${id}`)
        dispatch({
            type: ALL_QUESTIONS_SUCCESS,
            payload: data
        })
    } catch (error) {
        console.log(error)
        dispatch({
            type: ALL_QUESTIONS_FAIL,
            payload: error?.response?.data
        })
    }
}

export const getGroupQuestions = (id, activityId, creatorId) => async (dispatch) => {
    try {
        dispatch({ type: GROUP_QUESTIONS_REQUEST })
        
        const { data } = await axios.get(`/api/question/group/${id}?activityId=${activityId}&creatorId=${creatorId}`)
        dispatch({
            type: GROUP_QUESTIONS_SUCCESS,
            payload: data
        })
    } catch (error) {
        dispatch({
            type: GROUP_QUESTIONS_FAIL,
            payload: error.response.data.message
        })
    }
}

export const createQuestion = (creator, questionType, choices, content, correctAnswers, activity, questionImage, timeDiff, keywords) => async (dispatch) => {
    try {
        dispatch({ type: NEW_QUESTION_REQUEST })

        const config = {
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            onUploadProgress: (progressEvent) => {
                const progress = Math.round(
                    (progressEvent.loaded * 100) / progressEvent.total
                );
                dispatch({ type: QUESTION_UPLOAD_PROGRESS, payload: progress });
            },
        }
        const { data } = await axios.post(`/api/question/create`, {creator, questionType, choices, content, correctAnswers, activity, questionImage, timeDiff, keywords}, config)
        dispatch({
            type: NEW_QUESTION_SUCCESS,
            payload: data
        })
    } catch (error) {
        dispatch({
            type: NEW_QUESTION_FAIL,
            payload: error.response.data.error
        })
    }
}

export const editQuestion = ({ id, content, questionType, choices, correctAnswers, questionImage }) => async (dispatch) => {
    try {
        dispatch({ type: EDIT_QUESTION_REQUEST })

        const config = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }
        const { data } = await axios.put(`/api/question/${id}`, { content, questionType, choices, correctAnswers, questionImage }, config)
        dispatch({
            type: EDIT_QUESTION_SUCCESS,
            payload: data
        })
    } catch (error) {
        dispatch({
            type: EDIT_QUESTION_FAIL,
            payload: error.response.data.message
        })
    }
}

export const deleteQuestion = ({ id }) => async (dispatch) => {
    try {
        dispatch({ type: DELETE_QUESTION_REQUEST })

        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }
        const { data } = await axios.delete(`/api/question/${id}`, config)
        dispatch({
            type: DELETE_QUESTION_SUCCESS,
            payload: data
        })
    } catch (error) {
        dispatch({
            type: DELETE_QUESTION_FAIL,
            payload: error.response.data.message
        })
    }
}

export const rateQuestion = (creator, rating, question) => async (dispatch) => {
    try {
        dispatch({ type: RATE_QUESTION_REQUEST })

        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }
        const { data } = await axios.post(`/api/question/rate`, { creator, rating, question }, config)
        dispatch({
            type: RATE_QUESTION_SUCCESS,
            payload: data
        })
    } catch (error) {
        dispatch({
            type: RATE_QUESTION_FAIL,
            payload: error.response.data.message
        })
    }
}

export const getQuestionDetails = (id) => async (dispatch) => {
    try {
        dispatch({ type: QUESTION_DETAILS_REQUEST })
        const { data } = await axios.get(`/api/question/single/${id}`)
        dispatch({
            type: QUESTION_DETAILS_SUCCESS,
            payload: data
        })
    } catch (error) {
        dispatch({
            type: QUESTION_DETAILS_FAIL,
            payload: error.response.data.message
        })
    }
}

export const getMyQuestions = (id) => async (dispatch) => {
    try {
        dispatch({ type: MY_QUESTIONS_REQUEST })
        
        const { data } = await axios.get(`/api/question/my/questions/${id}`)
        dispatch({
            type: MY_QUESTIONS_SUCCESS,
            payload: data.questions
        })
    } catch (error) {
        dispatch({
            type: MY_QUESTIONS_FAIL,
            payload: error.response.data.message
        })
    }
}

export const clearErrors = () => async (dispatch) => {
    dispatch({
        type: CLEAR_ERRORS
    })
}