import axios from "axios"
import { CLEAR_ERRORS, DELETE_COMMENT_FAIL, DELETE_COMMENT_REQUEST, DELETE_COMMENT_SUCCESS, EDIT_RESPONSE_FAIL, EDIT_RESPONSE_REQUEST, EDIT_RESPONSE_SUCCESS, GET_RESPONSES_FAIL, GET_RESPONSES_REQUEST, GET_RESPONSES_SUCCESS, NEW_RCHOICE_FAIL, NEW_RCHOICE_REQUEST, NEW_RCHOICE_SUCCESS, NEW_RESPONSE_FAIL, NEW_RESPONSE_REQUEST, NEW_RESPONSE_SUCCESS } from "../constants/responseConstants"

export const getAllResponses = (id) => async (dispatch) => {
    try {
        dispatch({ type: GET_RESPONSES_REQUEST })
        
        const { data } = await axios.get(`/api/response/${id}`)
        dispatch({
            type: GET_RESPONSES_SUCCESS,
            payload: data.responses
        })
    } catch (error) {
        dispatch({
            type: GET_RESPONSES_FAIL,
            payload: error.response.data.message
        })
    }
}

export const createResponse = (creator, content, question, rating, timeThink, timeType, choice) => async (dispatch) => {
    try {
        dispatch({ type: NEW_RESPONSE_REQUEST })

        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }
        const { data } = await axios.post(`/api/response/create`, {creator, content, question, rating, timeThink, timeType, choice}, config)
        dispatch({
            type: NEW_RESPONSE_SUCCESS,
            payload: data
        })
    } catch (error) {
        dispatch({
            type: NEW_RESPONSE_FAIL,
            payload: error.response.data.message
        })
    }
}

export const editResponse = ({ id, content }) => async (dispatch) => {
    try {
        dispatch({ type: EDIT_RESPONSE_REQUEST })

        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }
        const { data } = await axios.put(`/api/response/${id}`, { content }, config)
        dispatch({
            type: EDIT_RESPONSE_SUCCESS,
            payload: data
        })
    } catch (error) {
        dispatch({
            type: EDIT_RESPONSE_FAIL,
            payload: error.response.data.message
        })
    }
}

export const createResponseChoice = (creator, question, choices) => async (dispatch) => {
    try {
        dispatch({ type: NEW_RCHOICE_REQUEST })

        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }
        const { data } = await axios.post(`/api/response/choices/submit`, { creator, question, choices }, config)
        dispatch({
            type: NEW_RCHOICE_SUCCESS,
            payload: data
        })
    } catch (error) {
        dispatch({
            type: NEW_RCHOICE_FAIL,
            payload: error.response.data.message
        })
    }
}

export const deleteComment = ({ id, deletedBy }) => async (dispatch) => {
    try {
        dispatch({ type: DELETE_COMMENT_REQUEST })

        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }
        const { data } = await axios.delete(`/api/response/${id}/${deletedBy}`, config)
        dispatch({
            type: DELETE_COMMENT_SUCCESS,
            payload: data
        })
    } catch (error) {
        dispatch({
            type: DELETE_COMMENT_FAIL,
            payload: error.response.data.message
        })
    }
}

export const clearErrors = () => async (dispatch) => {
    dispatch({
        type: CLEAR_ERRORS
    })
}