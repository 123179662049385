import { ACTIVITIES_COUNT_FAIL, ACTIVITIES_COUNT_REQUEST, ACTIVITIES_COUNT_SUCCESS, GET_ACTIVITIES_FAIL, GET_ACTIVITIES_REQUEST, GET_ACTIVITIES_SUCCESS, ALL_ADMINS_FAIL, ALL_ADMINS_REQUEST, ALL_ADMINS_SUCCESS, ADMIN_GROUPS_FAIL, ADMIN_GROUPS_REQUEST, ADMIN_GROUPS_SUCCESS, ALL_HISTORIES_FAIL, ALL_HISTORIES_REQUEST, ALL_HISTORIES_SUCCESS, ALL_MONTHS_FAIL, ALL_MONTHS_REQUEST, ALL_MONTHS_SUCCESS, ALL_USERS_FAIL, ALL_USERS_REQUEST, ALL_USERS_SUCCESS, CHANGE_ROLE_FAIL, CHANGE_ROLE_REQUEST, CHANGE_ROLE_RESET, CHANGE_ROLE_SUCCESS, CLEAR_ERRORS, CREATE_ADMIN_FAIL, CREATE_ADMIN_REQUEST, CREATE_ADMIN_RESET, CREATE_ADMIN_SUCCESS, CREATE_ROLE_FAIL, CREATE_ROLE_REQUEST, CREATE_ROLE_RESET, CREATE_ROLE_SUCCESS, GET_PERMISSIONS_FAIL, GET_PERMISSIONS_REQUEST, GET_PERMISSIONS_RESET, GET_PERMISSIONS_SUCCESS, GET_PROFILE_FAIL, GET_PROFILE_REQUEST, GET_PROFILE_SUCCESS, GET_ROLES_FAIL, GET_ROLES_REQUEST, GET_ROLES_SUCCESS, GROUPS_COUNT_FAIL, GROUPS_COUNT_REQUEST, GROUPS_COUNT_SUCCESS, LEVEL_DATA_FAIL, LEVEL_DATA_REQUEST, LEVEL_DATA_SUCCESS, QUESTIONS_COUNT_FAIL, QUESTIONS_COUNT_REQUEST, QUESTIONS_COUNT_SUCCESS, QUESTION_MONTHS_FAIL, QUESTION_MONTHS_REQUEST, QUESTION_MONTHS_SUCCESS, USERS_COUNT_FAIL, USERS_COUNT_REQUEST, USERS_COUNT_SUCCESS, GET_MEMBERS_REQUEST, GET_MEMBERS_SUCCESS, GET_MEMBERS_FAIL, ADMIN_QUESTIONS_REQUEST, ADMIN_QUESTIONS_SUCCESS, ADMIN_QUESTIONS_FAIL, ADMIN_ANSWERS_REQUEST, ADMIN_ANSWERS_SUCCESS, ADMIN_ANSWERS_FAIL } from "../constants/adminConstants";

export const countStatsReducer = (state = { usersCount: 0, groupsCount: 0, activitiesCount: 0, questionsCount: 0, users: [], admins: [], histories: [], months: [], questionMonths: [] }, action) => {
    switch (action.type) {
        case USERS_COUNT_REQUEST:
        case GROUPS_COUNT_REQUEST:
        case ACTIVITIES_COUNT_REQUEST:
        case QUESTIONS_COUNT_REQUEST:
        case ALL_USERS_REQUEST:
        case CREATE_ADMIN_REQUEST:
        case ALL_ADMINS_REQUEST:
        case ALL_HISTORIES_REQUEST:
        case ALL_MONTHS_REQUEST:
        case QUESTION_MONTHS_REQUEST:
        case GET_ROLES_REQUEST:
            return {
                ...state,
                loading: true
            }

        case USERS_COUNT_SUCCESS:
            return {
                ...state,
                loading: false,
                usersCount: action.payload
            }

        case GROUPS_COUNT_SUCCESS:
            return {
                ...state,
                loading: false,
                groupsCount: action.payload
            }

        case ALL_USERS_SUCCESS:
            return {
                ...state,
                loading: false,
                users: action.payload.users,
                eachUserGroupCount: action.payload.eachUserGroupCount
            }

        case ALL_ADMINS_SUCCESS:
            return {
                ...state,
                loading: false,
                admins: action.payload
            }

        case GET_ROLES_SUCCESS:
            return {
                ...state,
                loading: false,
                roles: action.payload
            }

        case ACTIVITIES_COUNT_SUCCESS:
            return {
                ...state,
                loading: false,
                activitiesCount: action.payload
            }

        case QUESTIONS_COUNT_SUCCESS:
            return {
                ...state,
                loading: false,
                questionsCount: action.payload
            }

        case CREATE_ADMIN_SUCCESS:
            return {
                ...state,
                loading: false,
                createdAdmin: action.payload
            }

        case ALL_HISTORIES_SUCCESS:
            return {
                ...state,
                loading: false,
                histories: action.payload
            }

        case ALL_MONTHS_SUCCESS:
            return {
                ...state,
                loading: false,
                months: action.payload
            }

        case QUESTION_MONTHS_SUCCESS:
            return {
                ...state,
                loading: false,
                questionMonths: action.payload
            }
        
        case USERS_COUNT_FAIL:
        case GROUPS_COUNT_FAIL:
        case ACTIVITIES_COUNT_FAIL:
        case QUESTIONS_COUNT_FAIL:
        case ALL_USERS_FAIL:
        case ALL_ADMINS_FAIL:
        case CREATE_ADMIN_FAIL:
        case ALL_HISTORIES_FAIL:
        case ALL_MONTHS_FAIL:
        case QUESTION_MONTHS_FAIL:
        case GET_ROLES_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload
            }

        case CREATE_ADMIN_RESET:
            return {
                ...state,
                createdAdmin: false
            }

        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }
    
        default:
            return state;
    }
};

export const rolesReducer = (state = {}, action) => {
    switch (action.type) {
        case LEVEL_DATA_REQUEST:
        case GET_PERMISSIONS_REQUEST:
        case CREATE_ROLE_REQUEST:
            return {
                ...state,
                loading: true
            }

        case LEVEL_DATA_SUCCESS:
            return {
                ...state,
                loading: false,
                levelData: action.payload
            }

        case CREATE_ROLE_SUCCESS:
            return {
                ...state,
                loading: false,
                createdRole: action.payload
            }

        case GET_PERMISSIONS_SUCCESS:
            return {
                ...state,
                loading: false,
                permissions: action.payload
            }
        
        case LEVEL_DATA_FAIL:
        case GET_PERMISSIONS_FAIL:
        case CREATE_ROLE_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload
            }

        case CREATE_ROLE_RESET:
            return {
                ...state,
                createdRole: false
            }

        case GET_PERMISSIONS_RESET:
            return {
                ...state,
                permissions: []
            }

        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }
    
        default:
            return state;
    }
};

export const adminUsersReducer = (state = {}, action) => {
    switch (action.type) {
        case GET_PROFILE_REQUEST:
        case CHANGE_ROLE_REQUEST:
        case ADMIN_GROUPS_REQUEST:
        case GET_ACTIVITIES_REQUEST:
        case GET_MEMBERS_REQUEST:
        case ADMIN_QUESTIONS_REQUEST:
        case ADMIN_ANSWERS_REQUEST:
            return {
                ...state,
                loading: true
            }

        case GET_PROFILE_SUCCESS:
            return {
                ...state,
                loading: false,
                user: action.payload
            }

        case CHANGE_ROLE_SUCCESS:
            return {
                ...state,
                loading: false,
                changedRole: action.payload
            }

        case ADMIN_GROUPS_SUCCESS:
            return {
                ...state,
                loading: false,
                groups: action.payload.groups,
                groupMembersCount: action.payload.groupMembersCount,
                activitiesCount: action.payload.activitiesCount
            }

        case GET_ACTIVITIES_SUCCESS:
            return {
                ...state,
                loading: false,
                activities: action.payload.activities,
                questionsCount: action.payload.questionsCount,
                latestQuestions: action.payload.latestQuestions
            }

        case GET_MEMBERS_SUCCESS:
            return {
                ...state,
                loading: false,
                memberships: action.payload.memberships
            }

        case ADMIN_QUESTIONS_SUCCESS:
            return {
                ...state,
                loading: false,
                questions: action.payload.questionTime
            }

        case ADMIN_ANSWERS_SUCCESS:
            return {
                ...state,
                loading: false,
                answers: action.payload.answerTime
            }
        
        case GET_PROFILE_FAIL:
        case CHANGE_ROLE_FAIL:
        case GET_ACTIVITIES_FAIL:
        case ADMIN_GROUPS_FAIL:
        case GET_MEMBERS_FAIL:
        case ADMIN_QUESTIONS_FAIL:
        case ADMIN_ANSWERS_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload
            }

        case CHANGE_ROLE_RESET:
            return {
                ...state,
                changedRole: false
            }

        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }
    
        default:
            return state;
    }
};