import React, { useEffect, useState } from 'react';
import Sidebar from './Sidebar';
import Default from '../../../assets/smilelogo.png';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { createUserRole, getCurrentLevelData } from '../../../actions/adminActions';
import { CREATE_ROLE_RESET } from '../../../constants/adminConstants';
import ReactGA from 'react-ga4';

const CreateRole = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const { loading, levelData, createdRole } = useSelector(state => state.roles);
    let permissions = [];
    const [name, setName] = useState('');

    useEffect(() => {
        dispatch(getCurrentLevelData(""));
    }, [dispatch]);

    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: (location.pathname + location.search), title: "Create Role" });
    }, [location]);

    useEffect(() => {
        if (createdRole) {
            dispatch({ type: CREATE_ROLE_RESET })
            navigate("/admin/roles", { replace: true });
        }
    }, [dispatch, navigate, createdRole]);

    const handleFormChange = (id) => {
        const findIdx = permissions.indexOf(id);
        if (findIdx > -1) {
            permissions.splice(findIdx, 1);
        } else {
            permissions.push(id);
        }
    };

    const submitRole = () => {
        dispatch(createUserRole(name, permissions))
    };

  return (
    <div className="m-0 font-sans text-base antialiased font-normal leading-default text-slate-500">

        <main className="relative h-full max-h-screen transition-all duration-200 ease-in-out xl:ml-68 rounded-xl">
            <nav className="fixed top-0 flex flex-wrap items-center px-0 py-2 mx-6 transition-all ease-in shadow-none duration-250 rounded-2xl lg:flex-nowrap" navbar-main navbar-scroll="false">
                <div className="flex items-center w-full px-4 py-1 mx-auto flex-wrap-inherit">
                <nav>
                <Link to="/admin/dashboard">
                    <img className='w-12 flex items-center' src={Default} alt="Smile Logo" />
                </Link>
                </nav>
                </div>
            </nav>
            <div className='mt-[80px] ml-14 mb-10 md:ml-64 px-6 py-6 mx-auto'>
                <h4 className="mb-4 text-lg font-semibold text-gray-600">Create a Role</h4>
                <div className='flex justify-between mb-5'>
                    <select onChange={(event) => dispatch(getCurrentLevelData(event.target.value))}>
                        <option value="">Select a type</option>
                        <option value="Admin">Admin</option>
                        <option value="Student">Student</option>
                    </select>
                    <div className='flex justify-center items-center gap-3'>
                        <p>Enter role name</p>
                        <input type="text" className='p-2 border-solid border-2 border-gray-300 rounded-lg' onChange={(e) => setName(e.target.value)} placeholder='Role Name' />
                    </div>
                </div>

                {!loading && levelData &&  <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
                    <table className="w-full text-sm text-left text-gray-500">
                        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                            <tr>
                                <th scope="col" className="px-6 py-3">
                                    Modules
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Read
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Write
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Delete
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {levelData?.modules?.map((role, index) => (
                                <tr key={index} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                    <th scope="row" className="px-6 py-4 font-medium text-gray-900 ">
                                        {role?.moduleId?.name}
                                    </th>
                                    <td className="px-6 py-4">
                                        {levelData?.selectablePermissions.find(element => element.name === (role.name + ".Read")) ?
                                        <input type='checkbox' value={levelData?.selectablePermissions.find(element => element.name === (role.name + ".Read"))._id} onClick={(event) => handleFormChange(event.target.value)} /> : "n/a"}
                                    </td>
                                    <td className="px-6 py-4">
                                        {levelData?.selectablePermissions.find(element => element.name === (role.name + ".Write")) ?
                                        <input type='checkbox' value={levelData?.selectablePermissions.find(element => element.name === (role.name + ".Write"))._id} onClick={(event) => handleFormChange(event.target.value)} /> : "n/a"}
                                    </td>
                                    <td className="px-6 py-4">
                                        {levelData?.selectablePermissions.find(element => element.name === (role.name + ".Delete")) ?
                                        <input type='checkbox' value={levelData?.selectablePermissions.find(element => element.name === (role.name + ".Delete"))._id} onClick={(event) => handleFormChange(event.target.value)} /> : "n/a"}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>}
                <button className='signinbtn' onClick={() => submitRole()}>Create</button>
            </div>
            <Sidebar />
        </main>
    </div>
  )
}

export default CreateRole