import axios from "axios"
import { ACTIVITY_QUESTIONS_FAIL, ACTIVITY_QUESTIONS_REQUEST, ACTIVITY_QUESTIONS_SUCCESS, ACTIVITY_REPORT_FAIL, ACTIVITY_REPORT_REQUEST, ACTIVITY_REPORT_SUCCESS, ADD_PROMPTER_FAIL, ADD_PROMPTER_REQUEST, ADD_PROMPTER_SUCCESS, ALL_ACTIVITIES_FAIL, ALL_ACTIVITIES_REQUEST, ALL_ACTIVITIES_SUCCESS, CHANGE_LEVEL_FAIL, CHANGE_LEVEL_REQUEST, CHANGE_LEVEL_SUCCESS, CHANGE_MODE_FAIL, CHANGE_MODE_REQUEST, CHANGE_MODE_SUCCESS, CLEAR_ERRORS, DELETE_ACTIVITY_FAIL, DELETE_ACTIVITY_REQUEST, DELETE_ACTIVITY_SUCCESS, GET_ACTIVITY_FAIL, GET_ACTIVITY_REQUEST, GET_ACTIVITY_SUCCESS, GET_ARESOURCES_FAIL, GET_ARESOURCES_REQUEST, GET_ARESOURCES_SUCCESS, GET_KEYWORDS_FAIL, GET_KEYWORDS_REQUEST, GET_KEYWORDS_SUCCESS, GET_RUBRIC_FAIL, GET_RUBRIC_REQUEST, GET_RUBRIC_SUCCESS, NEW_ACTIVITY_FAIL, NEW_ACTIVITY_REQUEST, NEW_ACTIVITY_SUCCESS, UPDATE_ACTIVITY_FAIL, UPDATE_ACTIVITY_REQUEST, UPDATE_ACTIVITY_SUCCESS, UPDATE_RUBRIC_FAIL, UPDATE_RUBRIC_REQUEST, UPDATE_RUBRIC_SUCCESS, UPLOAD_ARESOURCE_FAIL, UPLOAD_ARESOURCE_REQUEST, UPLOAD_ARESOURCE_SUCCESS } from "../constants/activityConstants"

export const getAllActivities = (id) => async (dispatch) => {
    try {
        dispatch({ type: ALL_ACTIVITIES_REQUEST })
        
        const { data } = await axios.get(`/api/activity/${id}`)
        dispatch({
            type: ALL_ACTIVITIES_SUCCESS,
            payload: data.activities
        })
    } catch (error) {
        dispatch({
            type: ALL_ACTIVITIES_FAIL,
            payload: error.response.data.message
        })
    }
}

export const getSingleActivity = (id) => async (dispatch) => {
    try {
        dispatch({ type: GET_ACTIVITY_REQUEST })
        
        const { data } = await axios.get(`/api/activity/single/${id}`)
        dispatch({
            type: GET_ACTIVITY_SUCCESS,
            payload: data
        })
    } catch (error) {
        dispatch({
            type: GET_ACTIVITY_FAIL,
            payload: error.response.data.message
        })
    }
}

export const createActivity = (creator, name, description, owningGroup, activityType, gradeLevels, hideUsernames, hideActivities) => async (dispatch) => {
    try {
        dispatch({ type: NEW_ACTIVITY_REQUEST })

        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }
        const { data } = await axios.post(`/api/activity/create`, {creator, name, description, owningGroup, activityType, gradeLevels, hideUsernames, hideActivities}, config)
        dispatch({
            type: NEW_ACTIVITY_SUCCESS,
            payload: data
        })
    } catch (error) {
        dispatch({
            type: NEW_ACTIVITY_FAIL,
            payload: error.response.data.message
        })
    }
}

export const updateActivity = (id, name, description, activityType, gradeLevels, hideUsernames, hideActivities, mode) => async (dispatch) => {
    try {
        dispatch({ type: UPDATE_ACTIVITY_REQUEST })

        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }
        const { data } = await axios.put(`/api/activity/update/${id}`, { name, description, activityType, gradeLevels, hideUsernames, hideActivities, mode }, config)
        dispatch({
            type: UPDATE_ACTIVITY_SUCCESS,
            payload: data.success
        })
    } catch (error) {
        dispatch({
            type: UPDATE_ACTIVITY_FAIL,
            payload: error.response.data.message
        })
    }
}

export const deleteActivity = (id) => async (dispatch) => {
    try {
        dispatch({ type: DELETE_ACTIVITY_REQUEST })

        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }
        const { data } = await axios.delete(`/api/activity/delete/${id}`, config)
        dispatch({
            type: DELETE_ACTIVITY_SUCCESS,
            payload: data.success
        })
    } catch (error) {
        dispatch({
            type: DELETE_ACTIVITY_FAIL,
            payload: error.response.data.message
        })
    }
}

export const updateActivityMode = (id, mode) => async (dispatch) => {
    try {
        dispatch({ type: CHANGE_MODE_REQUEST })

        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }
        const { data } = await axios.post(`/api/activity/mode/update/${id}`, {mode}, config)
        dispatch({
            type: CHANGE_MODE_SUCCESS,
            payload: data.success
        })
    } catch (error) {
        dispatch({
            type: CHANGE_MODE_FAIL,
            payload: error.response.data.message
        })
    }
}

export const getActivityReport = (id) => async (dispatch) => {
    try {
        dispatch({ type: ACTIVITY_REPORT_REQUEST })
        
        const { data } = await axios.get(`/api/activity/questions/report/${id}`)
        dispatch({
            type: ACTIVITY_REPORT_SUCCESS,
            payload: data
        })
    } catch (error) {
        dispatch({
            type: ACTIVITY_REPORT_FAIL,
            payload: error.response.data.message
        })
    }
}

export const getActivityUserQuestions = (user, activityId) => async (dispatch) => {
    try {
        dispatch({ type: ACTIVITY_QUESTIONS_REQUEST });
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }
        
        const { data } = await axios.post(`/api/activity/report/users`, { user, activityId }, config);
        dispatch({
            type: ACTIVITY_QUESTIONS_SUCCESS,
            payload: data
        })
    } catch (error) {
        dispatch({
            type: ACTIVITY_QUESTIONS_FAIL,
            payload: error.response.data.message
        })
    }
}

export const changeActivityLevel = (id, level) => async (dispatch) => {
    try {
        dispatch({ type: CHANGE_LEVEL_REQUEST })

        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }
        const { data } = await axios.put(`/api/activity/level/update/${id}`, { level }, config)
        dispatch({
            type: CHANGE_LEVEL_SUCCESS,
            payload: data
        })
    } catch (error) {
        dispatch({
            type: CHANGE_LEVEL_FAIL,
            payload: error.response.data.message
        })
    }
}

export const getActivityRubric = (id) => async (dispatch) => {
    try {
        dispatch({ type: GET_RUBRIC_REQUEST })

        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }
        const { data } = await axios.get(`/api/activity/rubric/${id}`, config)
        dispatch({
            type: GET_RUBRIC_SUCCESS,
            payload: data.activity
        })
    } catch (error) {
        dispatch({
            type: GET_RUBRIC_FAIL,
            payload: error.response.data.message
        })
    }
}

export const updateActivityRubric = (id, gradeLevels) => async (dispatch) => {
    try {
        dispatch({ type: UPDATE_RUBRIC_REQUEST })

        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }
        const { data } = await axios.put(`/api/activity/rubric/update/${id}`, {gradeLevels}, config)
        dispatch({
            type: UPDATE_RUBRIC_SUCCESS,
            payload: data.success
        })
    } catch (error) {
        dispatch({
            type: UPDATE_RUBRIC_FAIL,
            payload: error.response.data.message
        })
    }
}

export const uploadActivityresource = (creator, activity, doc) => async (dispatch) => {
    try {
        dispatch({ type: UPLOAD_ARESOURCE_REQUEST })

        const config = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }
        const { data } = await axios.post(`/api/activity/upload/resource`, {creator, activity, doc}, config)
        dispatch({
            type: UPLOAD_ARESOURCE_SUCCESS,
            payload: data.resource
        })
    } catch (error) {
        dispatch({
            type: UPLOAD_ARESOURCE_FAIL,
            payload: error.response.data.message
        })
    }
}

export const getActivityResources = (id) => async (dispatch) => {
    try {
        dispatch({ type: GET_ARESOURCES_REQUEST })

        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }
        const { data } = await axios.get(`/api/activity/resources/${id}`, config)
        dispatch({
            type: GET_ARESOURCES_SUCCESS,
            payload: data.resources
        })
    } catch (error) {
        dispatch({
            type: GET_ARESOURCES_FAIL,
            payload: error.response.data.message
        })
    }
}

export const addActivityKeywords = (activityId, wordsNumber, keywords) => async (dispatch) => {
    try {
        dispatch({ type: ADD_PROMPTER_REQUEST })

        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }
        const { data } = await axios.post(`/api/activity/prompter`, { activityId, wordsNumber, keywords }, config)
        dispatch({
            type: ADD_PROMPTER_SUCCESS,
            payload: data
        })
    } catch (error) {
        dispatch({
            type: ADD_PROMPTER_FAIL,
            payload: error.response.data.message
        })
    }
}

export const getActivityKeywords = (id) => async (dispatch) => {
    try {
        dispatch({ type: GET_KEYWORDS_REQUEST })
        
        const { data } = await axios.get(`/api/activity/prompter/${id}`)
        dispatch({
            type: GET_KEYWORDS_SUCCESS,
            payload: data
        })
    } catch (error) {
        dispatch({
            type: GET_KEYWORDS_FAIL,
            payload: error.response.data.message
        })
    }
}

export const clearErrors = () => async (dispatch) => {
    dispatch({
        type: CLEAR_ERRORS
    })
}