import React from 'react'
import MobileLayout from '../layouts/MobileLayout'
import { BsGraphUp, BsInfoCircle } from 'react-icons/bs'
import { Link, useParams } from 'react-router-dom'

const MobileReports = () => {
    const {id, activityId} = useParams();

  return (
    <div className='flex flex-col min-h-screen'>
        <MobileLayout>
            <div className='flex flex-row items-center border-b gap-2 border-gray-200 py-3 px-4 activityNames'>
                <BsGraphUp />
                <div>Reports</div>
            </div>
            <div className='flex-1 relative h-full justify-center items-center'>
                <div className=' absolute top-1/2 transform noReportsLabel px-11 -translate-y-1/2 gap-3 flex flex-col justify-center items-center text-center'>
                    <BsInfoCircle className='w-8 h-8' />
                    <div>For optimal viewing, we recommend using desktop view to access your reports.</div>
                </div>
            </div>
            <Link to={`/${id}/${activityId}`} className='px-3 goBackLabel bg-[#68758F] rounded-[10px] py-2 mb-3 text-center mx-3'>Go Back</Link>
        </MobileLayout>
    </div>
  )
}

export default MobileReports