import React from 'react';
import { MdOutlineClose } from 'react-icons/md';
import { PiPencilSimpleThin } from 'react-icons/pi';
import { RiDeleteBinLine } from 'react-icons/ri';
import { useNavigate, useParams } from 'react-router-dom';

const QuestionActions = ({ showQuestionActions, setShowQuestionActions, questionId, setAnswer, setSelectedComment, setQuestionConfirm }) => {
    const { id, activityId } = useParams();
    const navigate = useNavigate();

    // /:id/:activityId/:questionId/edit

  return (
    <div className={`w-full z-90 bottom-0 shadow-md right-0 left-0 bg-[#F8F9FA] fixed transition-all duration-500 transform ${showQuestionActions ? 'translate-y-0 opacity-100' : 'translate-y-full opacity-0 invisible'}`}>
        <div className='actionHeader h-[50px] border-b border-gray-200 px-4 flex justify-between items-center'>
            <div></div>
            <p>Actions</p>
            <MdOutlineClose className='color-[#2D3648] w-6 h-6' onClick={() => { setShowQuestionActions(false); setAnswer(""); setSelectedComment(null); }} />
        </div>
        <div className='flex flex-col justify-center w-full gap-4 p-6'>
            <div className='flex flex-row gap-2 items-center' onClick={() => {navigate(`/${id}/${activityId}/${questionId}/edit`);}}>
                <PiPencilSimpleThin />
                <div>Edit this question</div>
            </div>
            <div className='flex flex-row gap-2 items-center' onClick={() => setQuestionConfirm(true)}>
                <RiDeleteBinLine />
                <div>Delete this question</div>
            </div>
        </div>
    </div>
  )
}

export default QuestionActions