import React, { useEffect } from 'react';
import Navbar from './Navbar';
import ReactGA from 'react-ga4';
import { useLocation } from 'react-router-dom';

const ContactUs = () => {
    const location = useLocation();
    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: (location.pathname + location.search), title: "Contact Us" });
    }, [location]);

  return (
    <div style={{fontFamily: 'IBM Plex Sans',}}>
        <Navbar />
        <div className='container mt-[75px] mx-auto'>
            <div className='flex flex-wrap P-4'>
            <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSc02JoLWPV6dinz2nnM7e2Bu0WIXhB6-9BnmeWzY2lqkqiiZw/viewform?embedded=true" title="Contact Us" className='w-full' height="914" frameBorder="0" marginHeight="0" marginWidth="0">Loading…</iframe>
            </div>
        </div>
        
    </div>
  )
}

export default ContactUs