import { createStore, combineReducers, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";
import { discoverGroupsReducer, groupDetailsReducer, groupMembersReducer, groupReducer, groupResourcesReducer, groupsReducer, newGroupReducer } from "./reducers/groupReducers";
import { myQuestionsReducer, newQuestionReducer, questionDetailsReducer, questionsReducer } from "./reducers/questionReducers";
import { authReducer, forgotPasswordReducer, userReducer } from "./reducers/userReducers";
import { activitiesReducer, activityReducer, activityReportReducer, activityResourcesReducer, activityRubricReducer, newActivityReducer } from "./reducers/activityReducers";
import { newResponseReducer, responsesReducer } from "./reducers/responseReducers";
import { adminUsersReducer, countStatsReducer, rolesReducer } from "./reducers/adminReducers";
import { persistReducer } from 'redux-persist';
// import storage from 'redux-persist/lib/storage';
import sessionStorage from 'redux-persist/lib/storage/session';

const persistConfig = {
    key: 'root',
    storage: sessionStorage,
    whitelist: ['auth'],
    expires: 3 * 60 * 60
};

const reducer = combineReducers({
    auth: authReducer,
    groups: groupsReducer,
    newGroup: newGroupReducer,
    questions: questionsReducer,
    newQuestion: newQuestionReducer,
    questionDetails: questionDetailsReducer,
    activities: activitiesReducer,
    newActivity: newActivityReducer,
    discoverGroups: discoverGroupsReducer,
    group: groupReducer,
    groupDetails: groupDetailsReducer,
    responses: responsesReducer,
    newResponse: newResponseReducer,
    forgotPassword: forgotPasswordReducer,
    groupResources: groupResourcesReducer,
    groupMembers: groupMembersReducer,
    activity: activityReducer,
    activityRubric: activityRubricReducer,
    activityResources: activityResourcesReducer,
    user: userReducer,
    myQuestions: myQuestionsReducer,
    countStats: countStatsReducer,
    roles: rolesReducer,
    adminUsers: adminUsersReducer,
    activityReport: activityReportReducer
});

const persistedReducer = persistReducer(persistConfig, reducer);

const middleware = [thunk];

const store = createStore(
    persistedReducer,
    composeWithDevTools(applyMiddleware(...middleware))
);

export default store;