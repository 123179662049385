import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { RxLockClosed } from 'react-icons/rx'
import { BsRocket } from 'react-icons/bs'
import { HiOutlineChartBar, HiOutlinePuzzle } from 'react-icons/hi'
import { changeActivityLevel } from '../../actions/activityActions';

const ModeComponent = () => {

    const dispatch = useDispatch();
    const { activity } = useSelector(state => state.activity);
    const { group } = useSelector(state => state.groupDetails);

    const handleActivityLevelChange = (suggestedLevel) => {
        let level;
        switch (activity?.level) {
            case "locked":
                level = "create";
            break;
            case "create":
                level = "solve";
            break;
            case "solve":
                level = "review";
            break;
            default:
                level = "create";
            break;
        }
        if (activity?.level) {
            if (suggestedLevel !== level) {
                return;
            }
        }
        if (group?.role === 3) {
            return
        }
        dispatch(changeActivityLevel(activity?._id, level))
    }

  return (
    <>
        {activity?.activityType === 'OrganizerPaced' && 
        <div className="flex items-center pt-4 pb-7 px-8 bg-[#EBEDF2]">
            <div className="flex items-center relative">
                <div className={`flex justify-center items-center rounded-full w-6 h-6 ${(activity?.level === 'locked' || !activity?.level) ? 'bg-[#68758F]' : 'bg-[#C4C9D4]'}`}>
                    <RxLockClosed className='text-white w-3 h-3' />
                </div>
                <p className={`${activity?.level === 'locked' ? 'text-[#68758F]': 'text-[#A8B0BF]'} absolute top-0 -ml-1 mt-7 activeExamModeLabel`}>Locked</p>
            </div>
            <div className="flex-auto border-t-2 transition duration-500 ease-in-out border-gray-300"></div>
            <div className="flex items-center text-white relative" onClick={() => handleActivityLevelChange('create')}>
                <div className={`flex justify-center items-center rounded-full w-[24px] h-[24px] ${activity?.level === 'create' ? 'bg-[#68758F]' : 'bg-[#C4C9D4]'}`}>
                    <BsRocket className='text-white w-3 h-3' />
                </div>
                <p className={`${activity?.level === 'create' ? 'text-[#68758F]': 'text-[#A8B0BF]'} absolute top-0 -ml-1 mt-7 activeExamModeLabel`}>Create</p>
            </div>
            <div className="flex-auto border-t-2 transition duration-500 ease-in-out border-gray-300"></div>
            <div className="flex items-center text-gray-500 relative" onClick={() => handleActivityLevelChange('solve')}>
                <div className={`flex justify-center items-center rounded-full w-[24px] h-[24px] ${activity?.level === 'solve' ? 'bg-[#68758F]' : 'bg-[#C4C9D4]'}`}>
                    <HiOutlinePuzzle className='text-white w-3 h-3' />
                </div>
                <p className={`${activity?.level === 'solve' ? 'text-[#68758F]': 'text-[#A8B0BF]'} absolute top-0 -ml-1 mt-7 activeExamModeLabel`}>Solve</p>
            </div>
            <div className="flex-auto border-t-2 transition duration-500 ease-in-out border-gray-300"></div>
            <div className="flex items-center text-gray-500 relative" onClick={() => handleActivityLevelChange('review')}>
                <div className={`flex justify-center items-center rounded-full w-[24px] h-[24px] ${activity?.level === 'review' ? 'bg-[#68758F]' : 'bg-[#C4C9D4]'}`}>
                    <HiOutlineChartBar className='text-white w-3 h-3' />
                </div>
                <p className={`${activity?.level === 'review' ? 'text-[#68758F]': 'text-[#A8B0BF]'} absolute top-0 -ml-1 mt-7 activeExamModeLabel`}>Review</p>
            </div>
        </div>}
    </>
  )
}

export default ModeComponent