export const USERS_COUNT_REQUEST = 'USERS_COUNT_REQUEST';
export const USERS_COUNT_SUCCESS = 'USERS_COUNT_SUCCESS';
export const USERS_COUNT_FAIL = 'USERS_COUNT_FAIL';

export const ACTIVITIES_COUNT_REQUEST = 'ACTIVITIES_COUNT_REQUEST';
export const ACTIVITIES_COUNT_SUCCESS = 'ACTIVITIES_COUNT_SUCCESS';
export const ACTIVITIES_COUNT_FAIL = 'ACTIVITIES_COUNT_FAIL';

export const GROUPS_COUNT_REQUEST = 'GROUPS_COUNT_REQUEST';
export const GROUPS_COUNT_SUCCESS = 'GROUPS_COUNT_SUCCESS';
export const GROUPS_COUNT_FAIL = 'GROUPS_COUNT_FAIL';

export const QUESTIONS_COUNT_REQUEST = 'QUESTIONS_COUNT_REQUEST';
export const QUESTIONS_COUNT_SUCCESS = 'QUESTIONS_COUNT_SUCCESS';
export const QUESTIONS_COUNT_FAIL = 'QUESTIONS_COUNT_FAIL';

export const ALL_USERS_REQUEST = 'ALL_USERS_REQUEST';
export const ALL_USERS_SUCCESS = 'ALL_USERS_SUCCESS';
export const ALL_USERS_FAIL = 'ALL_USERS_FAIL';

export const CREATE_ADMIN_REQUEST = 'CREATE_ADMIN_REQUEST';
export const CREATE_ADMIN_SUCCESS = 'CREATE_ADMIN_SUCCESS';
export const CREATE_ADMIN_RESET = 'CREATE_ADMIN_RESET'; 
export const CREATE_ADMIN_FAIL = 'CREATE_ADMIN_FAIL'; 

export const ALL_ADMINS_REQUEST = 'ALL_ADMINS_REQUEST';
export const ALL_ADMINS_SUCCESS = 'ALL_ADMINS_SUCCESS';
export const ALL_ADMINS_FAIL = 'ALL_ADMINS_FAIL';

export const ALL_HISTORIES_REQUEST = 'ALL_HISTORIES_REQUEST';
export const ALL_HISTORIES_SUCCESS = 'ALL_HISTORIES_SUCCESS';
export const ALL_HISTORIES_FAIL = 'ALL_HISTORIES_FAIL';

export const ALL_MONTHS_REQUEST = 'ALL_MONTHS_REQUEST';
export const ALL_MONTHS_SUCCESS = 'ALL_MONTHS_SUCCESS';
export const ALL_MONTHS_FAIL = 'ALL_MONTHS_FAIL';

export const QUESTION_MONTHS_REQUEST = 'QUESTION_MONTHS_REQUEST';
export const QUESTION_MONTHS_SUCCESS = 'QUESTION_MONTHS_SUCCESS';
export const QUESTION_MONTHS_FAIL = 'QUESTION_MONTHS_FAIL';

export const GET_ROLES_REQUEST = 'GET_ROLES_REQUEST';
export const GET_ROLES_SUCCESS = 'GET_ROLES_SUCCESS';
export const GET_ROLES_FAIL = 'GET_ROLES_FAIL';

export const LEVEL_DATA_REQUEST = 'LEVEL_DATA_REQUEST';
export const LEVEL_DATA_SUCCESS = 'LEVEL_DATA_SUCCESS';
export const LEVEL_DATA_FAIL = 'LEVEL_DATA_FAIL';

export const GET_PERMISSIONS_REQUEST = 'GET_PERMISSIONS_REQUEST';
export const GET_PERMISSIONS_SUCCESS = 'GET_PERMISSIONS_SUCCESS';
export const GET_PERMISSIONS_RESET = 'GET_PERMISSIONS_RESET';
export const GET_PERMISSIONS_FAIL = 'GET_PERMISSIONS_FAIL';

export const CREATE_ROLE_REQUEST = 'CREATE_ROLE_REQUEST';
export const CREATE_ROLE_SUCCESS = 'CREATE_ROLE_SUCCESS';
export const CREATE_ROLE_RESET = 'CREATE_ROLE_RESET'; 
export const CREATE_ROLE_FAIL = 'CREATE_ROLE_FAIL';

export const GET_PROFILE_REQUEST = 'GET_PROFILE_REQUEST';
export const GET_PROFILE_SUCCESS = 'GET_PROFILE_SUCCESS';
export const GET_PROFILE_FAIL = 'GET_PROFILE_FAIL';

export const CHANGE_ROLE_REQUEST = 'CHANGE_ROLE_REQUEST';
export const CHANGE_ROLE_SUCCESS = 'CHANGE_ROLE_SUCCESS';
export const CHANGE_ROLE_RESET = 'CHANGE_ROLE_RESET'; 
export const CHANGE_ROLE_FAIL = 'CHANGE_ROLE_FAIL';

export const ADMIN_GROUPS_REQUEST = 'ADMIN_GROUPS_REQUEST';
export const ADMIN_GROUPS_SUCCESS = 'ADMIN_GROUPS_SUCCESS';
export const ADMIN_GROUPS_FAIL = 'ADMIN_GROUPS_FAIL';

export const GET_ACTIVITIES_REQUEST = 'GET_ACTIVITIES_REQUEST';
export const GET_ACTIVITIES_SUCCESS = 'GET_ACTIVITIES_SUCCESS';
export const GET_ACTIVITIES_FAIL = 'GET_ACTIVITIES_FAIL';

export const GET_MEMBERS_REQUEST = 'GET_MEMBERS_REQUEST';
export const GET_MEMBERS_SUCCESS = 'GET_MEMBERS_SUCCESS';
export const GET_MEMBERS_FAIL = 'GET_MEMBERS_FAIL';

export const ADMIN_QUESTIONS_REQUEST = 'ADMIN_QUESTIONS_REQUEST';
export const ADMIN_QUESTIONS_SUCCESS = 'ADMIN_QUESTIONS_SUCCESS';
export const ADMIN_QUESTIONS_FAIL = 'ADMIN_QUESTIONS_FAIL';

export const ADMIN_ANSWERS_REQUEST = 'ADMIN_ANSWERS_REQUEST';
export const ADMIN_ANSWERS_SUCCESS = 'ADMIN_ANSWERS_SUCCESS';
export const ADMIN_ANSWERS_FAIL = 'ADMIN_ANSWERS_FAIL';

export const CLEAR_ERRORS = 'CLEAR_ERRORS';