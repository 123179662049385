import React, { useEffect } from 'react'
import smile3 from '../../assets/smile3.png';
import smile8 from '../../assets/smile8.png';
import smile9 from '../../assets/smile9.png';
import smile10 from '../../assets/smile10.png';
import smile11 from '../../assets/smile11.png';
import dalle1 from '../../assets/dalle-nodes.jpg';
import unsplash1 from '../../assets/jack-stapleton-8lokOUd8tLI.jpg';
import Navbar from './Navbar';
import { Link, useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';

const LearnSmile = () => {
    const location = useLocation();

    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: (location.pathname + location.search), title: "Learn SMILE" });
    }, [location]);

  return (
    <div style={{fontFamily: 'IBM Plex Sans',}}>
      
      <Navbar />

        <div
          className="bg-no-repeat bg-cover bg-center p-0 h-96"
          style={{
            backgroundImage: `url("${dalle1}")`,
          }}
        >
          <div className="bg-black bg-opacity-50 w-full h-full flex items-end justify-center">
            <p className="text-white text-4xl font-light p-8">About SMILE</p>
          </div>
        </div>

        <div className="container mx-auto py-8 px-8">
          <div className="space-y-8 pb-8">
            <p className="text-2xl font-medium"><span className='text-gray-600'>The Stanford Mobile Inquiry-based Learning Environment (SMILE)</span> is a learning management systems designed to help students study school subject matter, develop higher order learning skills and generate transparent real-time learning analytics.</p>
          </div>

          <div className="flex flex-col md:flex-row mt-5 items-center md:items-start">
            <div className="w-full md:w-1/3 md:pr-2">
              <img alt="gallery" className="pb-5 block object-cover object-center w-full h-full" src={smile8} />
            </div>
            <div className="w-full md:w-2/3 md:pl-2">
              <p className="text-2xl font-medium mb-2">Our Goal</p>
              <p>SMILE technology allows students to create multiple-choice and open-ended questions on during class and share these questions with their classmates and teacher. The main goal of SMILE is to develop students’ questioning skills, encourage greater student-centric activities and practices in classrooms, and enable a low-cost mobile learning environment.</p>
            </div>
          </div>

          <div className="flex flex-col md:flex-row mt-5 items-center md:items-start">
            <div className="w-full md:w-2/3 md:pr-2">
              <p className="text-2xl font-medium mb-2">
                Classroom Use
              </p>
              <p>SMILE is intended for a wide range of educational settings. Not only SMILE is content-agnostic, the pedagogical model behind SMILE is to encourage the learner to make critical inquiries -- the hard work, the investigation --- instead of being told the answers by an educator. To address the rapidly advancing technological age, SMILE effectively allows schools and teachers to flip the classroom.</p>
            </div>
            <div className="w-full md:w-1/3 md:pl-2">
              <img alt="gallery" className="pt-5 block object-cover object-center w-full h-full" src={smile9} />
            </div>
          </div>
        </div>

        <div className="container mx-auto md:grid md:grid-cols-2 gap-16 py-8 px-8">
          <div className="col-span-1 text-gray-600 text-center">
            <p className="text-2xl font-medium mb-2">
              What are the benefits of using SMILE?
            </p>
            <ul>
              <li>Develops problem-solving skills</li>
              <li>Enhances creativity</li>
              <li>Increases academic achievement</li>
              <li>Improves decision-making</li>
            </ul>
          </div>

          <div className="col-span-1 text-gray-600 text-center mt-5 md:mt-0">
            <p className="text-2xl font-medium mb-2">
              What does a SMILE session look like?
            </p>
            <ul>
              <li>Observe context</li>
              <li>Ask hypothetical questions</li>
              <li>Brainstorm responses</li>
              <li>Discuss results</li>
              <li>Evaluate questions</li>
            </ul>
          </div>
        </div>

        <div className="container mx-auto lg:grid lg:grid-cols-2 lg:mt-5 pb-10 px-8">
          <div className="col-span-2 text-gray-600">
            <p className="text-2xl font-medium mb-2">
              Research Outcomes
            </p>
            <div className=" text-gray-500">
              <div className="flex flex-row space-x-4 items-center">
                <p>Studies suggest that SMILE could be implemented relatively easily in a wide range of classroom settings; it was adopted by students relatively quickly; and it increased the use of inquiry-based pedagogies.
                As students evaluated each other’s questions and understood which ones got higher ratings, they developed questions that were more conceptually difficult and of higher quality. Teachers need an initial training period and some follow-up mentoring so they can facilitate questions. Tailoring the content of the trainings to the local environment is crucial. Without putting the benefits of SMILE into the local context, teachers and students will find no compelling reason to adopt the pedagogy.
                </p>
              </div>
              <section className="overflow-hidden text-gray-700 ">
              <div className="container py-2 mx-auto lg:pt-6 flex sm:flex-col md:flex-row">
                <div className="w-full sm:pb-2 md:p-2 md:pl-0 md:w-1/3">
                  <img alt="gallery" className="block object-cover object-center w-full h-full"
                    src={smile3} />
                </div>
                <div className="w-full sm:pb-2 md:p-2 md:w-1/3">
                  <img alt="gallery" className="block object-cover object-center w-full h-full"
                    src={smile10} />
                </div>
                <div className="w-full md:p-2 md:pr-0 md:w-1/3">
                  <img alt="gallery" className="block object-cover object-center w-full h-full"
                    src={smile11} />
                </div>
              </div>
            </section>
            </div>
          </div>
        </div>

        <div className="container mx-auto lg:grid lg:grid-cols-2 gap-16 py-8 px-8">
          <div className="col-span-1">
            <img src={unsplash1} alt="Jack Stapleton on Unsplash" />
          </div>
          <div className="col-span-1 mt-5 lg:mt-0 text-gray-600">
            <p className="text-2xl font-medium mb-2">Join us</p>
            <p className="text-xl">
            SMILE has been deployed to over 30 countries, including U.S.A., Finland, India, Argentina, Taiwan, Mexico, Costa Rica, Colombia, Nepal, China, Uruguay, Indonesia, South Korea, South Africa, Sri Lanka, Pakistan, and Tanzania
            </p>
            <Link to={"/contactus"}
                className="block w-full px-4 py-2 my-5 text-center rounded-md shadow text-white bg-[#8C1515] hover:bg-gray-800">
                Get started today
            </Link>
          </div>
      </div>

  </div>
)
}

export default LearnSmile
