export const ChoiceMapper = [
    {
        key: 1, 
        value: "A"
    },
    {
        key: 2, 
        value: "B"
    },
    {
        key: 3, 
        value: "C"
    },
    {
        key: 4, 
        value: "D"
    },
    {
        key: 5, 
        value: "E"
    },
    {
        key: 6, 
        value: "F"
    },
    {
        key: 7, 
        value: "G"
    },
    {
        key: 8, 
        value: "H"
    },
    {
        key: 9, 
        value: "I"
    },
    {
        key: 10, 
        value: "J"
    },
    {
        key: 11, 
        value: "K"
    },
    {
        key: 12, 
        value: "L"
    },
    {
        key: 13, 
        value: "M"
    },
    {
        key: 14, 
        value: "N"
    },
    {
        key: 15, 
        value: "O"
    },
    {
        key: 16, 
        value: "P"
    },
    {
        key: 17, 
        value: "Q"
    },
    {
        key: 18, 
        value: "R"
    },
    {
        key: 19, 
        value: "S"
    },
    {
        key: 20, 
        value: "T"
    },
    {
        key: 21, 
        value: "U"
    },
    {
        key: 22, 
        value: "V"
    },
    {
        key: 23, 
        value: "W"
    },
    {
        key: 24, 
        value: "X"
    },
    {
        key: 25, 
        value: "Y"
    },
    {
        key: 26, 
        value: "Z"
    }
]


export const RoleMapper = [
    {
        key: 1, 
        value: "Admin"
    },
    {
        key: 2, 
        value: "Owner"
    },
    {
        key: 3, 
        value: "Member"
    },
    {
        key: 4, 
        value: "Co-Owner"
    }
]