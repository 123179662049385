import React, { useEffect, useState } from 'react'
import { FiSettings } from 'react-icons/fi';
import { useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import io from "socket.io-client";
import { getGroupMembers, getSingleGroup } from '../../actions/groupActions';
import { MdOutlineArrowBackIos } from 'react-icons/md';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SelectImageModal from '../popovers/SelectImageModal';
const Default = require('../../assets/groupdefault.jpg');
const baseUrl = process.env.REACT_APP_IMAGES_BASEURL;

const ENDPOINT = process.env.REACT_APP_ENDPOINT
var socket;

const MobileLayout = ({ children }) => {

    const [onlineUsers, setOnlineUsers] = useState([]);
    const [opensettings, setOpenSettings] = useState(4);
    const [selectedImage, setSelectedImage] = useState(null);
    const [socketConnected, setSocketConnected] = useState(false);

    const dispatch = useDispatch();

    const { user } = useSelector(state => state.auth);
    const { group } = useSelector(state => state.groupDetails);
    const { members } = useSelector(state => state.groupMembers);
    const navigate = useNavigate();

    const creator = user?._id;
    let { id, groupId } = useParams();
    const owningGroup = groupId ? groupId : id;

    const showToastMessage = (message) => {
        toast.success(message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000
        });
    };

    useEffect (() => {
        if (group && group?.group?._id !== owningGroup) {
            dispatch(getSingleGroup(owningGroup, creator));
            dispatch(getGroupMembers(owningGroup));
        } 
        
    }, [dispatch, group, owningGroup]);

    useEffect(() => {
        socket = io(ENDPOINT);
        socket.emit("setup", user);
        socket.on("connected", () => setSocketConnected(true));
    }, []);

    useEffect(() => {
        socket?.emit("addUser", { userId: creator, group: owningGroup });
        socket?.on("getUsers", (users) => {
            setOnlineUsers(users);
        });
    }, [creator, owningGroup]);

    const groupImageUrl = group?.group?.groupImage?.url ? (group?.group?.groupImage?.public_id === null ? ((baseUrl + group?.group?.groupImage?.url)) : group?.group?.groupImage?.url) : Default;

  return (
    <>
    <div onClick={() => setSelectedImage(groupImageUrl)} 
        className={(opensettings === 2 || opensettings === 1 || opensettings === 3 || opensettings === 5 ? "hidden " : " ") + ' bg-center h-[160px] md:h-[200px] bg-cover bg-no-repeat w-full p-6'} style={{
            backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url(${group?.group?.groupImage?.url ? (group?.group?.groupImage?.public_id === null ? ((baseUrl + group?.group?.groupImage?.url)) : group?.group?.groupImage?.url) : Default})`
        }}>
            <div className='relative h-full w-full'>
                <div className='flex justify-between items-center'>
                    <div className='flex flex-row gap-1 items-center' onClick={(e) => e.stopPropagation()}>
                        <MdOutlineArrowBackIos className='text-white cursor-pointer' onClick={() => navigate('/groups')} />
                        {id && <div className='mobileMediumLabel'>My Groups</div>}
                    </div>
                    <img onClick={(e) => e.stopPropagation()} src={user?.avatar?.url ? (user?.avatar?.public_id === null ? (baseUrl + user?.avatar?.url) : user?.avatar?.url) : Default} className='rounded-full cursor-pointer w-8 h-8' alt='user avatar' />
                </div>
                <div className='absolute bottom-0'>
                    <p className='groupModalName flex pb-2 gap-2' translate="no" onClick={(e) => e.stopPropagation()}>{group?.group?.name} <FiSettings onClick={(e) => { navigate(`/${owningGroup}/settings`)}} className='settingsIcon md:hidden' /></p>
                    <div className='flex flex-row items-end gap-3' onClick={(e) => e.stopPropagation()}>
                        <p className='groupNavInfo flex'>{group?.group?.isPrivate ? "Private" : "Public"} . Members {members?.length}</p>
                        {members && <div className={`flex flex-row gap-[4px]`}>
                            {members?.filter(x => (onlineUsers?.filter(x => x.userId.group === owningGroup)).find(y => y?.userId?.userId === x?.user?._id))?.slice(0, 3).map((ma, idx) =>
                                <div key={idx} className="relative w-[24px] h-[24px] rounded-full">
                                    <img src={ma?.user?.avatar?.url ? (ma?.user?.avatar?.public_id === null ? (baseUrl + ma?.user?.avatar?.url) : ma?.user?.avatar?.url) : Default} className="w-[24px] h-[24px] rounded-full" alt='user avatar' />
                                    <span className="bottom-0 right-0 absolute w-[6px] h-[6px] bg-green-400 rounded-full"></span>
                                </div>
                            )}
                            {members?.filter(x => (onlineUsers?.filter(x => x.userId.group === owningGroup)).find(y => y.userId.userId === x.user._id)).length > 3 &&
                            (
                                members?.filter(x => (onlineUsers?.filter(x => x.userId.group === owningGroup)).find(y => y.userId.userId === x.user._id))?.length === 4 ?
                                <img src={members[3]?.user?.avatar?.url ? (members[3]?.user?.avatar?.public_id === null ? (baseUrl + members[3]?.user?.avatar?.url) : members[3]?.user?.avatar?.url) : Default} className="w-[24px] h-[24px] rounded-full" alt='user avatar' /> :
                                <div className='w-[24px] h-[24px] rounded-full bg-white addText flex flex-row justify-center items-center gap-2'>+ {members.length - 3}</div>
                            )}
                        </div>}
                    </div>
                </div>
            </div>
    </div>
    {selectedImage && <SelectImageModal  setSelectedImage={setSelectedImage} selectedImage={selectedImage} />}
    <ToastContainer autoClose={1000} />
    {children}
    </>
  )
}

export default MobileLayout