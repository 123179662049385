import React, { Fragment, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { getAllAdmins } from '../../../actions/adminActions';
import Sidebar from './Sidebar'
import moment from 'moment'
import DefaultLogo from '../../../assets/smilelogo.png';
import { FaRegEdit } from 'react-icons/fa';
import DataTable from 'react-data-table-component';
import { ImBlocked } from 'react-icons/im';
import ReactGA from 'react-ga4';

const ListAdmins = () => {

    const dispatch = useDispatch();
    const location = useLocation();
    const [searchName, setSearchName] = useState('');
    const [filteredData, setFilteredData] = useState([]);

    const { admins, error, loading } = useSelector(state => state.countStats);
    const { permissions } = useSelector(state => state.auth);

    const isAllowed = permissions?.find((element) => element?.levelModulePermissionId?.name === 'Admin.Users.Read');
    const allowEdit = permissions?.find((element) => element?.levelModulePermissionId?.name === 'Admin.Users.Write');

    let navigate = useNavigate();

    useEffect(() => {

        dispatch(getAllAdmins())

    }, [dispatch]);

    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: (location.pathname + location.search), title: "Admin List" });
    }, [location]);

    const columns = [
        {
            name: 'First Name',
            selector: row => row.firstName,
            sortable: true,
        },
        {
            name: 'Last Name',
            selector: row => row.lastName,
            sortable: true,
        },
        {
            name: 'Username',
            selector: row => row.username,
            sortable: true,
        },
        {
            name: 'Email',
            selector: row => row.email,
            sortable: true,
            wrap: true, // enable wrap
            style: { whiteSpace: "normal" },
        },
        {
            name: 'Role',
            selector: row => (row?.role?.name),
            sortable: true,
        },
        {
            name: 'Created',
            selector: row => (moment(row?.createdAt).utc().format('MM/DD/YYYY')),
            sortable: true,
        },
        {
            name: 'Actions',
            selector: row => (allowEdit ? <FaRegEdit className='cursor-pointer' onClick={() => navigate(`/admin/admins/${row._id}`)} /> : <ImBlocked />),
        },
    ];

    // ** Converts table to CSV
    function convertArrayOfObjectsToCSV(array) {
        let result

        const columnDelimiter = ','
        const lineDelimiter = '\n'
        const keys = Object.keys(admins[0])

        result = ''
        result += keys.join(columnDelimiter)
        result += lineDelimiter

        array.forEach(item => {
        let ctr = 0
        keys.forEach(key => {
            if (ctr > 0) result += columnDelimiter

            result += item[key]

            ctr++
        })
        result += lineDelimiter
        })

        return result
    }

    function downloadCSV(array) {
        const link = document.createElement('a')
        let csv = convertArrayOfObjectsToCSV(array)
        if (csv === null) return
    
        const filename = 'admins.csv'
    
        if (!csv.match(/^data:text\/csv/i)) {
          csv = `data:text/csv;charset=utf-8,${csv}`
        }
    
        link.setAttribute('href', encodeURI(csv))
        link.setAttribute('download', filename)
        link.click()
    };

    // ** Function to handle search filter
    const handleFilter = e => {
        const value = e.target.value
        let updatedData = []
        setSearchName(value)

        if (value.length) {
            updatedData = admins.filter(item => {
                const startsWith =
                item.firstName.toLowerCase().startsWith(value.toLowerCase()) ||
                item.lastName.toLowerCase().startsWith(value.toLowerCase()) ||
                item.username.toLowerCase().startsWith(value.toLowerCase()) ||
                item.email.toLowerCase().startsWith(value.toLowerCase())

                const includes =
                item.firstName.toLowerCase().includes(value.toLowerCase()) ||
                item.lastName.toLowerCase().includes(value.toLowerCase()) ||
                item.username.toLowerCase().includes(value.toLowerCase()) ||
                item.email.toLowerCase().includes(value.toLowerCase())

                if (startsWith) {
                    return startsWith
                } else if (!startsWith && includes) {
                    return includes
                } else return null
            })
            setFilteredData(updatedData)
            setSearchName(value)
        }
    };

  return (
    <div class="m-0 font-sans text-base antialiased font-normal min-h-screen dark:bg-slate-900 leading-default bg-gray-50 text-slate-500">

    <main class="relative h-full transition-all duration-200 ease-in-out xl:ml-68 rounded-xl">
        <nav class="top-0 flex flex-wrap items-center justify-between px-0 py-2 mx-6 transition-all ease-in shadow-none duration-250 rounded-2xl lg:flex-nowrap lg:justify-start" navbar-main navbar-scroll="false">
            <div class="flex items-center justify-between w-full px-4 py-1 mx-auto flex-wrap-inherit">
                <nav>
                    <Link to="/admin/dashboard">
                        <img className='w-12' src={DefaultLogo} />
                    </Link>
                </nav>
            </div>
        </nav>

        <Sidebar />
        <div className='mt-[80px] ml-14 mb-10 md:ml-64 px-6 py-6 mx-auto'>
            <div className='flex justify-between mb-4'>
                <h4 class="text-lg font-semibold text-gray-600 dark:text-gray-300">All Admins</h4>
                <input value={searchName} onChange={handleFilter} placeholder="Search..." type="text" className="p-2 border border-gray-600 w-[200px] rounded-lg" />
                <button className="signinbtn" onClick={() => navigate('/admin/create/admins')}>Create Admin</button>
            </div>
            <DataTable
                columns={columns}
                data={searchName.length ? filteredData : admins}
                progressPending={loading}
                pagination
            />
        </div> 

    </main>
    </div>
  )
}

export default ListAdmins