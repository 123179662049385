import { ACTIVITY_QUESTIONS_FAIL, ACTIVITY_QUESTIONS_REQUEST, ACTIVITY_QUESTIONS_SUCCESS, ACTIVITY_REPORT_FAIL, ACTIVITY_REPORT_REQUEST, ACTIVITY_REPORT_SUCCESS, ADD_PROMPTER_FAIL, ADD_PROMPTER_REQUEST, ADD_PROMPTER_RESET, ADD_PROMPTER_SUCCESS, ALL_ACTIVITIES_FAIL, ALL_ACTIVITIES_REQUEST, ALL_ACTIVITIES_RESET, ALL_ACTIVITIES_SUCCESS, CHANGE_LEVEL_FAIL, CHANGE_LEVEL_REQUEST, CHANGE_LEVEL_RESET, CHANGE_LEVEL_SUCCESS, CHANGE_MODE_FAIL, CHANGE_MODE_REQUEST, CHANGE_MODE_RESET, CHANGE_MODE_SUCCESS, CLEAR_ERRORS, DELETE_ACTIVITY_FAIL, DELETE_ACTIVITY_REQUEST, DELETE_ACTIVITY_RESET, DELETE_ACTIVITY_SUCCESS, GET_ACTIVITY_FAIL, GET_ACTIVITY_REQUEST, GET_ACTIVITY_SUCCESS, GET_ARESOURCES_FAIL, GET_ARESOURCES_REQUEST, GET_ARESOURCES_SUCCESS, GET_KEYWORDS_FAIL, GET_KEYWORDS_REQUEST, GET_KEYWORDS_SUCCESS, GET_RUBRIC_FAIL, GET_RUBRIC_REQUEST, GET_RUBRIC_SUCCESS, NEW_ACTIVITY_FAIL, NEW_ACTIVITY_REQUEST, NEW_ACTIVITY_RESET, NEW_ACTIVITY_SUCCESS, UPDATE_ACTIVITY_FAIL, UPDATE_ACTIVITY_REQUEST, UPDATE_ACTIVITY_RESET, UPDATE_ACTIVITY_SUCCESS, UPDATE_RUBRIC_FAIL, UPDATE_RUBRIC_REQUEST, UPDATE_RUBRIC_RESET, UPDATE_RUBRIC_SUCCESS, UPLOAD_ARESOURCE_FAIL, UPLOAD_ARESOURCE_REQUEST, UPLOAD_ARESOURCE_RESET, UPLOAD_ARESOURCE_SUCCESS } from "../constants/activityConstants"

export const activitiesReducer = (state = { activities: [] }, action) => {
    switch (action.type) {
        case ALL_ACTIVITIES_REQUEST:
            return {
                loading: true,
                ...state,
            }

        case ALL_ACTIVITIES_SUCCESS:
            return {
                loading: false,
                activities: action.payload
            }
        
        case ALL_ACTIVITIES_FAIL:
            return {
                loading: false,
                error: action.payload
            }

        case ALL_ACTIVITIES_RESET:
            return {
                loading: false,
                activities: []
            }

        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }
    
        default:
            return state;
    }
}

export const activityReportReducer = (state = { questions: [] }, action) => {
    switch (action.type) {
        case ACTIVITY_REPORT_REQUEST:
        case ACTIVITY_QUESTIONS_REQUEST:
            return {
                loading: true,
                questions: [],
            }

        case ACTIVITY_REPORT_SUCCESS:
            return {
                loading: false,
                questions: action.payload?.questions,
                questionsCount: action.payload?.counts[0]?.questionsCount,
                responseCount: action.payload?.counts[0]?.responsesCount,
                users: action.payload?.users,
            }

        case ACTIVITY_QUESTIONS_SUCCESS:
            return {
                loading: false,
                ...state,
                frequentWords: action.payload?.frequentWords,
                individualUser: action.payload?.individualUser,
                individualQuestions: action.payload?.individualQuestions,
                individualResponses: action.payload?.individualResponses,
                individualRatings: action.payload?.individualRatings,
                receivedRatings: action.payload?.receivedRatings,
            }

        case ACTIVITY_REPORT_FAIL:
        case ACTIVITY_QUESTIONS_FAIL:
            return {
                loading: false,
                error: action.payload
            }

        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }
    
        default:
            return state;
    }
}

export const newActivityReducer = (state = { activity : {} }, action ) => {
    switch (action.type) {
        case NEW_ACTIVITY_REQUEST:
            return {
                ...state,
                loading: true
            }

        case NEW_ACTIVITY_SUCCESS:
            return {
                loading: false,
                success: action.payload.success,
                createdActivity: action.payload.createdActivity
            }

        case NEW_ACTIVITY_FAIL:
            return {
                ...state,
                error: action.payload
            }

        case NEW_ACTIVITY_RESET:
            return {
                ...state,
                createdActivity: false
            }

        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }
    
        default:
            return state
    }
}

export const activityRubricReducer = (state = { rubric : {} }, action ) => {
    switch (action.type) {
        case GET_RUBRIC_REQUEST:
            return {
                ...state,
                loading: true
            }

        case GET_RUBRIC_SUCCESS:
            return {
                loading: false,
                rubric: action.payload,
            }

        case GET_RUBRIC_FAIL:
            return {
                ...state,
                error: action.payload
            }

        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }
    
        default:
            return state
    }
}

export const activityResourcesReducer = (state = { resources: [] }, action ) => {
    switch (action.type) {
        case GET_ARESOURCES_REQUEST:
            return {
                ...state,
                loading: true
            }

        case GET_ARESOURCES_SUCCESS:
            return {
                loading: false,
                resources: action.payload
            }

        case GET_ARESOURCES_FAIL:
            return {
                ...state,
                error: action.payload
            }

        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }
    
        default:
            return state
    }
}

export const activityReducer = (state = { questionKeywords: [] }, action ) => {
    switch (action.type) {
        case CHANGE_MODE_REQUEST:
        case UPDATE_RUBRIC_REQUEST:
        case UPDATE_ACTIVITY_REQUEST:
        case UPLOAD_ARESOURCE_REQUEST:
        case ADD_PROMPTER_REQUEST:
        case GET_ACTIVITY_REQUEST:
        case GET_KEYWORDS_REQUEST:
        case CHANGE_LEVEL_REQUEST:
        case DELETE_ACTIVITY_REQUEST:
            return {
                ...state,
                loading: true,
                questionKeywords: []
            }

        case UPDATE_ACTIVITY_SUCCESS:
            return {
                ...state,
                loading: false,
                editedActivity: action.payload
            }

        case DELETE_ACTIVITY_SUCCESS:
            return {
                ...state,
                loading: false,
                deletedActivity: action.payload
            }

        case CHANGE_MODE_SUCCESS:
            return {
                ...state,
                loading: false,
                updatedActivity: action.payload
            }

        case UPDATE_RUBRIC_SUCCESS:
            return {
                ...state,
                loading: false,
                updatedRubric: action.payload
            }

        case UPLOAD_ARESOURCE_SUCCESS:
            return {
                ...state,
                loading: false,
                uActivityResource: action.payload
            }

        case ADD_PROMPTER_SUCCESS:
            return {
                ...state,
                loading: false,
                addedPrompter: action.payload.success
            }

        case GET_ACTIVITY_SUCCESS:
            return {
                ...state,
                loading: false,
                activity: action.payload.activity
            }

        case GET_KEYWORDS_SUCCESS:
            return {
                ...state,
                loading: false,
                keywords: action.payload?.keywords,
                activityWordsNumber: action.payload?.prompter,
                questionKeywords: action.payload?.questionKeywords,
            }

        case CHANGE_LEVEL_SUCCESS:
            return {
                ...state,
                loading: false,
                changedLevel: action.payload.success
            }

        case CHANGE_MODE_FAIL:
        case UPDATE_RUBRIC_FAIL:
        case UPDATE_ACTIVITY_FAIL:
        case UPLOAD_ARESOURCE_FAIL:
        case ADD_PROMPTER_FAIL:
        case GET_ACTIVITY_FAIL:
        case GET_KEYWORDS_FAIL:
        case CHANGE_LEVEL_FAIL:
        case DELETE_ACTIVITY_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload
            }

        case UPDATE_ACTIVITY_RESET:
            return {
                ...state,
                editedActivity: false
            }

        case DELETE_ACTIVITY_RESET:
            return {
                ...state,
                deletedActivity: false
            }

        case CHANGE_MODE_RESET:
            return {
                ...state,
                updatedActivity: false
            }


        case UPDATE_RUBRIC_RESET:
            return {
                ...state,
                updatedRubric: false
            }

        case UPLOAD_ARESOURCE_RESET:
            return {
                ...state,
                uActivityResource: false
            }

        case ADD_PROMPTER_RESET:
            return {
                ...state,
                addedPrompter: false
            }

        case CHANGE_LEVEL_RESET:
            return {
                ...state,
                changedLevel: false
            }

        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }
    
        default:
            return state
    }
}