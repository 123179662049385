import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getMyQuestions } from "../actions/questionActions";
import Navbar from '../components/layouts/Navbar';
import moment from 'moment'

const QuestionLog = () => {

    const dispatch = useDispatch();

    const { user } = useSelector(state => state.auth);
    const { questions } = useSelector(state => state.myQuestions);
    const creator = user?._id;

    useEffect(() => {

        dispatch(getMyQuestions(creator));

    }, []);

  return (
    <>
        <Navbar />
        <div>
        <>
            <div className="mx-auto bg-white mt-9 dark:bg-gray-800 shadow rounded w-full">
                <div className="py-6 xl:px-8 lg:px-8 md:px-8 px-4 flex justify-between items-center">
                    <p className="font-bold text-sm xl:text-lg lg:text-lg md:text-lg text-gray-800 dark:text-gray-100">
                        My Questions <span className="text-gray-500">Logs</span>
                    </p>
                </div>
                {questions?.map((question, index) => 
                <div className="xl:px-8 lg:px-8 md:px-8 px-4  bg-white dark:bg-gray-800">
                    <div className="p-5 flex justify-between rounded mb-6 bg-gray-100 dark:bg-gray-700">
                        <div className="w-3/5">
                            <p className="mb-2 text-sm font-bold text-gray-800 dark:text-gray-100">{question?.content}</p>
                            <ul>
                                <li className="mb-2 text-xs text-gray-600 dark:text-gray-400">- Activity Name: {question?.activity?.name}</li>
                                <li className="mb-2 text-xs text-gray-600 dark:text-gray-400">- Question Type: {question?.questionType === "multiple_choice" ? "Multiple Choice" : "Open Ended"}</li>
                                {question?.questionType === "multiple_choice" ? 
                                    <li className="mb-2 text-xs text-gray-600 dark:text-gray-400">Choices: </li>
                                 : null}
                                {question?.questionType === "multiple_choice" ? 
                                    question?.choice.map((choic, idx) =>
                                        <li className="mb-2 ml-9 text-xs text-gray-600 dark:text-gray-400">- Choice: {choic.choice}</li>
                                    )
                                 : null}
                                <li className="text-xs text-gray-600 dark:text-gray-400">- Created at: {moment(question?.createdAt).utc().format('DD/MM/YY')}</li>
                            </ul>
                        </div>
                        <div className="w-3/12 flex flex-col items-end justify-between">
                            <p className="text-xs text-gray-600 dark:text-gray-400">Updated at: {moment(question?.updatedAt).utc().format('DD/MM/YY')}</p>
                        </div>
                    </div>
                </div>
                )}
                
            </div>
        </>
        
        </div>
    </>
  )
}

export default QuestionLog