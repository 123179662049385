import React, { useEffect } from 'react'
import Navbar from './Navbar'
import dalle from '../../assets/dalle-about-smile.jpg';
import ReactGA from 'react-ga4';
import { useLocation } from 'react-router-dom';

const Terms = () => {
    const location = useLocation();

    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: (location.pathname + location.search), title: "Terms" });
    }, [location]);

  return (
    <div style={{fontFamily: 'IBM Plex Sans',}}>
        <Navbar />

        <div
          className="bg-no-repeat bg-cover bg-center p-0 h-96"
          style={{
            backgroundImage: `url("${dalle}")`,
          }}
        >
          <div className="bg-black bg-opacity-50 w-full h-full flex items-end justify-center">
            <p className="text-white text-4xl font-light p-8">Terms of Use</p>
          </div>
        </div>


        <div className="max-w-6xl mx-auto">
          <h2 className="text-xl mt-5">1. Purpose of SMILE</h2>
          <p>SMILE is a learning management system designed to help students study school subject matter, develop higher-order learning skills, and generate transparent real-time learning analytics. The main goal of SMILE is to develop students’ questioning skills, encourage greater student-centric activities and practices in classrooms, and enable a low-cost mobile learning environment.</p>

          <h2 className="text-xl mt-5">2. User Conduct</h2>
          <p>When using SMILE, you agree to:</p>
          <ul>
              <li>Use SMILE solely for educational purposes and in compliance with all applicable laws and regulations.</li>
              <li>Respect the intellectual property rights of others and refrain from posting or sharing any copyrighted materials without proper authorization.</li>
              <li>Refrain from engaging in any activity that may disrupt, damage, or impair the functioning of SMILE or its associated systems.</li>
              <li>Use SMILE in a responsible and respectful manner when interacting with other users, including students, teachers, and administrators.</li>
          </ul>

          <h2 className="text-xl mt-5">3. Ownership of Content</h2>
          <p>All content created and shared within SMILE, including multiple-choice and open-ended questions, remain the intellectual property of their respective creators. Users retain ownership of their content and grant Seeds of Empowerment and SMILE a non-exclusive, worldwide, royalty-free license to use, reproduce, modify, and distribute their content for educational and research purposes.</p>

          <h2 className="text-xl mt-5">4. Privacy and Data Collection</h2>
          <p>SMILE collects user data, including personal information and learning analytics, to improve the functionality and effectiveness of the platform. By using SMILE, you consent to the collection, processing, and storage of your data as outlined in our Privacy Policy.</p>

          <h2 className="text-xl mt-5">5. Prohibited Activities</h2>
          <p>Users of SMILE must not engage in the following activities:</p>
          <ul>
              <li>Posting or sharing content that is unlawful, offensive, defamatory, or violates the rights of others.</li>
              <li>Attempting to gain unauthorized access to SMILE or any user accounts.</li>
              <li>Interfering with the security measures or technical features of SMILE.</li>
          </ul>

          <h2 className="text-xl mt-5">6. Disclaimer of Warranty</h2>
          <p>SMILE is provided on an "as is" basis, and Seeds of Empowerment makes no warranties, express or implied, regarding the accuracy, reliability, or suitability of the platform for any particular purpose. The use of SMILE is at your own risk.</p>

          <h2 className="text-xl mt-5">7. Limitation of Liability</h2>
          <p>In no event shall Seeds of Empowerment or its affiliates be liable for any direct, indirect, incidental, special, or consequential damages arising out of or in connection with the use of SMILE, including but not limited to, damages for loss of data, revenue, or profits.</p>

          <h2 className="text-xl mt-5">8. Modifications and Updates</h2>
          <p>Seeds of Empowerment reserves the right to modify, update, or discontinue SMILE at any time without prior notice. These Terms of Use may also be updated from time to time, and continued use of SMILE constitutes your acceptance of any changes.</p>

          <h2 className="text-xl mt-5">9. Termination of Access</h2>
          <p>Seeds of Empowerment reserves the right to terminate or suspend your access to SMILE at its sole discretion, with or without cause, and without liability to you or any third party.</p>

          <h2 className="text-xl mt-5">10. Governing Law</h2>
          <p>These Terms of Use shall be governed by and construed in accordance with the laws of the State of California, United States.</p>

          <h2 className="text-xl mt-5">11. Contact Information</h2>
          <p>For questions, concerns, or feedback regarding SMILE or these Terms of Use, please contact the SMILE team at <a href="mailto:smile@seedsofempowerment.org">smile@seedsofempowerment.org</a>.</p>

          <p>By using SMILE, you acknowledge that you have read, understood, and agreed to these Terms of Use.</p>
        </div>
    </div>
  )
}

export default Terms