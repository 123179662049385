import React, { useEffect, useState } from 'react';
import "./Question.css";
import { BsChatDots, BsPencil, BsPlusLg, BsRocket } from 'react-icons/bs';
import { BiBaseball, BiHide, BiLockAlt, BiShow, BiTime } from 'react-icons/bi';
import { FiSettings } from 'react-icons/fi';
import { Link, createSearchParams, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { createQuestion, getQuestions } from '../../actions/questionActions';
import io from "socket.io-client";
import { changeActivityLevel, createActivity, getActivityKeywords, getActivityRubric, getAllActivities, updateActivityMode } from '../../actions/activityActions';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { CHANGE_LEVEL_RESET, CHANGE_MODE_RESET, DELETE_ACTIVITY_RESET, NEW_ACTIVITY_RESET, UPDATE_ACTIVITY_RESET } from '../../constants/activityConstants';
import { NEW_QUESTION_RESET } from '../../constants/questionConstants';
import { deleteGroup, getGroupMembers, getGroupResources, getSingleGroup, updateGroupSettings, uploadResource } from '../../actions/groupActions';
import { createResponse, getAllResponses } from '../../actions/responseActions';
import { DELETE_GROUP_RESET, UPDATE_SETTINGS_RESET, UPLOAD_RESOURCE_RESET } from '../../constants/groupConstants';
import Resources from '../layouts/group/Resources';
import Members from '../layouts/group/Members';
import { BiCloudUpload } from 'react-icons/bi';
import { MdAddAPhoto } from 'react-icons/md';
import { CgTrashEmpty } from 'react-icons/cg';
import { FaPlus } from 'react-icons/fa';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { NEW_RESPONSE_RESET } from '../../constants/responseConstants';
import { ChoiceMapper, RoleMapper } from '../helpers/Mappers';
import Settings from '../layouts/activity/Settings';
import Navbar from '../layouts/Navbar';
import AResources from '../layouts/activity/Resources';
import { TbRuler } from 'react-icons/tb';
import { CiBasketball } from 'react-icons/ci';
import SelectImageModal from '../popovers/SelectImageModal';
import Default from '../../assets/groupdefault.jpg';
import StarIcon from '../../assets/star.svg';
import ReactGA from 'react-ga4';
import { useLocation } from 'react-router-dom';
import GroupReport from '../layouts/group/GroupReport';
import ActivityReport from '../layouts/activity/ActivityReport';
import RateComponent from '../layouts/activity/RateComponent';
import ProgressBar from '@ramonak/react-progress-bar';
import { RxLockClosed } from 'react-icons/rx';
import { HiOutlineChartBar, HiOutlinePuzzle } from 'react-icons/hi';
import { ACTIVITIES, ACTIVITY_DESCRIPTION, ACTIVITY_NAME, ACTIVITY_TYPE, ADD_PHOTO, ANSWER_OPTION, ANSWER_TYPE, CANCEL, CLOSE_MODAL, CREATE, CREATE_ACTIVITY, CREATE_NEW_QUESTION, CREATE_QUESTION, DELETE_GROUP, DELETE_GROUP_WARNING, ENTER_QUESTION, ENTER_QUESTION_HERE, EXAM_MODE, EXAM_MODE_DESC, GENERAL, GROUPS, GROUP_DESCRIPTION, GROUP_NAME, GROUP_SETTINGS, GROUP_TYPE, HIDE_USERNAME, HIDE_USERNAME_DESC, LOCKED, MEMBERS, MULTIPLE_CHOICE, NO_CANCEL, OPEN_ENDED, PASSCODE_CHARACTERS, PRIVATE, PUBLIC, QUESTIONS, REPORTS, RESOURCES, RESULTS, REVIEW, SAVE, SAVE_CHANGES, SELECT, SELECT_A_CHOICE, SETTING, SETTINGS, SOLVE, STUDENTS_CONNECTED, STUDENT_MODE, STUDENT_MODE_DESC, STUDENT_TYPING, SUBMIT_ANSWER, SUBMIT_QUESTION, UPLOAD, UPLOAD_FILE, UPLOAD_IMAGES, UPLOAD_RESOURCE, VISIBLE, VISIBLE_DESC, WORDS_SELECTED, YES_SURE, YOUR_ANSWER } from '../../pages/words/GroupConstants';

const baseUrl = process.env.REACT_APP_IMAGES_BASEURL;
const ENDPOINT = process.env.REACT_APP_ENDPOINT;
let socket;

const QuestionDesktop = () => {

    const [openTab, setOpenTab] = useState('');
    const [openNext, setOpenNext] = useState(false);
    const [onlineUsers, setOnlineUsers] = useState([]);
    const [typinUsers, setTypinUsers] = useState([]);
    const [activityName, setActivityName] = useState('');
    const [activityId, setActivityId] = useState(undefined);
    const [editActivity, seteditActivity] = useState(undefined);
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [openSection, setOpenSection] = useState(2);
    const [content, setContent] = useState('');
    const [activeQuestion, setActiveQuestion] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [qid, setQid] = useState('');
    const [idxs, setIdxs] = useState(0);
    const [qOwnerId, setqOwnerId] = useState('');
    const [socketConnected, setSocketConnected] = useState(false);
    const [typing, setTyping] = useState(false);
    const [isTyping, setIsTyping] = useState(false);
    const [groupImage, setGrImage] = useState('');
    const [selectedGroupImage, setselectedGroupImage] = useState('');
    const [questionImage, setQuestionImage] = useState('');
    const [avatarPreview, setAvatarPreview] = useState(Default);
    const [grName, setGrName] = useState('');
    const [grDescription, setGrDescription] = useState('');
    const [grpasscode, setGrpasscode] = useState('');
    const [isPrivate, setIsPrivate] = useState(false);
    const [requirepasscode, setRequirepasscode] = useState(false);
    const [answer, setAnswer] = useState('');
    const [showUpload, setShowUpload] = useState(false);
    const [showMode, setShowMode] = useState(false);
    const [doc, setdoc] = useState('');
    const [opensettings, setOpenSettings] = useState(1);
    const [activityType, setActivityType] = useState('StudentPaced');
    const [activitType, setActivitType] = useState('');
    const [activeActivity, setActiveActivity] = useState('');
    const [activitMode, setActivitMode] = useState('');
    const [mode, setMode] = useState ('');
    const [openQtab, setOpenQtab] = useState(1);
    const [selectedChoice, setSelectedChoice] = useState('');
    const [inputFields, setInputFields] = useState([
        {choice: ''}
    ]);
    const [deleteModal, setDeleteModal] = useState(false);
    const [choiceAlphabet, setChoiceAlphabet] = useState(0);
    const [rating, setRating] = useState('');
    const [questionsy, setQuestionsy] = useState([]);
    var [arrivalQuestion, setArrivalQuestion] = useState(null);
    const [mobileView, setmobileView] = useState(4);
    const [errorMsg, setErrorMsg] = useState(false);
    const [activityErrorMsg, setActivityErrorMsg] = useState(false);
    const [alreadySubmitted, setAlreadySubmitted] = useState(false);
    const [groupUploadErrorMsg, setGroupUploadErrorMsg] = useState(false);
    const [searchResourceQuery, setSearchResourceQuery] = useState('');
    const [startTime, setStartTime] = useState(null);
    const [endTime, setEndTime] = useState(null);
    const [answerStartTime, setAnswerStartTime] = useState(null);
    const [answerEndTime, setAnswerEndTime] = useState(null);
    const [typeStartTime, setTypeStartTime] = useState(null);
    const [typeEndTime, setTypeEndTime] = useState(null);
    const [showPassword, setShowPassword] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    const [correctAnswerError, setCorrectAnswerError] = useState(false);
    const [choicesError, setChoicesError] = useState(false);
    const [qimageError, setqimageError] = useState(false);
    const [openCategory, setOpenCategory] = useState(1);
    const [submitted, setSubmitted] = useState(false);
    const [activeLevel, setActiveLevel] = useState("initial");
    const [hideUsernames, setHideUsernames] = useState(false);
    const [hideActivities, setHideActivities] = useState(true);
    const [activeTab, setActiveTab] = useState("");

    const inputRef = React.createRef();
    const dispatch = useDispatch();
    const location = useLocation();

    const { user } = useSelector(state => state.auth);
    const { group } = useSelector(state => state.groupDetails);
    const { resources } = useSelector(state => state.groupResources);
    const { members } = useSelector(state => state.groupMembers);
    const { updatedSettings, loading: sloading, uploadedResource, error: groupUploadError, deletedGroup } = useSelector(state => state.group);
    const { questions, error } = useSelector(state => state.questions);
    const { activities, loading: actLoading } = useSelector(state => state.activities);
    const { question, error: questionError, progress } = useSelector(state => state?.newQuestion);
    const { createdActivity } = useSelector(state => state?.newActivity);
    const { responses } = useSelector(state => state?.responses);
    const { response } = useSelector(state => state?.newResponse);
    const { editedActivity, updatedActivity, questionKeywords, changedLevel, deletedActivity } = useSelector(state => state.activity);
    const navigate = useNavigate();

    const creator = user?._id;
    let { groupId } = useParams();
    const owningGroup = groupId;

    const timeDiff = endTime && startTime ? endTime - startTime : null;
    const timeType = typeStartTime && typeEndTime ? typeEndTime - typeStartTime : null;

    const tab = new URLSearchParams(useLocation().search).get('tab');
    const category = new URLSearchParams(useLocation().search).get('category');
    const el = new URLSearchParams(useLocation().search).get('el');

    const showToastMessage = (message) => {
        toast.success(message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000
        });
    };

    // mobileview 4 = create activity

    useEffect (() => {
        if (group && group?.group?._id !== owningGroup) {
            dispatch(getSingleGroup(owningGroup, creator));
            dispatch(getAllActivities(owningGroup));
            dispatch(getGroupResources(owningGroup));
            dispatch(getGroupMembers(owningGroup));
            dispatch(getActivityRubric(owningGroup));
        } else {
            setGrName(group?.group?.name)
            setGrDescription(group?.group?.description)
            setGrpasscode(group?.group?.gpasscode)
            setIsPrivate(Boolean(group?.group?.isPrivate));
            setAvatarPreview(group?.group?.groupImage?.url ? (group?.group?.groupImage?.public_id === null ? ((baseUrl + group?.group?.groupImage?.url)) : group?.group?.groupImage?.url) : Default)
        }

        if (updatedSettings) {
            dispatch({ type: UPDATE_SETTINGS_RESET });
            dispatch(getSingleGroup(owningGroup, creator));
            showToastMessage("Updated settings successfully!");
        }
        
    }, [dispatch, group, owningGroup, updatedSettings]);

    useEffect (() => {
        setAnswerStartTime(Date.now());
    }, []);

    useEffect(() => {
        if (activityId) {
            dispatch(getActivityKeywords(activityId));
        }
    }, [activityId]);

    useEffect (() => {
        if(activities && !!activities.length) {
            if (activeLevel === "creating") {
                const latestId = activities?.length - 1
                setActivityName(activities?.[latestId]?.name);
                setActivityId(activities?.[latestId]?._id);
                setActiveActivity(activities?.[latestId]);
                socket?.emit("join activity", { userId: creator, activity: activities?.[latestId]?._id });
                dispatch(getQuestions(activities?.[latestId]?._id));
                setOpenCategory(1);
                
                // group?.role === 2 || group?.role === 4 ? setActivityId(undefined) : setActivityId(activities?.[0]?._id)
            } else if (activeLevel === "updating") {
                // setActivityName(activityName);
                // setActivityId(activityId);
                const hgh = activities.find(g => g._id === activityId);
                setActiveActivity(hgh);
                socket?.emit("join activity", { userId: creator, activity: activityId });
                dispatch(getQuestions(activityId));
            } else {
                setActivityName(activities?.[0]?.name);
                setActivityId(activities?.[0]?._id);
                setActiveActivity(activities?.[0]);
                socket?.emit("join activity", { userId: creator, activity: activities?.[0]?._id });
                dispatch(getQuestions(activities?.[0]?._id));
                setActiveLevel("initial");
                // group?.role === 2 || group?.role === 4 ? setActivityId(undefined) : setActivityId(activities?.[0]?._id)
            }
        }

        if (createdActivity) {
            showToastMessage("Activity created successfully!");
            dispatch({ type: NEW_ACTIVITY_RESET });
            dispatch(getAllActivities(owningGroup));
            setShowModal(false);
            setName('');
            setDescription('');
            setHideUsernames(false);
            setHideActivities(true);
        }

        if (editedActivity) {
            showToastMessage("Activity updated successfully!");
            dispatch(getAllActivities(owningGroup));
            dispatch({ type: UPDATE_ACTIVITY_RESET });
            // setOpenCategory(2);
        }

        if (deletedActivity) {
            showToastMessage("Activity deleted!");
            dispatch(getAllActivities(owningGroup));
            dispatch({ type: DELETE_ACTIVITY_RESET });
            setActiveLevel("initial");
            // setOpenCategory(2);
        }

        if (updatedActivity) {
            dispatch({ type: CHANGE_MODE_RESET });
            dispatch(getAllActivities(owningGroup));
            // setOpenCategory(2);
        }
        
    }, [createdActivity, activities, updatedActivity, editedActivity, group, activeLevel, creator, dispatch, deletedActivity]);

    useEffect (() => {
        if (group && group?.group?._id !== owningGroup) {
            dispatch(getGroupResources(owningGroup));
        } 

        if (uploadedResource) {
            showToastMessage("Resource uploaded successfully!");
            dispatch({ type: UPLOAD_RESOURCE_RESET });
            dispatch(getGroupResources(owningGroup));
            setShowUpload(false);
        }
        
    }, [group, owningGroup, uploadedResource]);

    useEffect(() => {
        socket = io(ENDPOINT);
        socket.emit("setup", user);
        socket.on("connected", () => setSocketConnected(true));
        socket.on("typing", (typingUsers) => {
            setTypinUsers(typingUsers)
            setIsTyping(true)
        });
        socket.on("stop typing", () => {
            setIsTyping(false)
        });
    }, []);

    useEffect(() => {
        socket?.emit("addUser", { userId: creator, group: owningGroup });
        socket?.on("getUsers", (users) => {
            setOnlineUsers(users);
        });
    }, [creator, owningGroup]);

    // useEffect(() => {
    //     socket?.on("getActiveUsers", (activityUsers) => {
    //     });
    // }, [creator]);

    useEffect (() => {
        if(activities && !!activities.length) {
            setQuestionsy(questions)
            if(questions && !qid) {
                // Very first time page loads. qid is undefined
                setActiveQuestion(questions?.[0]);
                setQid(questions?.[0]?._id);
                setqOwnerId(questions?.[0]?.creator?._id);
                setRating(questions?.[0]?.reviews?.find(r => r.user.toString() === user._id.toString()) ? questions?.[0]?.reviews?.find(r => r.user.toString() === user._id.toString())?.rating : 0)
            } 
            if(questions && qid) {
                // When user updates it means we will have qid
                const quiz = questions?.find(question => question?._id === qid)
                setActiveQuestion(quiz);
                setQid(quiz?._id);
                setqOwnerId(quiz?.creator?._id);
                setRating(quiz?.reviews?.find(r => r.user.toString() === user._id.toString()) ? quiz?.reviews?.find(r => r.user.toString() === user._id.toString())?.rating : 0)
            } 
        }


        if (question) {
            socket?.emit("new question", { userId: creator, activity: activityId, question: question });
            dispatch(getQuestions(activityId));
            setContent('');
            setInputFields([{choice: ''}]);
            inputRef.current.value = null;
            setQuestionImage("");
            setCorrectAnswerError(false);
            setChoicesError(false);
            showToastMessage("Question submitted successfully!");
            dispatch({ type: NEW_QUESTION_RESET });
        }
        
    }, [error, questions, question]);

    useEffect(() => {
        if (group?.group?.name) {
            ReactGA.send({ hitType: "pageview", page: (location.pathname + location.search), title: group?.group?.name });
        }
    }, [location, group]);

    useEffect(() => {
        socket?.on("question received", newQuestionReceived => {
            if (activityId !== undefined) {
                setArrivalQuestion(newQuestionReceived.question)
            }
        })
    }, [questions]);

    useEffect(() => {
        socket?.on("mode received", updatedMode => {
            setActiveLevel("updating");
            dispatch(getAllActivities(owningGroup));
        })
    }, []);

    // useEffect(() => {
    //     socket?.on("response received", updatedResponse => {
    //         dispatch(getQuestionDetails(questionId));
    //     })
    // }, []);

    useEffect(() => {
       arrivalQuestion && activityId !== undefined && setQuestionsy((prev) => [arrivalQuestion, ...prev]);
       setArrivalQuestion(null)
    }, [arrivalQuestion, questions, activityId]);

    useEffect(() => {
        if (qid) {
            dispatch(getAllResponses(qid));
        }

        if (response) {
            showToastMessage("Answer submitted successfully!");
            dispatch({ type: NEW_RESPONSE_RESET });
            dispatch(getQuestions(activityId));
            // dispatch(getAllResponses(qid));
        }
        
    }, [qid, response]);

    // useEffect(() => {

    //     if (qid && responses) {
    //         responses.map((res, idx) => 
    //             res?.question?._id === qid && res?.creator?._id === creator ? (setAnswer(res?.content), setAlreadySubmitted(true)) : (setAnswer(""), setAlreadySubmitted(false))
    //         )
    //     }
        
    // }, [qid, responses]);

    useEffect(() => {
        if (deletedGroup) {
            dispatch({ type: DELETE_GROUP_RESET });
            showToastMessage("Group Deleted!");
            navigate('/groups')
        }
    }, [deletedGroup]);

    useEffect(() => {
        if (changedLevel) {
            dispatch({ type: CHANGE_LEVEL_RESET });
            setActiveLevel("updating");
            dispatch(getAllActivities(owningGroup));
            socket?.emit("activity update", { userId: user?._id, activity: activityId });
        }
    }, [changedLevel]);

    const handleQuestionChange = () => {
        if (activityId) {
            dispatch(getActivityKeywords(activityId));
        }
    }

    const createQuestionHandler = (event) => {
        event.preventDefault();
        socket.emit("stop typing", {activity: activityId, userId: creator});
        let questionType;
        let choices = [];
        let correctAnswers = [];
        const formElements = event.target.elements;
        const newCheckedItems = [];

        for (let i = 0; i < formElements.length; i++) {
            const element = formElements[i];
            if (element.type === "checkbox" && element.checked) {
                newCheckedItems.push(element.value);
            }
        }
        const MAX_FILE_SIZE = 1024 * 1024 * 10;
        if(questionImage.size > MAX_FILE_SIZE){
            setqimageError("Image cannot exceed 10Mbs");
            return
        } else {
            setqimageError(false);
        }
        {openQtab === 2 ? questionType = "multiple_choice" : questionType = "open_ended"};
        if(openQtab === 2 && (inputFields.length <= 1)){
            setChoicesError("Please create at least two choices");
            return
        } else {
            setChoicesError(false);
        };
        if(openQtab === 2 && (newCheckedItems.length === 0)){
            setCorrectAnswerError("Please select correct answer for your question");
            return
        } else {
            setCorrectAnswerError(false);
        }
        {openQtab === 2 ? choices=JSON.stringify(inputFields) : choices=[]}
        {openQtab === 2 ? correctAnswers=JSON.stringify(newCheckedItems) : correctAnswers=[]};
        const qKeywords = (process.env.REACT_APP_ENABLE_OPENAIPROMPTER === "true") ? questionKeywords?.map(k => k?.keyword) : [];
        dispatch(getActivityKeywords(activityId));
        dispatch(createQuestion(creator, questionType, choices, content, correctAnswers, activityId, questionImage, timeDiff, qKeywords.toString()));
        inputRef.current.value = null;
        setqimageError(false);
        setStartTime(null);
        setEndTime(null);
        setSubmitted(true);
    }

    const typingHandler = () => {
        if (!socketConnected) return;

        if (!typing) {
            setTyping(true);
            socket.emit("typing", {activity: activityId, userId: creator});
        }
        let lastTypingTime = new Date().getTime();
        var timerLength = 3000;
        setTimeout(() => {
            var timeNow = new Date().getTime();
            var timeDiff = timeNow - lastTypingTime;

            if (timeDiff >= timerLength && typing) {
                socket.emit("stop typing", {activity: activityId, userId: creator});
                setTyping(false);
            }
        }, timerLength);
    }

    const createActivityHandler = (e) => {
        e.preventDefault();
        if(name === ""){
            setActivityErrorMsg("Please provide activity name");
            return
        };
        setActiveLevel("creating");
        let gradeLevels;

        dispatch(createActivity(creator, name, description, owningGroup, activityType, gradeLevels, hideUsernames, hideActivities));
    }

    const updateActivityHandler = () => {
        setActiveLevel("updating");
        dispatch(updateActivityMode(activityId, mode));
        seteditActivity(activityId);
        setShowMode(false);
    }

    const handleTabChange = (newTab, category, el) => {
        setActiveTab(newTab);
        const tab = [['tab', newTab], ['category', category], ['el', el ? "vp" : "vm"]];
        navigate({pathname: `/group/${groupId}`, search: `?${createSearchParams(tab)}`,});
    };

    useEffect (() => {
        if (el === "vm") {
            group?.role === 2 || group?.role === 4 ? handleTabChange("general", "group", "vp") : handleTabChange("questions", "activity", "vp");
        }            
    }, [group?.role]);

    useEffect(() => {
        if (!el) {
            if (!questions?.length) {
                // Show the create question div when questions are empty in an activity
                handleTabChange("answer", "activity");
            } else {
                handleTabChange("questions", "activity");
            }
        }
    }, [questions]);

    useEffect(() => {
        if (el && category === "activity" && (tab === "questions" || tab === "answer")) {
            if (!questions?.length) {
                // Show the create question div when questions are empty in an activity
                handleTabChange("answer", "activity", "vp");
            } else {
                handleTabChange("questions", "activity", "vp");
            }
        }
    }, [questions]);

    const alphabetMapper = (idX) => {
        return ChoiceMapper.find(i => i?.key === (idX + 1))?.value
    }

    const createAnswerHandler = () => {
        const question = qid;
        const timeThink = answerStartTime ? Date.now() - answerStartTime : null;
        dispatch(createResponse(creator, answer, question, rating, timeThink, timeType));
        // setAnswer('');
        // setRating('');
        setAnswerStartTime(null);
        setAnswerEndTime(null);
    }

    const createAnswerHandler2 = () => {
        const question = qid;
        const content = selectedChoice;
        const choice = alphabetMapper(Number(choiceAlphabet));
        setAnswerEndTime(Date.now());
        const timeThink = answerStartTime && answerEndTime ? answerEndTime - answerStartTime : null;
        dispatch(createResponse(creator, content, question, rating, timeThink, timeType, choice));
        // setAnswer('');
        setSelectedChoice('');
        setAnswerStartTime(null);
        setAnswerEndTime(null);
        setChoiceAlphabet(0);
    }

    const updateSettings = (e) => {
        e.preventDefault();

        const MAX_FILE_SIZE = 1024 * 1024 * 10;
        const id = owningGroup;

        if(selectedGroupImage.size > MAX_FILE_SIZE){
            setErrorMsg("File size cannot exceed 10Mbs");
            return
        }

        setErrorMsg("")
        dispatch(updateGroupSettings(id, grName, grDescription, isPrivate, grpasscode, requirepasscode, groupImage));
        setGrName("");
        setGrDescription('');
    }

    const handleFormChange = (index, event) => {
        let data = [...inputFields];
        data[index][event.target.name] = event.target.value;
        setInputFields(data);
    }

    const addFields = () => {
        let newfield = { choice: '' }
    
        setInputFields([...inputFields, newfield])
    }

    const removeFields = (index) => {
        let data = [...inputFields];
        data.splice(index, 1)
        setInputFields(data)
    }

    const onChange = e => {
        if(e.target.name === 'grimage') {
            const reader = new FileReader();
            reader.onload = () => {
                if (reader.readyState === 2) {
                    setAvatarPreview(reader.result)
                }
            }
            reader.readAsDataURL(e.target.files[0])
            setselectedGroupImage(e.target.files[0])
            setGrImage(e.target.files[0])
        } 
    }

    const onChange3 = e => {
        if(e.target.name === 'qrimage') {
            setQuestionImage(e.target.files[0]);
        } 
        
    }

    const handleUploadedFile = (e) => {
        setdoc(e.target.files[0]);
    }

    const upload = (e) => {
        e.preventDefault();
        const MAX_FILE_SIZE = 1024 * 1024 * 10;

        if(doc.size > MAX_FILE_SIZE){
            setGroupUploadErrorMsg("File size is greater than maximum limit");
            return
        }
        const group = owningGroup;
        dispatch(uploadResource(creator, group, doc));
    };

    const userRoleMapper = (idX) => {
        return RoleMapper.find(i => i?.key === (idX))?.value
    };

    const handleKeyDown = () => {
        if (!startTime) {
            setStartTime(Date.now());
        }
        setEndTime(Date.now());
    };

    const handleAnswerKeyDown = () => {
        if (!typeStartTime) {
            setTypeStartTime(Date.now());
        }
        setTypeEndTime(Date.now());
    };

    const deleteGroupHandler = () => {
        dispatch(deleteGroup(owningGroup));
    };

    const handleActivityLevelChange = (suggestedLevel) => {
        let level;
        switch (activeActivity?.level) {
            case "locked":
                level = "create";
            break;
            case "create":
                level = "solve";
            break;
            case "solve":
                level = "review";
            break;
            default:
                level = "create";
            break;
        }
        if (activeActivity?.level) {
            if (suggestedLevel !== level) {
                return;
            }
        }
        if (group?.role === 3) {
            return
        }
        dispatch(changeActivityLevel(activeActivity?._id, level))
    }

    useEffect(() => {
        if (showModal) {
        document.body.style.overflow = 'hidden';
        } else {
        document.body.style.overflow = 'auto';
        }
    
        return () => {
        document.body.style.overflow = 'auto';
        };
    }, [showModal]);

    const groupImageUrl = group?.group?.groupImage?.url ? (group?.group?.groupImage?.public_id === null ? ((baseUrl + group?.group?.groupImage?.url)) : group?.group?.groupImage?.url) : Default;
    const isCreateMode = (activeActivity?.activityType === 'StudentPaced' || (activeActivity?.activityType === 'OrganizerPaced' && activeActivity?.level === 'create'));
    const isSolveMode = (activeActivity?.activityType === 'StudentPaced' || (activeActivity?.activityType === 'OrganizerPaced' && activeActivity?.level === 'solve'));
    const isReviewMode = (activeActivity?.activityType === 'StudentPaced' || (activeActivity?.activityType === 'OrganizerPaced' && activeActivity?.level === 'review'));

  return (
    <>
    <Navbar />
    <ToastContainer />
    <div className='grid grid-cols-10'>
        <div className={(mobileView === 4 ? " " : "hidden ") + "mt-[53px] min-h-screen col-span-10 md:col-span-3 div1 md:flex"}>
            <div className='flex flex-row gap-3 mb-[15px] p-4 w-full md:mb-0'>
                <img className="imagediv1" onClick={() => setSelectedImage(groupImageUrl)}  src={group?.group?.groupImage?.url ? (group?.group?.groupImage?.public_id === null ? ((baseUrl + group?.group?.groupImage?.url)) : group?.group?.groupImage?.url) : Default} alt='' />
                {selectedImage && <SelectImageModal selectedImage={selectedImage} setSelectedImage={setSelectedImage} />}
                <div className='div1desc'>
                    <p className='owner'>{userRoleMapper(group?.role)}</p>
                    <ol className="inline-flex">
                        <li className="inline-flex">
                        <Link to={'/groups'} className="inline-flex grouptitle">
                            {GROUPS}
                        </Link>
                        </li>
                        <li className="inline-flex">
                            <svg className="w-6 h-6 text-[#343434]" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipule="evenodd"></path></svg>
                            <p className='grouptitlep' translate="no">{group?.group?.name}</p>
                        </li>
                    </ol>
                </div>
            </div>
            <div className='div1row2 pb-4 w-full'>
                <div className='activitydiv w-full'>
                    {activities ? (
                        activities.map((activity, index) =>
                            <div key={index} onClick={() => {setActivityName(activity?.name); setIdxs(0); setQid(''); 
                            setContent(''); setInputFields([{choice: ''}]); setQuestionImage(""); setCorrectAnswerError(false); setChoicesError(false);
                            setSubmitted(false); setqimageError(false); setActivityId(activity?._id); setActiveActivity(activity); setOpenCategory(1); setmobileView(1); setActivitType(activity?.activityType); setActivitMode(activity?.mode); dispatch(getQuestions(activity?._id)); socket.emit("join activity", { userId: creator, activity: activity?._id }); handleTabChange("questions", "activity", "vp");}} className='flex flex-col w-full px-4 py-2 cursor-pointer border-b border-gray-200'>
                                <p className='activityNameLabel' translate="no">{activity?.name}</p>
                                
                                <div className='flex w-full flex-row justify-between'>
                                    <p className={(activity?.activityType == "OrganizerPaced" ? ' bg-[#68758F] text-[#FFFF] ' : ' bg-[#aebad180] text-[#68758F] ') + " flex items-center activitytypen px-2 py-1 w-auto rounded-[20px]"}>{activity?.activityType == "OrganizerPaced" ? "Exam Mode" : "Student Mode"}</p>
                                </div>
                            </div>
                        )
                    ) : null}
                    {activities?.length === 0 && actLoading === false && <div className='flex flex-col items-center w-full'>
                        
                        <div className='flex w-[62px] h-[62px] rounded-full bg-[#777777]'>
                            <div className='w-full h-full relative'>
                                <BsPencil className='absolute right-2 bottom-4 h-7 w-6' />
                                <TbRuler className='absolute right-1 -top-2 h-6 w-6' />
                                <CiBasketball className='absolute left-1 -top-2 h-6 w-6' />
                            </div>
                        </div>
                        <p className='noActivity'>No Activities Yet</p>
                    </div>}
                    
                    {(group?.role === 2 || group?.role === 4) ?
                    <div onClick={() => setShowModal(true)} className='hidden cursor-pointer md:flex activitycreate'>
                        <BsPlusLg className='plus' />
                        <p className='activityname'>{CREATE_ACTIVITY}</p>
                    </div> : null}
                    {<div onClick={() => {setActivityId(undefined); group?.role === 3 ? setOpenSettings(3) : setOpenSettings(1); 
                        group?.role === 3 ? handleTabChange("members", "group", "vp") : handleTabChange("general", "group", "vp"); setOpenCategory(2); setmobileView(6)}} className='flex groupdiv cursor-pointer'>
                        <FiSettings className='settingsIcon' />
                        <p className='settingsLabel'>{GROUP_SETTINGS}</p>
                    </div>}
                    {showModal ? (
                        <>
                        <div className="flex overflow-x-hidden overflow-y-auto fixed inset-0 z-[1002] outline-none focus:outline-none">
                            <div className='flex justify-center overflow-y-auto items-center  mx-auto my-auto'>
                                <div className='createactivityboxcontent my-4 createactivitybox w-full md:max-w-[554px]'>
                                    <form className='w-full  p-4'>
                                        <div className="mb-4">
                                            <label className=" createFormLabels block text-sm mb-2" htmlFor="group_name">
                                                {ACTIVITY_NAME}
                                            </label>
                                            <input value={name} onChange={(e) => setName(e.target.value)} className="border rounded w-full py-2 px-3" id="group_name" type="text" />
                                            <p className='text-sm text-red-700'>{activityErrorMsg}</p>
                                        </div>
                                        <div className="">
                                            <label className=" createFormLabels block text-sm mb-2" htmlFor="group_description">
                                                {ACTIVITY_DESCRIPTION}
                                            </label>
                                            <textarea value={description} onChange={(e) => setDescription(e.target.value)} id="group_description" rows="3" className="mt-1 py-2 px-3 block w-full rounded border ">
                                            </textarea>
                                        </div>
                                        <div className="py-4">
                                            <label className="flex items-center createFormLabels text-sm mb-2" htmlFor="activityType">
                                                {ACTIVITY_TYPE}
                                            </label>
                                        </div>
                                        <div className="px-4 pb-4" id='activityType'>
                                            <label className='gap-4 inline-flex items-center mb-2'>
                                                <input checked={activityType === 'StudentPaced'} onChange={(e) => setActivityType("StudentPaced")} name="activitytype" type="radio" className="form-radio" />
                                                <div className='flex flex-row cursor-pointer gap-2'>
                                                    <div className='flex flex-col'>
                                                        <div className='flex flex-row gap-2'>
                                                            <BiBaseball className='w-4 h-4' />
                                                            <p className='activityTypeCtitle'>{STUDENT_MODE}</p>
                                                        </div>
                                                        <p className='activityTypeCinfo'>{STUDENT_MODE_DESC}</p>
                                                    </div>
                                                </div>
                                            </label>
                                            <label className='gap-4 inline-flex items-center'>
                                                <input type="radio" checked={activityType === 'OrganizerPaced'} onChange={(e) => setActivityType("OrganizerPaced")} name="activitytype" className="form-radio" />
                                                <div className='flex flex-row cursor-pointer gap-2'>
                                                    <div className='flex flex-col'>
                                                        <div className='flex flex-row gap-2'>
                                                            <BiTime className='w-4 h-4' />
                                                            <p className='activityTypeCtitle'>{EXAM_MODE}</p>
                                                        </div>
                                                        <p className='activityTypeCinfo'>{EXAM_MODE_DESC}</p>
                                                    </div>
                                                </div>
                                            </label>
                                        </div>
                                        <div className="mb-4 pt-4">
                                            <label className="flex items-center createFormLabels text-sm mb-2" htmlFor="activitySetting">
                                                {SETTING}
                                            </label>
                                        </div>
                                        <div className="px-4" id='activitySetting'>
                                            <label className='gap-2 inline-flex items-center mb-2'>
                                                <div className='flex flex-row gap-2'>
                                                    <div className='w-4'>
                                                        <BiHide className='w-4 h-4' />
                                                    </div>
                                                    <div className='flex flex-col'>
                                                        <p className='activityTypeCtitle'>{HIDE_USERNAME}</p>
                                                        <p className='activityTypeCinfo'>
                                                            {HIDE_USERNAME_DESC}
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="flex">
                                                    <label className="inline-flex relative items-center cursor-pointer">
                                                        <input
                                                            type="checkbox"
                                                            className="sr-only peer"
                                                            checked={hideUsernames}
                                                            readOnly
                                                        />
                                                        <div onClick={() => { setHideUsernames(!hideUsernames); }}
                                                            className="w-[28px] h-[16px] bg-gray-200 rounded-full peer  peer-focus:black peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-[12px] after:w-[12px] after:transition-all peer-checked:bg-black"
                                                        ></div>
                                                    </label>
                                                </div>
                                                
                                            </label>
                                            <label className='gap-2 inline-flex items-center'>
                                                <div className='flex flex-row gap-2'>
                                                    <div className='w-4'>
                                                        <BiLockAlt className='w-4 h-4' />
                                                    </div>
                                                    <div className='flex flex-col'>
                                                        <p className='activityTypeCtitle'>{VISIBLE}</p>
                                                        <p className='activityTypeCinfo'>
                                                            {VISIBLE_DESC}
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="flex">
                                                    <label className="inline-flex relative items-center cursor-pointer">
                                                        <input
                                                            type="checkbox"
                                                            className="sr-only peer"
                                                            checked={hideActivities}
                                                            readOnly
                                                        />
                                                        <div onClick={() => { setHideActivities(!hideActivities); }}
                                                            className="w-[28px] h-[16px] bg-gray-200 rounded-full peer  peer-focus:black peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-[12px] after:w-[12px] after:transition-all peer-checked:bg-black"
                                                        ></div>
                                                    </label>
                                                </div>
                                            </label>
                                        </div>
                                        <div className="px-4 flex flex-col justify-center items-center">
                                            <button className='flex justify-center cursor-pointer w-[200px] py-1 mb-3 bg-[#CBD2E0] rounded-[20px]' onClick={createActivityHandler}>
                                                {SAVE}
                                            </button>
                                            <div onClick={() => setShowModal(false)} className='flex justify-center cursor-pointer items-center w-[200px] py-1 border rounded-[20px]'>{CANCEL}</div>
                                        </div>
                                    </form>
                                </div>
                            </div> 
                        </div>
                        <div className="opacity-25 fixed inset-0 z-[1001] bg-black"></div>
                        </>
                    ) : null}

                </div>
            </div>
        </div>
        {category !== "group" ? 
        (<div className='col-span-10 mt-[53px] absolute w-full md:relative md:col-span-7'>
        <div className='hidden md:flex div2Header p-5'>
            <div className='div2OverallHeader w-full'>
                <div className='relative flex flex-wrap items-center justify-between div2Headersection1'>
                    <div className={(mobileView === 4 ? "hidden " : " ") + 'Tab1 p-2 md:flex'}>
                        <ol className="inline-flex">
                            <li className="inline-flex md:hidden items-center">
                                <p onClick={() => setmobileView(4)} className="inline-flex items-center cursor-pointer text-sm font-medium text-gray-700 hover:text-gray-900">
                                    {ACTIVITIES}
                                </p>
                                <svg className="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipule="evenodd"></path></svg>
                            </li>
                            <li className="inline-flex items-center">
                                <p className='grouptitlep' translate="no">{activeActivity?.name}</p>
                            </li>
                        </ol>
                        
                    </div>
                    <div className={'relative flex flex-wrap items-center justify-between md:ml-[150px] p-2 Tab2'}>
                        <div className={(mobileView === 4 ? "hidden " : "flex ") + 'md:flex Tab2Content'}>
                            <p onClick={() => {handleTabChange("questions", "activity", "vp"); setmobileView(1);}} className={tab === "answer" || tab === "questions" ? "Tab2active" : ""}>{QUESTIONS}</p>
                            {isReviewMode &&
                                <p onClick={() => {handleTabChange("results", "activity", "vp"); setmobileView(3);}} className={tab === "results" ? "Tab2active" : ""}>{RESULTS}</p>
                            }
                            <p onClick={() => {handleTabChange("activityresources", "activity", "vp"); setmobileView(8);}} className={tab === "activityresources" ? "Tab2active" : ""}>{RESOURCES}</p>
                            {(group?.role === 2 || group?.role === 4) &&
                            <p onClick={() => { handleTabChange("activityreports", "activity", "vp"); }} className={tab === "activityreports" ? "Tab2active" : ""}>{REPORTS}</p>}
                            {(group?.role === 2 || group?.role === 4) &&
                            <p onClick={() => { handleTabChange("activitygeneral", "activity", "vp"); setmobileView(9); }} className={
                                (tab === "activitygeneral" || tab === "activityrubric" || tab === "activityprompter") ? "Tab2active" : ""}>{SETTINGS}</p>}
                            <p className=''>|</p>
                        </div>
                        <div className={'hidden socketactivity md:flex'}>
                            <p className='studentconnected'>{onlineUsers.filter(x => x.userId.group === owningGroup).length} {STUDENTS_CONNECTED}</p>
                            {isTyping ? <p className='studenttyping'>{typinUsers.filter(x => x.activeUser.activity === activityId).length} {STUDENT_TYPING}</p> : <></>}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {activeActivity?.activityType === "OrganizerPaced" && 
        <div className="flex items-center pt-4 pb-7 px-8 bg-[#EBEDF2]">
            <div className="flex items-center relative">
                <div className={`flex justify-center items-center rounded-full w-6 h-6 ${(activeActivity?.level === 'locked' || !activeActivity?.level) ? 'bg-[#68758F]' : 'bg-[#C4C9D4]'}`}>
                    <RxLockClosed className='text-white w-3 h-3' />
                </div>
                <p className={`${activeActivity?.level === 'locked' ? 'text-[#68758F]': 'text-[#A8B0BF]'} absolute top-0 -ml-1 mt-7 activeExamModeLabel`}>{LOCKED}</p>
            </div>
            <div className="flex-auto border-t-2 transition duration-500 ease-in-out border-gray-300"></div>
            <div className="flex items-center text-white relative cursor-pointer" onClick={() => handleActivityLevelChange('create')}>
                <div className={`flex justify-center items-center rounded-full w-[24px] h-[24px] ${activeActivity?.level === 'create' ? 'bg-[#68758F]' : 'bg-[#C4C9D4]'}`}>
                    <BsRocket className='text-white w-3 h-3' />
                </div>
                <p className={`${activeActivity?.level === 'create' ? 'text-[#68758F]': 'text-[#A8B0BF]'} absolute top-0 -ml-1 mt-7 activeExamModeLabel`}>{CREATE}</p>
            </div>
            <div className="flex-auto border-t-2 transition duration-500 ease-in-out border-gray-300"></div>
            <div className="flex items-center text-gray-500 relative cursor-pointer" onClick={() => handleActivityLevelChange('solve')}>
                <div className={`flex justify-center items-center rounded-full w-[24px] h-[24px] ${activeActivity?.level === 'solve' ? 'bg-[#68758F]' : 'bg-[#C4C9D4]'}`}>
                    <HiOutlinePuzzle className='text-white w-3 h-3' />
                </div>
                <p className={`${activeActivity?.level === 'solve' ? 'text-[#68758F]': 'text-[#A8B0BF]'} absolute top-0 -ml-1 mt-7 activeExamModeLabel`}>{SOLVE}</p>
            </div>
            <div className="flex-auto border-t-2 transition duration-500 ease-in-out border-gray-300"></div>
            <div className="flex items-center text-gray-500 relative cursor-pointer" onClick={() => handleActivityLevelChange('review')}>
                <div className={`flex justify-center items-center rounded-full w-[24px] h-[24px] ${activeActivity?.level === 'review' ? 'bg-[#68758F]' : 'bg-[#C4C9D4]'}`}>
                    <HiOutlineChartBar className='text-white w-3 h-3' />
                </div>
                <p className={`${activeActivity?.level === 'review' ? 'text-[#68758F]': 'text-[#A8B0BF]'} absolute top-0 -ml-1 mt-7 activeExamModeLabel`}>{REVIEW}</p>
            </div>
        </div>}
        <div className='grid grid-cols-3'>
        <div className={(tab === "answer" || tab === "questions") || tab === "results" ? (mobileView === 1 ? " " : "hidden ") + "div2 min-h-screen col-span-3 md:col-span-1 md:flex " : "hidden"}>
        {questionsy ? (
            questionsy.map((question, idx) =>
            <div key={idx}  onClick={() => {tab === "answer" ? handleTabChange("questions", "activity", "vp") : setOpenNext(true); setSubmitted(false); setActiveQuestion(question); handleQuestionChange(); setIdxs(idx); setmobileView(2);
                setRating(question?.reviews?.find(r => r.user.toString() === user._id.toString()) ? (question?.reviews?.find(r => r.user.toString() === user._id.toString())?.rating) : 0)
                setContent(''); setInputFields([{choice: ''}]); setQuestionImage(""); setCorrectAnswerError(false); setChoicesError(false);
                setAnswerStartTime(Date.now()); setqOwnerId(question?.creator?._id); setQid(question._id); setOpenTab(question?._id);}} className={`cursor-pointer ${idx===idxs ? 'div2ActiveBody w-full' : 'div2ActiveBody2 w-full'}`}>
                <p className={`${idx===idxs ? 'question' : 'otherQuestions'}`} translate="no">{(questionsy?.length - idx) + ". " + question?.content}</p>
                {!activeActivity?.hideUsernames &&
                <div className='imagerow'>
                    <img className="imagequestion inline-flex" src={question?.creator?.avatar?.url ? (question?.creator?.avatar?.public_id === null ? (baseUrl + question?.creator?.avatar?.url) : question?.creator?.avatar?.url) : Default} alt='' />
                    <div className="group cursor-pointer relative underline hover:no-underline inline-block">
                        <p className='questionOwner' translate="no">{question?.creator?.username}</p>
                        <div translate="no" className="opacity-0 bg-[#8C1515] text-white text-center text-xs rounded-lg py-2 absolute z-10 group-hover:opacity-100 left-full top-1/2 ml-3 -translate-y-1/2 px-3 pointer-events-none">
                            {question?.creator?.username} <br /> {question?.creator?.email} <br /> {question?.creator?.username}
                            <span
                                className="absolute left-[-3px] top-1/2 -z-10 h-2 w-2 -translate-y-1/2 rotate-45 rounded-sm bg-[#8C1515]"
                            ></span>
                        </div>
                    </div>
                </div>}
                <div className='flex items-center gap-2'>
                    {question?.ratings ? 
                    <div className="flex items-center">
                        <img className="w-3 h-3 rounded-full" src={StarIcon} alt='Average rating' />
                        <p className="ml-2 questionOwner">{Number.isInteger(question?.ratings) ? question?.ratings : question?.ratings.toFixed(1)}</p>
                        <p className="ml-2 questionReviews">{question?.numOfReviews ? ("(" + question?.numOfReviews + " ratings)") : null}</p>
                    </div> : null}
                    <div className='flex items-center gap-2'>
                        <BsChatDots className='w-3 h-3' />
                        <p className='questionReviews'>Comments {question?.commentsCount}</p>
                    </div>
                </div>
            </div>
                )
        ) : null} 
        {isCreateMode && 
        <div onClick={e => {e.preventDefault(); handleTabChange("answer", "activity", "vp"); setIdxs(50); setmobileView(5);}} className='div2DistinctBody cursor-pointer'>
            <BsPlusLg className='plus' />
            <p className='question'>{CREATE_QUESTION}</p>
        </div>}
            
        </div>
        <div className='col-span-3 md:col-span-2'>
        {isCreateMode && 
        <div className={tab === "answer" ? (mobileView === 5 ? " " : "hidden ") + "create w-full min-h-screen md:flex " : "hidden"}>
                <div className='w-full'>
                    <p className='mpHeading'>{CREATE_NEW_QUESTION}</p>
                    {(questionKeywords?.length > 0 && (process.env.REACT_APP_ENABLE_OPENAIPROMPTER === "true")) && 
                    <>
                        <p className='py-1 prompterWordsLabel'>{WORDS_SELECTED}</p>
                        <div className='flex flex-wrap prompterBubblesText gap-2 pt-1 pb-3'>
                            {questionKeywords?.map((keyword, index) => 
                                <p key={index} className='border px-2 border-[#CBD2E0] rounded-[28px]' translate="no">{keyword?.keyword}</p>
                            )}
                        </div>
                    </>
                    }
                    <div className='mpcreatediv w-full'>
                        <p className=''>{ENTER_QUESTION}</p>
                        <input translate="no" type='text' onKeyDown={handleKeyDown} value={content} onChange={(e) => {typingHandler(); setContent(e.target.value);}} placeholder={ENTER_QUESTION_HERE} className='mpcreateinput w-full' />
                    </div>
                    {submitted && <p className='text-sm text-red-600'>{questionError}</p>}

                    <div className='mpcreatediv'>
                        <p className=''>{UPLOAD_IMAGES}</p>
                        <input type="file" id="qrimage" name="qrimage" ref={inputRef} className='mpcreateinput cursor-pointer w-full' onChange={onChange3} accept='image/*' required />
                    </div>
                    {progress && questionImage && <ProgressBar completed={progress} bgColor={'#8C1515'} />}
                    {qimageError && <p className='text-sm text-red-600'>{qimageError}</p>}
                    <form className='mpanswerdiv w-full' onSubmit={createQuestionHandler}>
                        <div className='mpanswerheader'>
                            <p className='mpanswerlabel'>{ANSWER_TYPE}</p>
                            <div className='mpchoice'>
                                <div className='mpchoicecontent'>
                                    <button type="button" className={openQtab == 2 ? "mpchoicebtn" : "mpopenbtn"} onClick={() => {setOpenQtab(2)}}>{MULTIPLE_CHOICE}</button>
                                    <button type="button" className={openQtab == 1 ? "mpchoicebtn" : "mpopenbtn"} onClick={() => {setOpenQtab(1)}}>{OPEN_ENDED}</button>
                                </div>
                            </div>
                        </div>
                        <div className={openQtab === 2 ? "mpchoicesdiv w-full" : "hidden"}>
                            {inputFields.map((input, idX) => 
                            (
                                <div key={idX} className="w-full">
                                <div className='mpchoicediv w-full'>
                                    <p className='mpchoiceA'>{alphabetMapper(idX)}</p>
                                    <input name='choice' placeholder='Choice' value={input.choice} onChange={event => handleFormChange(idX, event)} className='mpchoiceinput w-full' />
                                    <input type='checkbox' value={alphabetMapper(idX)} />
                                    <CgTrashEmpty className='icon5' onClick={() => removeFields(idX)} />
                                </div>
                                </div>
                            )
                            )}
                            {choicesError && <p className='text-sm text-red-600'>{choicesError}</p>}
                            {correctAnswerError && <p className='text-sm text-red-600'>{correctAnswerError}</p>}

                            <div onClick={addFields} className='mpaddchoice cursor-pointer'>
                                <FaPlus className='icon5' />
                                <p>{ANSWER_OPTION}</p>
                            </div>
                            
                        </div>

                        <button className='mpsubmitbtn' type='submit'>{SUBMIT_QUESTION}</button>
                    </form>
                </div>
            </div>}

            {!!questions?.length ? 
            <div className={tab === "questions" ? (mobileView === 2 ? " " : "hidden ") + "answerBox w-full md:flex " : "hidden"}>
                {(activeQuestion?.questionType === 'multiple_choice') ? 
                <>
                    {!activeActivity?.hideUsernames &&
                    <div className='answerheader w-full'>
                        <div className='answerprofile'>
                        <img className="image3" src={activeQuestion?.creator?.avatar?.public_id === null ? (baseUrl + activeQuestion?.creator?.avatar?.url) : activeQuestion?.creator?.avatar?.url} alt='' />
                        <div className="group relative inline-block">
                            <p className='owner3 cursor-pointer underline hover:no-underline' translate="no">{activeQuestion?.creator?.username ? activeQuestion?.creator?.username : activeQuestion?.creator?.firstName + " " + activeQuestion?.creator?.lastName}</p>
                            <div translate="no" className="opacity-0 bg-[#8C1515] text-white text-center text-xs rounded-lg py-2 absolute z-10 group-hover:opacity-100 left-full top-1/2 ml-3 -translate-y-1/2 px-3 pointer-events-none">
                                {activeQuestion?.creator?.firstName + " " + activeQuestion?.creator?.lastName} <br /> {activeQuestion?.creator?.email} <br /> {activeQuestion?.creator?.username}
                                <span
                                    className="absolute left-[-3px] top-1/2 -z-10 h-2 w-2 -translate-y-1/2 rotate-45 rounded-sm bg-[#8C1515]"
                                ></span>
                            </div>
                        </div>
                        </div>
                    </div>}
                    <p className='answerQuestion' translate="no">{activeQuestion?.content}</p>
                    <div className={(creator === qOwnerId ? "hidden " : "flex ") + " mb-3 md:mb-0"}>
                        <RateComponent rating={rating} setRating={setRating} />
                    </div>
                    <img onClick={() => setSelectedImage(activeQuestion?.questionImage?.public_id === null ? (baseUrl + activeQuestion?.questionImage?.url) : activeQuestion?.questionImage?.url)} src={activeQuestion?.questionImage?.public_id === null ? (baseUrl + activeQuestion?.questionImage?.url) : activeQuestion?.questionImage?.url} className='answerImage cursor-pointer' alt='' />
                    <div className='choicesdivholder'>
                    {activeQuestion?.choice?.map((choice, index) =>
                        <div key={index}>
                            <div className='qchoicesdiv'>
                                <div>{alphabetMapper(index)} </div>
                                <div translate="no">. {choice.choice}</div>
                            </div>
                        </div>
                    )}
                    </div>
                    <div className='youranswer'>
                        <p className='yanswer' translate="no">{YOUR_ANSWER}</p>
                        <div className="mb-3 xl:w-96">
                            <select translate="no" value={selectedChoice} onChange={(e) => {setSelectedChoice(e.target.value); setChoiceAlphabet(e.target[e.target.selectedIndex].id);}} 
                            className="form-select appearance-non block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none" aria-label="Select option">
                                <option>{SELECT_A_CHOICE}</option>
                                {activeQuestion?.choice?.map((input, index) => {
                                    return (
                                        <option translate="no" value={input.choice} key={index} id={index}>{alphabetMapper(index)}</option>
                                    )
                                })}
                            </select>
                        </div>
                    </div>
                    {isSolveMode &&
                    <button className='answersubmit' onClick={createAnswerHandler2}><p className='submittext'>{SUBMIT_ANSWER}</p></button>}
                    
                </>
                
                
                    : 
                
                < >
                {!activeActivity?.hideUsernames &&
                <div className='answerheader mb-3 md:mb-0'>
                    <div className='answerprofile'>
                        <img className="image3" src={activeQuestion?.creator?.avatar?.url ? (activeQuestion?.creator?.avatar?.public_id === null ? (baseUrl + activeQuestion?.creator?.avatar?.url) : activeQuestion?.creator?.avatar?.url) : Default} alt='' />
                        <div className="group relative inline-block">
                            <p className='owner3 cursor-pointer underline hover:no-underline' translate="no">{activeQuestion?.creator?.username ? activeQuestion?.creator?.username : activeQuestion?.creator?.firstName + " " + activeQuestion?.creator?.lastName}</p>
                            <div translate="no" className="opacity-0 bg-[#8C1515] text-white text-center text-xs rounded-lg py-2 absolute z-10 group-hover:opacity-100 left-full top-1/2 ml-3 -translate-y-1/2 px-3 pointer-events-none">
                                {activeQuestion?.creator?.firstName + " " + activeQuestion?.creator?.lastName} <br /> {activeQuestion?.creator?.email} <br /> {activeQuestion?.creator?.username}
                                <span
                                    className="absolute left-[-3px] top-1/2 -z-10 h-2 w-2 -translate-y-1/2 rotate-45 rounded-sm bg-[#8C1515]"
                                ></span>
                            </div>
                        </div>
                    </div>
                </div>}
                <p className='answerQuestion mb-3 md:mb-0' translate="no">{activeQuestion?.content}</p>
                <div className={(creator === qOwnerId ? "hidden " : "flex ") + " mb-3 md:mb-0"}>
                    <RateComponent rating={rating} setRating={setRating} />
                </div>
                <img onClick={() => setSelectedImage(activeQuestion?.questionImage?.public_id === null ? (baseUrl + activeQuestion?.questionImage?.url) : activeQuestion?.questionImage?.url)} src={activeQuestion?.questionImage?.public_id === null ? (baseUrl + activeQuestion?.questionImage?.url) : activeQuestion?.questionImage?.url} className='answerImage cursor-pointer mb-3 md:mb-0' alt='' />
                <div className='youranswer mb-3 md:mb-0 w-full'>
                    <p className='yanswer'>{YOUR_ANSWER}</p>
                    <textarea id='answer' name='answer' onKeyDown={handleAnswerKeyDown} value={answer} onChange={(e) => {setAnswer(e.target.value);}} className='answerbody'></textarea>
                </div>
                {isSolveMode &&
                <button className='answersubmit' onClick={createAnswerHandler}><p className='submittext'>{SUBMIT_ANSWER}</p></button>}
                </>
                
                }
            </div> : <></>}
            {isReviewMode && <div className={tab === "results" ? (mobileView === 3 ? "flex " : "hidden ") + "answerList md:flex " : "hidden"}>
            <p className='answerListQuestion' translate="no">{activeQuestion?.content}</p>
            {responses ? (
                responses.map((response, idx) =>
                <div key={idx} className='answerListBody'>
                    <div className='answerListItem'>
                        <div className='answerownerdiv'>
                            <img className="answerownerimage" src={response?.creator?.avatar?.url ? (response?.creator?.avatar?.public_id === null ? (baseUrl + response?.creator?.avatar?.url) : response?.creator?.avatar?.url) : Default} alt='' />
                            <p className='answerowner' translate="no">{response?.creator?.firstName + " " + response?.creator?.lastName}</p>
                        </div>
                        <p className='answeritembody' translate="no">{response?.choice}{response?.choice ? ": " : null} {response?.content}</p>
                    </div>
                </div>
                    )
            ) : null}
            </div>}
        </div>
        <div className='col-span-3'>
            <div className={mobileView === 9 ? " " : "hidden " + "md:block"}>
                {(tab === "activitygeneral" || tab === "activityrubric" || tab === "activityprompter")
                 ? <Settings id={activityId} setActiveLevel={setActiveLevel} handleTabChange={handleTabChange} tab={tab} /> : null}
            </div>

            <div className={tab === "activityreports" ? " " : "hidden " + "md:block"}>
                {tab === "activityreports" && <ActivityReport activityId={activityId} activityName={activeActivity?.name} />}
            </div>
            
            <div className={mobileView === 8 ? " " : "hidden " + "md:block"}>
                {tab === "activityresources" ? <AResources id={activityId} /> : null}
            </div>
        </div>
        </div>
        </div>) : 
        <>
        <div className={(mobileView === 6 ? " " : "hidden ") + ' col-span-10 md:col-span-7 md:block md mt-[53px]'}>
        <div className='gsnav2'>
                <div className='gsnavcontent2'>
                    <div className='gsnavcontentleft2'>
                        <FiSettings className='settingsIcon' />
                        <p className='hidden md:flex gsnavlabel'>{GROUP_SETTINGS}</p>
                        <div className='gsnavlist2 relative flex flex-wrap items-center justify-between'>
                            {(group?.role === 2 || group?.role === 4) && 
                            <div onClick={() => { handleTabChange("general", "group", "vp"); setOpenSettings(1); }} className={`cursor-pointer ${tab === "general" ? "gsnavlistactive" : "gsnavlistinactive"}`}>{GENERAL}</div>}
                            <div onClick={() => { handleTabChange("members", "group", "vp"); setOpenSettings(3) }} className={`cursor-pointer ${tab === "members" ? "gsnavlistactive" : "gsnavlistinactive"}`}>{MEMBERS}</div>
                            <div onClick={() => {handleTabChange("groupresources", "group", "vp"); setOpenSettings(2) }} className={`cursor-pointer ${tab === "groupresources" ? "gsnavlistactive" : "gsnavlistinactive"}`}>{RESOURCES}</div>
                            {(group?.role === 2 || group?.role === 4) && 
                            <div onClick={() => { handleTabChange("groupreports", "group", "vp"); setOpenSettings(4); }} className={`cursor-pointer ${tab === "groupreports" ? "gsnavlistactive" : "gsnavlistinactive"}`}>{REPORTS}</div>}
                            <div onClick={() => {setActivityId(activities?.[0]?._id); setOpenCategory(1); setmobileView(4);}} className={"gsnavlistinactive md:hidden cursor-pointer"}>{ACTIVITIES}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='gssettings w-full p-3'>
                <div className={tab === "groupresources" ? "w-full " : "hidden"}>
                <div className='research w-full'>
                    <input type='text' placeholder='Search files...' onChange={(e) => setSearchResourceQuery(e.target.value)} className='researchinput' />
                    <div onClick={() => {setShowUpload(true); setGroupUploadErrorMsg(false);}} className={`cursor-pointer ${group?.role === 2 || group?.role === 4 ? "researchright" : "hidden"}`}>
                        <BiCloudUpload className='fileicon' />
                        <p className=''>{UPLOAD_FILE}</p>
                    </div>
                </div>
                {resources ? (
                resources?.filter((resource) => resource?.originalname.toLowerCase().includes(searchResourceQuery))?.map((resource, idx) =>
                    <div className='rediv w-full' key={idx}>
                        <div className='redivrow w-full'>
                        <Resources resource={resource} />
                        </div>
                    </div>
                        )
                ) : null}
                {showUpload ? (
                    <>
                    <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                        <div className='joinpmodal w-[300px] md:w-[430px]'>
                            <div className='joinpmodalcontent p-4 w-full'>
                                <div className='joinpmodalheader w-full'>
                                    <p className='joinpmodalheaderight'>{UPLOAD_RESOURCE}</p>
                                    <AiOutlineCloseCircle className='joinpmodalheaderleft cursor-pointer' onClick={() => setShowUpload(false)} />
                                </div>
                                <form encType="multipart/form-data" className='joinpmodalbody w-full' onSubmit={upload}>
                                    <div className='joinpmodalbodydiv w-full'>
                                        <input type="file" id="doc" name="doc" onChange={handleUploadedFile} className='joinpmodalbodyinput' required />
                                        <p className='text-sm text-red-600'>{groupUploadErrorMsg}</p>
                                    </div>
                                    <button className='joinpmodalsubmit' type='submit'>{UPLOAD}</button>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                    </>
                ) : null}
                </div>
                <div className={tab === "members" ? "w-full " : "hidden"}>
                    <Members members={members} groupId={groupId} />
                </div>
                
                <div className={tab === "general" ? "w-full py-3 px-4 " : "hidden"}>
                    <div className={group?.role === 2 || group?.role === 4 ? "w-full " : "hidden"}>
                    
                    <p className="error-message text-red-600">{errorMsg}</p>
                    <div className='gsgroupname'>
                        <p className='gsgroupnamelabel'>{GROUP_NAME}</p>
                        <input type="text" value={grName} onChange={(e) => setGrName(e.target.value)} className='gsinputgroup' />
                    </div>
                    <div className='gsgroupdescdiv'>
                        <p className='gsgroupdesclabel'>{GROUP_DESCRIPTION}</p>
                        <input type="text" value={grDescription} onChange={(e) => setGrDescription(e.target.value)} className='gsinputgroupdesc w-full' />
                    </div>
                    <div className='flex flex-row justify-center items-center w-full py-2'>
                        <div className="flex justify-center items-center h-[130px] w-[130px] rounded-lg border-2 bg-gray-100 border-gray-100" style={{ 
                                backgroundImage: `url(${avatarPreview})`,
                                backgroundPosition: 'center',
                                backgroundSize: 'cover',
                                backgroundRepeat: 'no-repeat'
                            }}>
                            <div className="absolute">
                                <div className="flex flex-col items-center">
                                    <MdAddAPhoto className='w-9 h-9' />
                                    <span className="block text-gray-400 font-normal">{ADD_PHOTO}</span>
                                </div>
                            </div>
                            <input type="file" name="grimage" onChange={onChange} className="h-full w-full opacity-0" />
                        </div>
                    </div>
                    <div className="flex gap-4 w-full flex-col">
                        <label className="createFormLabels block" htmlFor="group_type">
                            {GROUP_TYPE}
                        </label>
                        <select className='border createFormInput h-[50px] px-4' value={isPrivate} onChange={(e) => setIsPrivate(e.target.value === "true")}>
                            <option>{SELECT}</option>
                            <option value={false}>{PUBLIC}</option>
                            <option value={true}>{PRIVATE}</option>
                        </select>
                    </div>
                    {isPrivate &&
                    <div className='gsgrouppasscodediv'>
                        <p className='gspasscodelabel'>{PASSCODE_CHARACTERS}</p>
                        <div className="relative w-full">
                        <input type={showPassword ? "text" : "password"} className='gsinputgrouppasscode w-full' autoComplete="new-password" value={grpasscode} onChange={(e) => setGrpasscode(e.target.value)} />
                        <div onClick={() => setShowPassword(!showPassword)} className="absolute top-1/2 right-3 -mt-2 text-gray-600">
                            {showPassword ?
                            <BiShow /> : <BiHide />}
                        </div>
                        </div>
                    </div>}
                    <div className='gssubmitbtndiv mt-5'>
                        <button className='gssavebtn' onClick={updateSettings}>
                            {sloading ? 
                                <>
                                <svg role="status" className="inline mr-1 w-4 h-4 text-white animate-spin" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#E5E7EB"/>
                                <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentColor"/>
                                </svg> {SAVE_CHANGES}
                                </> : [SAVE_CHANGES]
                            }
                        </button>
                        <button className='gsdeletebtn' onClick={() => setDeleteModal(true)}>{DELETE_GROUP}</button>
                    </div>
                    {deleteModal ? 
                    <>
                    <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                        <div className="relative p-4 w-full max-w-md h-full md:h-auto">
                            <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                                <button type="button" onClick={() => setDeleteModal(false)} className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white" data-modal-toggle="popup-modal">
                                    <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipule="evenodd"></path></svg>
                                    <span className="sr-only">{CLOSE_MODAL}</span>
                                </button>
                                <div className="p-6 text-center">
                                    <svg aria-hidden="true" className="mx-auto mb-4 w-14 h-14 text-gray-400 dark:text-gray-200" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
                                    <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">{DELETE_GROUP_WARNING}</h3>
                                    <button onClick={deleteGroupHandler} data-modal-toggle="popup-modal" type="button" className="text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center mr-2">
                                        {YES_SURE}
                                    </button>
                                    <button data-modal-toggle="popup-modal" onClick={() => setDeleteModal(false)} type="button" className="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600">{NO_CANCEL}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                    </> : null
                    }
                    </div>
                </div>

                <div className={tab === "groupreports" ? "w-full " : "hidden"}>
                    <GroupReport />
                </div>
            </div>
        </div> 
        </>
        }
    </div>
    </>
  )
}

export default QuestionDesktop