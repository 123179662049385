export const NEW_GROUP_REQUEST = 'NEW_GROUP_REQUEST';
export const NEW_GROUP_SUCCESS = 'NEW_GROUP_SUCCESS';
export const NEW_GROUP_RESET = 'NEW_GROUP_RESET';
export const NEW_GROUP_FAIL = 'NEW_GROUP_FAIL';

export const ALL_GROUPS_REQUEST = 'ALL_GROUPS_REQUEST';
export const ALL_GROUPS_SUCCESS = 'ALL_GROUPS_SUCCESS';
export const ALL_GROUPS_FAIL = 'ALL_GROUPS_FAIL';

export const GROUP_USERS_REQUEST = 'GROUP_USERS_REQUEST';
export const GROUP_USERS_SUCCESS = 'GROUP_USERS_SUCCESS';
export const GROUP_USERS_FAIL = 'GROUP_USERS_FAIL';

export const SINGLE_GROUP_REQUEST = 'SINGLE_GROUP_REQUEST';
export const SINGLE_GROUP_SUCCESS = 'SINGLE_GROUP_SUCCESS';
export const SINGLE_GROUP_RESET = 'SINGLE_GROUP_RESET';
export const SINGLE_GROUP_FAIL = 'SINGLE_GROUP_FAIL';

export const MY_GROUPS_REQUEST = 'MY_GROUPS_REQUEST';
export const MY_GROUPS_SUCCESS = 'MY_GROUPS_SUCCESS';
export const MY_GROUPS_FAIL = 'MY_GROUPS_FAIL';

export const DISCOVER_GROUPS_REQUEST = 'DISCOVER_GROUPS_REQUEST';
export const DISCOVER_GROUPS_SUCCESS = 'DISCOVER_GROUPS_SUCCESS';
export const DISCOVER_GROUPS_FAIL = 'DISCOVER_GROUPS_FAIL';

export const JOIN_GROUP_REQUEST = 'JOIN_GROUP_REQUEST';
export const JOIN_GROUP_SUCCESS = 'JOIN_GROUP_SUCCESS';
export const JOIN_GROUP_RESET = 'JOIN_GROUP_RESET';
export const JOIN_GROUP_FAIL = 'JOIN_GROUP_FAIL';

export const REMOVE_UGROUP_REQUEST = 'REMOVE_UGROUP_REQUEST';
export const REMOVE_UGROUP_SUCCESS = 'REMOVE_UGROUP_SUCCESS';
export const REMOVE_UGROUP_RESET = 'REMOVE_UGROUP_RESET';
export const REMOVE_UGROUP_FAIL = 'REMOVE_UGROUP_FAIL';

export const UPDATE_SETTINGS_REQUEST = 'UPDATE_SETTINGS_REQUEST';
export const UPDATE_SETTINGS_SUCCESS = 'UPDATE_SETTINGS_SUCCESS';
export const UPDATE_SETTINGS_RESET = 'UPDATE_SETTINGS_RESET';
export const UPDATE_SETTINGS_FAIL = 'UPDATE_SETTINGS_FAIL';

export const UPLOAD_RESOURCE_REQUEST = 'UPLOAD_RESOURCE_REQUEST';
export const UPLOAD_RESOURCE_SUCCESS = 'UPLOAD_RESOURCE_SUCCESS';
export const UPLOAD_RESOURCE_RESET = 'UPLOAD_RESOURCE_RESET';
export const UPLOAD_RESOURCE_FAIL = 'UPLOAD_RESOURCE_FAIL';

export const GET_RESOURCES_REQUEST = 'GET_RESOURCES_REQUEST';
export const GET_RESOURCES_SUCCESS = 'GET_RESOURCES_SUCCESS';
export const GET_RESOURCES_FAIL = 'GET_RESOURCES_FAIL';

export const GET_MEMBERS_REQUEST = 'GET_MEMBERS_REQUEST';
export const GET_MEMBERS_SUCCESS = 'GET_MEMBERS_SUCCESS';
export const GET_MEMBERS_FAIL = 'GET_MEMBERS_FAIL';

export const CHANGE_ROLE_REQUEST = 'CHANGE_ROLE_REQUEST';
export const CHANGE_ROLE_SUCCESS = 'CHANGE_ROLE_SUCCESS';
export const CHANGE_ROLE_RESET = 'CHANGE_ROLE_RESET';
export const CHANGE_ROLE_FAIL = 'CHANGE_ROLE_FAIL';

export const SEARCH_GROUP_REQUEST = 'SEARCH_GROUP_REQUEST';
export const SEARCH_GROUP_SUCCESS = 'SEARCH_GROUP_SUCCESS';
export const SEARCH_GROUP_RESET = 'SEARCH_GROUP_RESET';
export const SEARCH_GROUP_FAIL = 'SEARCH_GROUP_FAIL';

export const SEARCH_DISCOVER_REQUEST = 'SEARCH_DISCOVER_REQUEST';
export const SEARCH_DISCOVER_SUCCESS = 'SEARCH_DISCOVER_SUCCESS';
export const SEARCH_DISCOVER_FAIL = 'SEARCH_DISCOVER_FAIL';

export const DELETE_GROUP_REQUEST = 'DELETE_GROUP_REQUEST';
export const DELETE_GROUP_SUCCESS = 'DELETE_GROUP_SUCCESS';
export const DELETE_GROUP_RESET = 'DELETE_GROUP_RESET';
export const DELETE_GROUP_FAIL = 'DELETE_GROUP_FAIL';

export const GROUP_QUESTIONS_REQUEST = 'GROUP_QUESTIONS_REQUEST';
export const GROUP_QUESTIONS_SUCCESS = 'GROUP_QUESTIONS_SUCCESS';
export const GROUP_QUESTIONS_FAIL = 'GROUP_QUESTIONS_FAIL';

export const CLEAR_ERRORS = 'CLEAR_ERRORS';