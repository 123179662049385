import React, { useEffect, useRef, useState } from 'react'
import { BsFillStarFill } from 'react-icons/bs'
import { useDispatch, useSelector } from 'react-redux';
import { deleteActivity, getActivityRubric, updateActivity, updateActivityRubric } from '../../../actions/activityActions';
import { UPDATE_ACTIVITY_RESET, UPDATE_RUBRIC_RESET } from '../../../constants/activityConstants';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DesktopPrompter from './DesktopPrompter';
import { BiHide, BiLockAlt } from 'react-icons/bi';

const Settings = ({ id, setActiveLevel, handleTabChange, tab }) => {

    const [openActivitySetting, setOpenActivitySetting] = useState(1);
    const [oneStar, setOneStar] = useState('');
    const [twoStar, setTwoStar] = useState('');
    const [threeStar, setThreeStar] = useState('');
    const [fourStar, setFourStar] = useState('');
    const [fiveStar, setFiveStar] = useState('');
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [activityType, setActivityType] = useState('StudentPaced');
    const [hideUsernames, setHideUsernames] = useState(false);
    const [hideActivities, setHideActivities] = useState(true);

    const { editedActivity, updatedRubric } = useSelector(state => state.activity);
    const { rubric } = useSelector(state => state.activityRubric);
    const { group } = useSelector(state => state.groupDetails);

    const dispatch = useDispatch();

    const showToastMessage = (message) => {
        toast.success(message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000
        });
    };

    useEffect (() => {

        if (id && rubric?._id !== id) {
            setOneStar('');
            setTwoStar('');
            setThreeStar('');
            setFourStar('');
            setFiveStar('');
            dispatch(getActivityRubric(id));
        } else {
            setName(rubric?.name);
            setDescription(rubric?.description);
            setActivityType(rubric?.activityType);
            setHideActivities(rubric?.hideActivities);
            setHideUsernames(rubric?.hideUsernames);
            setOneStar(rubric?.gradeLevels?.oneStar);
            setTwoStar(rubric?.gradeLevels?.twoStar);
            setThreeStar(rubric?.gradeLevels?.threeStar);
            setFourStar(rubric?.gradeLevels?.fourStar);
            setFiveStar(rubric?.gradeLevels?.fiveStar);
        }

        if (editedActivity) {
            dispatch(getActivityRubric(id));
            dispatch({ type: UPDATE_RUBRIC_RESET });
        }

        if (updatedRubric) {
            showToastMessage("Rubric updated successfully!");
            dispatch(getActivityRubric(id));
            dispatch({ type: UPDATE_RUBRIC_RESET });
        }

    }, [dispatch, rubric?._id, id, updatedRubric, rubric?.gradeLevels, editedActivity]);

    const updateRubricHandler = () => {
        const gradeLevels = {
            oneStar, twoStar, threeStar, fourStar, fiveStar
        }
        dispatch(updateActivityRubric(id, gradeLevels));
        setOneStar('');
        setTwoStar('');
        setThreeStar('');
        setFourStar('');
        setFiveStar('');
    }

    const updateActivityHandler = () => {
        var mode = 0;
        setActiveLevel("updating");
        if (activityType === "OrganizerPaced") {
            mode = 1
        }
        let gradeLevels;
        dispatch(updateActivity(id, name, description, activityType, gradeLevels, hideUsernames, hideActivities));
    }

    const deleteActivityHandler = () => {
        dispatch(deleteActivity(id));
    }
    
  return (
    <div className='grid grid-cols-4'>
        <div className='settingsNav col-span-1 flex'>
            <div className='settingsNavContent'>
                <p onClick={() => handleTabChange("activitygeneral", "activity", "vp")} className={`cursor-pointer ${tab === "activitygeneral" && "Tab2active"}`}>General</p>
                <p onClick={() => handleTabChange("activityrubric", "activity", "vp")} className={`cursor-pointer ${tab === "activityrubric" && "Tab2active"}`}>Rubric</p>
                <p onClick={() => handleTabChange("activityprompter", "activity", "vp")} className={`cursor-pointer ${tab === "activityprompter" && "Tab2active"}`}>Prompter</p>
            </div>
        </div>

        {tab === "activitygeneral" &&
        <div className='col-span-3 min-h-screen'>
            <div className=' settingsbody w-full p-7'>
                <div className='settingsactivityname w-full'>
                    <p className='sactivityname'>Activity Name</p>
                    <input type="text" value={name} onChange={(e) => {setName(e.target.value)}} disabled={group?.role === 2 || group?.role === 4 ? false : true} placeholder='Artificial Intelligence' className='w-full flex sinputactivity' />
                </div>
                <div className='sactivitydesc w-full'>
                    <p className='sactivitydesclabel'>Activity Description</p>
                    <textarea className='sactivitydescinput w-full flex' value={description} onChange={(e) => {setDescription(e.target.value)}} disabled={group?.role === 2 || group?.role === 4 ? false : true}
                    placeholder='The artificial intelligence activity was created to help students develop a foundational understanding of modern technologies. Topics include data science, machine learning, neural networks, the human mind, and programming.'
                    ></textarea>
                </div>
                <div className='sactivitytype'>
                    <p className='sactivitytypelabel'>Activity Type</p>
                    <div className='settingsradio'>
                        <div className='sradioone'>
                            <input type="radio" name="activityType" checked={activityType === 'StudentPaced'} onChange={(e) => setActivityType('StudentPaced')} disabled={group?.role === 2 || group?.role === 4 ? false : true} className='sradiobtn ' /> 
                            <div className='sradiobtndiv w-full'>
                                <p className='sradioheader'>Student Organized Activity</p>
                                <p className='sradiobody'>Student can create, answer and review questions at their individual pace</p>
                            </div>
                        </div>
                        <div className='radioone'>
                            <input type="radio" name="activityType" checked={activityType === 'OrganizerPaced'} onChange={(e) => setActivityType('OrganizerPaced')} disabled={group?.role === 2 || group?.role === 4 ? false : true} className='sradiobtn' /> 
                            <div className='sradiobtndiv w-full'>
                                <p className='sradioheader'>Organizer paced exam</p>
                                <p className='sradiobody'>The organizer controls the pace of the activity. All students are either in create-question mode, answer-question mode, or review mode.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="">
                    <p className='sactivitytypelabel pb-1'>Setting</p>
                    <label className='gap-2 inline-flex items-center mb-2'>
                        <div className='flex flex-row gap-2'>
                            <div className='w-4'>
                                <BiHide className='w-4 h-4' />
                            </div>
                            <div className='flex flex-col'>
                                <p className='activityTypeCtitle'>Hide Username</p>
                                <p className='activityTypeCinfo'>
                                    Participants can see who created each question. To make questions anonymous, check this box.
                                </p>
                            </div>
                        </div>
                        <div className="flex">
                            <label className="inline-flex relative items-center cursor-pointer">
                                <input
                                    type="checkbox"
                                    className="sr-only peer"
                                    checked={hideUsernames}
                                    readOnly
                                />
                                <div onClick={() => setHideUsernames(!hideUsernames)}
                                    className="w-[28px] h-[16px] bg-gray-200 rounded-full peer  peer-focus:black peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-[12px] after:w-[12px] after:transition-all peer-checked:bg-black"
                                ></div>
                            </label>
                        </div>
                        
                    </label>
                    <label className='gap-2 inline-flex items-center'>
                        <div className='flex justify-between gap-2'>
                            <div className='w-4'>
                                <BiLockAlt className='w-4 h-4' />
                            </div>
                            <div className='flex flex-col'>
                                <p className='activityTypeCtitle'>Visible</p>
                                <p className='activityTypeCinfo'>
                                    Students can see all activities by default. To hide this activity from students, un-check this box.
                                </p>
                            </div>
                        </div>
                        <div className="flex">
                            <label className="inline-flex relative items-center cursor-pointer">
                                <input
                                    type="checkbox"
                                    className="sr-only peer"
                                    checked={hideActivities}
                                    readOnly
                                />
                                <div onClick={() => setHideActivities(!hideActivities)}
                                    className="w-[28px] h-[16px] bg-gray-200 rounded-full peer  peer-focus:black peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-[12px] after:w-[12px] after:transition-all peer-checked:bg-black"
                                ></div>
                            </label>
                        </div>
                    </label>
                </div>
                <div className='flex flex-row gap-2'>
                    <button className={group?.role === 2 || group?.role === 4 ? "mpsubmitbtn" : "hidden"} onClick={updateActivityHandler}>Update Activity</button>
                    <button className={group?.role === 2 || group?.role === 4 ? "mpsubmitbtn" : "hidden"} onClick={deleteActivityHandler}>Delete Activity</button>
                </div>
            </div>
        </div>}

        {tab === "activityrubric" &&
        <div className='col-span-3 min-h-screen'>
            <div className='rubricDiv p-7 w-full'>
            <div className='rubricDivRow w-full'>
                <div className='rubricDivRowHeader w-full'>
                    <p>1 Star</p>
                    <svg aria-hidden="true" className="w-5 h-5 text-yellow-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><title>First star</title><path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path></svg>
                </div>
                <input type='text' value={oneStar} onChange={(e) => {setOneStar(e.target.value);}} disabled={group?.role === 2 || group?.role === 4 ? false : true} className='rubricInput flex w-full]' placeholder='Needs severe improvement' />
            </div>

            <div className='rubricDivRow'>
                <div className='rubricDivRowHeader'>
                    <p>2 Stars</p>
                    <div className="flex items-center">
                        <svg aria-hidden="true" className="w-5 h-5 text-yellow-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><title>First star</title><path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path></svg>
                        <svg aria-hidden="true" className="w-5 h-5 text-yellow-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><title>Second star</title><path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path></svg>
                    </div>
                </div>
                <input type='text' value={twoStar} onChange={(e) => {setTwoStar(e.target.value);}} disabled={group?.role === 2 || group?.role === 4 ? false : true} className='rubricInput flex w-full]' placeholder='Lacking in skill; Can improve in several places' />
            </div>

            <div className='rubricDivRow'>
                <div className='rubricDivRowHeader'>
                    <p>3 Stars</p>
                    <div className="flex items-center">
                        <svg aria-hidden="true" className="w-5 h-5 text-yellow-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><title>First star</title><path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path></svg>
                        <svg aria-hidden="true" className="w-5 h-5 text-yellow-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><title>Second star</title><path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path></svg>
                        <svg aria-hidden="true" className="w-5 h-5 text-yellow-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><title>Third star</title><path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path></svg>
                    </div>
                </div>
                <input type='text' value={threeStar} onChange={(e) => {setThreeStar(e.target.value);}} disabled={group?.role === 2 || group?.role === 4 ? false : true} className='rubricInput flex w-full]' placeholder='Doing okay; Can do better but is working hard' />
            </div>

            <div className='rubricDivRow'>
                <div className='rubricDivRowHeader'>
                    <p>4 Stars</p>
                    <div className="flex items-center">
                        <svg aria-hidden="true" className="w-5 h-5 text-yellow-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><title>First star</title><path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path></svg>
                        <svg aria-hidden="true" className="w-5 h-5 text-yellow-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><title>Second star</title><path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path></svg>
                        <svg aria-hidden="true" className="w-5 h-5 text-yellow-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><title>Third star</title><path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path></svg>
                        <svg aria-hidden="true" className="w-5 h-5 text-yellow-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><title>Fourth star</title><path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path></svg>
                    </div>
                </div>
                <input type='text' value={fourStar} onChange={(e) => {setFourStar(e.target.value);}} disabled={group?.role === 2 || group?.role === 4 ? false : true} className='rubricInput flex w-full]' placeholder='Great job; room for small improvements' />
            </div>

            <div className='rubricDivRow'>
                <div className='rubricDivRowHeader'>
                    <p>5 Stars</p>
                    <div className="flex items-center">
                        <svg aria-hidden="true" className="w-5 h-5 text-yellow-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><title>First star</title><path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path></svg>
                        <svg aria-hidden="true" className="w-5 h-5 text-yellow-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><title>Second star</title><path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path></svg>
                        <svg aria-hidden="true" className="w-5 h-5 text-yellow-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><title>Third star</title><path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path></svg>
                        <svg aria-hidden="true" className="w-5 h-5 text-yellow-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><title>Fourth star</title><path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path></svg>
                        <svg aria-hidden="true" className="w-5 h-5 text-yellow-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><title>Fifth star</title><path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path></svg>
                    </div>
                </div>
                <input type='text' value={fiveStar} onChange={(e) => {setFiveStar(e.target.value);}} disabled={group?.role === 2 || group?.role === 4 ? false : true} className='rubricInput flex w-full]' placeholder='Amazing job; Keep up the great work!' />
            </div>

            <button className={group?.role === 2 || group?.role === 4 ? "mpsubmitbtn" : "hidden"} onClick={updateRubricHandler}>Submit Rubric</button>
            <ToastContainer />

            </div>

        </div>}

        {tab === "activityprompter" &&
        <div className='col-span-3 min-h-screen'>
            <DesktopPrompter activityId={id} />
        </div>}
    </div>
  )
}

export default Settings