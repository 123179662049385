import axios from "axios";
import { ACTIVITIES_COUNT_FAIL, ACTIVITIES_COUNT_REQUEST, ACTIVITIES_COUNT_SUCCESS, ALL_ADMINS_FAIL, ALL_ADMINS_REQUEST, ALL_ADMINS_SUCCESS, ADMIN_GROUPS_FAIL, ADMIN_GROUPS_REQUEST, ADMIN_GROUPS_SUCCESS, ALL_HISTORIES_FAIL, ALL_HISTORIES_REQUEST, ALL_HISTORIES_SUCCESS, ALL_MONTHS_FAIL, ALL_MONTHS_REQUEST, ALL_MONTHS_SUCCESS, ALL_USERS_FAIL, ALL_USERS_REQUEST, ALL_USERS_SUCCESS, CHANGE_ROLE_FAIL, CHANGE_ROLE_REQUEST, CHANGE_ROLE_SUCCESS, CREATE_ADMIN_FAIL, CREATE_ADMIN_REQUEST, CREATE_ADMIN_SUCCESS, CREATE_ROLE_FAIL, CREATE_ROLE_REQUEST, CREATE_ROLE_SUCCESS, GET_ACTIVITIES_FAIL, GET_ACTIVITIES_REQUEST, GET_ACTIVITIES_SUCCESS, GET_MEMBERS_FAIL, GET_MEMBERS_REQUEST, GET_MEMBERS_SUCCESS, GET_PERMISSIONS_FAIL, GET_PERMISSIONS_REQUEST, GET_PERMISSIONS_SUCCESS, GET_PROFILE_FAIL, GET_PROFILE_REQUEST, GET_PROFILE_SUCCESS, GET_ROLES_FAIL, GET_ROLES_REQUEST, GET_ROLES_SUCCESS, GROUPS_COUNT_FAIL, GROUPS_COUNT_REQUEST, GROUPS_COUNT_SUCCESS, LEVEL_DATA_FAIL, LEVEL_DATA_REQUEST, LEVEL_DATA_SUCCESS, QUESTIONS_COUNT_FAIL, QUESTIONS_COUNT_REQUEST, QUESTIONS_COUNT_SUCCESS, QUESTION_MONTHS_FAIL, QUESTION_MONTHS_REQUEST, QUESTION_MONTHS_SUCCESS, USERS_COUNT_FAIL, USERS_COUNT_REQUEST, USERS_COUNT_SUCCESS, ADMIN_QUESTIONS_REQUEST, ADMIN_QUESTIONS_SUCCESS, ADMIN_QUESTIONS_FAIL, ADMIN_ANSWERS_REQUEST, ADMIN_ANSWERS_SUCCESS, ADMIN_ANSWERS_FAIL } from "../constants/adminConstants";

export const getUsersCount = () => async (dispatch) => {
    try {
        dispatch({ type: USERS_COUNT_REQUEST })
        
        const { data } = await axios.get(`/api/auth/users/count`)
        dispatch({
            type: USERS_COUNT_SUCCESS,
            payload: data.usersCount
        })
    } catch (error) {
        dispatch({
            type: USERS_COUNT_FAIL,
            payload: error.response.data.message
        })
    }
};

export const getGroupsCount = () => async (dispatch) => {
    try {
        dispatch({ type: GROUPS_COUNT_REQUEST })
        
        const { data } = await axios.get(`/api/group/groups/count`)
        dispatch({
            type: GROUPS_COUNT_SUCCESS,
            payload: data.groupsCount
        })
    } catch (error) {
        dispatch({
            type: GROUPS_COUNT_FAIL,
            payload: error.response.data.message
        })
    }
};

export const getActivitiesCount = () => async (dispatch) => {
    try {
        dispatch({ type: ACTIVITIES_COUNT_REQUEST })
        
        const { data } = await axios.get(`/api/activity/activities/count`)
        dispatch({
            type: ACTIVITIES_COUNT_SUCCESS,
            payload: data.activitiesCount
        })
    } catch (error) {
        dispatch({
            type: ACTIVITIES_COUNT_FAIL,
            payload: error.response.data.message
        })
    }
};

export const getQuestionsCount = () => async (dispatch) => {
    try {
        dispatch({ type: QUESTIONS_COUNT_REQUEST })
        
        const { data } = await axios.get(`/api/question/questions/count`)
        dispatch({
            type: QUESTIONS_COUNT_SUCCESS,
            payload: data.questionsCount
        })
    } catch (error) {
        dispatch({
            type: QUESTIONS_COUNT_FAIL,
            payload: error.response.data.message
        })
    }
};

export const getAllUsers = () => async (dispatch) => {
    try {
        dispatch({ type: ALL_USERS_REQUEST })
        
        const { data } = await axios.get(`/api/auth/all/users`)
        dispatch({
            type: ALL_USERS_SUCCESS,
            payload: data
        })
    } catch (error) {
        dispatch({
            type: ALL_USERS_FAIL,
            payload: error.response.data.message
        })
    }
};

export const getAllActivities = () => async (dispatch) => {
    try {
        dispatch({ type: GET_ACTIVITIES_REQUEST })
        
        const { data } = await axios.get(`/api/activity/all`)
        dispatch({
            type: GET_ACTIVITIES_SUCCESS,
            payload: data
        })
    } catch (error) {
        dispatch({
            type: GET_ACTIVITIES_FAIL,
            payload: error.response.data.message
        })
    }
};

export const getMemberShips = (id) => async (dispatch) => {
    try {
        dispatch({ type: GET_MEMBERS_REQUEST })
        
        const { data } = await axios.get(`/api/group/memberships/${id}`)
        dispatch({
            type: GET_MEMBERS_SUCCESS,
            payload: data
        })
    } catch (error) {
        dispatch({
            type: GET_MEMBERS_FAIL,
            payload: error.response.data.message
        })
    }
};

export const getAdminQuestions = () => async (dispatch) => {
    try {
        dispatch({ type: ADMIN_QUESTIONS_REQUEST })
        
        const { data } = await axios.get(`/api/question/all/time`)
        dispatch({
            type: ADMIN_QUESTIONS_SUCCESS,
            payload: data
        })
    } catch (error) {
        dispatch({
            type: ADMIN_QUESTIONS_FAIL,
            payload: error.response.data.message
        })
    }
};

export const getAdminAnswers = () => async (dispatch) => {
    try {
        dispatch({ type: ADMIN_ANSWERS_REQUEST })
        
        const { data } = await axios.get(`/api/response/all/time`)
        dispatch({
            type: ADMIN_ANSWERS_SUCCESS,
            payload: data
        })
    } catch (error) {
        dispatch({
            type: ADMIN_ANSWERS_FAIL,
            payload: error.response.data.message
        })
    }
};

export const getAllGroups = () => async (dispatch) => {
    try {
        dispatch({ type: ADMIN_GROUPS_REQUEST });
        
        const { data } = await axios.get(`/api/group/all`);
        dispatch({
            type: ADMIN_GROUPS_SUCCESS,
            payload: data
        });
    } catch (error) {
        dispatch({
            type: ADMIN_GROUPS_FAIL,
            payload: error.response.data.message
        })
    }
};

export const getAllAdmins = () => async (dispatch) => {
    try {
        dispatch({ type: ALL_ADMINS_REQUEST })
        
        const { data } = await axios.get(`/api/auth/all/admins`)
        dispatch({
            type: ALL_ADMINS_SUCCESS,
            payload: data.users
        })
    } catch (error) {
        dispatch({
            type: ALL_ADMINS_FAIL,
            payload: error.response.data.message
        })
    }
};

export const createAdmin = (userData) => async (dispatch) => {
    try {
        dispatch({ type: CREATE_ADMIN_REQUEST })
        const config = {
            headers: {
                'Content-Type' : 'multipart/form-data'
            }
        }
        const { data } = await axios.post('/api/auth/register', userData, config);

        dispatch({
            type: CREATE_ADMIN_SUCCESS,
            payload: data.user
        })
    } catch (error) {
        dispatch({
            type: CREATE_ADMIN_FAIL,
            payload: error.response.data.error
        })
    }
};

export const getAllHistories = () => async (dispatch) => {
    try {
        dispatch({ type: ALL_HISTORIES_REQUEST })
        
        const { data } = await axios.get(`/api/group/all/histories`)
        dispatch({
            type: ALL_HISTORIES_SUCCESS,
            payload: data.histories
        })
    } catch (error) {
        dispatch({
            type: ALL_HISTORIES_FAIL,
            payload: error.response.data.message
        })
    }
};

export const getAllMonthsCount = (months) => async (dispatch) => {
    try {
        dispatch({ type: ALL_MONTHS_REQUEST });
        const config = {
            headers: {
                'Content-Type' : 'application/json'
            }
        };
        
        const { data } = await axios.post(`/api/auth/all/users/created/months`, {months}, config)
        dispatch({
            type: ALL_MONTHS_SUCCESS,
            payload: data.results
        })
    } catch (error) {
        dispatch({
            type: ALL_MONTHS_FAIL,
            payload: error.response.data.message
        })
    }
};

export const getQuestionMonthsCount = (months) => async (dispatch) => {
    try {
        dispatch({ type: QUESTION_MONTHS_REQUEST });
        const config = {
            headers: {
                'Content-Type' : 'application/json'
            }
        };
        
        const { data } = await axios.post(`/api/question/all/questions/created/months`, {months}, config)
        dispatch({
            type: QUESTION_MONTHS_SUCCESS,
            payload: data.results
        })
    } catch (error) {
        dispatch({
            type: QUESTION_MONTHS_FAIL,
            payload: error.response.data.message
        })
    }
};

export const getAllRoles = () => async (dispatch) => {
    try {
        dispatch({ type: GET_ROLES_REQUEST })
        
        const { data } = await axios.get(`/api/permissions/roles/all`)
        dispatch({
            type: GET_ROLES_SUCCESS,
            payload: data.roles
        })
    } catch (error) {
        dispatch({
            type: GET_ROLES_FAIL,
            payload: error.response.data.message
        })
    }
};

export const getCurrentLevelData = (level) => async (dispatch) => {
    try {
        dispatch({ type: LEVEL_DATA_REQUEST })
        
        const { data } = await axios.get(`/api/permissions/levels/${level}`)
        dispatch({
            type: LEVEL_DATA_SUCCESS,
            payload: data
        })
    } catch (error) {
        dispatch({
            type: LEVEL_DATA_FAIL,
            payload: error.response.data.message
        })
    }
};

export const getPermissions = (role) => async (dispatch) => {
    try {
        dispatch({ type: GET_PERMISSIONS_REQUEST })
        
        const { data } = await axios.get(`/api/permissions/${role}`)
        dispatch({
            type: GET_PERMISSIONS_SUCCESS,
            payload: data.permissions
        })
    } catch (error) {
        dispatch({
            type: GET_PERMISSIONS_FAIL,
            payload: error.response.data.message
        })
    }
};

export const createUserRole = (name, permissions) => async (dispatch) => {
    try {
        dispatch({ type: CREATE_ROLE_REQUEST })
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }
        const { data } = await axios.post('/api/permissions/role', { name, permissions }, config);

        dispatch({
            type: CREATE_ROLE_SUCCESS,
            payload: data.success
        })
    } catch (error) {
        dispatch({
            type: CREATE_ROLE_FAIL,
            payload: error.response.data.error
        })
    }
};

export const getUserProfile = (id) => async (dispatch) => {
    try {
        dispatch({ type: GET_PROFILE_REQUEST })
        
        const { data } = await axios.get(`/api/auth/me/${id}`)
        dispatch({
            type: GET_PROFILE_SUCCESS,
            payload: data.user
        })
    } catch (error) {
        dispatch({
            type: GET_PROFILE_FAIL,
            payload: error.response.data.message
        })
    }
};

export const changeRole = (id, role) => async (dispatch) => {
    try {
        dispatch({ type: CHANGE_ROLE_REQUEST })

        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }
        const { data } = await axios.put(`/api/permissions/changerole/${id}`, { role }, config)
        dispatch({
            type: CHANGE_ROLE_SUCCESS,
            payload: data.user
        })
    } catch (error) {
        dispatch({
            type: CHANGE_ROLE_FAIL,
            payload: error.response.data.message
        })
    }
}