import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, Link, useLocation } from 'react-router-dom'
import Sidebar from './Sidebar'
import { clearErrors, loadUser, updateProfile } from '../../../actions/userActions';
import { UPDATE_PROFILE_RESET } from '../../../constants/userConstants';

import Default from '../../../assets/default.png';
import DefaultLogo from '../../../assets/smilelogo.png';
import ReactGA from 'react-ga4';
const baseUrl = process.env.REACT_APP_IMAGES_BASEURL;

const EditAdmin = () => {

    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [username, setUsername] = useState('')
    const [email, setEmail] = useState('')
    const [avatar, setAvatar] = useState('')
    const [avatarPreview, setAvatarPreview] = useState(Default)

    let navigate = useNavigate();

    const dispatch = useDispatch();
    const location = useLocation();

    const { user } = useSelector(state => state.auth);
    const { error, isUpdated, loading } = useSelector(state => state.user)

    useEffect(() => {

        if(user) {
            setFirstName(user?.firstName);
            setLastName(user?.lastName);
            setUsername(user?.username);
            setEmail(user?.email);
            setAvatarPreview(user?.avatar?.url ? (user?.avatar?.public_id === null ? (baseUrl + user?.avatar?.url) : user?.avatar?.url) : Default);
        }

        if(error) {
            dispatch(clearErrors());
        }

        if (isUpdated) {
            dispatch(loadUser(user._id));
            navigate("/admin/dashboard", { replace: true });
            dispatch({
                type: UPDATE_PROFILE_RESET
            })
        }

    }, [dispatch, error, isUpdated]);

    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: (location.pathname + location.search), title: "Edit Admin" });
    }, [location]);

    const submitHandler = (e) => {
        e.preventDefault();
        dispatch(updateProfile(user._id, firstName, lastName, username, email, avatar))
    };

    const onChange = e => {
        const reader = new FileReader();
        reader.onload = () => {
            if (reader.readyState === 2) {
                setAvatarPreview(reader.result)
            }
        }
        reader.readAsDataURL(e.target.files[0])
        setAvatar(e.target.files[0])
    };

  return (
    <div class="m-0 font-sans text-base min-h-screen antialiased font-normal leading-default bg-gray-50 text-slate-500">

        <main class="relative h-full transition-all duration-200 ease-in-out xl:ml-68 rounded-xl">
        <nav class="top-0 flex flex-wrap items-center justify-between px-0 py-2 mx-6 transition-all ease-in shadow-none duration-250 rounded-2xl lg:flex-nowrap lg:justify-start" navbar-main navbar-scroll="false">
            <div class="flex items-center justify-between w-full px-4 py-1 mx-auto flex-wrap-inherit">
            <nav>
            <Link to="/admin/dashboard">
              <img className='w-12' src={DefaultLogo} />
            </Link>
            </nav>
            </div>
        </nav>

        <Sidebar />
        <div className='min-h-full flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8'>
        <div className='flex flex-row max-w-md'>

            <div className='profilediv'>
                <div className='profileimg' style={{ 
                    backgroundImage: `url(${avatarPreview})`,
                    backgroundPosition: 'center',
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat'
                    }}>
                </div>
                <input type="file" id="avatar" name="avatar" onChange={onChange} accept='image/*' required />

                <div className='formelements'>
                    <form onSubmit={submitHandler} className="mt-3" encType='multipart/form-data'>
                    <label for="firstname">First name:</label>
                    <input type="text" name="firstName" value={firstName} onChange={(e) => setFirstName(e.target.value)} />
                    <label for="lastname">Last name:</label>
                    <input type="text" className='required' id="lastName" name="lastName" value={lastName} onChange={(e) => setLastName(e.target.value)} />
                    <label for="username">Username:</label>
                    <input type="text" className='required' id="username" name="username" value={username} onChange={(e) => setUsername(e.target.value)} />
                    <label for="email">Email:</label>
                    <input type="email" className='required' id="email" name="email" value={email} onChange={(e) => setEmail(e.target.value)} />
                    <button type='submit' className='signinbtn'>Save</button>
                    </form>
            </div>

            </div>
        
            
        </div>
    </div>
        </main>

    </div>
  )
}

export default EditAdmin