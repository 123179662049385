import React, { useState } from 'react';
import { FiStar } from 'react-icons/fi';
import { useSelector } from 'react-redux';

const RateQuestion = ({ showModal, setShowModal, rating, setRating, submitQuestionRating }) => {
    const { rubric } = useSelector(state => state.activityRubric);
    const [hoverRating, setHoverRating] = useState(0);

    const onMouseLeave = () => {
        setHoverRating(0);
    };

  return (
    <div className={`w-full pb-4 z-90 bottom-0 shadow-md right-0 left-0 bg-[#F8F9FA] fixed transition-all duration-500 transform ${showModal ? 'translate-y-0 opacity-100' : 'translate-y-full opacity-0 invisible'}`}>
        <div className='actionHeader h-[50px] border-b border-gray-200 px-4 flex justify-center items-center'>
            <p>Rate</p>
        </div>
        <div className='flex flex-col justify-center w-full gap-2'>
            <div className='flex flex-row w-full justify-center mt-3 gap-1'>
                <FiStar 
                    fill={`${(Number(rating) >= 1) ? '#2D3648' : '#F8F9FA'}`} 
                    onClick={() => setRating(1)}
                    className={`w-7 h-7 text-[#CBD2E0] normalStar ${(1 <= hoverRating) ? ' text-[#2D3648]' : ''} ${(1 <= rating) ? ' active' : ''}`} 
                    onMouseEnter={() => setHoverRating(1)} onMouseLeave={() => onMouseLeave()} />
                <FiStar 
                    fill={`${(Number(rating) >= 2) ? '#2D3648' : '#F8F9FA'}`} 
                    onClick={() => setRating(2)}
                    className={`w-7 h-7 text-[#CBD2E0] normalStar ${(2 <= hoverRating) ? ' text-[#2D3648]' : ''} ${(2 <= rating) ? ' active' : ''}`} 
                    onMouseEnter={() => setHoverRating(2)} onMouseLeave={() => onMouseLeave()} />
                <FiStar 
                    fill={`${(Number(rating) >= 3) ? '#2D3648' : '#F8F9FA'}`} 
                    onClick={() => setRating(3)}
                    className={`w-7 h-7 text-[#CBD2E0] normalStar ${(3 <= hoverRating) ? ' text-[#2D3648]' : ''} ${(3 <= rating) ? ' active' : ''}`} 
                    onMouseEnter={() => setHoverRating(3)} onMouseLeave={() => onMouseLeave()} />
                <FiStar 
                    fill={`${(Number(rating) >= 4) ? '#2D3648' : '#F8F9FA'}`} 
                    onClick={() => setRating(4)}
                    className={`w-7 h-7 text-[#CBD2E0] normalStar ${(4 <= hoverRating) ? ' text-[#2D3648]' : ''} ${(4 <= rating) ? ' active' : ''}`} 
                    onMouseEnter={() => setHoverRating(4)} onMouseLeave={() => onMouseLeave()} />
                <FiStar 
                    fill={`${(Number(rating) >= 5) ? '#2D3648' : '#F8F9FA'}`} 
                    onClick={() => setRating(5)}
                    className={`w-7 h-7 text-[#CBD2E0] normalStar ${(5 <= hoverRating) ? ' text-[#2D3648]' : ''} ${(5 <= rating) ? ' active' : ''}`} 
                    onMouseEnter={() => setHoverRating(5)} onMouseLeave={() => onMouseLeave()} />
            </div>
            <p className='flex w-full justify-center rateLabel'>
                {rating === 1 ? (rubric?.gradeLevels?.oneStar ? rubric?.gradeLevels?.oneStar : 'Needs severe improvement') : 
                rating === 2 ? (rubric?.gradeLevels?.twoStar ? rubric?.gradeLevels?.twoStar : 'Lacking in skill; can improve in several places') : 
                rating === 3 ? (rubric?.gradeLevels?.threeStar ? rubric?.gradeLevels?.threeStar : 'Doing okay; can do better but is working hard') : 
                rating === 4 ? (rubric?.gradeLevels?.fourStar ? rubric?.gradeLevels?.fourStar : 'Great job; room for small improvements') : 
                rating === 5 ? (rubric?.gradeLevels?.fiveStar ? rubric?.gradeLevels?.fiveStar : 'Amazing job; keep up the great work') : ''}
            </p>
            <div className='flex flex-row gap-2 w-full justify-center '>
                <button onClick={() => {setShowModal(false); setRating('');}} className='rateButtonNormal w-[79px] py-1 border border-gray-300 rounded-[20px]'>
                    Cancel
                </button>
                <button onClick={submitQuestionRating} className='w-[150px] rateButtonBold py-1 bg-[#CBD2E0] rounded-[20px] border border-gray-300'>
                    Enter
                </button>
            </div>
        </div>
    </div>
  )
}

export default RateQuestion