import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams, useLocation } from 'react-router-dom';
import { changeRole, getAllRoles, getUserProfile } from '../../../actions/adminActions';
import Default from '../../../assets/smilelogo.png';
import { CHANGE_ROLE_RESET } from '../../../constants/adminConstants';
import Sidebar from './Sidebar';
import ReactGA from 'react-ga4';

const UpdateAdmin = () => {
    const dispatch = useDispatch();
    const [role, setRole] = useState('');

    const { user, changedRole } = useSelector((state) => state.adminUsers);
    const { roles } = useSelector(state => state.countStats);

    const { id } = useParams();
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        dispatch(getUserProfile(id));
        dispatch(getAllRoles());
    }, [id]);

    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: (location.pathname + location.search), title: "Update Admin" });
    }, [location]);

    useEffect(() => {
        if (changedRole) {
            dispatch({ type: CHANGE_ROLE_RESET });
            navigate('/admin/all/admins')
        }
    }, [changedRole]);

    const handleSubmit = () => {
        dispatch(changeRole(id, role));
    };

  return (
    <div className="m-0 font-sans text-base antialiased font-normal leading-default text-slate-500">

        <main className="relative h-full max-h-screen transition-all duration-200 ease-in-out xl:ml-68 rounded-xl">
            <nav className="fixed top-0 flex flex-wrap items-center px-0 py-2 mx-6 transition-all ease-in shadow-none duration-250 rounded-2xl lg:flex-nowrap" navbar-main navbar-scroll="false">
                <div className="flex items-center w-full px-4 py-1 mx-auto flex-wrap-inherit">
                <nav>
                <Link to="/admin/dashboard">
                    <img className='w-12 flex items-center' src={Default} alt="Smile Logo" />
                </Link>
                </nav>
                </div>
            </nav>
            <Sidebar />
            <div className='mt-[80px] ml-14 mb-10 md:ml-64 px-6 py-6 mx-auto'>
                <h4 className="mb-4 text-lg font-semibold text-gray-600 dark:text-gray-300">Change user role</h4>
                <div className="w-full mb-8 overflow-hidden rounded-lg shadow-xs">
                    <p>First Name: {user?.firstName}</p>
                    <p>Last Name: {user?.lastName}</p>
                    <p>Email: {user?.email}</p>
                    <p>Username: {user?.username}</p>
                    <p>Email: {user?.email}</p>
                    <p>Role: {user?.role?.name}</p>
                    <select id="default"onChange={(e) => setRole(e.target.value)} className="bg-gray-50 border border-gray-300 text-gray-900 mb-6 text-sm rounded-lg max-w-[200px] focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                        <option selected>Choose a role</option>
                        {roles?.map((role, index) => (
                            <option key={index} value={role?._id}>{role?.name}</option>
                        ))}
                    </select>
                    <button className='signinbtn' onClick={() => handleSubmit()}>Save Changes</button>
                </div>
            </div>
        </main>
    </div>
  )
}

export default UpdateAdmin