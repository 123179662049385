import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Default from '../../assets/logo3.png';

const Navbar = () => {

    const [navbar, setNavbar] = React.useState(false);

    const { user, loading } = useSelector(state => state.auth);
    const dispatch = useDispatch();

  return (
    <nav className="bg-white fixed top-0 z-10 h-17 w-full shadow">
            <div className="justify-between px-4 mx-auto lg:max-w-7xl md:items-center md:flex md:px-8">
                <div>
                    <div className="flex items-center justify-between py-3 md:py-5 md:block">
                        <Link to={'/'}>
                            <img className="w-14" src={Default} alt="" />
                        </Link>
                        <div className="md:hidden">
                            <button
                                className="p-2 text-gray-700 rounded-md outline-none focus:border-gray-400 focus:border"
                                onClick={() => setNavbar(!navbar)}
                            >
                                {navbar ? (
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="w-6 h-6 text-white"
                                        viewBox="0 0 20 20"
                                        fill="bg-[#8C1515]"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                            clipRule="evenodd"
                                        />
                                    </svg>
                                ) : (
                                    <svg className="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clipRule="evenodd"></path>
                                    </svg>
                                )}
                            </button>
                        </div>
                    </div>
                </div>
                <div>
                    <div
                        className={`flex-1 justify-self-center pb-3 mt-8 md:block md:pb-0 md:mt-0 ${
                            navbar ? "block" : "hidden"
                        }`}
                    >
                        <ul className="items-center justify-center space-y-8 md:flex md:space-x-6 md:space-y-0">
                            <li className="text-black hover:text-[#8C1515]">
                                <Link to={"/about/smile"}>About</Link>
                            </li>
                            <li className="text-black hover:text-[#8C1515]">
                                <Link to={"/smile/research"}>Research</Link>
                            </li>
                            <li className="text-black hover:text-[#8C1515]">
                                <Link to={"/smile/videos"}>Videos</Link>
                            </li>
                            <li className="text-black hover:text-[#8C1515]">
                                <Link to={"/contactus"}>Contact</Link>
                            </li>
                        </ul>

                        {user?.roleId === 1 ? (
                            <Link to={"/groups"} className='md:hidden inline-block w-full px-4 py-2 text-center text-white bg-gray-600 rounded-md shadow hover:bg-gray-800'>
                                Open App
                            </Link>
                        ) : !loading && (
                            <div className="mt-3 space-y-2 md:hidden inline-block">
                                <Link to={"/login"}
                                    className="inline-block w-full px-4 py-2 text-center text-white bg-gray-600 rounded-md shadow hover:bg-gray-800"
                                >
                                    Sign in
                                </Link>
                                <Link to={"/register"}
                                    className="inline-block w-full px-4 py-2 text-center text-gray-800 bg-white rounded-md shadow hover:bg-gray-100"
                                >
                                    Sign up
                                </Link>
                                <Link to={"/admin/login"}
                                    className="inline-block w-full px-4 py-2 text-center text-gray-800 bg-white rounded-md shadow hover:bg-gray-100"
                                >
                                    Admin Login
                                </Link>
                            </div>
                        )}
                    </div>
                </div>
                {user?.roleId === 1 ? (
                    <Link to={"/groups"} className='hidden md:inline-block px-4 py-2 text-white bg-[#8C1515] rounded-md shadow hover:bg-gray-800'>
                        Open App
                    </Link>
                ) : !loading && (
                    <div className="hidden space-x-2 md:inline-block">
                    <Link to={"/login"}
                        className="px-4 py-2 text-white bg-[#8C1515] rounded-md shadow hover:bg-gray-800"
                    >
                        Sign in
                    </Link>
                    <Link to={"/register"}
                        className="px-4 py-2 text-gray-800 bg-white rounded-md shadow hover:bg-gray-100"
                    >
                        Sign up
                    </Link>
                    <Link to={"/admin/login"}
                        className="px-4 py-2 text-gray-800 bg-white rounded-md shadow hover:bg-gray-100"
                    >
                        Admin Login
                    </Link>
                </div>
                )}
            </div>
        </nav>
  )
}

export default Navbar
