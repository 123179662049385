import React from 'react';
import { MdClose } from 'react-icons/md';

const SelectImageModal = ({ selectedImage, setSelectedImage }) => {
  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50" onClick={() => setSelectedImage(null)}>
        <div className="relative p-2 max-w-lg md:max-w-2xl xl:max-w-4xl" style={{ maxHeight: '70vh' }}>
            <img src={selectedImage} onClick={(e) => e.stopPropagation()} className="w-full" style={{ maxHeight: '70vh' }} alt='modal' />
            <div onClick={() => setSelectedImage(null)} className="absolute cursor-pointer top-2 md:top-4 right-2 md:right-4 m-2 p-2 bg-white text-black rounded-full">
                <MdClose />
            </div>
        </div>
    </div>
  )
}

export default SelectImageModal