import React, { useEffect, useState } from 'react'
import MobileLayout from '../layouts/MobileLayout'
import { MdClose, MdKeyboardArrowRight } from 'react-icons/md'
import { GoPlus } from 'react-icons/go'
import Default from '../../assets/groupdefault.jpg';
import { useNavigate, useParams, useLocation, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getQuestions } from '../../actions/questionActions';
import moment from 'moment';
import { getSingleActivity } from '../../actions/activityActions';
import ReactGA from 'react-ga4';
import { QUESTION_DETAILS_RESET } from '../../constants/questionConstants'
import { AiOutlineMenu, AiOutlineSetting } from 'react-icons/ai'
import ActivityMenu from '../popovers/ActivityMenu'
import { getSingleGroup } from '../../actions/groupActions'
import { CHANGE_LEVEL_RESET } from '../../constants/activityConstants';
import { CgLock } from 'react-icons/cg';
import ModeComponent from './ModeComponent';
import io from "socket.io-client";
import { BsChatDots, BsGraphUp } from 'react-icons/bs';
import StarIcon from '../../assets/star.svg';

const baseUrl = process.env.REACT_APP_IMAGES_BASEURL;
const ENDPOINT = process.env.REACT_APP_ENDPOINT;
let socket;

const Activity = () => {
    const { id, activityId } = useParams();
    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [showMenu, setShowMenu] = useState(false);
    const [questions, setQuestions] = useState([]);
    const { user } = useSelector(state => state.auth);
    const { mobileQuestions, responses } = useSelector(state => state.questions);
    const { activity, changedLevel } = useSelector(state => state.activity);
    const { group } = useSelector(state => state.groupDetails);

    useEffect(() => {
        socket = io(ENDPOINT);
    }, []);

    useEffect(() => {
        setQuestions(mobileQuestions);
    }, [mobileQuestions]);

    useEffect(() => {
        socket.emit("join activity", { userId: user?._id, activity: activityId });
    }, []);

    useEffect(() => {
        function handleResize() {
          if (window.innerWidth >= 768) { 
            navigate(`/group/${id}`);
          }
        }
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
      }, []);

    useEffect(() => {
        if (activityId) {
            dispatch(getSingleGroup(id, user?._id));
            dispatch(getSingleActivity(activityId));
            dispatch(getQuestions(activityId));
            dispatch({ type: QUESTION_DETAILS_RESET });
        }
    }, [activityId]);

    useEffect(() => {
        socket?.on("question received", newQuestionReceived => {
            setQuestions((prev) => [newQuestionReceived.question, ...prev]);
        });
    }, []);

    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: (location.pathname + location.search), title: "Activity" });
    }, [location]);

    useEffect(() => {
        if (changedLevel) {
            dispatch({ type: CHANGE_LEVEL_RESET });
            dispatch(getSingleActivity(activityId));
            socket?.emit("activity update", { userId: user?._id, activity: activityId });
        }
    }, [changedLevel]);

    useEffect(() => {
        socket?.on("mode received", updatedMode => {
            dispatch(getSingleActivity(activityId));
        })
    }, []);

    const handleSettingsClick = () => {
        navigate(`/${id}/${activityId}/update`);
    };

    const handleCreateQuestionBtnClick = () => {
        if (activity?.activityType === 'OrganizerPaced' && activity?.level !== 'create') {
            return;
        }
        navigate(`/${id}/${activityId}/questions/create`);
    };

  return (
    <MobileLayout>
        <div className='flex flex-row items-center border-b border-gray-200 p-3'>
            <div className={`flex flex-row items-center ${(group?.role === 3) ? 'w-full' : 'w-5/6'} activityNames`}>
                <p onClick={() => navigate(`/group/${id}`)}>Activity</p>
                <MdKeyboardArrowRight />
                <p className='flex-1 block overflow-y overflow-x-hidden break-words' translate="no">{activity?.name}</p>
            </div>
            {(group?.role === 2 || group?.role === 4) && 
            <div className='w-1/6 relative items-center'>
                <AiOutlineMenu className='ml-auto' onClick={() => setShowMenu(!showMenu)} />
            </div>}
        </div>

        <ModeComponent />

        {questions &&  questions?.map((question, index) =>
        <Link key={index} to={`/${id}/${activityId}/${question?._id}/question`} className='flex flex-col gap-3 border-b border-gray-200 p-3'>
            <div className='flex justify-between'>
                {!activity?.hideUsernames &&
                <div className='flex flex-row gap-2 items-center'>
                    <img className="imagequestion1 inline-flex" src={question?.creator?.avatar?.url ? (question?.creator?.avatar?.public_id === null ? (baseUrl + question?.creator?.avatar?.url) : question?.creator?.avatar?.url) : Default} alt='Question' />
                    <p className='questionAuthor' translate="no">{question?.creator?.firstName + " " + question?.creator?.lastName}</p>
                </div>}
                <p className='questionAuthor'>{moment(question?.createdAt).utc().format('MM/DD/YYYY')}</p>
            </div>
            {/* text-[#822826] text-[#2D3648] */}
            <p className={`${responses?.some((obj) => obj?.creator?._id === user?._id && obj?.question?._id === question?._id) ? "text-[#822826]" : "text-[#2D3648]"} questionContentq`}><span class="bg-gray-100 text-gray-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-gray-300" translate="no">{(questions?.length - index)}.</span>{question?.content}</p>
            <div className='flex flex-row gap-2'>
                {question?.ratings !== 0 && 
                <div className='flex flex-row gap-2'>
                    <img className="w-4 h-4 rounded-full" src={StarIcon} alt='Average rating' />
                    <p className="questionAuthor">(Avg. {Number?.isInteger(question?.ratings) ? question?.ratings : question?.ratings?.toFixed(1)})</p>
                </div>}
                <div className='flex items-center gap-2'>
                    <BsChatDots className='w-3 h-3' />
                    <p className='questionReviews'>Comments {question?.commentsCount}</p>
                </div>
            </div>
        </Link>
        )}
        <div onClick={handleCreateQuestionBtnClick} 
            className={`createGroupBtn ${(activity?.activityType === 'OrganizerPaced' && activity?.level !== 'create') ? 'text-[#9297A1]' : 'text-[#2D3648]'} flex md:hidden flex-row gap-1 bg-[#E8EAED] rounded-lg px-5 py-3 fixed z-90 bottom-10 right-8`}>
            <p>Create Question</p>
            {!(activity?.activityType === 'OrganizerPaced' && activity?.level !== 'create') && <GoPlus className='w-[12px] h-[12px]' />}
            {activity?.activityType === 'OrganizerPaced' && activity?.level !== 'create' && <CgLock className='w-[12px] h-[12px]' />}
        </div>
        <div className={`w-full pb-6 z-90 bottom-0 shadow-md right-0 left-0 bg-[#F8F9FA] fixed transition-all duration-500 transform ${showMenu ? 'translate-y-0 opacity-100' : 'translate-y-full opacity-0 invisible'}`}>
            <div className='actionHeader h-[50px] border-b border-gray-200 px-4 flex justify-between items-center'>
                <div></div>
                <p>Action</p>
                <MdClose onClick={() => setShowMenu(false)} />
            </div>
            <div className="w-full flex flex-col gap-6 px-6 mt-6 justify-center">
                <div className='flex flex-col activitySettingLabels'>
                    <Link to={`/${id}/${activityId}/update`} className="flex items-center mb-6">
                        <AiOutlineSetting />
                        <div className="ml-2 popoverLabel" onClick={handleSettingsClick}>Activity Setting</div>
                    </Link>
                    <Link to={`/${id}/${activityId}/reports`} className="flex items-center">
                        <BsGraphUp />
                        <p className="ml-2 popoverLabel">Reports</p>
                    </Link>
                </div>
            </div>
        </div>
    </MobileLayout>
  )
}

export default Activity