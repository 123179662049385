import React, { useEffect, useState } from 'react'
import { BsFileEarmarkFill } from 'react-icons/bs'
import { BiCloudUpload } from 'react-icons/bi'
import { AiOutlineCloseCircle } from 'react-icons/ai'
import { Link } from 'react-router-dom'
import axios from 'axios';
import download from 'downloadjs';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getActivityResources, uploadActivityresource } from '../../../actions/activityActions'
import { UPLOAD_ARESOURCE_RESET } from '../../../constants/activityConstants'
import moment from 'moment'

const AResources = ({ id }) => {

    const [showUpload, setShowUpload] = useState(false);
    const [doc, setdoc] = useState('');
    const [uploadErrorMsg, setUploadErrorMsg] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');

    const { user } = useSelector(state => state.auth);
    const { resources } = useSelector(state => state.activityResources);
    const { group } = useSelector(state => state.groupDetails);
    const { uActivityResource } = useSelector(state => state.activity);
    
    const creator = user?._id;

    const dispatch = useDispatch();

    const showToastMessage = (message) => {
        toast.success(message, {
            position: toast.POSITION.TOP_RIGHT
        });
    };

    useEffect (() => {

        if (id) {
            dispatch(getActivityResources(id));
        }

        if (uActivityResource) {
            showToastMessage("Resource uploaded successfully!");
            dispatch({ type: UPLOAD_ARESOURCE_RESET });
        }

    }, [uActivityResource, id]);

    const downloadFile = async (id, doc, filemimetype) => {
        try {
          const result = await axios.get(`/api/activity/resource/download/${id}`, {
            responseType: 'blob'
          });
          const split = doc.split('/');
          const filename = split[split.length - 1];
          return download(result.data, filename, filemimetype);
        } catch (error) {
          console.log(error)
        }
    }; 

    const handleUploadedFile = (e) => {
        setdoc(e.target.files[0]);
    }

    const upload = (e) => {
        e.preventDefault();
        const MAX_FILE_SIZE = 1024 * 1024 * 10;

        if(doc.size > MAX_FILE_SIZE){
            setUploadErrorMsg("File size cannot exceed 10Mbs");
            return
        }
        const activity = id;
        dispatch(uploadActivityresource(creator, activity, doc));
        setShowUpload(false);
    };

  return (
    <div className='activityResourceDiv col-span-3 w-full'>
        <div className='activityResearch w-full'>
            <input type='text' onChange={(e) => setSearchQuery(e.target.value)} placeholder='Search files...' className='researchinput' />
            <div onClick={() => {setShowUpload(true); setUploadErrorMsg(false);}} className={group?.role === 2 || group?.role === 4 ? "researchright cursor-pointer" : "hidden"}>
                <BiCloudUpload className='fileicon' />
                <p className=''>Upload file</p>
            </div>
        </div>

        {resources ? (
            resources?.filter((resource) => resource?.originalname.toLowerCase().includes(searchQuery))?.map((resource, idx) =>
            <div className='redivcontent w-full'>
                <div className='redivleft'>
                    <Link to='' onClick={() => {downloadFile(resource?._id, resource?.doc, resource?.file_mimetype)}}><BsFileEarmarkFill className='fileicon'  /> </Link>
                    <p className='refilename'>{resource?.originalname}</p>
                </div>
                <p className='redate'>{moment(resource?.createdAt).utc().format('DD/MM/YY')}</p>
            </div>
            )
        ) : null}
            
        

        {showUpload ? (
            <>
            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                <div className='joinpmodal w-[300px] md:w-[430px]'>
                    <div className='joinpmodalcontent p-4 w-full'>
                        <div className='joinpmodalheader w-full'>
                            <p className='joinpmodalheaderight'>Upload Resource</p>
                            <AiOutlineCloseCircle className='joinpmodalheaderleft cursor-pointer' onClick={() => setShowUpload(false)} />
                        </div>
                        <form encType="multipart/form-data" className='joinpmodalbody w-full' onSubmit={upload}>
                            <div className='joinpmodalbodydiv w-full'>
                                <input type="file" id="doc" name="doc" onChange={handleUploadedFile} className='joinpmodalbodyinput w-full' required />
                                <p className='text-sm text-red-600'>{uploadErrorMsg}</p>
                            </div>
                            <button className='joinpmodalsubmit' type='submit'>Upload</button>
                        </form>
                    </div>
                </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
            </>
        ) : null}
    </div>
  )
}

export default AResources