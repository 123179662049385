import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { logout } from "../../actions/userActions";
import { useNavigate } from "react-router-dom";

const Logout = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { isAuthenticated, user } = useSelector(state => state.auth)

    useEffect(() => {
        dispatch(logout());
    }, [dispatch]);

    useEffect(() => {
        if (isAuthenticated === false) {
            window.location.replace('/login');
            // navigate('/login');
        }
    }, [isAuthenticated])

    return <></>;
};

export default Logout;