import React, { useEffect, useState } from 'react';
import Default from '../../../assets/groupdefault.jpg';
import { AiFillStar } from 'react-icons/ai';
import { useDispatch, useSelector } from 'react-redux';
import { getActivityReport, getActivityUserQuestions } from '../../../actions/activityActions';
import { BsChatDots } from 'react-icons/bs';
import { BiStopwatch } from 'react-icons/bi';
import { AiOutlineStar } from 'react-icons/ai';
import moment from 'moment';
import { Bar } from "react-chartjs-2";
import { Chart as ChartJS, BarElement, Title, Tooltip, Legend, CategoryScale, LinearScale, } from 'chart.js';
import { TagCloud } from 'react-tagcloud';
import StarIcon from '../../../assets/star.svg';

const baseUrl = process.env.REACT_APP_IMAGES_BASEURL;

const ActivityReport = ({ activityId, activityName }) => {
    const dispatch = useDispatch();
    const [openReport, setOpenReport] = useState(1);
    const [openSection, setOpenSection] = useState(1);
    const [user, setUser] = useState('');

    const { questions, questionsCount, responseCount, users, frequentWords, individualUser, individualQuestions, individualResponses, individualRatings, receivedRatings } = useSelector(state => state.activityReport);

    useEffect(() => {
        if (openReport === 1) {
            dispatch(getActivityReport(activityId));
        }
    }, [activityId, openReport]);

    useEffect(() => {
        if (openReport === 2 && user) {
            dispatch(getActivityUserQuestions(user, activityId));
        }
    }, [activityId, openReport]);

    ChartJS.register(
        CategoryScale,
        LinearScale,
        BarElement,
        Title,
        Tooltip,
        Legend
    );
      
    const options = {
        responsive: true,
        plugins: {
        legend: {
            position: 'top',
        },
        title: {
            display: false,
            text: 'Chart.js Bar Chart',
        },
        },
    };
      
    const labels = ['1', '2', '3', '4', '5'];
    const oneRating = individualRatings?.filter(x => x===1)?.length;
    const twoRating = individualRatings?.filter(x => x===2)?.length;
    const threeRating = individualRatings?.filter(x => x===3)?.length;
    const fourRating = individualRatings?.filter(x => x===4)?.length;
    const fiveRating = individualRatings?.filter(x => x===5)?.length;

    const oneRRating = receivedRatings?.filter(x => x===1)?.length;
    const twoRRating = receivedRatings?.filter(x => x===2)?.length;
    const threeRRating = receivedRatings?.filter(x => x===3)?.length;
    const fourRRating = receivedRatings?.filter(x => x===4)?.length;
    const fiveRRating = receivedRatings?.filter(x => x===5)?.length;

    const findLargestRating = (individualRatings) => {
        if (individualRatings.length === 0) {
            return null;
        }
        
        let maxNumber = individualRatings[0];
        for (let i = 1; i < individualRatings.length; i++) {
            if (individualRatings[i] > maxNumber) {
            maxNumber = individualRatings[i];
            }
        }
        
        return maxNumber;
    }
      
    const data = {
        labels,
        datasets: [
            {
                label: 'Given Star Rate',
                data: [oneRating, twoRating, threeRating, fourRating, fiveRating],
                backgroundColor: 'rgb(244,220,132)',
            },
        ],
    };

    const receivedData = {
        labels,
        datasets: [
            {
                label: 'Received Star Rate',
                data: [oneRRating, twoRRating, threeRRating, fourRRating, fiveRRating],
                backgroundColor: 'rgb(244,220,132)',
            },
        ],
    };

  return (
    <div>
        {openReport === 1 &&
         <div className='flex flex-col py-4 px-4 gap-2'>

            <p><span translate="no">{activityName}</span> | Reports </p>

            <div className='flex flex-row gap-2'>
                <div className='flex flex-col py-2 pl-2 pr-8 bg-[#F4F5F6] rounded-[10px] gap-2'>
                    <p className='activityReportSmallLabel'>Total Questions</p>
                    <p className='activityReportBigLabel'>{questionsCount}</p>
                </div>
                <div className='flex flex-col py-2 pl-2 pr-8 bg-[#F4F5F6] rounded-[10px] gap-2'>
                    <p className='activityReportSmallLabel'>Total Answers</p>
                    <p className='activityReportBigLabel'>{responseCount}</p>
                </div>
            </div>

            <div className='max-w-[400px] bg-[#F4F5F6] rounded-[10px]'>
                <p className='activityReportSmallLabel pt-4 px-2'>Best Questions Top 5</p>
                {questions?.map((question, index) =>
                <div key={index} className='flex flex-col'>
                    <div className='flex flex-col gap-2 py-4 border-b px-3 border-gray-300'>
                        <div className='flex flex-row gap-1'>
                            <img className="w-4 h-4 rounded-full" src={StarIcon} alt='Average rating' />
                            <p className='activityReportRateLabel'>{question?.ratings}</p>
                            <p className='reportActivityLabel'>|</p>
                            <img className="w-[16px] h-[16px] rounded-full" src={Default} alt='' />
                            <p className='reportAuthorLabel' translate="no">{question?.creator?.username ? question?.creator?.username : question?.creator?.firstName}</p>
                        </div>
                        <div className='reportQuestionLabel'>
                            <p translate="no">{question?.content}</p>
                        </div>
                    </div>
                </div>)}
            </div>
            
            <div className="relative overflow-x-auto">
                <table className="w-full text-sm text-left text-gray-500">
                    <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                        <tr>
                            <th scope="col" className="px-6 py-3 border-r">
                                Name
                            </th>
                            <th scope="col" className="px-6 py-3 border-r">
                                Created
                            </th>
                            <th scope="col" className="px-6 py-3 border-r">
                                Comments
                            </th>
                            <th scope="col" className="px-6 py-3 border-r">
                                Star Avg
                            </th>
                            <th scope="col" className="px-6 py-3 border-r">
                                5
                            </th>
                            <th scope="col" className="px-6 py-3 border-r">
                                4
                            </th>
                            <th scope="col" className="px-6 py-3 border-r">
                                3
                            </th>
                            <th scope="col" className="px-6 py-3 border-r">
                                2
                            </th>
                            <th scope="col" className="px-6 py-3 border-r">
                                1
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Words
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {users?.map((user, index) => 
                        <tr key={index} className="bg-white border-b">
                            <td translate="no" scope="row" onClick={() => {setUser(user?._id); setOpenReport(2);}} className="px-6 underline text-blue-500 cursor-pointer py-2 border-r">
                                {user?.username ? user?.username : user?.firstName}
                            </td>
                            <td className="px-6 py-2 border-r">
                                {user?.questionCount}
                            </td>
                            <td className="px-6 py-2 border-r">
                                {user?.responseCount}
                            </td>
                            <td className="px-6 py-2 border-r">
                                {user?.questionRatings?.length > 0 ? (user?.questionRatings.reduce((a, b) => a + b, 0) / user?.questionRatings?.length).toFixed(1) : 0}
                            </td>
                            <td className="px-6 py-2 border-r">
                                {user?.questionRatings?.reduce((total,x) => (x === 5 ? total+1 : total), 0)}
                            </td>
                            <td className="px-6 py-2 border-r">
                                {user?.questionRatings?.reduce((total,x) => (x === 4 ? total+1 : total), 0)}
                            </td>
                            <td className="px-6 py-2 border-r">
                                {user?.questionRatings?.reduce((total,x) => (x === 3 ? total+1 : total), 0)}
                            </td>
                            <td className="px-6 py-2 border-r">
                                {user?.questionRatings?.reduce((total,x) => (x === 2 ? total+1 : total), 0)}
                            </td>
                            <td className="px-6 py-2 border-r">
                                {user?.questionRatings?.reduce((total,x) => (x === 1 ? total+1 : total), 0)}
                            </td>
                            <td className="px-6 py-2">
                                {user?.questionWordCount}
                            </td>
                        </tr>)}
                    </tbody>
                </table>
            </div>

        </div>}

        {openReport === 2 && 
        <div className='flex flex-col py-4 px-4 gap-2'>
            <p className='cursor-pointer' onClick={() => setOpenReport(1)}><span translate="no">{activityName}</span> | Reports</p>

            <div className='flex flex-row w-full px-3 gap-8 py-3'>
                <img className="w-[90px] h-[90px] rounded-full" src={individualUser?.avatar?.url ? (individualUser?.avatar?.public_id === null ? (baseUrl + individualUser?.avatar?.url) : individualUser?.avatar?.url) : Default} alt='User' />
                <div className='flex flex-col'>
                    <div className='reportOwnerLabel capitalize' translate="no">{individualUser?.firstName} {individualUser?.lastName}</div>
                    <div className='reportOwnerSocialsLabel' translate="no">{individualUser?.email}</div>
                </div>
            </div>

            <div className='flex flex-row w-full px-3 bg-[#F4F5F6] gap-3 py-3'>
                <div className='reportActivityHeadings'>Most Frequently used word</div>
            </div>

            <div className='flex flex-row justify-center items-center px-12 w-full'>
                <TagCloud
                    minSize={12}
                    maxSize={35}
                    tags={frequentWords? frequentWords : []}
                    colorOptions={{
                        hue: 'blue',
                      }}
                >
                </TagCloud>
            </div>

            <div className='flex flex-row w-full px-3 bg-[#F4F5F6] gap-3 py-3'>
                <div className='reportActivityHeadings'>Star Rate</div>
            </div>
            
            <div className='flex flex-row px-6 w-full'>
                <div className='w-1/2'>
                    <div className='px-12'>
                        <Bar options={options} data={receivedData} />
                    </div>
                    <div className='graphLabels'>
                        <div className='flex justify-between px-8'>
                            <div>{receivedRatings?.length > 0 ? (receivedRatings.reduce((a, b) => a + b, 0) / receivedRatings?.length).toFixed(1) : 'N/A'}</div>
                            <div>{receivedRatings?.length}</div>
                            <div>{receivedRatings?.length > 0 ? findLargestRating(receivedRatings) : 'N/A'}</div>
                        </div>
                        <div className='flex justify-between px-8'>
                            <div>Star Avg</div>
                            <div>Number of Star</div>
                            <div>Top Rate</div>
                        </div>
                    </div>
                </div>
                <div className='w-1/2 '>
                    <div className='px-12'>
                        <Bar options={options} data={data} />
                    </div>
                    <div className='graphLabels'>
                        <div className='flex justify-between px-8'>
                            <div>{individualRatings?.length > 0 ? (individualRatings.reduce((a, b) => a + b, 0) / individualRatings?.length).toFixed(1) : 'N/A'}</div>
                            <div>{individualRatings?.length}</div>
                            <div>{individualRatings?.length > 0 ? findLargestRating(individualRatings) : 'N/A'}</div>
                        </div>
                        <div className='flex justify-between px-8'>
                            <div>Star Avg</div>
                            <div>Number of Star</div>
                            <div>Top Rate</div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='flex justify-between w-full px-3 bg-[#F4F5F6] gap-3 py-3'>
                <div className='reportActivityHeadings'>{individualQuestions?.length} Questions</div>
                {/* <div className=' flex flex-row border-solid border-2 border-[#CBD2E0] rounded-lg'>
                    <div onClick={() => setOpenSection(1)} className={`flex items-center cursor-pointer justify-center newQuizSmall px-6 py-1 text-[#2D3648] rounded-l-lg ${openSection === 1 && 'bg-[#7AA6FF] text-[#FFFFFF]'}`}>
                        Questions
                    </div>
                    <div className={`flex items-center cursor-pointer justify-center newQuizSmall px-6 py-1 text-[#2D3648] rounded-r-lg ${openSection === 2 && 'bg-[#7AA6FF] text-[#FFFFFF]'}`}>
                        Answers
                    </div>
                </div> */}
            </div>

            {openSection === 1 && 
            individualQuestions?.map((question, index) =>
            <div key={index} className='flex flex-col gap-2 py-4 border-b px-3 border-gray-300'>
                <div className='flex justify-between'>
                    <div className='flex flex-row gap-1'>
                        <p className='reportAuthorLabel' translate="no">{question?.group?.name}</p>
                        <p className='reportActivityLabel'>|</p>
                        <p className='reportActivityLabel' translate="no">{question?.activity?.name}</p>
                    </div>
                    <p className='reportActivityLabel'>{moment(question?.createdAt).utc().format('MM-DD-YYYY')}</p>
                </div>
                <div className='reportQuestionLabel'>
                    <p translate="no">{question?.content}</p>
                </div>
                <div className='flex flex-row justify-end gap-2 reportActionsLabel'>
                    <div className='flex flex-row gap-1'>
                        <AiOutlineStar className='w-4 h-4' />
                        <p>{question?.ratings}</p>
                    </div>
                    <div className='flex flex-row gap-1'>
                        <BsChatDots className='w-3 h-3' />
                        <p>{question?.responseCount}</p>
                    </div>
                    <div className='flex flex-row gap-1'>
                        <BiStopwatch className='w-4 h-4' />
                        <p>{question?.questiontimes?.usedTime}</p>
                    </div>
                </div>
            </div>)}

            {openSection === 2 &&
             individualResponses?.map((response, index) =>
            <div key={index} className='flex flex-col gap-2 py-4 border-b px-3 border-gray-300'>
                <div className='flex flex-row gap-1'>
                    {/* <p className='reportActivityLabel'>|</p> */}
                    <p className='reportActivityLabel'>{moment(response?.createdAt).utc().format('MM/DD/YYYY')}</p>
                </div>
                <div className='reportQuestionLabel'>
                    <p translate="no">{response?.content}</p>
                </div>
            </div>)}
        </div>}
    </div>
  )
}

export default ActivityReport