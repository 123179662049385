import React from 'react';
import { AiOutlineDown, AiOutlineUp } from 'react-icons/ai';
import { MdOutlineClose } from 'react-icons/md';

const FilterComponent = ({ setSortParam, sortParam, setSortbyName2, sortbyName2, sortUpdatedAt2, setSortUpdatedAt2, sortCreatedAt2, setSortCreatedAt2, showFilter, setshowFilter, onCheckboxChange }) => {
  return (
    <div className={`w-full h-[300px] z-90 bottom-0 right-0 left-0 bg-[#FAF9F8] transition-all duration-200` + (showFilter ? ' fixed' : ' hidden')}>
        <div className='filterPopHeader h-[50px] border-b-2 border-[#00000033] px-4 flex flex-row justify-between items-center'>
            <p></p>
            <p>Filter and sort</p>
            <MdOutlineClose className='color-[#2D3648] w-6 h-6' onClick={() => setshowFilter(false)} />
        </div>
        <div className="h-[250px] w-full flex flex-col gap-6 px-6 justify-center">
            <div className='flex flex-col'>
                <div className="flex items-center mb-4">
                    <input id="member" onChange={(e) => onCheckboxChange(e.target.value)} type="checkbox" value={3} className="w-[24px] h-[24px] text-[#2D3648] bg-[#2D3648] border-r-[2.4px] border-gray-300 rounded focus:ring-[#2D3648]" />
                    <label htmlFor="member" className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Member</label>
                </div>
                <div className="flex items-center">
                    <input id="owner" onChange={(e) => onCheckboxChange(e.target.value)} type="checkbox" value={2} className="w-[24px] h-[24px] text-blue-600 bg-[#2D3648] border-r-[2.4px] border-gray-300 rounded focus:ring-[#2D3648]" />
                    <label htmlFor="owner" className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Owner/ Co-Owner</label>
                </div>
            </div>
            <div>
                <div className="flex justify-between items-center mb-4" onClick={() => {setSortParam("name"); setSortbyName2(!sortbyName2);}}>
                    <p className={`ml-2 text-sm font-medium text-gray-900 ${sortParam === 'name' ? 'activeFilterType' : 'inactiveFilterType'}`}>Name</p>
                    {sortbyName2 ? <AiOutlineUp /> : <AiOutlineDown />}
                </div>
                <div className="flex justify-between items-center mb-4" onClick={() => {setSortParam("updatedAt"); setSortUpdatedAt2(!sortUpdatedAt2);}}>
                    <p className={`ml-2 text-sm font-medium text-gray-900 ${sortParam === 'updatedAt' ? 'activeFilterType' : 'inactiveFilterType'}`}>Date Updated</p>
                    {sortUpdatedAt2 ? <AiOutlineUp /> : <AiOutlineDown />}
                </div>
                <div className="flex justify-between items-center mb-4" onClick={() => {setSortParam("createdAt"); setSortCreatedAt2(!sortCreatedAt2);}}>
                    <p className={`ml-2 text-sm font-medium text-gray-900 ${sortParam === 'createdAt' ? 'activeFilterType' : 'inactiveFilterType'}`}>Date Created</p>
                    {sortCreatedAt2 ? <AiOutlineUp /> : <AiOutlineDown />}
                </div>
            </div>
        </div>
    </div>
  )
}

export default FilterComponent