import React, { useEffect, useState } from 'react'
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { changeRole, getGroupMembers, getSingleGroup, removeUserGroup } from '../../actions/groupActions';
import { useDispatch, useSelector } from 'react-redux';
import { CHANGE_ROLE_RESET, REMOVE_UGROUP_RESET } from '../../constants/groupConstants';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { RoleMapper } from '../helpers/Mappers';
import MembersPop from '../popovers/MembersPop';
import { useNavigate, useParams } from 'react-router-dom';
import { MdOutlineArrowBackIos } from 'react-icons/md';

const GroupMembers = () => {
    const [showMode, setShowMode] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [recordForEdit, setRecordForEdit] = useState(null);
    const [role, setRole] = useState('');
    const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
    const { id } = useParams();
    const groupId = id;

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { user } = useSelector(state => state.auth);
    const { updatedRole, removedUser, loading } = useSelector(state => state.group);
    const { members } = useSelector(state => state.groupMembers);
    const { group } = useSelector(state => state.groupDetails);
    const creator = user?._id;

    const showToastMessage = (message) => {
        toast.success(message, {
            position: toast.POSITION.TOP_RIGHT
        });
    };

    useEffect(() => {
        dispatch(getGroupMembers(groupId));
        dispatch(getSingleGroup(id, creator));

        if (updatedRole) {
            showToastMessage("Changed Role successfully!");
            setShowMode(false);
            dispatch({ type: CHANGE_ROLE_RESET });
            dispatch(getGroupMembers(groupId));
        }
    }, [updatedRole, groupId]);

    useEffect(() => {

        if (removedUser) {
            showToastMessage("Removed User successfully!");
            setShowModal(false);
            dispatch({ type: REMOVE_UGROUP_RESET });
            group?.role === 3 ? navigate("/groups") : dispatch(getGroupMembers(groupId));
        }
    }, [removedUser]);

    useEffect(() => {
        selectedCheckboxes?.length ? setShowModal(true) : setShowModal(false);
    }, [selectedCheckboxes]);

    const editRoleHandler = (id) => {
        const user = creator;
        const group = groupId;
        dispatch(changeRole(id, group, user, role));
        setRole('');
    }

    const userRoleMapper = (idX) => {
        return RoleMapper.find(i => i?.key === (idX))?.value
    };

    const handleCheckboxChange = (event) => {
        const value = event.target.value;
        if (event.target.checked) {
            setSelectedCheckboxes([...selectedCheckboxes, value]);
        } else {
            setSelectedCheckboxes(selectedCheckboxes.filter((v) => v !== value));
        }
    };

    const handleSelectAll = (event) => {
        if (event.target.checked) {
            const allValues = members.filter((checkbox) => checkbox.role !== 2).map((checkbox) => checkbox.role !== 2 && checkbox.user._id);
            setSelectedCheckboxes(allValues);
        } else {
            setSelectedCheckboxes([]);
        }
    };

    const handleRemoveFromGroup = () => {
        const group = groupId;
        const users = selectedCheckboxes;
        dispatch(removeUserGroup(users, group))
    };

    const changeRoleHandler = (role) => {
        const group = groupId;
        const users = selectedCheckboxes;
        dispatch(changeRole(users, group, role));
    };

  return (
    <>
        <div className='flex md:hidden justify-between w-full items-center py-4 px-3 border-b border-gray-300'>
            <MdOutlineArrowBackIos onClick={() => navigate(`/${id}/settings`)} />
            <p>Members</p>
            <p></p>
        </div>
        <div className='memberrow w-full'>
            <div className='memberrowcontent w-full py-3 px-4'>
                <div className='flex flex-row gap-2'>
                    {(group?.role === 2 || group?.role === 4) ?
                    <input id="checkbox-all-search" type="checkbox" onChange={handleSelectAll} className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded" /> :
                    <div className='w-4 h-4'></div>}
                    <p className='memberTitle'>Name</p>
                </div>
                <p className='memberTitle'>Role</p>
                <p className='memberTitle'>Login Id</p>
            </div>
            {members && (
                members.map((member, index) =>
                <div className='memberrowcontent w-full py-3 px-4' key={index}>
                    <div className="group relative inline-block">
                        <div className='flex flex-row gap-2'>
                            {group?.role === 3 && (user?._id === member?.user?._id ?
                            <input id="checkbox-all-search" type="checkbox" value={member.user._id} checked={selectedCheckboxes.includes(member.user._id)} onChange={handleCheckboxChange} className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded" /> :
                            <div className='w-4 h-4'></div>)}
                            {(group?.role === 2 || group?.role === 4) && 
                            (member?.role === 2 ? <div className='w-4 h-4'></div> :
                            <input id="checkbox-all-search" type="checkbox" value={member.user._id} checked={selectedCheckboxes.includes(member.user._id)} onChange={handleCheckboxChange} className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded" />)}
                            <p className='memberDescription cursor-pointer'>{member?.user?.firstName + " " + member?.user?.lastName}</p>
                        </div>
                    </div>
                    
                    <p className='memberDescription'>{userRoleMapper(member.role)}</p>
                    <div className="group relative inline-block">
                        <div className='flex flex-row'>
                        <p className='memberDescription cursor-pointer'>{member?.user?.firstName + " " + member?.user?.lastName}</p>
                        </div>
                    </div>
                </div>
                )
            )}

            <MembersPop showModal={showModal} setShowModal={setShowModal} selectedCheckboxes={selectedCheckboxes} members={members} handleRemoveFromGroup={handleRemoveFromGroup} loading ={loading} changeRoleHandler={changeRoleHandler} />


        {showMode ? (
            <>
            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                <div className='joinpmodal2 w-[300px] md:w-[430px]'>
                    <div className='joinpmodalcontent py-4 w-full'>
                        <div className='joinpmodalheader w-full px-4'>
                            <p className='joinpmodalheaderight'>Change Role</p>
                            <AiOutlineCloseCircle className='joinpmodalheaderleft cursor-pointer' onClick={() => setShowMode(false)} />
                        </div>
                        <div className='joinpmodalbodydiv w-full px-4'>
                            <p className='joinpwords'>{recordForEdit.user.firstName + " " + recordForEdit.user.lastName}</p>
                            <p className='joinpwords'>{userRoleMapper(recordForEdit.role)}</p>
                        </div>
                        <div className='joinpmodalbodydiv2 w-full px-4'>
                        <label htmlFor="underline_select" className="sr-only">Change Role</label>
                        <select id="underline_select" value={role} onChange={(e) => setRole(e.target.value)} className="block py-2.5 px-0 w-full text-sm text-gray-500 bg-transparent border-0 border-b-2 border-gray-200 appearance-none dark:text-gray-400 dark:border-gray-700 focus:outline-none focus:ring-0 focus:border-gray-200 peer">
                            <option selected>Choose a role</option>
                            <option value="3">Member</option>
                            <option value="4">Editor</option>
                        </select>
                        </div>
                        <div className='joinpmodalbody2 w-full px-4'>
                                
                                <button className='joinpmodalsubmit2' onClick={() => editRoleHandler(recordForEdit._id)}>Change</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
            </>
        ) : null}
        </div>
    </>
  )
}

export default GroupMembers