import React, { Fragment, useState } from 'react';
import { Link } from 'react-router-dom';
import Default from '../../assets/groupdefault.jpg';
import { RiUserSettingsLine } from 'react-icons/ri';
import { RoleMapper } from '../helpers/Mappers';
import { useDispatch, useSelector } from 'react-redux';
import { joinGroup } from '../../actions/groupActions';
import JoinModal from '../popovers/JoinModal';
import { BiLock } from 'react-icons/bi';
import { HiEye } from 'react-icons/hi';

const baseUrl = process.env.REACT_APP_IMAGES_BASEURL;

const GroupCard = ({ group, joinedGroup, gusers }) => {
    const [showJoin2, setShowJoin2] = useState(false);
    const [selectedGroupId, setSelectedGroupId] = useState('');
    const [gpassword, setGpassword] = useState('');

    const { error: joinError } = useSelector(state => state.group);
    const { user } = useSelector(state => state.auth);

    const dispatch = useDispatch();

    const userRoleMapper = (idX) => {
        return RoleMapper.find(i => i?.key === (idX))?.value
    };
    const groupLink = `/group/${group?.group?._id}`;

    const joinGroupHandler = (group) => {
        const student = user?._id
        dispatch(joinGroup(student, group, gpassword));
    };
    
    const backImage = joinedGroup ?
    group?.group?.groupImage?.url ? (group?.group?.groupImage?.public_id === null ? ((baseUrl + group?.group?.groupImage?.url)) : group?.group?.groupImage?.url) : Default :
    group?.groupImage?.url ? (group?.groupImage?.public_id === null ? ((baseUrl + group?.groupImage?.url)) : group?.groupImage?.url) : Default;
    const author = group?.creator?.firstName + " " + group?.creator?.lastName;

  return (
    <Fragment>
        <Link 
            to={`${joinedGroup ? groupLink : ''}`} 
            onClick={() => {!joinedGroup && setShowJoin2(true); setSelectedGroupId(group?._id);}}
            className="w-full h-[180px] px-4 py-5 bg-center bg-no-repeat bg-cover rounded-[12px] hover:scale-105 duration-300 linear-bg" style={{
                backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url(${backImage})`
            }}
        >
            <div className='relative h-full w-full'>
                <p className='groupAuthor' translate="no">{group?.creator?.firstName + " " + group?.creator?.lastName}</p>
                <p className='groupTitleNew flex items-center gap-2' translate="no">{joinedGroup ? group?.group?.name : group?.name} {group?.isPrivate && <BiLock />} {group?.group?.isPrivate && <HiEye />}</p>
                <div className='absolute bottom-0 w-full'>
                    <div className='flex justify-between w-full'>
                        <div className={`${joinedGroup ? 'flex flex-row' : 'hidden'} justify-center items-center groupRoleDiv px-3 py-1`}>
                            <RiUserSettingsLine className='w-[12px] h-[12px]' />
                            <p>{userRoleMapper(group?.role)}</p>
                        </div>
                        <div className={`${joinedGroup ? 'hidden' : 'block'}`}></div>
                        {joinedGroup && <div className={`flex flex-row gap-[4px]`}>
                            {gusers?.slice(0, 3).map((ma, idx) =>
                                <img src={ma?.user?.avatar?.url ? (ma?.user?.avatar?.public_id === null ? (baseUrl + ma?.user?.avatar?.url) : ma?.user?.avatar?.url) : Default} key={idx} className="w-[24px] h-[24px] rounded-full" alt='user avatar' />
                            )}
                            {gusers?.length > 3 &&
                            (
                                gusers?.length === 4 ?
                                <img src={gusers[3]?.user?.avatar?.url ? (gusers[3]?.user?.avatar?.public_id === null ? (baseUrl + gusers[3]?.user?.avatar?.url) : gusers[3]?.user?.avatar?.url) : Default} className="w-[24px] h-[24px] rounded-full" alt='user avatar' /> :
                                <div className='w-[24px] h-[24px] rounded-full bg-white addText flex flex-row justify-center items-center gap-2'>+ {gusers.length - 3}</div>
                            )}
                        </div>}
                        {!joinedGroup && <div className={`flex flex-row gap-[4px]`}>
                            <img src={group?.creator?.avatar?.url ? (group?.creator?.avatar?.public_id === null ? (baseUrl + group?.creator?.avatar?.url) : group?.creator?.avatar?.url) : Default} className="w-[24px] h-[24px] rounded-full" alt='user avatar' />
                            {gusers.length > 1 && <div className='w-[24px] h-[24px] rounded-full bg-white addText flex flex-row justify-center items-center gap-2'>
                                + {gusers.length - 1}
                            </div>}
                        </div>}
                    </div>
                </div>
            </div>            
        </Link>
        <JoinModal backImage={backImage} selectedGroupId={selectedGroupId} requirePass={group?.isPrivate} author={author} showJoin2={showJoin2} joinError={joinError} setShowJoin2={setShowJoin2} gpassword={gpassword} setGpassword={setGpassword} selectedGroupName={group?.name} joinGroupHandler={joinGroupHandler} />
    </Fragment>
  )
}

export default GroupCard