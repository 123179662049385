import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import Default from '../../../assets/default.png';
import Sidebar from './Sidebar'
import { CREATE_ADMIN_RESET } from '../../../constants/adminConstants';
import { createAdmin } from '../../../actions/adminActions';

import DefaultLogo from '../../../assets/smilelogo.png';
import { FaLock } from 'react-icons/fa';
import ReactGA from 'react-ga4';

const CreateAdmins = () => {

    const [user, setUser] = useState({
        firstName: '',
        lastName: '',
        username: '',
        email: '',
        password: '',
        confirmPassword: '',
        roleId: 2
    });

    const [errr, setErrr] = useState({
        username: '',
        password: '',
        confirmPassword: ''
    });

    const { firstName, lastName, username, email, password, confirmPassword, roleId } = user;

    const [avatar, setAvatar] = useState('');
    const [avatarPreview, setAvatarPreview] = useState(Default);

    const dispatch = useDispatch();
    const location = useLocation();

    const { createdAdmin, error, loading } = useSelector(state => state.countStats);
    const { permissions } = useSelector(state => state.auth);

    const isAllowed = permissions?.find((element) => element?.levelModulePermissionId?.name === 'Admin.Users.Write');

    let navigate = useNavigate();

    useEffect(() => {

        if(createdAdmin) {
        dispatch({ type: CREATE_ADMIN_RESET });
        navigate("/admin/all/admins", { replace: true });
        }

    }, [dispatch, createdAdmin]);

    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: (location.pathname + location.search), title: "Create Admin" });
    }, [location]);

    const submitHandler = (e) => {
        e.preventDefault();
        if (email && password && username && password === confirmPassword) {
        const formData = new FormData();
        formData.set('firstName', firstName);
        formData.set('lastName', lastName);
        formData.set('username', username);
        formData.set('email', email);
        formData.set('password', password);
        formData.set('avatar', avatar);
        formData.set('roleId', roleId);
        dispatch(createAdmin(formData));
        }
    }

    const onChange = e => {
        if(e.target.name === 'avatar') {
        const reader = new FileReader();
        reader.onload = () => {
            if (reader.readyState === 2) {
                setAvatarPreview(reader.result)
            }
        }
        reader.readAsDataURL(e.target.files[0])
        setAvatar(e.target.files[0])
        } else {
            setUser({ ...user, [e.target.name] : e.target.value });
            validateInput(e);
        }
    }

const validateInput = e => {
    let { name, value } = e.target;
    setErrr(prev => {
      const stateObj = { ...prev, [name]: "" };
 
      switch (name) {
        case "username":
          if (!value) {
            stateObj[name] = "Please enter Username.";
          }
          break;
 
        case "password":
          if (!value) {
            stateObj[name] = "Please enter Password.";
          } else if (user.confirmPassword && value !== user.confirmPassword) {
            stateObj["confirmPassword"] = "Password and Confirm Password do not match.";
          } else {
            stateObj["confirmPassword"] = user.confirmPassword ? "" : errr.confirmPassword;
          }
          break;
 
        case "confirmPassword":
          if (!value) {
            stateObj[name] = "Please enter Confirm Password.";
          } else if (user.password && value !== user.password) {
            stateObj[name] = "Password and Confirm Password do not match.";
          }
          break;
 
        default:
          break;
      }
 
      return stateObj;
    });
  }

  return (
    <div class="m-0 font-sans text-base antialiased font-normal leading-default text-slate-500">

    <main class="relative h-full max-h-screen transition-all duration-200 ease-in-out xl:ml-68 rounded-xl">
      <nav class="fixed top-0 flex flex-wrap items-center justify-between px-0 py-2 mx-6 transition-all ease-in shadow-none duration-250 rounded-2xl lg:flex-nowrap lg:justify-start" navbar-main navbar-scroll="false">
        <div class="flex items-center justify-between w-full px-4 py-1 mx-auto flex-wrap-inherit">
          <nav>
          <Link to="/admin/dashboard">
              <img className='w-12' src={DefaultLogo} />
          </Link>
          </nav>
        </div>
      </nav>

      <Sidebar />

        {isAllowed ? <div className='mt-[80px] ml-14 mb-10 md:ml-64 px-6 py-6 mx-auto'>
            <h4 class="mb-4 text-lg font-semibold text-gray-600">Create Admin</h4>

            <div className='registerscreen'>
            <div className='profilediv'>
            <div className='profileimg' style={{ 
                backgroundImage: `url(${avatarPreview})`,
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat'
                }}>
            </div>
            <input type="file" id="avatar" name="avatar" onChange={onChange} accept='image/*' required />
            <p className='uploadinfo'>Upload profile picture</p>
            </div>
            
            <div className='formelements'>
                <form onSubmit={submitHandler} encType='multipart/form-data'>
                    {error?<p className='errormsg'>{error}</p>:null}
                    <label for="firstName" className='fnamelabel asterisk_input'>First name:</label>
                    <input type="text" id="firstName" name="firstName" value={firstName} onChange={onChange} required />
                    <label for="lastName">Last name:</label>
                    <input type="text" id="lastName" name="lastName" value={lastName} onChange={onChange} onBlur={validateInput} />
                    <label for="username" className='asterisk_input'>Username:</label>
                    <input type="text" id="username" name="username" value={username} onChange={onChange} required />
                    {errr.username && <span className='err'>{errr.username}</span>}
                    <label for="lname" className='asterisk_input'>Email:</label>
                    <input type="email" id="email" name="email" value={email} onChange={onChange} required />
                    <label for="password" className='asterisk_input'>Password:</label>
                    <input type="password" id="password" name="password" value={password} onChange={onChange} onBlur={validateInput} required />
                    {errr.password && <span className='err'>{errr.password}</span>}
                    <label for="confirmpassword">Confirm Password:</label>
                    <input type="password" name="confirmPassword" id="confirmPassword" value={confirmPassword} onChange={onChange} onBlur={validateInput} required />
                    {errr.confirmPassword && <span className='err'>{errr.confirmPassword}</span>}
                    <button type='submit' className='signupsubmitbtn'>
                    {loading ? 
                        <>
                        <svg role="status" class="inline mr-3 w-4 h-4 text-white animate-spin" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#E5E7EB"/>
                        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentColor"/>
                        </svg> Create
                        </>: 'Create'}
                    </button>
                </form>
                

            </div>
        </div>
        </div> :
        <div className='mt-[80px] pt-[50px] ml-14 mb-10 md:ml-64 flex flex-col items-center h-full justify-center'>
            <h4 className="text-lg font-semibold mb-6 text-gray-600">Forbidden 403</h4>
            <FaLock className='w-[300px] h-[300px]' />
            <h4 className="text-lg font-semibold text-gray-600">Access denied!</h4>
            <p className=" text-gray-600 font-light">Please check with the site admin if you believe this is a mistake.</p>
        </div>}
    </main>
    </div>
  )
}

export default CreateAdmins