import React, { useEffect, useRef, useState } from 'react';
import { MdAddAPhoto, MdOutlineArrowBackIos } from 'react-icons/md';
import { useNavigate, useParams, Link } from 'react-router-dom';
import { ChoiceMapper } from '../helpers/Mappers';
import { CgTrashEmpty } from 'react-icons/cg';
import { FaPlus } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { createQuestion, editQuestion, getQuestionDetails } from '../../actions/questionActions';
import { EDIT_QUESTION_RESET, NEW_QUESTION_RESET } from '../../constants/questionConstants';
import { getActivityKeywords } from '../../actions/activityActions';
import io from "socket.io-client";

const ENDPOINT = process.env.REACT_APP_ENDPOINT;
const baseUrl = process.env.REACT_APP_IMAGES_BASEURL;
let socket;

const EditQuestion = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { id, activityId, questionId } = useParams();
    const inputRef = React.createRef();
    const formRef = useRef(null);

    const [opensection, setOpenSection] = useState(1); 
    const [inputFields, setInputFields] = useState([{choice: ''}]);
    const [correctAnswerError, setCorrectAnswerError] = useState(false);
    const [choicesError, setChoicesError] = useState(false);
    const [content, setContent] = useState("");
    const [startTime, setStartTime] = useState(null);
    const [questionImage, setQuestionImage] = useState('');
    const [endTime, setEndTime] = useState(null);
    const [avatarPreview, setAvatarPreview] = useState('');
    const [qimageError, setqimageError] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [lockChange, setLockChange] = useState(false);

    const { user } = useSelector(state => state.auth);
    const { editedQuestion } = useSelector(state => state?.newQuestion);
    const { questionKeywords } = useSelector(state => state?.activity);
    const { question, comments, responseChoices, loading, error } = useSelector(state => state.questionDetails);

    useEffect(() => {
        socket = io(ENDPOINT);
    }, []);

    useEffect(() => {
        function handleResize() {
            if (window.innerWidth >= 768) { 
                navigate(`/group/${id}`);
            }
        }
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        if (activityId) {
            dispatch(getActivityKeywords(activityId));
        }
    }, [activityId]);

    useEffect(() => {
        if (activityId && questionId) {
            dispatch(getQuestionDetails(questionId));
        }
    }, [activityId, questionId]);

    const alphabetMapperReverse = (idX) => {
        return ChoiceMapper.find(i => i?.value === idX)?.key - 1
    };
    const formElements = formRef?.current?.elements;

    useEffect(() => {
        if (question) {
            setContent(question?.content);
            setOpenSection(question?.questionType === "multiple_choice" ? 2 : 1);
            if (question?.questionType === "multiple_choice") {
                setInputFields(question?.choice);  
            }
            if (question.correctAnswer) {
                for (const answ of question.correctAnswer) {
                    for (let i = 0; i < formElements?.length; i++) {
                        const element = formElements[i];
                        if (element.type === "checkbox") {
                            if (answ === element.value) {
                                element.checked = true;
                            }
                        }
                    }
                }
            }
            if (question?.questionImage?.url) {
                setAvatarPreview((baseUrl + question?.questionImage?.url))
            }
            if (question?.questionType === "multiple_choice" && (responseChoices?.length > 0 || comments?.length > 0)) {
                setLockChange(true);
            }
            if (question?.questionType !== "multiple_choice" && comments?.length > 0) {
                setLockChange(true);
            }
        }
    }, [question]);

    useEffect(() => {
        if (editedQuestion) {
            dispatch({ type: EDIT_QUESTION_RESET });
            navigate(`/${id}/${activityId}`)
        }
    }, [editedQuestion]);

    const alphabetMapper = (idX) => {
        return ChoiceMapper.find(i => i?.key === (idX + 1))?.value
    };

    const handleFormChange = (index, event) => {
        let data = [...inputFields];
        data[index][event.target.name] = event.target.value;
        setInputFields(data);
    };

    const addFields = () => {
        let newfield = { choice: '' }
    
        setInputFields([...inputFields, newfield])
    };

    const removeFields = (index) => {
        let data = [...inputFields];
        data.splice(index, 1);
        setInputFields(data);
    };

    const createQuestionHandler = (event) => {
        event.preventDefault();
        let questionType;
        let choices = [];
        let correctAnswers = [];
        const formElements = event.target.elements;
        const newCheckedItems = [];

        for (let i = 0; i < formElements.length; i++) {
            const element = formElements[i];
            if (element.type === "checkbox" && element.checked) {
                newCheckedItems.push(element.value);
            }
        }
        {opensection === 2 ? questionType = "multiple_choice" : questionType = "open_ended"};
        const MAX_FILE_SIZE = 1024 * 1024 * 10;
        if(questionImage.size > MAX_FILE_SIZE){
            setqimageError("File size cannot exceed 10Mbs");
            return
        } else {
            setqimageError(false);
        }
        if(opensection === 2 && (inputFields.length <= 1)){
            setChoicesError("Please create at least two choices");
            return
        } else {
            setChoicesError(false);
        };
        if(opensection === 2 && (newCheckedItems.length === 0)){
            setCorrectAnswerError("Please select correct answer for your question");
            return
        } else {
            setCorrectAnswerError(false);
        };
        {opensection === 2 ? choices=JSON.stringify(inputFields) : choices=[]}
        {opensection === 2 ? correctAnswers=JSON.stringify(newCheckedItems) : correctAnswers=[]}
        const qKeywords = questionKeywords?.map(k => k?.keyword);
        dispatch(editQuestion({ id: questionId, questionType, choices, content, correctAnswers, questionImage }));
        setSubmitted(true);
    };

    const handleKeyDown = () => {
        if (!startTime) {
            setStartTime(Date.now());
        }
        setEndTime(Date.now());
    };

    const onChange = e => {
        if(e.target.name === 'qrimage') {
            const reader = new FileReader();
            reader.onload = () => {
                if (reader.readyState === 2) {
                    setAvatarPreview(reader.result)
                }
            }
            reader.readAsDataURL(e.target.files[0])
            setQuestionImage(e.target.files[0]);
        } 
    };

  return (
    <div>
        <div className={'flex justify-between w-full items-center py-4 px-3 border-b border-gray-300'}>
            <MdOutlineArrowBackIos onClick={() => navigate(`/${id}/${activityId}`)} /> 
            <p className='newQuizHeader'>Edit Question</p>
            <p></p>
        </div>
        <form ref={formRef} className='p-4' onSubmit={createQuestionHandler}>
            <p className='newQuizSubHeader pt-4 pb-1'>Question Type</p>
            <div className='w-full flex flex-row border-solid border-2 border-[#CBD2E0] rounded-lg'>
                <div onClick={() => {(!lockChange && setOpenSection(1)); (!lockChange && setInputFields([ {choice: ''} ])); }} className={`w-1/2 flex items-center justify-center newQuizSmall py-1 text-[#2D3648] rounded-l-lg ${opensection === 1 && 'bg-[#7AA6FF] text-[#FFFFFF]'}`}>
                    Open Ended
                </div>
                <div onClick={() => {(!lockChange && setOpenSection(2))}} className={`w-1/2 flex items-center justify-center newQuizSmall py-1 text-[#2D3648] rounded-r-lg ${opensection === 2 && 'bg-[#7AA6FF] text-[#FFFFFF]'}`}>
                    Multiple Choice
                </div>
            </div>
            <p className='newQuizSubHeader pt-6'>Enter Question</p>
            {questionKeywords?.length > 0 && 
            <>
                <p className='py-1 prompterWordsLabel'>Words selected for you to use in your question are:</p>
                <div className='flex flex-wrap prompterBubblesText gap-2 pt-1 pb-3'>
                    {questionKeywords?.map((keyword, index) => 
                        <p key={index} className='border px-2 border-[#CBD2E0] rounded-[28px]' translate="no">{keyword?.keyword}</p>
                    )}
                </div>
            </>
            }
            <textarea translate="no" onKeyDown={handleKeyDown} value={content} onChange={(e) => {setContent(e.target.value);}} className='w-full border-[#CBD2E0] rounded-[6px] border-2 p-4 newQuizInput' rows="4"></textarea>
            {submitted && <p className='text-sm text-red-600'>{error}</p>}
            <p className='newQuizSubHeader pt-6 pb-1'>Upload an Image</p>
            <div className='flex flex-row justify-center items-center w-full mb-2'>
                <div className="flex justify-center items-center h-[150px] w-full rounded-lg border-2 bg-gray-100 border-gray-100" style={{ 
                        backgroundImage: `url(${avatarPreview})`,
                        backgroundPosition: 'center',
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat'
                    }}>
                    <div className="absolute">
                        <div className="flex flex-col items-center">
                            <MdAddAPhoto className='w-9 h-9' />
                            <span className="block text-gray-400 font-normal">Add Photo</span>
                        </div>
                    </div>
                    <input type="file" id="qrimage" name="qrimage" onChange={onChange} ref={inputRef} className="h-full w-full opacity-0" />
                </div>
            </div>
            {qimageError && <p className='text-sm text-red-600'>{qimageError}</p>}
            <div className={opensection === 2 ? "mpchoicesdiv w-full" : "hidden"}>
                {inputFields.map((input, idX) => 
                (
                    <div key={idX} className="w-full">
                        <div className='mpchoicediv w-full'>
                            <p className='mpchoiceA'>{alphabetMapper(idX)}</p>
                            <input translate="no" name='choice' placeholder='Choice' value={input.choice} onChange={event => handleFormChange(idX, event)} className='mpchoiceinput rounded-[10px] h-[39px] px-[16px] border-[#E8E8E8] py-[8px] bg-[#2EC56E] w-full' />
                            <input type='checkbox' className='accent-[#2EC56E] text-white' value={alphabetMapper(idX)} />
                            <CgTrashEmpty className='icon5' onClick={() => removeFields(idX)} />
                        </div>
                    </div>
                )
                )}
                {choicesError && <p className='text-sm text-red-600'>{choicesError}</p>}
                <Link to='' onClick={addFields} className='mpaddchoice'>
                    <FaPlus className='icon5' />
                    <p>Answer option</p>
                </Link>
                {correctAnswerError && <p className='text-sm text-red-600'>{correctAnswerError}</p>}
                {/* <div className="flex justify-center w-full">
                    <div className="mb-3 w-full">
                        <select value={correctAnswer} onChange={(e) => setCorrectAnswer(e.target.value)} className="form-select appearance-none block w-full px-3  py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none" aria-label="Select option">
                            <option>Select correct answer choice</option>
                            {inputFields.map((input, index) => {
                                return (
                                    <option key={alphabetMapper(index)} value={alphabetMapper(index)}>{alphabetMapper(index)}</option>
                                )
                            })}
                        </select>
                    </div>
                </div> */}
                </div>
            <div className="mt-6 flex flex-col items-center">
                <button className='w-[230px] h-10 mb-3 bg-[#CBD2E0] rounded-[20px]' type='submit'>
                    {loading && <svg role="status" className="inline mr-3 w-4 h-4 text-white animate-spin" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#E5E7EB"/>
                        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentColor"/>
                    </svg>}
                    Submit Question
                </button>
                <Link to={`/${id}/${activityId}`} className='w-[230px] h-10 flex justify-center items-center border rounded-[20px]' type='button'>Cancel</Link>
            </div>
        </form>
    </div>
  )
}

export default EditQuestion