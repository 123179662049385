export const NEW_RESPONSE_REQUEST = 'NEW_RESPONSE_REQUEST';
export const NEW_RESPONSE_SUCCESS = 'NEW_RESPONSE_SUCCESS';
export const NEW_RESPONSE_RESET = 'NEW_RESPONSE_RESET';
export const NEW_RESPONSE_FAIL = 'NEW_RESPONSE_FAIL';

export const GET_RESPONSES_REQUEST = 'GET_RESPONSES_REQUEST';
export const GET_RESPONSES_SUCCESS = 'GET_RESPONSES_SUCCESS';
export const GET_RESPONSES_FAIL = 'GET_RESPONSES_FAIL';

export const DELETE_COMMENT_REQUEST = 'DELETE_COMMENT_REQUEST';
export const DELETE_COMMENT_SUCCESS = 'DELETE_COMMENT_SUCCESS';
export const DELETE_COMMENT_RESET = 'DELETE_COMMENT_RESET';
export const DELETE_COMMENT_FAIL = 'DELETE_COMMENT_FAIL';

export const NEW_RCHOICE_REQUEST = 'NEW_RCHOICE_REQUEST';
export const NEW_RCHOICE_SUCCESS = 'NEW_RCHOICE_SUCCESS';
export const NEW_RCHOICE_RESET = 'NEW_RCHOICE_RESET';
export const NEW_RCHOICE_FAIL = 'NEW_RCHOICE_FAIL';

export const EDIT_RESPONSE_REQUEST = 'EDIT_RESPONSE_REQUEST';
export const EDIT_RESPONSE_SUCCESS = 'EDIT_RESPONSE_SUCCESS';
export const EDIT_RESPONSE_RESET = 'EDIT_RESPONSE_RESET';
export const EDIT_RESPONSE_FAIL = 'EDIT_RESPONSE_FAIL';

export const CLEAR_ERRORS = 'CLEAR_ERRORS'