import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { logout } from '../../../actions/userActions';
import { AiOutlineEdit } from 'react-icons/ai';
import { BiLogOut } from 'react-icons/bi';
import { BsActivity, BsQuestionCircleFill } from 'react-icons/bs';
import { HiOutlineDocumentReport } from 'react-icons/hi';
import { FaUserTag } from 'react-icons/fa';

const Sidebar = () => {
    const dispatch = useDispatch();
    const { user } = useSelector(state => state.auth);

    const logoutHandler = () => {
        dispatch(logout());
    }

  return (
    <div className="fixed flex flex-col top-[80px] left-0 w-14 hover:w-64 md:w-64 bg-gray-700 dark:bg-gray-900 h-full text-white transition-all duration-300 border-none z-10 sidebar">
        <div className="overflow-y-auto overflow-x-hidden flex flex-col justify-between flex-grow">
            <ul className="flex flex-col py-4 space-y-1">
                <li className="px-5 hidden md:block">
                    <div className="flex flex-row items-center h-8">
                        <div className="text-sm text-white uppercase">{user?.firstName + " " + user?.lastName}</div>
                    </div>
                </li>
                <li>
                    <Link to={"/admin/dashboard"} className="relative flex flex-row items-center h-11 focus:outline-none hover:bg-[#8C1515] dark:hover:bg-gray-600 text-white-600 hover:text-white-800 border-l-4 border-transparent dark:hover:border-gray-800 pr-6">
                    <span className="inline-flex justify-center items-center ml-4">
                        <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"></path></svg>
                    </span>
                    <span className="ml-2 text-sm tracking-wide truncate">Dashboard</span>
                    </Link>
                </li>
                <li>
                    <Link to={"/admin/edit/profile"} className="relative flex flex-row items-center h-11 focus:outline-none hover:bg-[#8C1515] dark:hover:bg-gray-600 text-white-600 hover:text-white-800 border-l-4 border-transparent dark:hover:border-gray-800 pr-6">
                    <div className="inline-flex justify-center items-center ml-4">
                    <AiOutlineEdit className="w-5 h-5" />
                    </div>
                    <span className="ml-2 text-sm tracking-wide truncate">Edit Profile</span>
                    </Link>
                </li>
                <li>
                    <Link to="/admin/all/admins" className="relative flex flex-row items-center h-11 focus:outline-none hover:bg-[#8C1515] dark:hover:bg-gray-600 text-white-600 hover:text-white-800 border-l-4 border-transparent dark:hover:border-gray-800 pr-6">
                    <span className="inline-flex justify-center items-center ml-4">
                        <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"></path></svg>
                    </span>
                    <span className="ml-2 text-sm tracking-wide truncate">Admins</span>
                    </Link>
                </li>
                <li>
                    <Link to="/admin/users" className="relative flex flex-row items-center h-11 focus:outline-none hover:bg-[#8C1515] dark:hover:bg-gray-600 text-white-600 hover:text-white-800 border-l-4 border-transparent dark:hover:border-gray-800 pr-6">
                    <span className="inline-flex justify-center items-center ml-4">
                        <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"></path></svg>
                    </span>
                    <span className="ml-2 text-sm tracking-wide truncate">Users</span>
                    </Link>
                </li>
                <li>
                    <Link to="/admin/activities" className="relative flex flex-row items-center h-11 focus:outline-none hover:bg-[#8C1515] dark:hover:bg-gray-600 text-white-600 hover:text-white-800 border-l-4 border-transparent dark:hover:border-gray-800 pr-6">
                    <span className="inline-flex justify-center items-center ml-4">
                        <BsActivity className="w-5 h-5" />
                    </span>
                    <span className="ml-2 text-sm tracking-wide truncate">Activities</span>
                    </Link>
                </li>
                <li>
                    <Link to="/admin/groups" className="relative flex flex-row items-center h-11 focus:outline-none hover:bg-[#8C1515] dark:hover:bg-gray-600 text-white-600 hover:text-white-800 border-l-4 border-transparent dark:hover:border-gray-800 pr-6">
                    <span className="inline-flex justify-center items-center ml-4">
                        <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"></path></svg>
                    </span>
                    <span className="ml-2 text-sm tracking-wide truncate">Groups</span>
                    </Link>
                </li>
                <li>
                    <Link to="/admin/roles" className="relative flex flex-row items-center h-11 focus:outline-none hover:bg-[#8C1515] dark:hover:bg-gray-600 text-white-600 hover:text-white-800 border-l-4 border-transparent dark:hover:border-gray-800 pr-6">
                    <div className="inline-flex justify-center items-center ml-4">
                        <FaUserTag className="w-5 h-5" />
                    </div>
                    <span className="ml-2 text-sm tracking-wide truncate">Roles</span>
                    </Link>
                </li>

                <li>
                    <Link to="/admin/questions" className="relative flex flex-row items-center h-11 focus:outline-none hover:bg-[#8C1515] dark:hover:bg-gray-600 text-white-600 hover:text-white-800 border-l-4 border-transparent dark:hover:border-gray-800 pr-6">
                    <div className="inline-flex justify-center items-center ml-4">
                        <BsQuestionCircleFill className="w-5 h-5" />
                    </div>
                    <span className="ml-2 text-sm tracking-wide truncate">Questions</span>
                    </Link>
                </li>
                <li>
                    <Link to="/admin/answers" className="relative flex flex-row items-center h-11 focus:outline-none hover:bg-[#8C1515] dark:hover:bg-gray-600 text-white-600 hover:text-white-800 border-l-4 border-transparent dark:hover:border-gray-800 pr-6">
                    <div className="inline-flex justify-center items-center ml-4">
                        <HiOutlineDocumentReport className="w-5 h-5" />
                    </div>
                    <span className="ml-2 text-sm tracking-wide truncate">Responses</span>
                    </Link>
                </li>

                <li>
                    <a onClick={logoutHandler} className="relative flex flex-row cursor-pointer items-center h-11 focus:outline-none hover:bg-[#8C1515] dark:hover:bg-gray-600 text-white-600 hover:text-white-800 border-l-4 border-transparent dark:hover:border-gray-800 pr-6">
                        <div className="inline-flex justify-center items-center ml-4">
                            <BiLogOut className="w-5 h-5" />
                        </div>
                        <span className="ml-2 text-sm tracking-wide truncate">Logout</span>
                    </a>
                </li>
            </ul>
        </div>
      </div>
  )
}

export default Sidebar