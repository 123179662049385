import React, { useContext } from 'react';
import GroupLayout, { LayoutContext } from '../../components/layouts/group/GroupLayout';
import Question from '../../components/Question/Question';
import QuestionDesktop from '../../components/Question/QuestionDesktop';

function GroupToggle(props) {
    const { isMobile } = useContext(LayoutContext);

  return (
    <div>
        {isMobile ? (
            // Show multi-page app on mobile
            <Question />
        ) : (
            // Show single-page app on desktop
            <QuestionDesktop />
        )}
    </div>
  );
}

export default GroupLayout(GroupToggle);