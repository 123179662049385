import React, { useEffect } from 'react';
import './Modal.css';
import { BiLock } from 'react-icons/bi';
import { useSelector } from 'react-redux';

const JoinModal = ({ backImage, requirePass, author, showJoin2, setShowJoin2, gpassword, setGpassword, selectedGroupName, selectedGroupId, joinGroupHandler, joinError }) => {
    const { hasJoined } = useSelector(state => state.group);
    useEffect(() => {
        if (hasJoined) {
            setShowJoin2(false);
        }
    }, [hasJoined]);

  return (
    <>
    {showJoin2 && 
    <>
        <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className='groupModal w-[300px] md:w-[430px]'>
                <div>
                    <p className='groupModalTitle'>Enter Group</p>
                </div>
                <div className='h-[130px] md:h-[180px] bg-cover bg-no-repeat w-full p-6' style={{
                    backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url(${backImage})`
                }}>
                    <p className='groupModalCreator'>{author}</p>
                    <p className='groupModalName flex'>{selectedGroupName} {requirePass && <BiLock />}</p>
                </div>
                <div className='w-full px-6 pb-6'>
                    {requirePass && joinError?<p className='errormsg'>{joinError}</p>:null}
                    {requirePass && 
                    <>
                    <p className='groupModalLabel pb-1'>Password</p>
                    <input type="text" value={gpassword} onChange={(e) => setGpassword(e.target.value)} className='w-full groupModalInput p-2' />
                    </>}
                    <div className='pt-4 flex justify-between'>
                        <button className='groupModalJoin' 
                            onClick={() => {joinGroupHandler(selectedGroupId, gpassword)}}
                        >Join</button>
                        <button className='groupModalCancel' onClick={() => setShowJoin2(false)}>Cancel</button>
                    </div>
                </div>
            </div> 
        </div>
        <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>}
    </>
  )
}

export default JoinModal