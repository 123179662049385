import React, { useEffect, useState } from 'react';
import MobileLayout from '../layouts/MobileLayout';
import { MdKeyboardArrowLeft } from 'react-icons/md';
import { useNavigate, useParams } from 'react-router-dom';
import Default from '../../assets/groupdefault.jpg';
import { FiStar } from 'react-icons/fi';
import { deleteQuestion, getQuestionDetails, rateQuestion } from '../../actions/questionActions';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { getActivityRubric, getSingleActivity } from '../../actions/activityActions';
import RateQuestion from '../popovers/RateQuestion';
import CommentQuestion from '../popovers/CommentQuestion';
import { createResponse, createResponseChoice, deleteComment, editResponse } from '../../actions/responseActions';
import { DELETE_COMMENT_RESET, EDIT_RESPONSE_RESET, NEW_RCHOICE_RESET, NEW_RESPONSE_RESET } from '../../constants/responseConstants';
import { DELETE_QUESTION_RESET, RATE_QUESTION_RESET } from '../../constants/questionConstants';
import CheckAnswer from '../popovers/CheckAnswer';
import { ChoiceMapper } from '../helpers/Mappers';
import { AiOutlineComment, AiOutlineStop } from 'react-icons/ai';
import { BiLockAlt } from 'react-icons/bi';
import { HiOutlineDotsHorizontal } from 'react-icons/hi';
import { getSingleGroup } from '../../actions/groupActions';
import ModeComponent from '../activity/ModeComponent';
import { CHANGE_LEVEL_RESET } from '../../constants/activityConstants';
import io from "socket.io-client";
import ConfirmDeleteComment from '../popovers/ConfirmDeleteComment';
import ActionsPop from '../popovers/ActionsPop';
import QuestionActions from '../popovers/QuestionActions';
import QuestionConfirm from '../popovers/QuestionConfirm';
import StarIcon from '../../assets/star.svg';

const baseUrl = process.env.REACT_APP_IMAGES_BASEURL;
const ENDPOINT = process.env.REACT_APP_ENDPOINT;
let socket;

const SingleQuestion = () => {
    const { id, activityId, questionId } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [answer, setAnswer] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [showCommentModal, setShowCommentModal] = useState(false);
    const [answerStartTime, setAnswerStartTime] = useState(null);
    const [typeStartTime, setTypeStartTime] = useState(null);
    const [typeEndTime, setTypeEndTime] = useState(null);
    const [rating, setRating] = useState(0);
    const [selectedChoice, setSelectedChoice] = useState([]);
    const [showCheck, setShowCheck] = useState(false);
    const [correct, setCorrect] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [selectedComment, setSelectedComment] = useState(null);
    const [showActions, setShowActions] = useState(false);
    const [showQuestionActions, setShowQuestionActions] = useState(false);
    const [questionConfirm, setQuestionConfirm] = useState(false);

    const { user } = useSelector(state => state.auth);
    const { question, comments, responseChoices, loading } = useSelector(state => state.questionDetails);
    const { activity, changedLevel } = useSelector(state => state.activity);
    const { response, deletedComment, responseChoice, editedComment } = useSelector(state => state?.newResponse);
    const { ratedQuiz, deletedQuestion } = useSelector(state => state?.newQuestion);
    const { group } = useSelector(state => state.groupDetails);

    useEffect(() => {
        socket = io(ENDPOINT);
    }, []);

    useEffect(() => {
        socket.emit("join activity", { userId: user?._id, activity: activityId });
    }, []);

    useEffect(() => {
        function handleResize() {
            if (window.innerWidth >= 768) { 
                navigate(`/group/${id}`);
            }
        }
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        if (activityId && questionId) {
            dispatch(getSingleGroup(id, user?._id));
            dispatch(getSingleActivity(activityId));
            dispatch(getQuestionDetails(questionId));
            dispatch(getActivityRubric(activityId));
        }
    }, [activityId, questionId]);

    useEffect (() => {
        setAnswerStartTime(Date.now());
    }, []);

    useEffect(() => {
        if (response) {
            socket?.emit("new response", { userId: user?._id, activity: activityId });
            dispatch({ type: NEW_RESPONSE_RESET });
            dispatch(getQuestionDetails(questionId));
            setShowCommentModal(false);
        }
        if (ratedQuiz) {
            socket?.emit("new response", { userId: user?._id, activity: activityId });
            dispatch({ type: RATE_QUESTION_RESET });
            dispatch(getQuestionDetails(questionId));
            setShowModal(false);
        }
        if (deletedComment) {
            socket?.emit("new response", { userId: user?._id, activity: activityId });
            dispatch({ type: DELETE_COMMENT_RESET });
            dispatch(getQuestionDetails(questionId));
            setOpenModal(false);
        }
        if (editedComment) {
            socket?.emit("new response", { userId: user?._id, activity: activityId });
            dispatch({ type: EDIT_RESPONSE_RESET });
            dispatch(getQuestionDetails(questionId));
            setShowCommentModal(false);
        }
        if (deletedQuestion) {
            console.log("deleted")
            dispatch({ type: DELETE_QUESTION_RESET });
            navigate(`/${id}/${activityId}`)
        }
    }, [response, ratedQuiz, deletedComment, editedComment, deletedQuestion]);

    useEffect(() => {
        if (question) {
            const userRating = question?.reviews?.find(r => r.user.toString() === user._id.toString())
            if (userRating) {
                setRating(userRating?.rating);
            } else {
                setRating(0);
            }
        }
    }, [question]);

    useEffect(() => {
        if (changedLevel) {
            dispatch({ type: CHANGE_LEVEL_RESET });
            dispatch(getSingleActivity(activityId));
            socket?.emit("activity update", { userId: user?._id, activity: activityId });
        }
    }, [changedLevel]);

    useEffect(() => {
        socket?.on("mode received", updatedMode => {
            dispatch(getSingleActivity(activityId));
        })
    }, []);

    useEffect(() => {
        socket?.on("response received", updatedResponse => {
            dispatch(getQuestionDetails(questionId));
        })
    }, []);

    useEffect(() => {
        if (responseChoice) {
            dispatch({ type: NEW_RCHOICE_RESET });
            dispatch(getQuestionDetails(questionId));
        }
    }, [responseChoice]);

    const timeType = typeStartTime && typeEndTime ? typeEndTime - typeStartTime : null;

    const handleComment = (e) => {
        e.preventDefault();
        if (!selectedComment) {
            const question = questionId;
            const creator = user._id;
            const rating = ''; // During commenting we are not rating the question
            const timeThink = answerStartTime ? Date.now() - answerStartTime : null;
            dispatch(createResponse(creator, answer, question, rating, timeThink, timeType));
            setAnswer('');
            setAnswerStartTime(null);
        } else {
            dispatch(editResponse({ id: selectedComment._id , content: answer}));
            setAnswer('');
            setAnswerStartTime(null);
        }
    };

    const handleAnswerKeyDown = () => {
        if (!typeStartTime) {
            setTypeStartTime(Date.now());
        }
        setTypeEndTime(Date.now());
    };

    const submitQuestionRating = () => {
        if (!rating) {
            return
        }
        const creator = user._id;
        const question = questionId;
        dispatch(rateQuestion(creator, rating, question));
    };

    const handleCheck = () => {
        if (selectedChoice.length === 0) {
            return
        };
        if (Array.isArray(question?.correctAnswer)) {
            if (question?.correctAnswer.length === selectedChoice.length && question?.correctAnswer.every( ai => selectedChoice.includes(ai) )) {
                setCorrect(1);
            } else if (question?.correctAnswer.some( ai => selectedChoice.includes(ai) )){
                setCorrect(2);
            } else {
                setCorrect(3);
            }
        } else {
            if (selectedChoice.length === 1 && selectedChoice.includes(question?.correctAnswer)) {
                setCorrect(1);
            } else {
                setCorrect(3);
            }
        }
        const choices = selectedChoice;
        const creator = user?._id;
        const quiz = question?._id;
        dispatch(createResponseChoice(creator, quiz, choices));
        setShowCheck(true);
    };

    const alphabetMapper = (idX) => {
        return ChoiceMapper.find(i => i?.key === (idX + 1))?.value
    };

    const alphabetMapperReverse = (idX) => {
        return ChoiceMapper.find(i => i?.value === idX)?.key - 1
    };

    const deleteCommentHandler = () => {
        dispatch(deleteComment({ id: selectedComment._id, deletedBy: user?._id }));
        setSelectedComment(null);
        setShowActions(false);
    }

    const deleteQuestionHandler = () => {
        dispatch(deleteQuestion({ id: question?._id }));
        // setQuestionConfirm(false);
    }

    const handleRateClick = () => {
        if (activity?.activityType === 'OrganizerPaced' && activity?.level !== 'solve') {
            return;
        }
        setShowCommentModal(false); 
        setShowModal(!showModal);
    };

    const handleCommentClick = () => {
        if (activity?.activityType === 'OrganizerPaced' && activity?.level !== 'solve') {
            return;
        }
        if (!showCommentModal && !showActions) {
            setAnswer('');
            setSelectedComment(null);
        }
        setShowModal(false); 
        setShowCommentModal(!showCommentModal);
    };

    const handleVoteClick = (choice, activeChoice) => {
        const users = responseChoices?.filter(res => res?.choices?.includes(choice));
        navigate(`/${id}/${activityId}/${questionId}/voters`, {state:{choices: question?.choice, responseChoices: responseChoices}});
    };

    const correctAnswerContent = question?.choice?.length ? (Array.isArray(question?.correctAnswer) ? question?.correctAnswer?.map((element) => ({ ...question?.choice[alphabetMapperReverse(element)] })) : question?.choice[(alphabetMapperReverse(question?.correctAnswer))]) : '';
    const UserUrl = question?.creator?.avatar?.url ? (question?.creator?.avatar?.public_id === null ? (baseUrl + question?.creator?.avatar?.url) : question?.creator?.avatar?.url) : Default;
    const isSolve = (activity?.activityType === 'StudentPaced' || (activity?.activityType === 'OrganizerPaced' && activity?.level === 'solve'));
    const isSolveOnly = ((activity?.activityType === 'OrganizerPaced' && activity?.level === 'solve'));
    const isCreateLockSolve = (activity?.activityType === 'StudentPaced' || (activity?.activityType === 'OrganizerPaced' && (activity?.level === 'create' || activity?.level === 'solve' || activity?.level === 'locked')));
    const isReview = (activity?.activityType === 'StudentPaced' || (activity?.activityType === 'OrganizerPaced' && activity?.level === 'review'));
    const allChoices = responseChoices?.flatMap(obj => obj?.choices);

  return (
    <div className='min-h-screen flex flex-col'>
    <MobileLayout>
        <div className='flex items-center border-b activityNames border-gray-200 p-3'>
            <MdKeyboardArrowLeft onClick={() => navigate(`/${id}/${activityId}`)} />
            <p className='flex-1 block overflow-y overflow-x-hidden break-words' translate="no">{activity?.name}</p>
        </div>
        <ModeComponent />
        {question?._id &&
        <div className='flex flex-col gap-3 border-b border-gray-200 p-3'>
            <div className='flex justify-between'>
                {!activity?.hideUsernames && 
                <div className='flex flex-row gap-2 items-center'>
                    <img className="imagequestion1 inline-flex" src={UserUrl} alt='' />
                    <p className='questionAuthor' translate="no">{question?.creator?.firstName + " " + question?.creator?.lastName}</p>
                </div>}
                {((group?.role === 2 || group?.role === 4) || (question?.creator?._id === user?._id)) &&
                <HiOutlineDotsHorizontal onClick={() => setShowQuestionActions(true)} />}
                {/* <p className='questionAuthor'>{moment(question?.createdAt).utc().format('MM/DD/YYYY')}</p> */}
            </div>
            <img className='max-h-[220px] w-full object-cover object-center' src={question?.questionImage?.public_id === null ? (baseUrl + question?.questionImage?.url) : question?.questionImage?.url} alt='' />
            <p className='questionContentq' translate="no">{question?.content}</p>
            {question?.questionType === "multiple_choice" && (responseChoices?.filter(r => r?.creator?._id === user?._id)?.length >= 1) && 
             <div className='yourAnswerLabel'>
                <p className='text-[#2D3648]'>Answer you chose: {(responseChoices?.filter(r => r?.creator?._id === user?._id))[0]?.choices?.map((element) => ({ ...question?.choice[alphabetMapperReverse(element)] }))?.map((obj) => obj?.choice)?.join(', ')}</p>
                <p className='text-[#02CC3B]'>Correct Answer: {correctAnswerContent?.map((obj) => obj?.choice)?.join(', ')}</p>
            </div>}
            <div className='flex justify-between gap-2'>
                {!question?.ratings ?
                <div></div> :
                <div className='flex flex-row'>
                    <img className="w-4 h-4 rounded-full" src={StarIcon} alt='Average rating' />
                    <p className="questionAuthor">(Avg. {Number.isInteger(question?.ratings) ? question?.ratings : question?.ratings.toFixed(1)})</p>
                </div>}
                <div className={`flex flex-row gap-2 
                    ${isSolve ? 'text-[#2D3648]' : 'text-[#9297A1]'}`}>
                    {(question?.creator?._id !== user?._id) && 
                    <div onClick={handleRateClick} className='flex flex-row bg-[#CBD2E0] rounded-md p-1 gap-1'>
                        <FiStar />
                        <p className='quizButtonLabel'>Rate</p>
                    </div>}
                    {!(activity?.activityType == "OrganizerPaced" && question?.questionType === "multiple_choice") && 
                    <div onClick={handleCommentClick} className='flex flex-row bg-[#CBD2E0] rounded-md p-1 gap-1'>
                        <AiOutlineComment />
                        <p className='quizButtonLabel'>{activity?.activityType == "OrganizerPaced" ? "Answer" : "Comment"}</p>
                    </div>}
                </div>
            </div>
        </div>}

        {questionConfirm && <QuestionConfirm setQuestionConfirm={setQuestionConfirm} deleteQuestionHandler={deleteQuestionHandler} />}

        {question?.choice?.length >= 1 && (activity?.activityType === 'OrganizerPaced' && activity?.level === 'review') &&
        <div className='flex flex-col gap-3 p-3'>
            {question?.choice?.map((choice, index) => 
            <div key={index} className='flex flex-col'>
                <div className='flex justify-between w-full responseReviewLabel'>
                    <p>{choice?.choice}</p>
                    <p>{allChoices?.reduce((total,x) => (x===alphabetMapper(index) ? total+1 : total), 0) / allChoices?.length * 100}%</p>
                </div>
                <div className="w-full h-2 bg-gray-200 rounded-full overflow-hidden">
                    <div onClick={() => handleVoteClick(alphabetMapper(index), choice?.choice)} 
                        className={`h-full bg-blue-500 rounded-full`} style={{ width: `${allChoices?.reduce((total,x) => (x===alphabetMapper(index) ? total+1 : total), 0) / allChoices?.length * 100}%` }}></div>
                </div>
            </div>
            )}
        </div>
        }

        {question?.choice?.length >= 1 && isCreateLockSolve && (responseChoices?.filter(r => r?.creator?._id === user?._id)?.length < 1) &&
        <div className='flex flex-col gap-3 p-3'>
            {question?.choice?.map((choice, index) => 
            <div key={index} onClick={() => {isSolve && setSelectedChoice(selectedChoice?.includes(alphabetMapper(index)) ? selectedChoice?.filter(f => f !== alphabetMapper(index)) : [...selectedChoice, alphabetMapper(index)]); setShowCheck(false);}} className={`flex justify-center rounded-[10px] mobChoiceContent ${selectedChoice?.includes(alphabetMapper(index)) ? 'bg-[#7aa6ff80] border-[#7AA6FF]' : 'border-[#7c849480]'} border-2 w-full py-2`}>
                {choice?.choice}
            </div>
            )}
        </div>
        }
        {question?.choice?.length >= 2 && (responseChoices?.filter(r => r?.creator?._id === user?._id)?.length < 1) && isSolve &&
        <div onClick={handleCheck} className='flex flex-col gap-3 p-3'>
            <div className='flex justify-center rounded-[10px] mobChoiceContent bg-[#CBD2E0] w-full py-2'>
                Submit
            </div>
        </div>}
        {!loading && comments?.length === 0 && group?.role === 3 && activity?.activityType === 'StudentPaced' && 
        <div className='min-h-[200px] px-3 flex items-center justify-center w-full'>
            <p className='viewCommentLabel text-center'>
                You are the first student to make a comment on this question. 
            </p>
        </div>}
        {comments?.length !== 0 && isReview &&
        <div className="relative pb-3 pt-5 flex-1 min-h-[150px]">
            {!comments?.some(r => r.creator._id === user._id) && group?.role === 3 && activity?.activityType === 'StudentPaced' && 
            <>
            <div className="blur-sm w-full h-full absolute top-0 left-0 backdrop-filter backdrop-blur-sm"></div>
            <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white flex flex-col w-3/4 items-center text-center">
                <BiLockAlt className='text-black' />
                <p className="blurLabel">To view others' answers, please submit your answer first.</p>
            </div>
            </>}
            {comments.map((comment, index) => 
                <div key={index}>
                {comment?.isDeleted === true ?
                <div className='flex flex-col bg-[#CBD2E0] bg-opacity-20 w-full py-3 mb-5'>
                    <div className='flex flex-row gap-2 px-3 mb-1'>
                        <img className="imagequestion1 inline-flex" src={comment?.creator?.avatar?.url ? (comment?.creator?.avatar?.public_id === null ? (baseUrl + comment?.creator?.avatar?.url) : comment?.creator?.avatar?.url) : Default} alt='' />
                        <p className='responseAuthor' translate="no">{comment?.creator?.firstName + " " + comment?.creator?.lastName}</p>
                    </div>
                    <div className='flex flex-row gap-2 justify-center text-center'>
                        <AiOutlineStop />
                        <p className='deletedCommentLabel'>{comment?.creator?._id === comment?.deletedBy ? "This comment was deleted by the author." : "This comment was deleted by an admin."}</p>
                    </div>
                </div> :
                <div className='flex flex-row gap-2 px-3 mb-5'>
                    <img className="imagequestion1 inline-flex" src={comment?.creator?.avatar?.url ? (comment?.creator?.avatar?.public_id === null ? (baseUrl + comment?.creator?.avatar?.url) : comment?.creator?.avatar?.url) : Default} alt='' />
                    <p className='responseAuthor' translate="no">{comment?.creator?.firstName + " " + comment?.creator?.lastName}</p>
                    <p className='flex-1 responseContent block overflow-y overflow-x-hidden break-words' translate="no">{comment?.content}</p>
                    {
                    ((group?.role === 2 || group?.role === 4) || comment?.creator?._id === user?._id) && 
                    <HiOutlineDotsHorizontal className='cursor-pointer' onClick={() => { setShowCommentModal(false); setSelectedComment(comment); setAnswer(comment?.content); setShowActions(true); }} />}
                </div>}
                </div>
            )}
            {openModal && <ConfirmDeleteComment setOpenModal={setOpenModal} deleteCommentHandler={deleteCommentHandler} setSelectedComment={setSelectedComment} />}
        </div>}
        {comments?.length !== 0 && isSolveOnly &&
        <div className="relative pb-3 pt-5 flex-1 min-h-[150px]">
            {comments.filter(r => r?.creator?._id === user?._id).map((comment, index) => 
                <div key={index}>
                {comment?.isDeleted === true ?
                <div className='flex flex-col bg-[#CBD2E0] bg-opacity-20 w-full py-3 mb-5'>
                    <div className='flex flex-row gap-2 px-3 mb-1'>
                        <img className="imagequestion1 inline-flex" src={comment?.creator?.avatar?.url ? (comment?.creator?.avatar?.public_id === null ? (baseUrl + comment?.creator?.avatar?.url) : comment?.creator?.avatar?.url) : Default} alt='' />
                        <p className='responseAuthor' translate="no">{comment?.creator?.firstName + " " + comment?.creator?.lastName}</p>
                    </div>
                    <div className='flex flex-row gap-2 justify-center text-center'>
                        <AiOutlineStop />
                        <p className='deletedCommentLabel'>This comment was deleted by an admin.</p>
                    </div>
                </div> :
                <div className='flex flex-row gap-2 px-3 mb-5'>
                    <img className="imagequestion1 inline-flex" src={comment?.creator?.avatar?.url ? (comment?.creator?.avatar?.public_id === null ? (baseUrl + comment?.creator?.avatar?.url) : comment?.creator?.avatar?.url) : Default} alt='' />
                    <p className='responseAuthor' translate="no">{comment?.creator?.firstName + " " + comment?.creator?.lastName}</p>
                    <p className='flex-1 responseContent block overflow-y overflow-x-hidden break-words' translate="no">{comment?.content}</p>
                </div>}
                </div>
            )}
        </div>}
        <CheckAnswer correct={correct} answer={correctAnswerContent} showCheck={showCheck} setShowCheck={setShowCheck} />
        <ActionsPop showActions={showActions} setShowActions={setShowActions} setOpenModal={setOpenModal} setShowCommentModal={setShowCommentModal} setAnswer={setAnswer} setSelectedComment={setSelectedComment} />
        <QuestionActions showQuestionActions={showQuestionActions} questionId={questionId} setQuestionConfirm={setQuestionConfirm} setShowQuestionActions={setShowQuestionActions} setOpenModal={setOpenModal} setShowCommentModal={setShowCommentModal} setAnswer={setAnswer} setSelectedComment={setSelectedComment} />
        <RateQuestion showModal={showModal} setShowModal={setShowModal} rating={rating} setRating={setRating} submitQuestionRating={submitQuestionRating} />
        <CommentQuestion answer={answer} setAnswer={setAnswer} showCommentModal={showCommentModal} handleComment={handleComment} handleAnswerKeyDown={handleAnswerKeyDown} selectedComment={selectedComment} setSelectedComment={setSelectedComment} />
    </MobileLayout>
    </div>
  )
}

export default SingleQuestion