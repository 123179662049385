import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSearchParams, useLocation, useNavigate } from 'react-router-dom';
import { getGroupUsers, searchDiscoverGroups, searchGroup } from '../../actions/groupActions';
import Navbar from '../layouts/Navbar';
import GroupCard from './GroupCard';
import noresults from '../../assets/noresults.png';
import { AiOutlineArrowLeft } from 'react-icons/ai';
import ReactGA from 'react-ga4';

const GroupSearch = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();

    const [query, setQuery] = useState('');

    const { groups, loading, discGroups } = useSelector(state => state.group);
    const { user } = useSelector(state => state.auth);
    const { users } = useSelector(state => state.groups);

    const groupName = new URLSearchParams(useLocation().search).get('groupName');
    const type = new URLSearchParams(useLocation().search).get('type');

    useEffect(() => {
        dispatch(getGroupUsers());
        if (type === 'groups' && groupName !== '') {
            dispatch(searchGroup(groupName, user._id));
        } else if (type === 'discover' && groupName !== '') {
            dispatch(searchDiscoverGroups(groupName, user._id));
        }
    }, [dispatch, groupName, user, type]);

    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: (location.pathname + location.search), title: "Search Groups" });
    }, [location]);

    const handleSubmit = (e) => {
        e.preventDefault();
        const search = [['groupName', query], ['type', type]];
        navigate({pathname: '/groups/search', search: `?${createSearchParams(search)}`,});
    };

  return (
    <div>
        <div className='hidden md:block'>
            <Navbar />
        </div>
        <div className='flex flex-row items-center px-4 pt-6 md:mt-[80px]'>
        <AiOutlineArrowLeft className='w-5 h-5 mr-3 cursor-pointer' onClick={() => navigate('/groups')} />
        <form className="flex items-center w-full"  onSubmit={handleSubmit} encType='multipart/form-data'>   
            <label htmlFor="simple-search" className="sr-only">Search</label>
            <div className="relative w-full">
                <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                    <svg aria-hidden="true" className="w-5 h-5 text-gray-500 dark:text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clipRule="evenodd"></path></svg>
                </div>
                <input type="text" id="simple-search" value={query} onChange={(e) => setQuery(e.target.value)} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-3xl focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-1.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Search" />
            </div>
        </form>
        </div>
        {!loading && groupName === '' && (type === 'groups' || 'discover') && <p className='pt-4 inactiveHeader'> Try searching for topics, keywords, or group codes </p>}
        {!loading && 
        <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6 pt-4 px-4 w-full'>
            {groups?.map((group, index) => 
                <GroupCard key={index} group={group} joinedGroup={true} gusers={users?.filter(dg =>  group?.group?._id === dg?.group?._id)} />
            )} 
        </div>}
        {!loading && 
        <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6 pt-4 px-4 w-full'>
            {discGroups?.map((group, index) => 
                <GroupCard key={index} group={group} joinedGroup={false} gusers={users?.filter(dg =>  group?._id === dg?.group?._id)} />
            )} 
        </div>}
        {!loading && groups?.length === 0 && type === 'groups' && groupName !== '' &&
        <div className='flex flex-col gap-4 pt-7 px-4 w-full items-center justify-center'>
            <img class="w-24 h-24" src={noresults} alt="No results" />
            <p className="boldHeader">Oops!! No Results Found</p>
            <p className='inactiveHeader'>Please check spelling or try different key words</p>
        </div>}
        {!loading && discGroups?.length === 0 && type === 'discover' && groupName !== '' &&
        <div className='flex flex-col gap-4 pt-7 px-4 w-full items-center justify-center'>
            <img class="w-24 h-24" src={noresults} alt="No results" />
            <p className="boldHeader">Oops!! No Results Found</p>
            <p className='inactiveHeader'>Please check spelling or try different key words</p>
        </div>}
    </div>
  )
}

export default GroupSearch