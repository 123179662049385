exports.GROUPS = "Groups";
exports.CREATE_ACTIVITY = "Create Activity";
exports.GROUP_SETTINGS = "Group Settings";
exports.ACTIVITY_NAME = "Activity Name";
exports.ACTIVITY_DESCRIPTION = "Activity Description";
exports.ACTIVITY_TYPE = "Activity Type";
exports.STUDENT_MODE = "Student Mode";
exports.STUDENT_MODE_DESC = "Student can create, answer and review questions at their individual pace";
exports.EXAM_MODE = "Exam Mode";
exports.EXAM_MODE_DESC = "The organizer controls the pace of the activity. All students are either in create-question mode, answer-question mode, or review mode.";
exports.SETTING = "Setting";
exports.HIDE_USERNAME = "Hide Username";
exports.HIDE_USERNAME_DESC = "Participants can see who created each question. To make questions anonymous, check this box.";
exports.VISIBLE = "Visible";
exports.VISIBLE_DESC = "Students can see all activities by default. To hide this activity from students, un-check this box.";
exports.SAVE = "Save";
exports.CANCEL = "Cancel";
exports.ACTIVITIES = "Activities";
exports.QUESTIONS = "Questions";
exports.RESULTS = "Results";
exports.REPORTS = "Reports";
exports.SETTINGS = "Settings";
exports.RESOURCES = "Resources";
exports.STUDENTS_CONNECTED = "students connected";
exports.STUDENT_TYPING = "student is typing a question...";
exports.LOCKED = "Locked";
exports.CREATE = "Create";
exports.SOLVE = "Solve";
exports.REVIEW = "Review";
exports.RATING_STAR = "Rating star";
exports.CREATE_QUESTION = "Create a Question";
exports.CREATE_NEW_QUESTION = "Create a new question";
exports.WORDS_SELECTED = "Words selected for you to use in your question are:";
exports.ENTER_QUESTION = "Enter a question";
exports.ENTER_QUESTION_HERE = "Enter question here...";
exports.UPLOAD_IMAGES = "Upload an image";
exports.ANSWER_TYPE = "Answer Type";
exports.MULTIPLE_CHOICE = "Multiple choice";
exports.OPEN_ENDED = "Open Ended";
exports.ANSWER_OPTION = "Answer option";
exports.SUBMIT_QUESTION = "Submit Question";
exports.YOUR_ANSWER = "Your answer";
exports.SELECT_A_CHOICE = "Select your answer choice";
exports.SUBMIT_ANSWER = "Submit answer";
exports.GENERAL = "General";
exports.MEMBERS = "Members";
exports.UPLOAD_FILE = "Upload file";
exports.UPLOAD_RESOURCE = "Upload Resource";
exports.UPLOAD = "Upload";
exports.GROUP_NAME = "Group Name";
exports.GROUP_DESCRIPTION = "Group Description";
exports.ADD_PHOTO = "Add Photo";
exports.GROUP_TYPE = "Group Type";
exports.SELECT = "Select";
exports.PUBLIC = "Public";
exports.PRIVATE = "Private";
exports.PASSCODE_CHARACTERS = "Passcode (4+ characters)";
exports.SAVE_CHANGES = "Save Changes";
exports.DELETE_GROUP = "Delete Group";
exports.CLOSE_MODAL = "Close modal";
exports.DELETE_GROUP_WARNING = "Are you sure you want to delete this group?";
exports.YES_SURE = "Yes, I'm sure";
exports.NO_CANCEL = "No, cancel";