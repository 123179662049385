import React, { useEffect, useState } from 'react'
import "./Question.css"
import { BsPencil } from 'react-icons/bs';
import { FiSettings } from 'react-icons/fi';
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import io from "socket.io-client";
import { getAllActivities } from '../../actions/activityActions';
import { CHANGE_MODE_RESET, NEW_ACTIVITY_RESET, UPDATE_ACTIVITY_RESET } from '../../constants/activityConstants';
import { getSingleGroup } from '../../actions/groupActions';
import { MdOutlineArrowBackIos, MdOutlineLibraryAdd } from 'react-icons/md';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { TbRuler } from 'react-icons/tb';
import { CiBasketball } from 'react-icons/ci';
import SelectImageModal from '../popovers/SelectImageModal';
import ReactGA from 'react-ga4';
import { useLocation } from 'react-router-dom';
import Default from '../../assets/groupdefault.jpg';
import { HiOutlineClock } from 'react-icons/hi';
import { BiTennisBall } from 'react-icons/bi';
import { ALL_QUESTIONS_RESET } from '../../constants/questionConstants';
import MobileLayout from '../layouts/MobileLayout';
const baseUrl = process.env.REACT_APP_IMAGES_BASEURL;

const ENDPOINT = process.env.REACT_APP_ENDPOINT
var socket;

const Question = () => {

    const [onlineUsers, setOnlineUsers] = useState([]);
    const [socketConnected, setSocketConnected] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);

    const dispatch = useDispatch();

    const { user } = useSelector(state => state.auth);
    const { group } = useSelector(state => state.groupDetails);
    const { members } = useSelector(state => state.groupMembers);
    const { activities, loading: actLoading } = useSelector(state => state.activities);
    const { createdActivity } = useSelector(state => state?.newActivity);
    const { editedActivity, updatedActivity } = useSelector(state => state.activity);
    const navigate = useNavigate();
    const location = useLocation();

    const creator = user?._id;
    let { groupId } = useParams();
    const owningGroup = groupId;

    const showToastMessage = (message) => {
        toast.success(message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000
        });
    };

    useEffect (() => {
        if (owningGroup) {
            dispatch(getSingleGroup(owningGroup, creator));
            dispatch(getAllActivities(owningGroup));
        } 
        
    }, [dispatch, owningGroup]);

    useEffect (() => {

        if (createdActivity) {
            showToastMessage("Activity created successfully!");
            dispatch({ type: NEW_ACTIVITY_RESET });
            dispatch(getAllActivities(owningGroup));
        }

        if (editedActivity) {
            showToastMessage("Activity updated successfully!");
            dispatch(getAllActivities(owningGroup));
            dispatch({ type: UPDATE_ACTIVITY_RESET });
        }

        if (updatedActivity) {
            dispatch({ type: CHANGE_MODE_RESET });
            dispatch(getAllActivities(owningGroup));
        }
        
    }, [createdActivity, updatedActivity, editedActivity]);

    useEffect(() => {
        if (group?.group?.name) {
            ReactGA.send({ hitType: "pageview", page: (location.pathname + location.search), title: group?.group?.name });
        }
    }, [location, group]);

    useEffect(() => {
        socket = io(ENDPOINT);
        socket.emit("setup", user);
        socket.on("connected", () => setSocketConnected(true));
    }, []);

    useEffect(() => {
        socket?.emit("addUser", { userId: creator, group: owningGroup });
        socket?.on("getUsers", (users) => {
            setOnlineUsers(users);
        });
    }, [creator, owningGroup]);

    useEffect(() => {
        dispatch({ type: ALL_QUESTIONS_RESET });
    }, []);

  return (
    <>
    <MobileLayout>
    <div className={'border-b border-gray-200 md:hidden p-3'}>
        <p className='activitiesLabel'>Activities</p>
    </div>
    {(group?.role === 2 || group?.role === 4) && 
    <Link to={`/${groupId}/activity/create`} 
        className={'createGroupBtn flex md:hidden flex-row gap-3 bg-[#E8EAED] rounded-lg px-5 py-3 fixed z-90 bottom-10 right-8'}>
        <p>Create Activity</p>
        <MdOutlineLibraryAdd className='w-[12px] h-[12px]' />
    </Link>}
    <ToastContainer autoClose={1000} />
    <div className='grid grid-cols-10'>
        <div className={"col-span-10 md:col-span-3 div1 md:flex"}>
            <div className='div1row2 py-4 w-full'>
                <div className='activitydiv w-full'>
                    {activities ? (
                        activities.map((activity, index) =>
                            <div key={index} onClick={() => navigate(`/${groupId}/${activity._id}`)} className='flex flex-row w-full px-4 pt-2 pb-4 cursor-pointer border-b border-gray-200'>
                                <p className='activityNameLabel w-4/6' translate="no">{activity?.name}</p>
                                <div className='w-2/6 relative'>
                                    <p className={(activity?.activityType == "OrganizerPaced" ? ' bg-[#68758F] text-[#FFFF] ' : ' bg-[#aebad180] text-[#68758F] ') + " absolute right-0 flex flex-row gap-1 items-center activitytypen px-1 py-2 w-auto rounded-[20px] truncate"}>
                                        {activity?.activityType == "OrganizerPaced" ? <HiOutlineClock /> : <BiTennisBall />}
                                        {activity?.activityType == "OrganizerPaced" ? "Exam Mode" : "Student Mode"}
                                    </p>
                                </div>
                            </div>
                        )
                    ) : null}
                    {activities?.length === 0 && actLoading === false && <div className='flex flex-col items-center w-full'>
                        
                        <div className='flex w-[62px] h-[62px] rounded-full bg-[#777777]'>
                            <div className='w-full h-full relative'>
                                <BsPencil className='absolute right-2 bottom-4 h-7 w-6' />
                                <TbRuler className='absolute right-1 -top-2 h-6 w-6' />
                                <CiBasketball className='absolute left-1 -top-2 h-6 w-6' />
                            </div>
                        </div>
                        <p className='noActivity'>No Activities Yet</p>
                    </div>}

                </div>
            </div>
        </div>
        
    </div>
    </MobileLayout>
    </>
  )
}

export default Question