import React, { useState } from 'react'
import { FiStar } from 'react-icons/fi';
import { useSelector } from 'react-redux';

const RateComponent = ({ rating, setRating}) => {
    const [hoverRating, setHoverRating] = useState(0);
    const [previousRating, setPreviousRating] = useState(0);    
    const { rubric } = useSelector(state => state.activityRubric);

    const handleMouseEnter = (hoveredRating) => {
        setHoverRating(hoveredRating);
        setPreviousRating(rating);
        setRating(hoveredRating);
    };
    
    const handleMouseLeave = () => {
        setRating(previousRating);
        setHoverRating(0);
        setPreviousRating(0);
    };
    
    const handleClick = (selectedRating) => {
        setRating(selectedRating);
        setPreviousRating(selectedRating);
    };

  return (
    <div className='flex flex-row w-full gap-1'>
        {[1, 2, 3, 4, 5].map((star) => (
            <FiStar
                key={star}
                fill={(star <= rating || star <= hoverRating) ? '#2D3648' : '#F8F9FA'}
                onClick={() => handleClick(star)}
                className={`w-5 h-5 text-[#CBD2E0] normalStar cursor-pointer ${(star <= rating) ? 'active' : ''}`}
                onMouseEnter={() => handleMouseEnter(star)}
                onMouseLeave={handleMouseLeave}
                title={star === 1 ? (rubric?.gradeLevels?.oneStar || "Needs severe improvement") :
                    star === 2 ? (rubric?.gradeLevels?.twoStar || "Lacking in skill; Can improve in several places") :
                    star === 3 ? (rubric?.gradeLevels?.threeStar || "Doing okay; Can do better but is working hard") :
                    star === 4 ? (rubric?.gradeLevels?.fourStar || "Great job; room for small improvements") :
                    (rubric?.gradeLevels?.fiveStar || "Amazing job; Keep up the great work!")}
            />
        ))}
    </div>
  )
}

export default RateComponent