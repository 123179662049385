import React, { Fragment } from 'react'
import { Routes, Route, Navigate, Outlet, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'

const ProtectedRoute = ({ allowedRoles }) => {

    const location = useLocation();

    const { isAuthenticated, loading, user } = useSelector(state => state.auth);
    // let isEmpty = Object.keys(user).length === 0;

    return (
        <Fragment>
            {/* {allowedRoles.includes(user?.roleId) ? <Outlet /> : <Navigate to="/login" state={{ from: location }} replace />} */}
            {allowedRoles.includes(user?.roleId) ? <Outlet /> : window.location.replace('/login')}
            {/* if (isAdmin === true) {
                isAdmin === true && user.roleId !== 2 ? <Outlet /> : <Navigate to="/login" />
            } */}
            
        </Fragment>
    )
}

export default ProtectedRoute
