import React, { useEffect } from 'react'
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Navbar from '../components/layouts/Navbar';
import ReactGA from 'react-ga4';
import { useLocation } from 'react-router-dom';

const baseUrl = process.env.REACT_APP_IMAGES_BASEURL;

const Profile = () => {

    const location = useLocation();

    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: (location.pathname + location.search), title: "Profile view" });
    }, [location]);

    const { user, loading } = useSelector(state => state.auth);
    
  return (
    <>
    <Navbar />
    <div className='flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8'>
        <div className='registerblock'>
            <div className='registermain'>
                <div className='heading'>
                    <h1 className='headingsign'>My SMILE Account</h1>
                </div>
                <div className='registerscreen'>
                    <div className='profilediv'>
                        <div className='profileimg' style={{ 
                            backgroundImage: `url(${user?.avatar?.public_id === null ? (baseUrl + user?.avatar?.url) : user?.avatar?.url})`,
                            backgroundPosition: 'center',
                            backgroundSize: 'cover',
                            backgroundRepeat: 'no-repeat'
                            }}>
                        </div>
                    </div>
                    <div className=''>
                        <label for="firstName" className='fnamelabel'>First name: {user?.firstName}</label>
                        <p className='py-3'></p>
                        <label for="lastName">Last name: {user?.lastName}</label>
                        <p className='py-3'></p>
                        <label for="username" className=''>Username: {user?.username}</label>
                        <p className='py-3'></p>
                        <label for="lname" className=''>Email: {user?.email}</label>
                        <p className='py-3'></p>
                        <button className='signupsubmitbtn'>
                            <Link to='/profile/update'>Edit my profile </Link>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </>
  )
}

export default Profile