import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './App.css';
import Login3 from './components/Login3/Login3';
import Register from './components/Register/Register';
import ProtectedRoute from './components/route/ProtectedRoute';
import { GoogleOAuthProvider } from '@react-oauth/google';
import Group from './components/Group/Group';
import ForgotPassword from './components/ForgotPassword/ForgotPassword';
import ResetPassword from './components/ResetPassword/ResetPassword';
import ForgotUsername from './components/forgotusername/ForgotUsername';
import Profile from './pages/Profile';
import UpdateProfile from './pages/UpdateProfile';
import QuestionLog from './pages/QuestionLog';
import Dashboard from './components/layouts/admin/Dashboard';
import LearnSmile from './pages/static/LearnSmile';
import Resources from './pages/static/Resources';
import Privacy from './pages/static/Privacy';
import Terms from './pages/static/Terms';
import LoginAdmin from './components/layouts/admin/LoginAdmin';
import AdminRoute from './components/route/AdminRoute';
import CreateAdmins from './components/layouts/admin/CreateAdmins';
import ListAdmins from './components/layouts/admin/ListAdmins';
import Home from './pages/static/Home';
import SmileVideos from './pages/static/SmileVideos';
import EditAdmin from './components/layouts/admin/EditAdmin';
import ContactUs from './pages/static/ContactUs';
import GroupSearch from './components/Group/GroupSearch';
import Roles from './components/layouts/admin/Roles';
import ViewRole from './components/layouts/admin/ViewRole';
import CreateRole from './components/layouts/admin/createRole';
import UpdateAdmin from './components/layouts/admin/UpdateAdmin';
import ListUsers from './components/layouts/admin/ListUsers';
import ListActivities from './components/layouts/admin/ListActivities';
import ListGroups from './components/layouts/admin/ListGroups';
import GroupMemberships from './components/layouts/admin/GroupMemberships';
import CreateGroup from './components/Group/CreateGroup';
import TimeQuestionList from './components/layouts/admin/TimeQuestionList';
import TimeAnswerList from './components/layouts/admin/TimeAnswerList';
import Report from './components/layouts/admin/Report';
import CreateActivity from './components/activity/CreateActivity';
import Prompter from './components/activity/Prompter';
import UpdateActivity from './components/activity/UpdateActivity';
import GroupSettings from './components/Group/Settings';
import GroupGeneral from './components/Group/General';
import GroupMembers from './components/Group/Members';
import GroupResources from './components/Group/Resources';
import GroupToggle from './pages/Group/GroupToggle';
import Activity from './components/activity/Activity';
import { useEffect } from 'react';
import ReactGA from 'react-ga4';
import CreateQuestion from './components/Question/CreateQuestion';
import SingleQuestion from './components/Question/SingleQuestion';
import Logout from './pages/Authentication/Logout';
import VoterComponent from './components/Question/VoterComponent';
import MobileReports from './components/activity/MobileReports';
import EditQuestion from './components/Question/EditQuestion';

function App() {

    useEffect(() => {
        ReactGA.initialize(process.env.REACT_APP_GA_MEASUREMENT_ID);
    }, []);

  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_CLIENT_PUBLIC_GOOGLE_API_TOKEN}>
      <BrowserRouter>
        <Routes>

          {/* Protected routes */}
            <Route element={<ProtectedRoute allowedRoles={[1]} />}>
                <Route path="/groups" element={<Group />} />
                <Route path="/group/:groupId" element={<GroupToggle />} exact />
                <Route path="/profile" element={<Profile />} />
                <Route path="/profile/update" element={<UpdateProfile />} />
                <Route path="/my/questions" element={<QuestionLog />} />
                <Route path="/groups/search" element={<GroupSearch />} />
                <Route path="/groups/create" element={<CreateGroup />} />
                <Route path="/:id/:activityId/create" element={<CreateActivity />} />
                <Route path="/:id/:activityId/update" element={<UpdateActivity />} />
                <Route path="/:id/:activityId" element={<Activity />} />
                <Route path="/:id/:activityId/prompter" element={<Prompter />} />
                <Route path="/:id/settings" element={<GroupSettings />} />
                <Route path="/:id/settings/general" element={<GroupGeneral />} />
                <Route path="/:id/settings/members" element={<GroupMembers />} />
                <Route path="/:id/settings/resources" element={<GroupResources />} />
                <Route path="/:id/:activityId/questions/create" element={<CreateQuestion />} />
                <Route path="/:id/:activityId/:questionId/edit" element={<EditQuestion />} />
                <Route path="/:id/:activityId/:questionId/question" element={<SingleQuestion />} />
                <Route path="/:id/:activityId/:questionId/voters" element={<VoterComponent />} />
                <Route path="/:id/:activityId/reports" element={<MobileReports />} />
            </Route>

            <Route element={<AdminRoute allowedRoles={[2]} />}>
                <Route path="/admin/dashboard" element={<Dashboard />} />
                <Route path="/admin/edit/profile" element={<EditAdmin />} />
                <Route path="/admin/create/admins" element={<CreateAdmins />} />
                <Route path="/admin/all/admins" element={<ListAdmins />} />
                <Route path="/admin/roles" element={<Roles />} />
                <Route path="/admin/roles/:role" element={<ViewRole />} />
                <Route path="/admin/roles/create" element={<CreateRole />} />
                <Route path="/admin/admins/:id" element={<UpdateAdmin />} />
                <Route path="/admin/users" element={<ListUsers />} />
                <Route path="/admin/activities" element={<ListActivities />} />
                <Route path="/admin/groups" element={<ListGroups />} />
                <Route path="/admin/memberships/:id" element={<GroupMemberships />} />
                <Route path="/admin/questions" element={<TimeQuestionList />} />
                <Route path="/admin/answers" element={<TimeAnswerList />} />
                <Route path="/admin/reports" element={<Report />} />
            </Route>

            {/* Public routes */}
            <Route path="/register" element={<Register />} />
            <Route path="/login" element={<Login3 />} />
            <Route path="/forgot/password" element={<ForgotPassword />} />
            <Route path="/forgot/username" element={<ForgotUsername />} />
            <Route path="/password/reset/:token" element={<ResetPassword />} />
            <Route path="/logout" element={<Logout />} />

            {/* Admin routes */}
            <Route path="/admin/login" element={<LoginAdmin />} />

            {/* Static Routes */}
            <Route path="/about/smile" element={<LearnSmile />} />
            <Route path="/smile/research" element={<Resources />} />
            <Route path="/" element={<Home />} />
            <Route path="/smile/videos" element={<SmileVideos />} />
            <Route path="/smile/privacy" element={<Privacy />} />
            <Route path="/smile/terms" element={<Terms />} />
            <Route path="/contactus" element={<ContactUs />} />
          
        </Routes>
      </BrowserRouter>
    </GoogleOAuthProvider>
  );
}

export default App;
