import React from 'react';
import { AiOutlineSetting } from 'react-icons/ai';
import { BsGraphUpArrow } from 'react-icons/bs';
import { MdClose } from 'react-icons/md';

const ActivityMenu = ({ setShowMenu, showMenu, handleSettingsClick }) => {

  return (
    <div className={`w-full pb-6 z-90 bottom-0 shadow-md right-0 left-0 bg-[#F8F9FA] fixed transition-all duration-500 transform ${showMenu ? 'translate-y-0 opacity-100' : 'translate-y-full opacity-0 invisible'}`}>
        <div className='actionHeader h-[50px] border-b border-gray-200 px-4 flex justify-between items-center'>
            <div></div>
            <p>Action</p>
            <MdClose onClick={() => setShowMenu(false)} />
        </div>
        <div className="w-full flex flex-col gap-6 px-6 mt-6 justify-center">
            <div className='flex flex-col'>
                <div className="flex items-center mb-6">
                    <AiOutlineSetting />
                    <div className="ml-2 popoverLabel" onClick={handleSettingsClick}>Activity Setting</div>
                </div>
                <div className="flex items-center">
                    <BsGraphUpArrow />
                    <p className="ml-2 popoverLabel">Reports</p>
                </div>
            </div>
        </div>
    </div>
  )
}

export default ActivityMenu