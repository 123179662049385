import React, { useEffect, useState } from 'react'
import Navbar from './Navbar'
import dalle3 from '../../assets/dalle-videos.jpeg';
import ReactGA from 'react-ga4';
import { useLocation } from 'react-router-dom';

const SmileVideos = () => {
    const location = useLocation();

    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: (location.pathname + location.search), title: "SMILE Videos" });
    }, [location]);

  const videos = [
    { id: 'https://www.youtube.com/embed/dFDp-PWAcrQ', title: 'What is SMILE?' },
    { id: 'https://www.youtube.com/embed/na_n7gGKPSQ', title: 'Talk to Stanford SMILE (AI COACH DEVELOPED BY PAUL KIM) Stanford University' },
    { id: 'https://www.youtube.com/embed/HPmqBHNpE0I', title: 'SMILE (Stanford Mobile Interactive Learning Environment)-MedRIC-Korea-Part2' },
    { id: 'https://www.youtube.com/embed/GDL19HWQC1U', title: 'Introducing SMILE: Stanford Mobile Inquiry-Based Learning Environment' },
    { id: 'https://www.youtube.com/embed/_loCtB-9FiY', title: 'SMILE Consortium - Stanford Mobile Inquiry-based Learning Environment' },
    { id: 'https://www.youtube.com/embed/QrgdJ5p2Ah4', title: 'DNLE Week 8: S.M.I.L.E.' },
    { id: 'https://www.youtube.com/embed/-DQA_AKNSDQ', title: 'Paul Kim: Technology, Education and the People' },
    // { id: 'https://www.youtube.com/embed/vNCfAWTNLBE', title: 'S M I L E' },
    { id: 'https://www.youtube.com/embed/LXKYhmlwG7M', title: '스탠퍼드 대학교 한국인 부학장 폴 킴의 \'혁신을 위한 질문\'   스탠퍼드 대학교 부학장 폴 킴' },
    { id: 'https://www.youtube.com/embed/2VGarthGzOM', title: '전교 꼴찌 출신 스탠포드 교수의 교육 이야기 | 스탠포드 교육대학원 부학장 폴킴 [리얼밸리 시즌 2 EP 03]' },
    { id: 'https://www.youtube.com/embed/rrqbLW-0Kzk', title: '[제1회 AI융합교육포럼] Paul Kim(스탠포드대학교 교수)_AI의 교육적 활용: SMILE 개발 배경과 활용 방안' },
    // { id: 'https://www.youtube.com/embed/pTmZ9Uujmd0', title: 'Global HR Forum 2017   Paul Kim Assistant Dean, Stanford University 폴 킴 스탠퍼드대 교육대학원 부원장' },
    { id: 'https://www.youtube.com/embed/NaXihvvBC20', title: '21세기에 태어난 아이들에게 가장 중요한 학문은? │스탠퍼드대 교육대학원 부학장 폴 김 #월간커넥트2 EP.1 | tvN 220205 방송' },
  ];

  const [selectedVideo, setSelectedVideo] = useState(videos[0]);

  const onVideoClick = (video) => {
    setSelectedVideo(video);
  };

  return (
    <div style={{fontFamily: 'IBM Plex Sans',}}>
      <Navbar />

      <div
        className="bg-no-repeat bg-cover bg-center p-0 h-96"
        style={{
          backgroundImage: `url("${dalle3}")`,
        }}
      >
        <div className="bg-black bg-opacity-50 w-full h-full flex items-end justify-center">
          <p className="text-white text-4xl font-light p-8">SMILE Videos</p>
        </div>
      </div>

      <div className='container mx-auto flex flex-col md:flex-row mt-3'>
        <div className="video-player md:w-8/12 w-full p-4">
          {selectedVideo && (
            <iframe className='w-full aspect-video' src={selectedVideo.id} opts={{ width: '100%', height: '100%' }} title={selectedVideo.title} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
          )}
        </div>
        <div className="video-list md:w-4/12 w-full mt-4 ">
          {videos.map((video) => (
            <div
              key={video.id}
              className={`mb-2 p-4 cursor-pointer video-item ${selectedVideo && selectedVideo.id === video.id ? 'selected bg-[#8C1515] text-white' : 'bg-gray-100'}`}
              onClick={() => onVideoClick(video)}
            >
              {video.title}
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default SmileVideos